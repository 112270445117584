import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { makeVar } from "@apollo/client";
import * as dateFns from "date-fns";

import { TextTextingValidatedFormModel } from "../../../components/organisms/forms/texts-details-texting";
import { TextMessagesValidatedFormModel } from "../../../components/organisms/forms/texts-details-messages";
import { TextBonusValidatedFormModel } from "../../../components/organisms/forms/texts-details-bonus";
import { BoyGirl, ProductAlphaId, TextCreateInput } from "../../../types/graphql";
import { config } from "../../../config";
import { formatTextTime } from "../../../model/text";

import { basketState } from ".";
import { setState } from "./utils";

const CACHE_KEY = "state.textCreate";

interface ITextCreateState {
  details?: TextTextingValidatedFormModel;
  messages?: TextMessagesValidatedFormModel;
  bonus?: TextBonusValidatedFormModel;
  addOns?: ProductAlphaId[];
}

const getDefaultData = (): ITextCreateState => {
  const storedData = localStorage.getItem(CACHE_KEY);

  if (!storedData) {
    return {};
  }

  const data: ITextCreateState = JSON.parse(storedData);

  return {
    ...data,
    ...(data.messages &&
      data.messages.text2TimeDate &&
      data.messages.text3TimeDate && {
        messages: {
          ...data.messages,
          text1TimeDate: dateFns.parseISO(data.messages.text1TimeDate.toString()),
          text2TimeDate: dateFns.parseISO(data.messages.text2TimeDate.toString()),
          text3TimeDate: dateFns.parseISO(data.messages.text3TimeDate.toString()),
        },
      }),
  };
};

export const textCreateState = makeVar<ITextCreateState>(getDefaultData());

interface ITextState {
  setDetails(data: TextTextingValidatedFormModel): void;
  setMessages(data: TextMessagesValidatedFormModel): void;
  setBonus(data: TextBonusValidatedFormModel): void;
  clear(): void;
  addToBasket(withBonus: boolean): void;
}

export const textState = (): ITextState => {
  const setTextState = (value: ITextCreateState): void =>
    setState(textCreateState, CACHE_KEY, value);

  return {
    setDetails: details =>
      setTextState({
        ...textCreateState(),
        details,
      }),
    setMessages: messages =>
      setTextState({
        ...textCreateState(),
        messages,
      }),
    setBonus: bonus =>
      setTextState({
        ...textCreateState(),
        bonus,
      }),
    clear: () => setTextState({}),
    addToBasket: withBonus => {
      const { details, messages, bonus } = textCreateState();

      if (details && messages && messages.text2TimeDate && messages.text3TimeDate) {
        const formatTime = (date: Date, time: string): string =>
          formatTextTime(date, time, extractIdFromUrn(UrnResource.TIMEZONE, details.timezoneUrn));

        const text: TextCreateInput = {
          firstName: messages.firstName,
          boyGirl: messages.boyGirl as BoyGirl,
          ageYears: messages.ageYears,
          town: messages.town,
          ...(messages.petId && { petId: messages.petId }),
          ...(messages.petName && { petName: messages.petName }),
          templateId: messages.templateId,
          phoneCountryUrn: details.phoneCountryUrn,
          phoneNumber: details.phoneNumber,
          timezoneUrn: details.timezoneUrn,
          text1Time: formatTime(messages.text1TimeDate, messages.text1TimeTime),
          text1Body: messages.text1Body,
          text2Time: formatTime(messages.text2TimeDate, messages.text2TimeTime),
          text2Body: messages.text2Body,
          text3Time: formatTime(messages.text3TimeDate, messages.text3TimeTime),
          text3Body: messages.text3Body,
          ...(withBonus && bonus && { bonusBody: bonus.bonusBody }),
        };

        basketState().addToBasket({
          productAlphaId: ProductAlphaId.SANTA_TEXT,
          addOns: withBonus ? [ProductAlphaId.SANTA_TEXT_BONUS] : [],
          text,
        });

        if (config.features.clearTextAfterBasket) {
          setTextState({});
        }
      }
    },
  };
};
