import { ISelectMultilineOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelectMultiline } from "../../form-input-select-multiline";

interface IProps {
  id?: string;
  label?: string;
  required?: boolean;
  info?(): JSX.Element;
  options: ISelectMultilineOption[];
  placeholder?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getErrorMessage = (message: object): string => (message as any).value;

export const FormFieldTemplate: React.FC<IProps> = ({
  id = "template",
  label = "Choose letter story",
  placeholder = "Select story",
  required = true,
  options,
  info,
}) => (
  <FormFieldWrapper
    label={label}
    name={id}
    info={info}
    required={required}
    noLabel
    getErrorMessage={getErrorMessage}
  >
    <Field
      name={id}
      component={FormikFormInputSelectMultiline}
      placeholder={placeholder}
      options={options}
      noLabel={true}
    />
  </FormFieldWrapper>
);
