import { Visible } from "react-grid-system";
import { flatMap, uniq } from "lodash";
import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { Loader } from "../../atoms/loader";
import { Carousel } from "../../organisms/carousel";
import { isLoaded, notNull, getLocaleForApi } from "../../../utils/graphql";
import { getProductLookup } from "../../../model/basket";
import { ProductComboInfo } from "../../organisms/product-combo-info";
import { ProductAlphaId, useSantaLetterHomeQuery } from "../../../types/graphql";

const StyledCarousel = styled(Carousel)`
  @media ${device.mobileS} {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 345px;
  }

  @media ${device.tablet} {
    margin-top: 52px;
    margin-bottom: 90px;
    width: 700px;
  }

  @media ${device.laptopL} {
    margin-top: 52px;
    margin-bottom: 90px;
    width: 1116px;
  }
`;

// this is a list of lists of products to show in the carousel
// TODO: Change to constants
const productCombos: ProductAlphaId[][] = [
  [ProductAlphaId.SANTA_PLUSH, ProductAlphaId.MAGIC_SNOW],
  [ProductAlphaId.COOKIE_MIX, ProductAlphaId.MAGIC_SNOW],
];
const alphaIds = [...uniq(flatMap<ProductAlphaId>(productCombos)), ProductAlphaId.SANTA_LETTER];

interface IProps {
  onClickCreate(addOnIds: string[]): void;
}

export const LetterCarousel: React.FC<IProps> = ({ onClickCreate }) => {
  const { data } = useSantaLetterHomeQuery({ variables: { alphaIds, locale: getLocaleForApi() } });

  if (isLoaded(data, ["allProducts"])) {
    // create a lookup of products
    const productLookup = getProductLookup(notNull(data.allProducts!));

    // create a list of combo product items for the carousel
    const carouselItems = productCombos.map((c, i) => {
      const letterProduct = productLookup.SANTA_LETTER;

      if (!letterProduct) {
        return undefined;
      }

      interface IProduct {
        title: string;
        imageUrl: string;
        price: number;
      }

      const products: (IProduct | undefined)[] = [
        {
          title: letterProduct.title!,
          imageUrl: letterProduct.image!.url!,
          price: letterProduct.price!,
        },
        ...c.map(id => {
          // get the produce by id
          const product = productLookup[id];

          if (!product) {
            return undefined;
          }

          return {
            title: product.title!,
            imageUrl: product.image!.url!,
            price: product.price!,
          };
        }),
      ];

      const definedProducts = products.filter((p): p is IProduct => typeof p !== undefined);

      const handleClickProductCombo = (): void => onClickCreate(c);

      // eslint-disable-next-line react/jsx-no-bind
      return (
        // eslint-disable-next-line react/jsx-no-bind
        <ProductComboInfo key={i} products={definedProducts} onClick={handleClickProductCombo} />
      );
    });

    const definedCarouselItems = carouselItems.filter(
      (i): i is JSX.Element => typeof i !== undefined,
    );

    return (
      <>
        <Visible md lg xl>
          <StyledCarousel autoplay>{carouselItems}</StyledCarousel>
        </Visible>
        <Visible xs sm>
          <StyledCarousel autoplay arrowsAbove>
            {definedCarouselItems}
          </StyledCarousel>
        </Visible>
      </>
    );
  } else {
    return <Loader />;
  }
};
