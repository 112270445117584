import { SiteCountryCode } from "../../../types/graphql";
import { config } from "../../../config";

import { ReactComponent as RedLogoGb } from "./red-logo-gb.svg";
import { ReactComponent as YellowLogoGb } from "./yellow-logo-gb.svg";
import { ReactComponent as RedLogoUs } from "./red-logo-us.svg";
import { ReactComponent as YellowLogoUs } from "./yellow-logo-us.svg";

interface IProps {
  id?: string;
  type?: "yellow" | "red";
}

export const Logo: React.FC<IProps> = ({ type, id }) => {
  switch (config.site.country) {
    case SiteCountryCode.GB:
      switch (type) {
        case "yellow":
          return <YellowLogoGb data-santa-id={id} />;
        default:
          return <RedLogoGb data-santa-id={id} />;
      }
    default:
      switch (type) {
        case "yellow":
          return <YellowLogoUs data-santa-id={id} />;
        default:
          return <RedLogoUs data-santa-id={id} />;
      }
  }
};
