import { Field, Form, FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { Paragraph } from "../../../../atoms/text";
import { FormFieldWrapper } from "../../../form-field-wrapper";
import { FormikFormInputText } from "../../../../molecules/form/form-input-text";
import { BowButton } from "../../../../atoms/bow-button";
import { passwordWarning } from "../../../../../model/authentication";

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
`;

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .matches(/\d/, passwordWarning)
    .matches(/[a-z]/, passwordWarning)
    .matches(/[A-Z]/, passwordWarning)
    .required("Required"),
  passwordConfirm: Yup.string().test(
    "matchesTest",
    "Passwords must match",
    function (value?: string): boolean {
      return value ? this.parent.password === "" || value === this.parent.password : true;
    },
  ),
});

export interface IFormModel {
  code: string;
  password: string;
  passwordConfirm: string;
}

interface IOwnProps {
  formError?: string;
  onSubmit(code: string, password: string): void;
}

type Props = FormikProps<IFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const { submitForm, isValid } = props;

  return (
    <Form>
      {props.formError && <Error>{props.formError}</Error>}
      <FormFieldWrapper label="Code" name="code" required>
        <Field
          name="code"
          component={FormikFormInputText}
          placeholder="Enter the code we sent you"
          maxlength={50}
          required
          autoComplete="off"
        />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Create a Password"
        name="password"
        tooltip={passwordWarning}
        required
      >
        <Field
          name="password"
          type="password"
          component={FormikFormInputText}
          placeholder="Enter a new password"
          required
        />
      </FormFieldWrapper>
      <FormFieldWrapper label="Confirm password" name="passwordConfirm" required>
        <Field
          name="passwordConfirm"
          type="password"
          component={FormikFormInputText}
          placeholder="Confirm your password"
          required
        />
      </FormFieldWrapper>

      <SubmitContainer>
        <BowButton size={210} onClick={submitForm} disabled={!isValid}>
          Submit
        </BowButton>
      </SubmitContainer>
    </Form>
  );
};

export const ForgotPasswordCodeForm = withFormik<IOwnProps, IFormModel>({
  validationSchema,
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values.code, values.password);
  },
})(C);
