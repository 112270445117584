import styled from "styled-components";

import arrowImage from "./box-arrow.png";

const Wrapper = styled.div<Pick<IProps, "id">>`
  position: relative;
  display: inline-block;

  &:hover {
    > [data-santa-id="${({ id }): string => id}"] {
      display: block;
    }
  }
`;

const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  right: -20px;
`;

const Menu = styled.div`
  margin-top: 10px;
  min-height: 50px;
  background-color: white;
  border: 1px solid ${({ theme }): string => theme.colours.divider.simple};
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
  position: relative;

  > div {
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }): string => theme.colours.divider.simple};
    }
  }
`;

const Arrow = styled.img`
  width: 9px;
  height: 5px;
  position: absolute;
  top: -5px;
  right: 20px;
`;

interface IProps {
  id: string;
  items: JSX.Element[];
}

export const DropDownMenu: React.FC<IProps> = ({ children, items, id }) => (
  <Wrapper id={id}>
    {children}
    <MenuWrapper data-santa-id={id}>
      <Menu>
        <Arrow src={arrowImage} alt="Arrow" />
        {items}
      </Menu>
    </MenuWrapper>
  </Wrapper>
);
