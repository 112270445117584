import { IDescriptionAddOn, getAddOnDescription } from "../common";

interface IGetVideoDescriptionParams {
  addOnProducts: IDescriptionAddOn[];
  recipientName?: string | null;
}

/**
 * Return a description for an audio call given some parameters to describe it
 * @param params Params for call
 */
export const getVideoDescription = (params: IGetVideoDescriptionParams): string =>
  `for ${params.recipientName}.${getAddOnDescription(
    params.addOnProducts,
  )}. Link to video sent on 1st December or within 4 hours of purchase.`;
