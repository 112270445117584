import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { MobileBackLink } from "../../../../molecules/mobile-back-link";
import { StageTitle } from "../../../../atoms/stage-title";
import { Paragraph } from "../../../../atoms/text";
import { device } from "../../../../../utils/media-queries";
import { SantaLetterDetailsSendingForm } from "../../../../organisms/forms/santa-letter-details-sending";
import { Accordion } from "../../../../molecules/accordion";
import { LetterSectionTitle } from "../../../../molecules/letter-section-title";
import { DataLoadedContainer } from "../../../../control/data-loaded-container";

import { ISurcharge, useData } from "./hooks/use-data";

const TitleCol = styled(Col)`
  h2 {
    margin-bottom: 5px;
  }

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`;

const Subtitle = styled(Paragraph)`
  margin-bottom: 20px;
`;

export interface ILocationState {
  address?: {
    line1: string;
    line2: string;
    town: string;
    county: string;
    usStateUrn: string;
    postcode: string;
    countryUrn: string;
  };
}

interface IProps {
  updateSendingDetails(): void;
  onClickOpenMobilePreview(): void;
  internationalSurcharge?: ISurcharge;
}

export const SantaLetterDetailsSending: React.FC<IProps> = ({
  internationalSurcharge,
  updateSendingDetails,
  onClickOpenMobilePreview,
}) => {
  const { formProps, faqs, isLoading, refetch } = useData(
    updateSendingDetails,
    internationalSurcharge,
  );

  return (
    <>
      <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

      <Row>
        <TitleCol>
          <LetterSectionTitle />
          <StageTitle totalStages={3} currentStage={1}>
            Santa needs...
          </StageTitle>
          <Visible xs>
            <Subtitle>(you can select story template next step)</Subtitle>
          </Visible>
        </TitleCol>
      </Row>

      <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
        {faqs && <Accordion items={faqs} />}
      </DataLoadedContainer>

      <SantaLetterDetailsSendingForm
        {...formProps}
        onClickOpenMobilePreview={onClickOpenMobilePreview}
      />
    </>
  );
};
