import { useCallback } from "react";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";

import { Paragraph } from "../../../atoms/text";
import {
  FormSectionCallRecipient,
  validationRules as recipientValidationRules,
  IDefaultValuesModel as RecipientDefaultValuesModel,
} from "../../form-sections/call/form-section-call-recipient";
import {
  FormSectionCallCalling,
  videoValidationRules as videoCallingValidationRules,
  audioValidationRules as audioCallingValidationRules,
  IDefaultValuesModel as CallingDefaultValuesModel,
} from "../../form-sections/call/form-section-call-calling";
import { FormSubmitRow } from "../../../molecules/form-submit-row";
import { CallType } from "../../../../types/graphql";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const audioValidationSchema = yup.object({
  ...recipientValidationRules,
  ...audioCallingValidationRules,
});
export type AudioFormModel = yup.Asserts<typeof audioValidationSchema>;

const videoValidationSchema = yup.object({
  ...recipientValidationRules,
  ...videoCallingValidationRules,
});
export type VideoFormModel = yup.Asserts<typeof videoValidationSchema>;

const getValidationSchema = (
  type: CallType,
): typeof audioValidationSchema | typeof videoValidationSchema =>
  type === CallType.AUDIO ? audioValidationSchema : videoValidationSchema;

type DefaultFormModel = RecipientDefaultValuesModel & CallingDefaultValuesModel;

interface IOwnProps {
  type: CallType;
  defaultValues: DefaultFormModel;
  formError?: string;
  isSubmitting: boolean;
  dates?: Date[];
  isDatesLoading: boolean;
  slotOptions?: ISelectOption[];
  isSlotsLoading: boolean;
  timezone?: string;
  countryOptions: ISelectOption[];
  timezoneOptions: ISelectOption[];
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
  onChangeCountry(urn: string, setField: FormikProps<{}>["setFieldValue"]): void;
  onChangeTimezone(urn: string): void;
  onChangeDate(date: string): void;
  onSubmit(values: AudioFormModel | VideoFormModel): void;
  onClickBack(): void;
  refetchDates?(): void;
  refetchSlots?(): void;
}

type Props = FormikProps<DefaultFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const {
    type,
    values,
    formError,
    isSubmitting,
    dates,
    slotOptions,
    countryOptions,
    timezoneOptions,
    petOptions,
    frontDoorOptions,
    touched,
    errors,
    setFieldValue,
    onChangeCountry,
    onChangeTimezone,
    onChangeDate,
    submitForm,
    onClickBack,
  } = props;

  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = audioValidationSchema.isValidSync(values);

  const handleChangeCountry = useCallback(
    (urn: string): void => onChangeCountry(urn, setFieldValue),
    [onChangeCountry, setFieldValue],
  );

  return (
    <Form>
      {formError && <Error>{formError}</Error>}

      <FormSectionCallCalling
        layout="wide"
        type={type}
        countryOptions={countryOptions}
        timezoneOptions={timezoneOptions}
        slotOptions={slotOptions}
        dates={dates}
        onChangeCountry={handleChangeCountry}
        onChangeTimezone={onChangeTimezone}
        onChangeDate={onChangeDate}
      />

      <FormSectionCallRecipient
        petOptions={petOptions}
        frontDoorOptions={frontDoorOptions}
        touched={touched}
        errors={errors}
      />

      <FormSubmitRow
        isSubmitEnabled={isValid && !isSubmitting}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
      />
    </Form>
  );
};

export const CallEditForm = withFormik<IOwnProps, DefaultFormModel>({
  mapPropsToValues: ({ defaultValues }: IOwnProps) => defaultValues,
  validationSchema: ({ type }: IOwnProps) => getValidationSchema(type),
  validateOnMount: true,
  handleSubmit: (values, { props: { onSubmit, type } }) =>
    onSubmit(getValidationSchema(type).cast(values) as AudioFormModel | VideoFormModel),
})(C);
