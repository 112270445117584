import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";

import { FormSubmitRow } from "../../../../molecules/form-submit-row";
import {
  FormSectionVideoRecipient,
  validationRules,
  IFormSectionModel,
} from "../../../form-sections/video/form-section-video-recipient";

const validationSchema = yup.object(validationRules);

export type VideoDetailsValidatedFormModel = yup.Asserts<typeof validationSchema>;
type FormModel = IFormSectionModel;

interface IOwnProps {
  defaultValues: FormModel;
  frontDoors: ISelectOption[];
  pets: ISelectOption[];
  countries: ISelectOption[];
  onChange(values: VideoDetailsValidatedFormModel): void;
  onSubmit(values: VideoDetailsValidatedFormModel): void;
  onClickBack(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  frontDoors,
  pets,
  countries,
  values,
  touched,
  errors,
  onChange,
  submitForm,
  onClickBack,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as VideoDetailsValidatedFormModel);
    }
  }, [values]);

  return (
    <Form>
      <FormSectionVideoRecipient
        pets={pets}
        frontDoors={frontDoors}
        countries={countries}
        touched={touched}
        errors={errors}
      />

      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
      />
    </Form>
  );
};

export const VideoDetailsForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as VideoDetailsValidatedFormModel),
})(C);
