import styled from "styled-components";

import { device } from "../../../../utils/media-queries";

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }): string => theme.colours.contentBackground};

  @media ${device.mobileS} {
    width: 375px;
    margin: 80px auto 0 auto;
  }

  @media ${device.tablet} {
    width: 768px;
    margin: 80px auto 0 auto;
  }

  @media ${device.laptopL} {
    width: 1440px;
    margin-top: 178px;
  }
`;
