import styled from "styled-components";

import { ReactComponent as ArrowImage } from "./back-arrow.svg";

interface IProps {
  colour?: string;
}

export const BackArrowIcon = styled(ArrowImage)<IProps>`
  width: 34px;
  height: 10px;

  path {
    fill: ${({ colour, theme }): string =>
      typeof colour !== "undefined" ? colour : theme.colours.icon};
  }
`;
