import styled from "styled-components";

export const FormFieldLink = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: inline-block;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 10px;
  text-decoration: underline;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  &:hover {
    text-decoration: none;
  }
`;
