import React, { useCallback } from "react";
import styled from "styled-components";

import { PopularRibbon } from "../../atoms/popular-ribbon";
import { Heading2, Paragraph } from "../../atoms/text";
import { BowPrice } from "../../molecules/bow-price";
import { FakeCheckbox } from "../../molecules/fake-checkbox";
import { device } from "../../../utils/media-queries";

interface IStyledCompProps {
  isSelected: boolean;
}

const Panel = styled.div<IStyledCompProps>`
  position: relative;
  min-height: 180px;
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
  border: ${({ isSelected, theme }): string => (isSelected ? theme.colours.spotGold : "white")} 3px
    3px solid;
  display: flex;
  flex-direction: row;
  flex: 1;

  @media ${device.mobileS} {
    padding: 17px 10px;
  }

  @media ${device.laptopL} {
    padding: 27px;
  }
`;

interface IImageContainerProps {
  url: string;
}

const ImageContainer = styled.div<IImageContainerProps>`
  cursor: zoom-in;
  background-image: url(${({ url }): string => url});
  background-size: cover;
  background-position: center center;

  @media ${device.mobileS} {
    min-width: 88px;
    height: 123px;
  }

  @media ${device.laptopL} {
    min-width: 118px;
    height: 165px;
  }
`;

const Content = styled.div`
  margin-left: 30px;
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
`;

const PriceRow = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Ribbon = styled(PopularRibbon)`
  position: absolute;

  @media ${device.mobileS} {
    width: 91px;
    height: 91px;
    bottom: -10px;
    right: -10px;
  }

  @media ${device.laptopL} {
    width: 146px;
    height: 146px;
    bottom: -13px;
    right: -13px;
  }
`;

interface IProps {
  title: string;
  description: string;
  isSelected: boolean;
  imageUrl: string;
  price: number;
  isPopular?: boolean;
  onClick(): void;
  onClickImage(): void;
}

export const ProductAddOnPanel: React.FC<IProps> = props => {
  const onClickImage = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      e.stopPropagation();
      props.onClickImage();
    },
    [props],
  );

  return (
    <Panel onClick={props.onClick} isSelected={props.isSelected}>
      {props.isPopular && <Ribbon />}
      <ImageContainer onClick={onClickImage} url={`${props.imageUrl}?h=165`} />
      <Content>
        <HeadingRow>
          <Heading2>{props.title}</Heading2>
          <FakeCheckbox isChecked={props.isSelected} />
        </HeadingRow>
        <Paragraph>{props.description}</Paragraph>
        <PriceRow>
          <BowPrice price={props.price} />
        </PriceRow>
      </Content>
    </Panel>
  );
};
