import { CallType } from "@santa/common/lib/products/calls";

export const routePaths = {
  about: "/about",
  basket: "/basket",
  calls: {
    home: "/santa-call",

    [CallType.AUDIO]: {
      home: "/santa-call/audio",
      calling: "/santa-call/audio/details",
      recipient: "/santa-call/audio/details/recipient",
      addOns: "/santa-call/audio/add-ons",
    },
    [CallType.VIDEO]: {
      home: "/santa-call/video",
      calling: "/santa-call/video/details",
      recipient: "/santa-call/video/details/recipient",
      addOns: "/santa-call/video/add-ons",
    },
  },
  video: {
    home: "/santa-video",
    details: "/santa-video/details",
    images: "/santa-video/images",
  },
  letter: {
    home: "/santa-letter",
    types: {
      classic: "/santa-letter/classic",
      cookieMix: "/santa-letter/cookie-mix",
      plush: "/santa-letter/plush-teddy",
      magicSnow: "/santa-letter/magic-snow",
      santaSack: "/santa-letter/santa-sack",
    },
    detailsRoute: "/santa-letter/details",
    recipient: "/santa-letter/details/recipient",
    content: "/santa-letter/details/content",
    addOns: "/santa-letter/add-ons",
    templates: "/santa-letter/templates",
  },
  text: {
    home: "/santa-text",
    details: "/santa-text/details",
    messages: "/santa-text/messages",
    bonus: "/santa-text/bonus",
  },
  affiliates: "/affiliates",
  myAccount: {
    home: "/my-account",
    letters: "/my-account/santa-letter/:id",
    calls: "/my-account/santa-call/:id",
    texts: "/my-account/santa-text/:id",
  },
};

export type LetterHomeOption = keyof typeof routePaths.letter.types;
