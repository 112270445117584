import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import bauble1 from "./bauble-1.png";
import bauble2 from "./bauble-2.png";
import bauble3 from "./bauble-3.png";

export const BaubleList = styled.div`
  ul {
    padding-left: 0;
    list-style: none;

    li {
      font-size: ${({ theme }): string => theme.fontSizes.size16};
      background-position: left top;
      background-repeat: no-repeat;

      @media ${device.mobileS} {
        background-size: 25px 30px;
        padding: 2px 0 20px 40px;
      }

      @media ${device.laptopL} {
        background-size: auto auto;
        padding: 0 0 20px 44px;
      }

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      :nth-child(3n + 1) {
        background-image: url(${bauble1});
      }
      :nth-child(3n + 2) {
        background-image: url(${bauble2});
      }
      :nth-child(3n + 3) {
        background-image: url(${bauble3});
      }
    }
  }
`;
