import { useCallback } from "react";

import { getLocaleForApi } from "../../../utils/graphql";
import { ProductAlphaId, useHomePageQuery } from "../../../types/graphql";
import { DataLoadedContainer } from "../../control/data-loaded-container";
import { HomeWave } from "../../molecules/home-wave";

interface ISectionProps {
  title: string;
  bullets: string;
}

type HeroProps = React.ComponentProps<typeof HomeWave>;

interface IData {
  loadingProps: React.ComponentProps<typeof DataLoadedContainer>;
  data?: {
    heroProps: HeroProps;
    letter?: ISectionProps;
    voiceCall?: ISectionProps;
    zoomCall?: ISectionProps;
    text?: ISectionProps;
  };
}

export const useData = (): IData => {
  const {
    data,
    error,
    loading: isLoading,
    refetch: dataRefetch,
  } = useHomePageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      audioCallAlphaId: ProductAlphaId.SANTA_CALL,
      videoCallAlphaId: ProductAlphaId.SANTA_CALL_VIDEO,
      letterAlphaId: ProductAlphaId.SANTA_LETTER,
      textAlphaId: ProductAlphaId.SANTA_TEXT,
      locale: getLocaleForApi(),
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  return {
    loadingProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && error && { refetch }),
    },
    ...(data && {
      data: {
        heroProps: {
          videoUrl: data?.page?.videoUrl || "",
          imageUrl: data?.page?.videoFallbackImageUrl || "",
          cta: data?.featureFlag?.homePageHeroProduct as HeroProps["cta"],
        },
        ...(data.letter && {
          letter: {
            title: data.letter.title || "",
            bullets: data.letter.homePageDescription || "",
          },
        }),
        ...(data.audioCall && {
          voiceCall: {
            title: data.audioCall.title || "",
            bullets: data.audioCall.homePageDescription || "",
          },
        }),
        ...(data.videoCall && {
          zoomCall: {
            title: data.videoCall.title || "",
            bullets: data.videoCall.homePageDescription || "",
          },
        }),
        ...(data.text && {
          text: {
            title: data.text.title || "",
            bullets: data.text.homePageDescription || "",
          },
        }),
      },
    }),
  };
};
