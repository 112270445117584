import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import { ReactComponent as Santa } from "./santa.svg";
import { ReactComponent as Trophy } from "./trophy.svg";
import { ReactComponent as Envelope } from "./envelope.svg";
import { ReactComponent as Sleigh } from "./sleigh.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as Snowman } from "./snowman.svg";
import { ReactComponent as Stocking } from "./stocking.svg";
import { ReactComponent as Sweet } from "./sweet.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    @media ${device.mobileS} {
      width: 98px;
      height: 98px;
    }

    @media ${device.laptopL} {
      width: 168px;
      height: 168px;
    }
  }
`;

const labels = {
  sleigh: <Sleigh />,
  trophy: <Trophy />,
  envelope: <Envelope />,
  santa: <Santa />,
  globe: <Globe />,
  snowman: <Snowman />,
  stocking: <Stocking />,
  sweet: <Sweet />,
};

interface IProps {
  type: "sleigh" | "trophy" | "envelope" | "santa" | "globe" | "snowman" | "stocking" | "sweet";
}

export const UspItem: React.FC<IProps> = ({ type, children }) => (
  <Container>
    {labels[type]}
    {children}
  </Container>
);
