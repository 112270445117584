import { debounce } from "lodash";
import { useCallback } from "react";

import { BoyGirl } from "../../types/graphql";
import { getTextMessageTemplates } from "../../model/text/text-template";
import { ISelectOption, mapObjectsToSelectOptions } from "@santa/common/lib/utils/form";

export const TEXT_CUSTOM_TEMPLATE_ID = "13948727";

type SetFields = (fields: [string, string, string]) => void;

type UseTextMessages = (params: {
  templates?: Parameters<typeof getTextMessageTemplates>["0"]["templates"];
  pets?: Parameters<typeof getTextMessageTemplates>["0"]["pets"];
}) => {
  onChangeSourceField(
    setFields: SetFields,
    values: {
      templateId?: string;
      firstName?: string;
      boyGirl?: string | null;
      ageYears?: string;
      town?: string;
      petId?: string;
      petName?: string;
    },
  ): void;
  onChangeTemplate(setFields: SetFields, templateId: string): void;
  templateOptions: ISelectOption[];
  petOptions: ISelectOption[];
};

export const useTextMessages: UseTextMessages = ({ templates, pets }) => ({
  onChangeSourceField: useCallback(
    debounce((setFields, values): void => {
      const { templateId, firstName, boyGirl, ageYears, town, petId, petName } = values;

      if (
        templates &&
        pets &&
        templateId &&
        firstName &&
        ageYears &&
        boyGirl &&
        town &&
        templateId !== TEXT_CUSTOM_TEMPLATE_ID
      ) {
        const messages = getTextMessageTemplates(
          { templates, pets },
          {
            templateId,
            name: firstName,
            boyGirl: values.boyGirl as BoyGirl,
            age: Number(ageYears),
            town,
            petId,
            petName,
          },
        );

        if (messages) {
          setFields(messages);
        }
      }
    }, 500),
    [templates, pets],
  ),
  onChangeTemplate: useCallback((setFields, templateId) => {
    if (templateId === TEXT_CUSTOM_TEMPLATE_ID) {
      setFields(["", "", ""]);
    }
  }, []),
  templateOptions: mapObjectsToSelectOptions(templates || [], "id", "label"),
  petOptions: mapObjectsToSelectOptions(pets || [], "id", "label"),
});
