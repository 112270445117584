import * as dateFns from "date-fns";
import * as dateFnsTz from "date-fns-tz";

import { getLetterDescription } from "./letter";
import { IDescriptionAddOn, ProductAlphaId } from "./common";
import { getAudioCallDescription, getVideoCallDescription } from "./calls";
import { getTextDescription } from "./texts";
import { getVideoDescription } from "./videos";
import { getSackDescription } from "./sack";

interface IItem {
  productAlphaId: string;
  letter?: {
    template: {
      label?: string | null;
    };
    firstName: string;
    date: string;
  } | null;
  call?: {
    firstName: string;
    time: string;
    phoneNumber?: string | null;
    timezone: {
      timezone: string;
    };
    phoneCountry?: {
      callingCode: string;
    } | null;
  } | null;
  text?: {
    firstName: string;
    text1Time: string;
    timezone: {
      timezone: string;
    };
    phoneCountry: {
      callingCode: string;
    };
    phoneNumber: string;
  } | null;
  sack?: {
    name: string;
    addressTown: string;
    date: string;
  } | null;
  video?: {
    firstName: {
      label?: string | null;
    };
  } | null;
  addOnProducts: IDescriptionAddOn[];
}

/**
 * Get description for a product
 * @param params Details of product
 */
export const getDescription = ({
  productAlphaId,
  letter,
  call,
  text,
  video,
  sack,
  addOnProducts,
}: IItem): string => {
  switch (productAlphaId) {
    case ProductAlphaId.SANTA_LETTER: {
      if (letter) {
        return getLetterDescription({
          templateName: letter.template.label,
          recipientName: letter.firstName,
          sendDate: dateFns.parseISO(letter.date),
          addOnProducts,
        });
      }
      throw new Error("Missing product information for letter");
    }
    case ProductAlphaId.SANTA_CALL_VIDEO:
      if (call) {
        return getVideoCallDescription({
          recipientName: call.firstName,
          callTime: dateFnsTz.utcToZonedTime(dateFns.parseISO(call.time), call.timezone.timezone),
          timezone: call.timezone.timezone,
          addOnProducts,
          includeEmailDetails: true,
        });
      }
      throw new Error("Missing product information for video call");
    case ProductAlphaId.SANTA_CALL: {
      if (call) {
        return getAudioCallDescription({
          recipientName: call.firstName,
          callTime: dateFnsTz.utcToZonedTime(dateFns.parseISO(call.time), call.timezone.timezone),
          ...(call.phoneCountry &&
            call.phoneNumber && {
              phoneNumber: `+${call.phoneCountry.callingCode}${call.phoneNumber}`,
            }),
          timezone: call.timezone.timezone,
          addOnProducts,
          includeEmailDetails: true,
        });
      }
      throw new Error("Missing product information for audio call");
    }
    case ProductAlphaId.SANTA_TEXT: {
      if (text) {
        return getTextDescription({
          recipientName: text.firstName,
          textTime: dateFnsTz.utcToZonedTime(
            dateFns.parseISO(text.text1Time),
            text.timezone.timezone,
          ),
          phoneNumber: `+${text.phoneCountry.callingCode}${text.phoneNumber}`,
          timezone: text.timezone.timezone,
          addOnProducts,
        });
      }
      throw new Error("Missing product information for text");
    }
    case ProductAlphaId.SANTA_VIDEO_MESSAGE: {
      if (video) {
        return getVideoDescription({
          recipientName: video.firstName.label,
          addOnProducts,
        });
      }
      throw new Error("Missing product information for video message");
    }
    case ProductAlphaId.SANTA_SACK: {
      if (sack) {
        return getSackDescription({
          recipientName: sack.name,
          town: sack.addressTown,
          sendDate: dateFns.parseISO(sack.date),
          addOnProducts,
        });
      }
      throw new Error("Missing product information for sack");
    }
    default:
      throw new Error(`Unrecognised product ${productAlphaId}`);
  }
};
