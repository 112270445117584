import { Field, FormikErrors, FormikTouched } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  yearsId?: string;
  monthsId?: string;
  yearsPlaceholder?: string;
  monthsPlaceholder?: string;
  label?: string;
}

export const FormFieldAge: React.FC<IProps> = ({
  touched,
  errors,
  yearsId = "ageYears",
  monthsId = "ageMonths",
  yearsPlaceholder = "Years",
  monthsPlaceholder = "Months",
  label = "Child's age",
}) => {
  const getError = (name: string): string | undefined =>
    touched[name] ? (errors[name] as string) : undefined;

  let ageFieldError: string | undefined;
  if (typeof getError(yearsId) !== "undefined") {
    ageFieldError = "ageYears";
  } else if (typeof getError(monthsId) !== "undefined") {
    ageFieldError = "ageMonths";
  }

  return (
    <FormFieldWrapper label={label} name={ageFieldError} required noLabel>
      <Field
        name={yearsId}
        component={FormikFormInputText}
        type="number"
        placeholder={yearsPlaceholder}
      />
      <Field
        name={monthsId}
        component={FormikFormInputText}
        type="number"
        placeholder={monthsPlaceholder}
      />
    </FormFieldWrapper>
  );
};
