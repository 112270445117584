import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { debounce } from "lodash";

import { routePaths } from "../../../../model/route";
import { VideoImagesForm } from "../../../organisms/forms/video/video-images-form";
import { videoCreateState, videoState } from "../../../../model/graphql/cache";

type FormProps = React.ComponentProps<typeof VideoImagesForm>;
type FormParams = FormProps["defaultValues"];

const mapDataToForm = ({ images }: ReturnType<typeof videoCreateState>): FormParams => {
  if (images) {
    return {
      childImageId: images.childImageId || "",
      houseImageId: images.houseImageId || "",
    };
  }

  return {
    childImageId: "",
    houseImageId: "",
  };
};

interface IUseData {
  formProps: FormProps;
}

export const useData = (): IUseData => {
  const history = useHistory();
  const currentState = videoCreateState();

  const goBack = useCallback((): void => history.push(routePaths.video.details), [history]);
  const goToBasket = useCallback((): void => history.push(routePaths.basket), [history]);

  useEffect(() => {
    if (!currentState.details) {
      goBack();
    }
  }, [history, currentState]);

  const onChange = useCallback<FormProps["onChange"]>(
    debounce(data => {
      videoState().setImages(data);
    }, 500),
    [],
  );

  const onClickSkip = useCallback<FormProps["onClickSkip"]>(() => {
    videoState().addToBasket({ skipImages: true });
    goToBasket();
  }, [history]);

  const onSubmit = useCallback<FormProps["onSubmit"]>(
    data => {
      videoState().setImages(data);
      videoState().addToBasket();
      goToBasket();
    },
    [history],
  );

  const onClickBack = useCallback(goBack, [history]);

  const defaultValues = useMemo(() => mapDataToForm(currentState), []);

  return {
    formProps: {
      onClickBack,
      onClickSkip,
      onSubmit,
      onChange,
      defaultValues,
    },
  };
};
