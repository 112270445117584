import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import {
  TextsDetailsTextingForm,
  TextTextingValidatedFormModel,
} from "../../../organisms/forms/texts-details-texting";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { ProductAlphaId, useTextsTextingQuery } from "../../../..//types/graphql";
import { routePaths } from "../../../../model/route";
import { textCreateState, textState } from "../../../../model/graphql/cache";
import { config } from "../../../../config";
import { useTextPhone } from "../../../../hooks/text/use-text-phone";
import { useCallValidateNumber } from "../../../..//hooks/call/use-call-validate-number";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";
import { getLocaleForApi } from "../../../../utils/graphql";

type FormProps = React.ComponentProps<typeof TextsDetailsTextingForm>;
type FormParams = FormProps["defaultValues"];
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapDataToForm = (): FormParams => {
  const { details } = textCreateState();

  if (details) {
    return {
      phoneCountryUrn: details.phoneCountryUrn,
      phoneNumber: details.phoneNumber,
      timezoneUrn: details.timezoneUrn,
    };
  }

  return {
    phoneCountryUrn: config.defaultCountry,
    phoneNumber: "",
    timezoneUrn: config.defaultTimezone,
  };
};

interface IUseData {
  formProps?: FormProps;
  loaderProps: LoaderProps;
  faqs?: ReturnType<typeof mapCmsFaqsToAccordion>;
}

export const useData = (): IUseData => {
  const history = useHistory();

  const {
    client,
    data,
    loading: isLoading,
    refetch: dataRefetch,
    error,
  } = useTextsTextingQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      locale: getLocaleForApi(),
      textAlphaId: ProductAlphaId.SANTA_TEXT,
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const { onChangePhoneCountry, countryOptions, timezoneOptions } = useTextPhone({
    countries: data?.countries,
    timezones: data?.timezones,
  });
  const validatePhoneNumber = useCallValidateNumber(client);

  const onChange = useCallback(
    debounce((values: TextTextingValidatedFormModel): void => {
      textState().setDetails(values);
    }, 500),
    [],
  );

  const onSubmit = useCallback(
    async (
      setPhoneError: (error: string) => void,
      values: TextTextingValidatedFormModel,
    ): Promise<void> => {
      try {
        const phoneNumber = await validatePhoneNumber(values.phoneNumber, values.phoneCountryUrn);

        textState().setDetails({
          ...values,
          phoneNumber,
        });
        history.push(routePaths.text.messages);
      } catch (error) {
        if (error instanceof Error) {
          setPhoneError(error.message);
        }
      }
    },
    [history],
  );

  const onClickBack = useCallback(() => history.push(routePaths.text.home), [history]);

  const defaultValues = useMemo(mapDataToForm, []);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && error && { refetch }),
    },
    ...(data && {
      formProps: {
        defaultValues,
        countryOptions,
        timezoneOptions,
        onSubmit,
        onClickBack,
        onChange,
        onChangePhoneCountry,
      },
      ...(data.product?.faqs && { faqs: mapCmsFaqsToAccordion(data.product.faqs) }),
    }),
  };
};
