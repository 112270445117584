import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { useCallback, useEffect } from "react";

import { Paragraph } from "../../../atoms/text";
import {
  FormSectionTextPhone,
  validationRules as phoneValidationRules,
  IFormSectionModel as PhoneDefaultValuesModel,
} from "../../form-sections/text/form-section-text-phone";
import {
  FormSectionTextTemplates,
  validationRules as contentValidationRules,
  IFormSectionModel as MessagesDefaultValuesModel,
} from "../../form-sections/text/form-section-text-messages";
import { FormSubmitRow } from "../../../molecules/form-submit-row";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const validationSchema = yup.object({
  ...phoneValidationRules,
  ...contentValidationRules,
});
export type TextEditValidatedFormModel = yup.Asserts<typeof validationSchema>;
type FormModel = PhoneDefaultValuesModel & MessagesDefaultValuesModel;

type SetFields = (fields: [string, string, string]) => void;

interface IOwnProps {
  defaultValues: FormModel;
  timezoneOptions: ISelectOption[];
  countryOptions: ISelectOption[];
  templateOptions: ISelectOption[];
  petOptions: ISelectOption[];
  formError?: string;
  isSubmitting: boolean;
  onSubmit(values: TextEditValidatedFormModel): void;
  onChangePhoneCountry(setTimezone: (value: string) => void, urn: string): void;
  onChangeSourceField(update: SetFields, details: FormModel): void;
  onChangeTemplate(update: SetFields, templateId: string): void;
  onClickBack(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  templateOptions,
  petOptions,
  timezoneOptions,
  countryOptions,
  formError,
  isSubmitting,
  touched,
  onChangePhoneCountry,
  onChangeSourceField,
  onChangeTemplate,
  onClickBack,
  setFieldValue,
  submitForm,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  const setFields: SetFields = ([field1, field2, field3]) => {
    setFieldValue("text1Body", field1);
    setFieldValue("text2Body", field2);
    setFieldValue("text3Body", field3);
  };

  const handleChangeTemplate = useCallback(
    (templateId: string) => onChangeTemplate(setFields, templateId),
    [setFieldValue, onChangeTemplate],
  );

  const handleChangePhoneCountry = useCallback(
    (urn: string): void =>
      onChangePhoneCountry((u: string) => setFieldValue("timezoneUrn", u), urn),
    [onChangePhoneCountry, setFieldValue],
  );

  const { templateId, firstName, ageYears, town, boyGirl, petId, petName } = values;
  useEffect(() => {
    if (
      touched.templateId ||
      touched.firstName ||
      touched.ageYears ||
      touched.town ||
      touched.boyGirl ||
      touched.petId ||
      touched.petName
    ) {
      onChangeSourceField(setFields, values);
    }
  }, [templateId, firstName, ageYears, town, boyGirl, petId, petName]);

  return (
    <Form>
      {formError && <Error>{formError}</Error>}
      <FormSectionTextPhone
        countryOptions={countryOptions}
        timezoneOptions={timezoneOptions}
        onChangePhoneCountry={handleChangePhoneCountry}
      />
      <FormSectionTextTemplates
        petOptions={petOptions}
        templateOptions={templateOptions}
        onChangeTemplate={handleChangeTemplate}
      />
      <FormSubmitRow
        isSubmitEnabled={isValid && !isSubmitting}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
        submitLabel="Save"
      />
    </Form>
  );
};

export const TextEditForm = withFormik<IOwnProps, FormModel>({
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  validationSchema,
  validateOnMount: true,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as TextEditValidatedFormModel),
})(C);
