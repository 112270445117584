import { ApolloClient } from "@apollo/client";

import {
  ValidatePhoneNumberDocument,
  ValidatePhoneNumberQuery,
  ValidatePhoneNumberQueryVariables,
} from "../../types/graphql";

type UseCallValidateNumber = (
  client: ApolloClient<{}>,
) => (number: string, countryUrn: string) => Promise<string>;

export const useCallValidateNumber: UseCallValidateNumber =
  client => async (number, countryUrn) => {
    try {
      const { data: validateData } = await client.query<
        ValidatePhoneNumberQuery,
        ValidatePhoneNumberQueryVariables
      >({
        query: ValidatePhoneNumberDocument,
        variables: { number, countryUrn },
      });

      if (!validateData.validatePhoneNumber?.number) {
        throw new Error("This phone number appears to be invalid");
      }

      return validateData.validatePhoneNumber.number;
    } catch {
      throw new Error("Sorry, an error occurred validating the phone number. Please try again.");
    }
  };
