import { SantaErrorCode } from "@santa/common/lib/errors";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { config } from "./config";
import { OrderStatus } from "./components/molecules/my-account-order";
import { ProductAlphaId, SiteCountryCode } from "./types/graphql";

// the translations
const resources = {
  en: {
    translation: {
      // home page
      home: {
        title: "Make this Christmas extra special!",
        subtitle: "Supplying the world's most popular greetings from Santa",
        usp: {
          list: {
            1: "Guaranteed Fast and Shipping Worldwide",
            2: "Trusted Category Leader since 1999",
            3: "Personalised Packages From Santa",
            4: "More Than 1,000,000 Happy Kids",
          },
        },
        daysUntilChristmas: "days until Christmas",
      },

      // forms
      form: {
        address: {
          "search-gb": {
            label: "Search for Child's address",
            placeholder: "Start typing child's address",
          },
          "search-us": {
            label: "Search for Child's address",
            placeholder: "Start typing child's address",
          },
          address1: {
            label: "Address 1",
          },
          address2: {
            label: "Address 2",
          },
          "town-us": {
            label: "City",
          },
          "town-other": {
            label: "Town",
          },
          state: {
            label: "State",
          },
          county: {
            label: "County",
          },
          "postcode-us": {
            label: "ZIP",
          },
          "postcode-other": {
            label: "Postcode",
          },
        },
      },

      // sack
      sack: {
        form: {
          field: {
            name: {
              label: "Child's First & Last name",
              placeholder: "Enter full name",
            },
            date: {
              label: "Date to send",
              placeholder: "Select a date",
            },
          },
          submit: {
            label: "Add to Basket",
          },
        },
      },

      // video
      video: {
        form: {
          submit: {
            label: "Finish",
          },
        },
      },

      letter: {
        sectionTitle: {
          [ProductAlphaId.COOKIE_MIX]: "Santa Letter with Cookie Mix",
          [ProductAlphaId.SANTA_PLUSH]: "Santa Letter with Santa Plush",
          [ProductAlphaId.MAGIC_SNOW]: "Santa Letter with Magic Snow",
          [ProductAlphaId.SANTA_SACK]: "Santa Letter with Santa Sack",
        },
      },

      // my account
      myaccount: {
        orders: {
          order: {
            itemCount: "{{count}} item",
            itemCount_plural: "{{count}} items",
            status: {
              [OrderStatus.SCHEDULED]: "Scheduled",
              [OrderStatus.IN_PROGRESS]: "In Progress",
              [OrderStatus.COMPLETED]: "Completed",
            },
          },
        },
        text: {
          errors: {
            [SantaErrorCode.TEXT_TIME_TOO_SOON]:
              "One or more of your text messages is set to happen in the past.",
          },
        },
      },

      // checkout
      checkout: {
        errors: {
          [SantaErrorCode.CALL_RESERVATION_EXPIRED]:
            "The grace period of 60 mins for your Santa Call has expired. Please remove from basket and reschedule.",
          [SantaErrorCode.TEXT_TIME_TOO_SOON]:
            "One or more of your text messages is set to happen in the past. Please remove from basket and reschedule.",
        },
      },
    },
  },
  "en-GB": {
    translation: {
      // footer
      main: {
        footer: {
          address:
            "Father Christmas Ltd. 7 Forbes Business Centre, Kempson Way, Bury St. Edmunds IP32 7AR England",
          telephone: "01284 636308",
        },
      },

      // home page
      home: {
        usp: {
          heading: "Why Santa.co.uk?",
        },
      },

      // letters
      letter: {
        home: {
          cta: {
            label: "Personalise Letter Now",
          },
        },
      },

      // calls
      call: {
        home: {
          cta: {
            label: "Personalise Call Now",
          },
        },
      },

      // calls
      text: {
        home: {
          cta: {
            label: "Personalise Text Now",
          },
        },
      },

      // video
      video: {
        home: {
          cta: {
            label: "Personalise Video Now",
          },
        },
      },

      // my account
      myaccount: {
        support: {
          email: "myaccount2020@santa.co.uk",
        },
      },

      login: {
        title: "Log in to Santa.co.uk",
      },
    },
  },
  "en-US": {
    translation: {
      // footer
      main: {
        footer: {
          address: "Santa Claus, Inc., 101 Convention Center Drive, Suite 700, Las Vegas, NV 89109",
          telephone: "(725) 218 3265",
        },
      },

      // home page
      home: {
        usp: {
          heading: "Why Santa-Claus.com?",
        },
      },

      // letters
      letter: {
        home: {
          cta: {
            label: "Customize Letter Now",
          },
        },
      },

      // calls
      call: {
        home: {
          cta: {
            label: "Customize Call Now",
          },
        },
      },

      // calls
      text: {
        home: {
          cta: {
            label: "Customize Text Now",
          },
        },
      },

      // video
      video: {
        home: {
          cta: {
            label: "Customize Video Now",
          },
        },
      },

      // my account
      myaccount: {
        support: {
          email: "helpme@santa-claus.com",
        },
      },

      login: {
        title: "Log in to Santa-Claus.com",
      },
    },
  },
};

export default i18n.use(initReactI18next).init({
  resources,
  lng: config.site.country === SiteCountryCode.GB ? "en-GB" : "en-US",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
