import { nanoid } from "nanoid";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { InfoIcon } from "../../atoms/icons/info-icon";
import { SpanText } from "../../atoms/text";

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
`;

const Label = styled(SpanText)`
  color: #78909c;
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  span.required {
    color: ${({ theme }): string => theme.colours.alert};
  }
`;

interface IProps {
  label: string;
  required?: boolean;
  tooltip?: string;
}

export const FormFieldLabel: React.FC<IProps> = ({ label, tooltip, required = false }) => {
  const requiredElement = required ? <span className="required"> *</span> : null;
  let tooltipElement: JSX.Element | undefined;

  if (tooltip) {
    const id = nanoid();
    tooltipElement = (
      <>
        <InfoIcon data-tip data-for={`tooltip-${id}`} />
        <ReactTooltip type="dark" effect="solid" id={`tooltip-${id}`}>
          {tooltip}
        </ReactTooltip>
      </>
    );
  }

  return (
    <LabelWrapper>
      <Label>
        {label}
        {requiredElement}
      </Label>
      {tooltipElement}
    </LabelWrapper>
  );
};
