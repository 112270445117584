import { config } from "../../config";
import { SiteLocale, SiteCountryCode } from "../../types/graphql";

/**
 * Returns true if the object is defined and key in the object is defined
 * @param object Object to check
 * @param key Key in object to check
 */
export const isLoaded = <T extends object, K extends keyof T>(
  object: T | undefined,
  keys?: K[],
): object is T => {
  if (typeof object === "undefined") {
    return false;
  }

  if (typeof keys !== "undefined") {
    return keys.reduce<boolean>((a, k) => a && typeof object[k] !== "undefined", true);
  }

  return true;
};

/**
 * Returns items from the array that are not null
 * @param objects Objects to filter
 */
export const notNull = <T>(objects: (T | null | undefined)[]): T[] => {
  return objects.filter((v): v is T => v !== null && typeof v !== "undefined");
};

export const getLocaleForApi = (): SiteLocale =>
  config.site.country === SiteCountryCode.GB ? SiteLocale.EN_GB : SiteLocale.EN_US;
