import { ISelectOption } from "@santa/common/lib/utils/form";

import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { RequiredObjectSchema, TypeOfShape } from "yup/lib/object";

import { ContentPanel } from "../../../atoms/content-panel";
import { Heading3 } from "../../../atoms/text";
import {
  FormSectionCallRecipient,
  // FormSectionModel,
  validationRules,
} from "../../form-sections/call/form-section-call-recipient";
import { PanelDiscHeading } from "../../../molecules/panel-disc-heading";
import { CrossButton } from "../../../molecules/buttons/cross";
import { FormSubmitRow } from "../../../molecules/form-submit-row";
import { Loader } from "../../../atoms/loader";

const PanelHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Panel = styled(ContentPanel)`
  margin: 20px 0;

  > h3,
  > div h3 {
    :first-child {
      margin-top: 0;
      font-weight: normal;
    }
    :last-child {
      margin-bottom: 0;
      font-weight: normal;
    }
  }
`;

const ButtonPanel = styled(Panel)`
  cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type RecipientSchema = {
  first: RequiredObjectSchema<
    typeof validationRules,
    Record<string, unknown>,
    TypeOfShape<typeof validationRules>
  >;
  second: RequiredObjectSchema<
    typeof validationRules,
    Record<string, unknown>,
    TypeOfShape<typeof validationRules>
  >;
};
type FormSchema = RequiredObjectSchema<
  RecipientSchema,
  Record<string, unknown>,
  TypeOfShape<RecipientSchema>
>;

const getValidationSchema = (includeSecond: boolean): FormSchema => {
  const rules = yup.object(validationRules).required();

  return yup.object({
    first: rules,
    ...(includeSecond && { second: rules }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
};

type FormModel = yup.Asserts<FormSchema>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CallContentFormModel {
  first: FormModel["first"];
  second?: FormModel["first"];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialValues: any = {
  // FormSectionModel = {
  firstName: "",
  lastName: "",
  boyGirl: "",
  ageYears: undefined,
  ageMonths: undefined,
  hobby: "",
  gift: "",
  friend: "",
  frontDoorId: "",
  pet1Id: "",
  pet1Name: "",
  pet2Id: "",
  pet2Name: "",
  notes: "",
};

interface IOwnProps {
  defaultValues?: CallContentFormModel;
  pets: ISelectOption[];
  frontDoors: ISelectOption[];
  isSecondRecipientAvailable: boolean;
  isSecondRecipientSelected: boolean;
  isFetchingSecondRecipientUrn: boolean;
  onSubmit(values: CallContentFormModel): void;
  onClickBack(): void;
  onClickAddSecondRecipient(): void;
  onClickRemoveSecondRecipient(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  submitForm,
  onClickBack,
  values,
  pets,
  frontDoors,
  touched,
  errors,
  isSecondRecipientAvailable,
  isSecondRecipientSelected,
  isFetchingSecondRecipientUrn,
  setFieldValue,
  onClickAddSecondRecipient,
  onClickRemoveSecondRecipient,
}) => {
  const { first: touchedFirst, second: touchedSecond } = touched;
  const { first: errorsFirst, second: errorsSecond } = errors;

  type CopyValues = Pick<
    CallContentFormModel["first"],
    "lastName" | "frontDoorId" | "pet1Id" | "pet1Name" | "pet2Id" | "pet2Name"
  >;
  const [valuesToCopy, setValuesToCopy] = useState<CopyValues>();
  const isValid = getValidationSchema(isSecondRecipientSelected).isValidSync(values);

  let secondRecipientPanel: JSX.Element | undefined = undefined;

  // copy data when show second form is triggered
  useEffect(() => {
    if (valuesToCopy) {
      setFieldValue("second.lastName", valuesToCopy.lastName);
      setFieldValue("second.frontDoorId", valuesToCopy.frontDoorId);
      setFieldValue("second.pet1Id", valuesToCopy.pet1Id);
      setFieldValue("second.pet1Name", valuesToCopy.pet1Name);
      setFieldValue("second.pet2Id", valuesToCopy.pet2Id);
      setFieldValue("second.pet2Name", valuesToCopy.pet2Name);
    }
  }, [isSecondRecipientSelected, valuesToCopy, setFieldValue]);

  /**
   * Handle click second recipient
   */
  const handleClickAddSecondRecipient = useCallback((): void => {
    setValuesToCopy({
      lastName: values.first.lastName,
      frontDoorId: values.first.frontDoorId,
      pet1Id: values.first.pet1Id,
      pet1Name: values.first.pet1Name,
      pet2Id: values.first.pet2Id,
      pet2Name: values.first.pet2Name,
    });

    onClickAddSecondRecipient();
  }, [setValuesToCopy, onClickAddSecondRecipient, values]);

  if (isSecondRecipientAvailable) {
    // second recipient form should be available
    if (isFetchingSecondRecipientUrn) {
      secondRecipientPanel = <Loader />;
    } else if (isSecondRecipientSelected) {
      // second recipient form is selected to show
      secondRecipientPanel = (
        <Panel>
          <PanelHeading>
            <Heading3>Info about second child</Heading3>
            <CrossButton onClick={onClickRemoveSecondRecipient} />
          </PanelHeading>
          <FormSectionCallRecipient
            prefix="second"
            petOptions={pets}
            frontDoorOptions={frontDoors}
            touched={touchedSecond || {}}
            errors={errorsSecond || {}}
          />
        </Panel>
      );
    } else {
      // second recipient form should be closed
      secondRecipientPanel = (
        <ButtonPanel onClick={handleClickAddSecondRecipient}>
          <PanelDiscHeading active icon="+">
            Add second child info
          </PanelDiscHeading>
        </ButtonPanel>
      );
    }
  }

  return (
    <Form>
      <Panel>
        <Heading3>Info about child</Heading3>
        <FormSectionCallRecipient
          prefix="first"
          petOptions={pets}
          frontDoorOptions={frontDoors}
          touched={touchedFirst || {}}
          errors={errorsFirst || {}}
        />
      </Panel>

      {secondRecipientPanel}

      <FormSubmitRow isSubmitEnabled={isValid} onClickBack={onClickBack} onClickSubmit={submitForm}>
        <>
          By clicking Next Step I accept the{" "}
          <a href="/terms" target="_blank">
            Terms &amp; Conditions
          </a>
        </>
      </FormSubmitRow>
    </Form>
  );
};

export const CallsDetailsRecipientForm = withFormik<IOwnProps, FormModel>({
  validationSchema: (props: IOwnProps) => getValidationSchema(props.isSecondRecipientSelected),
  mapPropsToValues: props => {
    if (props.defaultValues) {
      const second = props.defaultValues.second || initialValues;

      return {
        ...props.defaultValues,
        second,
      };
    }

    return { first: initialValues, second: initialValues };
  },
  isInitialValid: props => getValidationSchema(props.isSecondRecipientSelected).isValidSync(props),
  handleSubmit: (values, { props }) =>
    props.onSubmit(
      getValidationSchema(props.isSecondRecipientSelected).cast(values) as CallContentFormModel,
    ),
})(C);
