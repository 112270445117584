import React, { useCallback } from "react";

import { Loader } from "../../atoms/loader";
import { LoadingError } from "../../molecules/loading-error";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any;
}

export const checkData = <T extends {} | undefined>(d: T): d is NonNullable<Required<T>> =>
  typeof d !== "undefined";

export const DataLoadedContainerOld: React.FC<IProps> = ({ response, children }) => {
  const handleRefetch = useCallback((): void => {
    response.refetch();

    return;
  }, [response]);

  if (response.loading) {
    return <Loader />;
  } else if (response.error || !response.data) {
    return <LoadingError onPressReload={handleRefetch} />;
  }

  return <>{children}</>;
};

interface INewProps {
  isLoading: boolean;
  isNetworkError?: boolean;
  refetch?(): void;
}

export const DataLoadedContainer: React.FC<INewProps> = ({
  isLoading,
  isNetworkError,
  refetch,
  children,
}) => {
  if (isLoading) {
    return <Loader />;
  } else if (refetch) {
    return <LoadingError onPressReload={refetch} isNetworkError={isNetworkError} />;
  }

  return <>{children}</>;
};
