export enum SantaErrorCode {
  USER_URN_NOT_SPECIFIED = "USER_URN_NOT_SPECIFIED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",

  CALL_RESERVATION_NOT_FOUND = "CALL_RESERVATION_NOT_FOUND",
  CALL_RESERVATION_NOT_AVAILABLE = "CALL_RESERVATION_NOT_AVAILABLE",
  CALL_RESERVATION_EXPIRED = "CALL_RESERVATION_EXPIRED",
  CALL_NOT_FOUND = "CALL_NOT_FOUND",

  COUNTRY_NOT_FOUND = "COUNTRY_NOT_FOUND",

  DB_UNEXPECTED_MULTIPLE_ITEMS = "DB_UNEXPECTED_MULTIPLE_ITEMS",

  SCHEMA_VALIDATION_ERROR = "SCHEMA_VALIDATION_ERROR",

  LETTER_NOT_FOUND = "LETTER_NOT_FOUND",
  LETTER_SECRET_ACCESS_LOGIN_ALREADY_CREATED = "LETTER_SECRET_ACCESS_LOGIN_ALREADY_CREATED",
  LETTER_DATE_TOO_LATE = "LETTER_DATE_TOO_LATE",

  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_TEXT_NOT_VALID = "ORDER_TEXT_NOT_VALID",
  ORDER_PAYMENT_TOKEN_REQUIRED = "ORDER_PAYMENT_TOKEN_REQUIRED",

  ORDER_ITEM_NOT_FOUND = "ORDER_ITEM_NOT_FOUND",
  ORDER_ITEM_TYPE_NOT_FOUND = "ORDER_ITEM_TYPE_NOT_FOUND",

  PROMO_CODE_NOT_FOUND = "PROMO_CODE_NOT_FOUND",
  PROMO_CODE_EXPIRED = "PROMO_CODE_EXPIRED",
  PROMO_CODE_USED = "PROMO_CODE_USED",

  SACK_NOT_FOUND = "SACK_NOT_FOUND",

  TEXT_NOT_FOUND = "TEXT_NOT_FOUND",
  TEXT_TIME_TOO_SOON = "TEXT_TIME_TOO_SOON",

  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND",

  USER_NOT_FOUND = "USER_NOT_FOUND",

  VIDEO_NOT_FOUND = "VIDEO_NOT_FOUND",
  VIDEO_LOGIN_CREATE_LETTER_URN_VIDEO_URN_NOT_SUPPLIED = "VIDEO_LOGIN_CREATE_LETTER_URN_VIDEO_URN_NOT_SUPPLIED",

  USER_REQUIRED_FOR_UPDATE = "USER_REQUIRED_FOR_UPDATE",
}
