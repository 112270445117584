import { stringifyList } from "../../utils/string";

export enum ProductAlphaId {
  COOKIE_MIX = "COOKIE_MIX",
  DANGLY_ELF = "DANGLY_ELF",
  SANTA_PLUSH = "SANTA_PLUSH",
  SANTA_SACK = "SANTA_SACK",
  MAGIC_SNOW = "MAGIC_SNOW",
  SANTA_LETTER = "SANTA_LETTER",
  VIDEO_MESSAGE = "VIDEO_MESSAGE",
  INTERNATIONAL_SURCHARGE_LETTER = "INTERNATIONAL_SURCHARGE_LETTER",
  INTERNATIONAL_SURCHARGE_PRODUCT = "INTERNATIONAL_SURCHARGE_PRODUCT",
  PANDP_SURCHARGE = "PANDP_SURCHARGE",
  SANTA_CALL = "SANTA_CALL",
  SANTA_CALL_VIDEO = "SANTA_CALL_VIDEO",
  CALL_CERTIFICATE = "CALL_CERTIFICATE",
  SANTA_TEXT = "SANTA_TEXT",
  SANTA_TEXT_BONUS = "SANTA_TEXT_BONUS",
  SANTA_VIDEO_MESSAGE = "SANTA_VIDEO_MESSAGE",
}

export const surchargeAddOns = [
  ProductAlphaId.INTERNATIONAL_SURCHARGE_LETTER,
  ProductAlphaId.INTERNATIONAL_SURCHARGE_PRODUCT,
  ProductAlphaId.PANDP_SURCHARGE,
];

export interface IDescriptionAddOn {
  alphaId?: string | null;
  title?: string | null;
  price?: number | null;
}

/**
 * Return a listed description of addons
 * @param addOns Addons to create list from
 */
export const getAddOnDescription = (addOns: IDescriptionAddOn[]): string => {
  const productAddOns = addOns
    .filter((a): a is Omit<IDescriptionAddOn, "title"> & { title: string } => Boolean(a.title))
    .map(a => a.title);

  if (productAddOns.length > 0) {
    return ` Plus ${stringifyList(productAddOns)}.`;
  }

  return "";
};
