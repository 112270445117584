import { Field } from "formik";
import { min, max } from "lodash";
import * as dateFns from "date-fns";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputDate } from "../../form-input-date";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  dates?: Date[];
  onChange?(date: string): void;
}

const getMonthsShown = ({
  dates,
  minDate,
  maxDate,
}: Pick<IProps, "dates" | "minDate" | "maxDate">): 1 | 2 => {
  const thisMinDate = minDate || min(dates || []);
  const thisMaxDate = maxDate || max(dates || []);

  return thisMinDate && thisMaxDate && dateFns.getMonth(thisMaxDate) > dateFns.getMonth(thisMinDate)
    ? 2
    : 1;
};

export const FormFieldDate: React.FC<IProps> = ({
  id = "date",
  label = "Date to send",
  placeholder = "Select a date",
  minDate,
  maxDate,
  required = true,
  dates,
  onChange,
}) => {
  const fieldProps = {
    name: "date",
    component: FormikFormInputDate,
    placeholder,
    label: false,
    monthsShown: getMonthsShown({ minDate, maxDate, dates }),
    required,
    ...(minDate && { minDate }),
    ...(maxDate && { maxDate }),
    ...(onChange && { onChange }),
    ...(dates && { includeDates: dates }),
  };
  return (
    <FormFieldWrapper label={label} name={id} required={required}>
      <Field {...fieldProps} />
    </FormFieldWrapper>
  );
};
