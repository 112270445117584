import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { MainWrapper } from "../../atoms/containers/main-wrapper";
import { Heading2, Paragraph } from "../../atoms/text";
import { ForgotPasswordEmailForm } from "../../organisms/forms/forgot-password/forgot-password-email-form";
import { sendResetPasswordCode, resetPassword } from "../../../model/authentication";
import { ForgotPasswordCodeForm } from "../../organisms/forms/forgot-password/forgot-password-code-form";
import { BowButton } from "../../atoms/bow-button";
import { MobileBackLink } from "../../molecules/mobile-back-link";
import { IHistoryLocationState } from "../../app";

const GoBackButton = styled(BowButton)`
  margin-top: 35px;
`;

enum Status {
  GET_EMAIL,
  GET_CODE,
  DONE,
}

export const ForgotPassword: React.FC = () => {
  const history = useHistory<IHistoryLocationState>();

  const [status, setStatus] = useState<Status>(Status.GET_EMAIL);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (history.location.state && history.location.state.forgotPasswordReturnLocation) {
      setRedirectTo(history.location.state.forgotPasswordReturnLocation);
    }
  }, [history.location.state]);

  const handleClickBack = useCallback((): void => {
    history.push(redirectTo ? redirectTo : "/");
  }, [history, redirectTo]);

  const handleSubmitEmail = useCallback(
    async (newEmail: string): Promise<void> => {
      const lowerEmail = newEmail.toLowerCase();
      try {
        await sendResetPasswordCode(lowerEmail);
        setEmail(lowerEmail);
        setStatus(Status.GET_CODE);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      }
    },
    [setEmail, setStatus, setError],
  );

  const handleSubmitCode = useCallback(
    async (code: string, password: string): Promise<void> => {
      try {
        if (email) {
          await resetPassword(email, code, password);
          setStatus(Status.DONE);
        } else {
          // for some reason we have no email - redirect back to get email form
          setStatus(Status.GET_EMAIL);
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      }
    },
    [email, setStatus, setError],
  );

  let content: JSX.Element | null = null;

  if (status === Status.GET_EMAIL) {
    content = (
      <>
        <Paragraph>
          If you have forgotten your password we can send you a code to your email address so that
          you can set a new one.
        </Paragraph>
        <ForgotPasswordEmailForm
          onSubmit={handleSubmitEmail}
          formError={error}
          onClickBack={handleClickBack}
        />
      </>
    );
  } else if (status === Status.GET_CODE) {
    content = (
      <>
        <Paragraph>
          We just emailed a code to your email address. Please enter it below along with your new
          password.
        </Paragraph>
        <ForgotPasswordCodeForm onSubmit={handleSubmitCode} formError={error} />
      </>
    );
  } else if (status === Status.DONE) {
    content = (
      <>
        <Paragraph>Your password has been set. You can now log in.</Paragraph>
        <GoBackButton size={210} onClick={handleClickBack}>
          Go Back
        </GoBackButton>
      </>
    );
  }

  return (
    <MainWrapper>
      <Container>
        {status !== Status.DONE && <MobileBackLink onClick={handleClickBack}>Back</MobileBackLink>}

        <Row>
          <Col xl={6} offset={{ xl: 3 }}>
            <Heading2>Forgot your Password?</Heading2>
            {content}
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  );
};
