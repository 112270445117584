import styled from "styled-components";

import { CrossButton } from "../buttons/cross";

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  overflow: visible;
`;

const Image = styled.img`
  height: 100px;
`;

const CloseWrapper = styled.div`
  background-color: ${({ theme }): string => theme.colours.spotGold};
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

interface IProps {
  onClick(): void;
  src: string;
}

export const ImageWithClose: React.FC<IProps> = ({ src, onClick }) => (
  <Wrapper>
    <Image src={src} alt="Click cross to remove" />
    <CloseWrapper>
      <CrossButton onClick={onClick} colour="white" />
    </CloseWrapper>
  </Wrapper>
);
