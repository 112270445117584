/* eslint-disable func-names */
import * as Yup from "yup";

export const LETTER_CONTENT_FORM_PS_OTHER_ID = "OTHER";

/**
 * Validation rules for sending form
 */
export const sendingValidationRules = {
  firstNameId: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  ageYears: Yup.number()
    .min(0)
    .max(200, "Are they really that old?!")
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageMonths } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageMonths)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  ageMonths: Yup.number()
    .min(0)
    .max(36, "The letter will read better if you use years")
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageYears } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageYears)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  boyGirl: Yup.string().nullable().required("Required"),
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string(),
  addressTown: Yup.string().required("Required"),
  addressCounty: Yup.string(),
  addressUsStateUrn: Yup.string(),
  addressPostcode: Yup.string().required("Required"),
  addressCountryUrn: Yup.string().required("Required"),
  date: Yup.date().required("Required"),
};

declare module "yup" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface StringSchema {
    requiredIfPsOtherSelected(msg: string): StringSchema;
  }
}

/**
 * Formik rule that returns false if psID field is set to OTHER and field is empty
 * @param this formik object
 * @param message error message to return
 */
function requiredIfPsOtherSelected(this: Yup.StringSchema, message: string): Yup.StringSchema {
  return this.test({
    name: "requiredIfPsOtherSelected",
    message,
    // eslint-disable-next-line object-shorthand
    test: function (value?: string): boolean {
      const { psId } = this.parent;

      if (psId === LETTER_CONTENT_FORM_PS_OTHER_ID) {
        return Boolean(value);
      }

      return true;
    },
  });
}

Yup.addMethod(Yup.string, "requiredIfPsOtherSelected", requiredIfPsOtherSelected);

/**
 * Validation rules for content form
 */
export const contentValidationRules = {
  hobby: Yup.string().max(50, "Maximum 50 characters"),
  gift: Yup.string().max(50, "Maximum 50 characters"),
  friend: Yup.string().max(50, "Maximum 50 characters"),
  templateId: Yup.string().required("Required"),
  signatureId: Yup.string().required("Required"),
  frontDoorId: Yup.string(),
  pet1Id: Yup.string(),
  pet1Name: Yup.string(),
  pet2Id: Yup.string(),
  pet2Name: Yup.string(),
  psId: Yup.string(),
  psCustom: Yup.string().max(105).requiredIfPsOtherSelected("Required"),
};

/**
 * Validation schema for sending form
 */
export const sendingValidationSchema = Yup.object().shape(sendingValidationRules);

/**
 * Validation schema for content form
 */
export const contentValidationSchema = Yup.object().shape(contentValidationRules);

/**
 * Validation schema for entire form
 */
export const validationSchema = Yup.object().shape({
  ...sendingValidationRules,
  ...contentValidationRules,
});
