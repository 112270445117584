import { Field, Form, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import * as yup from "yup";

import { BowButton } from "../../../atoms/bow-button";
import { BackLink } from "../../../molecules/back-link";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { ContentPanel } from "../../../atoms/content-panel";
import { Heading3 } from "../../../atoms/text";
import { FormikFormInputTextarea } from "../../../molecules/form/form-input-textarea";
import { config } from "../../../../config";
import { ButtonLink } from "../../../atoms/button-link";
import { device } from "../../../../utils/media-queries";

const Panel = styled(ContentPanel)`
  > h3 {
    :first-child {
      margin-top: 0;
      font-weight: normal;
    }
  }

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

const ButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;

  @media ${device.mobileS} {
    justify-content: space-between;
  }

  @media ${device.laptopL} {
    justify-content: flex-end;

    & > * {
      margin-left: 30px;
    }
  }
`;

const validationRules = {
  bonusBody: yup.string().required(),
};

const validationSchema = yup.object({
  ...validationRules,
});

export type TextBonusValidatedFormModel = yup.Asserts<typeof validationSchema>;

interface IFormSectionModel {
  bonusBody: string;
}

type FormModel = IFormSectionModel;

interface IOwnProps {
  defaultValues: FormModel;
  price: string;
  onChange(values: TextBonusValidatedFormModel): void;
  onSubmit(values: TextBonusValidatedFormModel): void;
  onClickSkip(): void;
  onClickBack(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({ price, values, submitForm, onChange, onClickBack, onClickSkip }) => {
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => onChange(values), [values, onChange]);

  return (
    <Form>
      <Panel>
        <Heading3>Add Your Bonus Message ({price} extra)</Heading3>
        <Row>
          <Col>
            <FormFieldWrapper label="Message text" name="bonusBody" required>
              <Field
                name="bonusBody"
                component={FormikFormInputTextarea}
                placeholder="Type your message here"
                maxLength={config.texts.messageMaxLength}
                rows={4}
              />
            </FormFieldWrapper>
          </Col>
        </Row>
      </Panel>

      <SubmitRow>
        <Visible md lg xl>
          <BackLink label="Back" onClick={onClickBack} />
        </Visible>
        <ButtonCol>
          <ButtonLink label="Skip" onClick={onClickSkip} />
          <BowButton size={210} onClick={submitForm} disabled={!isValid}>
            Next Step
          </BowButton>
        </ButtonCol>
      </SubmitRow>
    </Form>
  );
};

export const TextsDetailsBonusForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as TextBonusValidatedFormModel),
})(C);
