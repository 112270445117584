import { Form, FormikProps, withFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { FormSubmitRow } from "../../../../molecules/form-submit-row";
import {
  FormSectionVideoImages,
  validationRules,
  IFormSectionModel,
} from "../../../form-sections/video/form-section-video-images";
import { ButtonLink } from "../../../../atoms/button-link";

const validationSchema = yup.object({
  ...validationRules,
});

export type VideoImagesValidatedFormModel = yup.Asserts<typeof validationSchema>;

type FormModel = IFormSectionModel;

interface IOwnProps {
  defaultValues: FormModel;
  onChange(values: VideoImagesValidatedFormModel): void;
  onSubmit(values: VideoImagesValidatedFormModel): void;
  onClickBack(): void;
  onClickSkip(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  onClickBack,
  submitForm,
  setFieldValue,
  onChange,
  onClickSkip,
}) => {
  const { t } = useTranslation();

  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as FormModel);
    }
  }, [values]);

  return (
    <Form>
      <FormSectionVideoImages setFieldValue={setFieldValue} values={values} />
      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
        submitLabel={t("video.form.submit.label")}
        size={210}
      >
        <ButtonLink label="Skip" onClick={onClickSkip} />
      </FormSubmitRow>
    </Form>
  );
};

export const VideoImagesForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  validateOnMount: true,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as VideoImagesValidatedFormModel),
})(C);
