import styled from "styled-components";

import { SiteCountryCode } from "../../../types/graphql";
import { SpanText } from "../../atoms/text";
import { config } from "../../../config";

import background from "./background.png";

const Container = styled.span`
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 140px;
  height: 52px;

  img {
    z-index: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 151px;
    height: 66px;
  }
`;

const Currency = styled(SpanText)`
  font-size: ${({ theme }): string => theme.fontSizes.size24};
  position: absolute;
  left: 18px;
  top: 12px;
  color: white;
  text-shadow: 2px 2px 0 #c30000;
  z-index: 1;
`;

const Price = styled(SpanText)`
  display: block;
  z-index: 1;
  color: black;
  margin-left: 60px;
  font-size: ${({ theme }): string => theme.fontSizes.size24};
`;

const symbol = config.site.country === SiteCountryCode.GB ? "£" : "$";

interface IProps {
  price: number;
  className?: string;
}

export const BowPrice: React.FC<IProps> = props => (
  <Container className={props.className}>
    <img src={background} alt="Decoration with a bow" />
    <Currency>{symbol}</Currency>
    <Price>{props.price.toFixed(2)}</Price>
  </Container>
);
