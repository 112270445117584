import styled from "styled-components";

import { hashStringToInteger } from "../../../utils/string";

const colours = [
  ["ab3f00", "f75c03"],
  ["33658a", "4688b9"],
  ["5f8498", "86bbd8"],
  ["4c5c34", "758e4f"],
  ["8f651a", "f6ae2d"],
];

const getRgbForIndex = (text: string, offset: number): string => {
  const index = hashStringToInteger(text, colours.length - 1);

  return colours[index][offset];
};

const Wrapper = styled.div<{ label: string }>`
  background: #${({ label }): string => getRgbForIndex(label, 0)};
  background: linear-gradient(
    0deg,
    #${({ label }): string => getRgbForIndex(label, 0)} 0%,
    #${({ label }): string => getRgbForIndex(label, 1)} 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Label = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin-top: -0.1em;
`;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  initials: string;
}

export const AvatarPlaceholder: React.FC<IProps> = ({ initials, className }) => (
  <Wrapper label={initials!} className={className}>
    <Label>{initials}</Label>
  </Wrapper>
);
