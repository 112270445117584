import { useApolloClient } from "@apollo/client";
import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Visible } from "react-grid-system";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { device } from "../../../utils/media-queries";
import { Logo } from "../../atoms/logo";
import { MyAccountButton } from "../../atoms/my-account-button";
import { BasketButton } from "../../molecules/buttons/basket-button";
import { AccountWidget } from "../account-widget";
import { TopNavigation } from "../top-navigation";
import { logout, IUserProfile } from "../../../model/authentication";
import { HamburgerMenu } from "../../atoms/hamburger-menu";
import { initialsFromString } from "../../../utils/string";

const NAVIGATION_SCROLL_THRESHOLD = 80;
const DELAY_SECONDS = 0.3;

const HeaderOuterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  transition: all ${DELAY_SECONDS}s;

  @media ${device.mobileS} {
    height: 80px;
  }

  @media ${device.laptopL} {
    height: 178px;
  }

  [data-santa-id="top-navigation-menu"] {
    a {
      transition: all ${DELAY_SECONDS}s;
    }
  }

  [data-santa-id="red-logo"] {
    transition: all ${DELAY_SECONDS}s;
    opacity: 1;
  }

  [data-santa-id="yellow-logo"] {
    transition: all ${DELAY_SECONDS}s;
    opacity: 0;
  }

  &.short {
    height: 80px;
  }

  &.light {
    background: transparent;

    [data-santa-id="hamburger-menu"] {
      fill: white;
    }

    [data-santa-id="red-logo"] {
      opacity: 0;
    }

    [data-santa-id="yellow-logo"] {
      opacity: 1;
    }

    [data-santa-id="top-navigation-menu"] {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      a {
        color: ${({ theme }): string => theme.colours.navigation.light};
      }

      a:after {
        background-color: ${({ theme }): string => theme.colours.navigation.light};
      }
    }

    [data-santa-id="my-account-button"] {
      border-color: ${({ theme }): string => theme.colours.navigation.light};

      [data-santa-id="my-account-button-label"] {
        color: ${({ theme }): string => theme.colours.navigation.light};
      }

      [data-santa-id="my-account-button-icon-red"] {
        display: none;
      }

      [data-santa-id="my-account-button-icon-white"] {
        display: inline-block;
      }
    }

    [data-santa-id="account-widget-name"],
    [data-santa-id="dropdown-label-letter"],
    [data-santa-id="dropdown-label-call"] {
      color: ${({ theme }): string => theme.colours.navigation.light};
    }
  }
`;

const LogoCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const GridContainer = styled(Container)`
  flex: 1;
`;

const LogoContainer = styled.div`
  position: relative;

  @media ${device.mobileS} {
    width: 165px;
    height: 38px;
  }

  @media ${device.laptopL} {
    width: 250px;
    height: 57px;
  }

  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const NavigationCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > ul {
    margin-top: 10px;
  }

  > * {
    :not(:last-child) {
      margin-right: 24px;
    }
  }
`;

interface IProps {
  basketCount: number;
  onClickMobileMenu(): void;
  profile?: IUserProfile;
}

export const Header: React.FC<IProps> = ({ basketCount, profile, onClickMobileMenu }) => {
  const history = useHistory();
  const client = useApolloClient();

  const [headerShort, setHeaderShort] = useState<boolean>(false);

  useEffect(() => {
    const handlePosition = (): void => {
      if (window.scrollY >= NAVIGATION_SCROLL_THRESHOLD && !headerShort) {
        setHeaderShort(true);
      } else if (window.scrollY < NAVIGATION_SCROLL_THRESHOLD && headerShort) {
        setHeaderShort(false);
      }
    };

    window.onscroll = (): void => handlePosition();

    // set initial state
    handlePosition();
  }, [headerShort]);

  const lightNavPagesRegex = new RegExp("^(/|/about|/privacy-policy|/support|/terms|/affiliates)$");
  const light = lightNavPagesRegex.test(history.location.pathname);

  const handleClickBasket = useCallback((): void => history.push("/basket"), [history]);

  let accountButton = <MyAccountButton />;

  const handleLogOut = useCallback(async (): Promise<void> => await logout(client), [client]);
  const handleClickMyAccount = useCallback((): void => history.push("/my-account"), [history]);

  if (profile) {
    let initials = profile.email.substr(0, 1).toUpperCase();

    if (profile.firstName && profile.lastName) {
      initials = initialsFromString(`${profile.firstName} ${profile.lastName}`);
    }

    accountButton = (
      <AccountWidget
        name={profile.firstName}
        initials={initials}
        onClickLogOut={handleLogOut}
        onClickProfile={handleClickMyAccount}
      />
    );
  }

  return (
    <HeaderOuterContainer>
      <HeaderContainer className={headerShort ? "short" : light ? "light" : ""}>
        <GridContainer>
          <Row align="center">
            <LogoCol xl={3} xs={12}>
              <Visible xs md lg>
                <HamburgerMenu onClick={onClickMobileMenu} count={basketCount} />
              </Visible>
              <Link to="/">
                <LogoContainer>
                  <Logo type="yellow" id="yellow-logo" />
                  <Logo type="red" id="red-logo" />
                </LogoContainer>
              </Link>
            </LogoCol>
            <Visible xl>
              <NavigationCol md={9}>
                <TopNavigation />
                <BasketButton onClick={handleClickBasket} count={basketCount} />
                {accountButton}
              </NavigationCol>
            </Visible>
          </Row>
        </GridContainer>
      </HeaderContainer>
    </HeaderOuterContainer>
  );
};
