import { CallType } from "@santa/common/lib/products/calls";
import React, { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { routePaths } from "../../../model/route";

import { CallsTypeHome } from "./calls-type-home";
import { CallsDetailsCalling } from "./calls-details-calling";
import { CallsDetailsRecipient } from "./calls-details-recipient";
import { CallsAddOns } from "./calls-add-ons";

export const Calls: React.FC = () => {
  const { AUDIO: audioPaths, VIDEO: videoPaths } = routePaths.calls;

  return (
    <Switch>
      <Route exact path={routePaths.calls.home}>
        <Redirect to={audioPaths.home} />
      </Route>
      <Route
        exact
        path={audioPaths.home}
        component={useCallback(
          (): JSX.Element => (
            <CallsTypeHome type={CallType.AUDIO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={audioPaths.calling}
        component={useCallback(
          (): JSX.Element => (
            <CallsDetailsCalling type={CallType.AUDIO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={audioPaths.recipient}
        component={useCallback(
          (): JSX.Element => (
            <CallsDetailsRecipient type={CallType.AUDIO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={audioPaths.addOns}
        component={useCallback(
          (): JSX.Element => (
            <CallsAddOns type={CallType.AUDIO} />
          ),
          [],
        )}
      />

      <Route
        exact
        path={videoPaths.home}
        component={useCallback(
          (): JSX.Element => (
            <CallsTypeHome type={CallType.VIDEO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={videoPaths.calling}
        component={useCallback(
          (): JSX.Element => (
            <CallsDetailsCalling type={CallType.VIDEO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={videoPaths.recipient}
        component={useCallback(
          (): JSX.Element => (
            <CallsDetailsRecipient type={CallType.VIDEO} />
          ),
          [],
        )}
      />
      <Route
        exact
        path={videoPaths.addOns}
        component={useCallback(
          (): JSX.Element => (
            <CallsAddOns type={CallType.VIDEO} />
          ),
          [],
        )}
      />
    </Switch>
  );
};
