import { CallType } from "@santa/common/lib/products/calls";
import { Col, Container, Row, Hidden } from "react-grid-system";
import styled from "styled-components";

import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { Paragraph } from "../../../atoms/text";
import { CallsFrameImage, CallVideoFrameImage } from "../../../atoms/calls-frame-image";
import { CallsDetailsCallingForm } from "../../../organisms/forms/calls-details-calling";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { StageTitle } from "../../../atoms/stage-title";
import { Accordion } from "../../../molecules/accordion";

import { useData } from "./use-data";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

interface IProps {
  type: CallType;
}

export const CallsDetailsCalling: React.FC<IProps> = ({ type }) => {
  const { formProps, formError, isLoading, faqs, refetch } = useData(type);

  return (
    <MainWrapper>
      <Container>
        <Row>
          <Col md={6}>
            <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

            <Row>
              <Col>
                <StageTitle totalStages={3} currentStage={1}>
                  Santa needs...
                </StageTitle>
              </Col>
            </Row>

            <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
              {faqs && <Accordion items={faqs} />}
              {formError && <Error>{formError}</Error>}
              <CallsDetailsCallingForm {...formProps} />
            </DataLoadedContainer>
          </Col>
          <Hidden xs>
            <Col md={6}>
              {type === CallType.VIDEO ? <CallVideoFrameImage /> : <CallsFrameImage />}
            </Col>
          </Hidden>
        </Row>
      </Container>
    </MainWrapper>
  );
};
