import { ISelectOption } from "@santa/common/lib/utils/form";
import * as yup from "yup";
import { FormikErrors, FormikTouched } from "formik";
import { Col, Row } from "react-grid-system";

import { FormFieldGift } from "../../../../molecules/form/form-fields/form-field-gift";
import { FormFieldFrontDoor } from "../../../../molecules/form/form-fields/form-field-front-door";
import { FormFieldPetType } from "../../../../molecules/form/form-fields/form-field-pet-type";
import { FormFieldHobby } from "../../../../molecules/form/form-fields/form-field-hobby";
import { FormFieldCountry } from "../../../../molecules/form/form-fields/form-field-country";
import { FormFieldFirstName } from "../../../../molecules/form/form-fields/form-field-first-name";
import { FormFieldAgeYears } from "../../../../molecules/form/form-fields/form-field-age-years";

export const validationRules = {
  firstName: yup.object({
    value: yup.string().required("Required"),
    label: yup.string(),
  }),
  ageYears: yup
    .number()
    .min(0)
    .max(200, "Are they really that old?!")
    // eslint-disable-next-line func-names
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageMonths } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageMonths)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .required("Required")
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  hobby: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable(),
  gift: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable(),
  frontDoorId: yup.string().optional(),
  petId: yup.string().optional(),
  countryUrn: yup.string().required("Required"),
};

export interface IFormSectionModel {
  firstName?: ISelectOption;
  ageYears?: string;
  hobby?: ISelectOption;
  gift?: ISelectOption;
  frontDoorId?: string;
  petId?: string;
  countryUrn?: string;
}

interface IProps {
  frontDoors: ISelectOption[];
  pets: ISelectOption[];
  countries: ISelectOption[];
  errors: FormikErrors<IFormSectionModel>;
  touched: FormikTouched<IFormSectionModel>;
}

/**
 * Form section for video message recipient
 */
export const FormSectionVideoRecipient: React.FC<IProps> = ({ frontDoors, pets, countries }) => (
  <>
    <Row>
      <Col xl={6}>
        <FormFieldFirstName />
      </Col>
      <Col xl={6}>
        <FormFieldAgeYears />
      </Col>
    </Row>
    <Row>
      <Col xl={6}>
        <FormFieldGift />
      </Col>
      <Col xl={6}>
        <FormFieldHobby />
      </Col>
    </Row>
    <Row>
      <Col xl={6}>
        <FormFieldFrontDoor options={frontDoors} />
      </Col>
      <Col xl={6}>
        <FormFieldPetType options={pets} />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormFieldCountry options={countries} required />
      </Col>
    </Row>
  </>
);
