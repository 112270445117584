import styled from "styled-components";
import { Link } from "react-router-dom";

import { Heading3, Paragraph } from "../../atoms/text";
import { SiteCountryCode } from "../../../types/graphql";
import { displayPrice } from "../../../utils/price";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Image = styled.div<{ url: string }>`
  background-image: url(${({ url }): string => url});
  background-size: cover;
  background-position: center center;
  min-width: 48px;
  height: 64px;
  margin-right: 24px;
`;

const DetailsWrapper = styled.div`
  flex: 1;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  > * {
    margin-top: -6px;
    margin-bottom: 0;
  }
`;

const Price = styled(Heading3)`
  font-weight: normal;
  color: ${({ theme }): string => theme.colours.red};
`;

interface IProps {
  image: string;
  title: string;
  price: number;
  site: SiteCountryCode;
  description: string;
  editLink?: string;
}

export const MyAccountOrderItem: React.FC<IProps> = ({
  image,
  title,
  price,
  site,
  description,
  editLink,
}) => (
  <Wrapper>
    <Image url={image} />
    <DetailsWrapper>
      <HeadingContainer>
        <Heading3>{title}</Heading3>
        <Price>{displayPrice(price, site)}</Price>
      </HeadingContainer>
      <Paragraph>
        {description}
        {editLink && <Link to={editLink}> - Edit</Link>}
      </Paragraph>
    </DetailsWrapper>
  </Wrapper>
);
