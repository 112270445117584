import Amplify from "aws-amplify";
import ReactDOM from "react-dom";
import { hotjar } from "react-hotjar";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";

import { App } from "./components/app";
import { config } from "./config";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

// Non-React specific initialisation should go here

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

if (typeof config.sentry !== "undefined") {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env,
    release: config.sentry.release,
  });
}

if (typeof config.hotjar !== "undefined") {
  hotjar.initialize(config.hotjar.id, config.hotjar.snippetVersion);
}

if (typeof config.tracking.google !== "undefined") {
  TagManager.initialize({
    gtmId: config.tracking.google.accountId,
  });
}

ReactDOM.render(<App />, document.getElementById("app-root"));

if (module.hot) {
  module.hot.accept("./components/app", () => {
    const NextApp = require("./components/app").App;
    ReactDOM.render(<NextApp />, document.getElementById("app-root"));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister().catch(console.error);
