import styled from "styled-components";

import { ReactComponent as CrossSvg } from "./cross.svg";

const Container = styled.div`
  padding: 5px;
  margin: -5px;
  cursor: pointer;
`;

interface ICrossProps {
  colour?: string;
}

const Cross = styled(CrossSvg)<ICrossProps>`
  width: 14px;
  height: 14px;

  path {
    fill: ${({ colour, theme }): string =>
      typeof colour !== "undefined" ? colour : theme.colours.icon};
  }
`;

interface IProps {
  className?: string;
  colour?: string;
  onClick(): void;
}

export const CrossButton: React.FC<IProps> = ({ onClick, className, colour }) => (
  <Container onClick={onClick} className={className}>
    <Cross colour={colour} />
  </Container>
);
