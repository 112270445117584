import axios from "axios";
import httpStatus from "http-status";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";

import { config } from "../../config";
// import { zipSearchQuery } from "../graphql/queries/misc";
// import { ZipSearchQuery, ZipSearchQueryVariables } from "../../types/graphql/zip-search-query";

/* eslint-disable @typescript-eslint/naming-convention */
interface IApiAddressesResponse {
  latitude: number;
  longitude: number;
  postcode: string;
  formatted_address: string[];
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  town_or_city: string;
  county: string;
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: string;
  locality: string;
  district: string;
  country: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

interface IAutoCompleteAddressResponse {
  suggestions: {
    address: string;
    id: string;
    url: string;
  }[];
}

interface IUsApiAddress {
  city: string;
  entries: number;
  secondary: string;
  state: string;
  street_line: string;
  zipcode: string;
}

interface ISmartyAddressAutocompleteResponse {
  suggestions: IUsApiAddress[];
}

export type SearchableCountry = "gb" | "us";

export interface IAutoCompleteAddress {
  address: string;
  id: string;
  country: SearchableCountry;
}

export interface IApiAddress {
  line1?: string;
  line2?: string;
  town: string;
  county?: string;
  stateUrn?: string;
  postcode: string;
}

/**
 * Fetch addresses by UK postcode
 * @param search Postcode to fetch addresses for
 */
export const fetchAddresses = async (
  countryUrn: SearchableCountry,
  search: string,
): Promise<IAutoCompleteAddress[]> => {
  try {
    if (countryUrn === "gb") {
      const response = await axios.get<IAutoCompleteAddressResponse>(
        `https://api.getAddress.io/autocomplete/${search}?api-key=${config.addressApi.gbKey}&expand=true&all=true`,
      );

      if (response.status === httpStatus.OK && response.data.suggestions) {
        return response.data.suggestions.map(a => ({
          address: a.address,
          id: a.id,
          country: countryUrn,
        }));
      }
    } else {
      const response = await axios.get<ISmartyAddressAutocompleteResponse>(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${config.addressApi.usKey}&search=${search}`,
      );

      if (response.status === httpStatus.OK && response.data.suggestions) {
        return response.data.suggestions.map(a => ({
          address: `${a.secondary && `${a.secondary}, `}${a.street_line}, ${a.city} ${a.zipcode}`,
          id: JSON.stringify(a),
          country: countryUrn,
        }));
      }
    }
  } catch {
    // no catch
  }

  return [];
};

/**
 * Create a string for address line from parts
 * @param parts Parts to build line from
 */
const joinFrom = (parts: string[]): string => parts.filter(p => Boolean(p)).join(", ");

/**
 * Get address using supplied ID
 * @param address Address to get
 */
export const getAddress = async (
  address: IAutoCompleteAddress,
): Promise<IApiAddress | undefined> => {
  try {
    if (address.country === "gb") {
      const response = await axios.get<IApiAddressesResponse>(
        `https://api.getAddress.io/get/${address.id}?api-key=${config.addressApi.gbKey}&expand=true`,
      );

      if (response.status === httpStatus.OK) {
        const { data } = response;
        return {
          line1: joinFrom([data.line_1, data.line_2]),
          line2: joinFrom([data.line_3, data.locality]),
          town: data.town_or_city,
          county: data.county,
          postcode: data.postcode,
        };
      }
    } else {
      const fetchedAddress: IUsApiAddress = JSON.parse(address.id);

      return Promise.resolve({
        line1: fetchedAddress.secondary ? fetchedAddress.secondary : fetchedAddress.street_line,
        line2: fetchedAddress.secondary ? fetchedAddress.street_line : "",
        town: fetchedAddress.city,
        stateUrn: createUrn(UrnResource.US_STATE, fetchedAddress.state.toLowerCase()),
        postcode: fetchedAddress.zipcode,
      });
    }
  } catch {
    // no catch
  }
};
