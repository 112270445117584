import * as dateFns from "date-fns";

/**
 * Return the number of days until next Christmas
 */
export const daysUntilChristmas = (): number => {
  const now = dateFns.startOfDay(new Date());

  const year =
    dateFns.getMonth(now) === 11 && dateFns.getDate(now) > 25
      ? dateFns.getYear(now) + 1
      : dateFns.getYear(now);

  const nextChristmas = dateFns.parseISO(`${year}-12-25`);

  return Math.abs(Math.ceil(dateFns.differenceInDays(now, nextChristmas)));
};
