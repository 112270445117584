import styled from "styled-components";

import { ReactComponent as WarningImage } from "./warning.svg";

interface IProps {
  colour?: string;
}

export const WarningIcon = styled(WarningImage)<IProps>`
  width: 100px;
  height: 100px;

  path {
    fill: ${({ colour, theme }): string =>
      typeof colour !== "undefined" ? colour : theme.colours.icon};
  }
`;
