import { CallType } from "@santa/common/lib/products/calls";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik, FormikErrors } from "formik";
import { useCallback } from "react";
import * as yup from "yup";
import styled from "styled-components";

import { Paragraph } from "../../../atoms/text";
import { CallDetailsCallingInput } from "../../../../model/call";
import { config } from "../../../../config";
import { FormSubmitRow } from "../../../molecules/form-submit-row";
import {
  FormSectionCallCalling,
  audioValidationRules,
  videoValidationRules,
} from "../../form-sections/call/form-section-call-calling";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const audioValidationSchema = yup.object(audioValidationRules);
const videoValidationSchema = yup.object(videoValidationRules);

const getValidationSchema = (
  type: CallType,
): typeof audioValidationSchema | typeof videoValidationSchema =>
  type === CallType.AUDIO ? audioValidationSchema : videoValidationSchema;

export type AudioCallScheduleFormModel = yup.Asserts<typeof audioValidationSchema>;
export type VideoCallScheduleFormModel = yup.Asserts<typeof videoValidationSchema>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialModel = (type: CallType): any => ({
  // CallScheduleFormModel = {
  ...(type === CallType.AUDIO && { phoneCountryUrn: config.defaultCountry }),
  timezoneUrn: config.defaultTimezone,
  date: "",
  time: "",
});

interface IOwnProps {
  type: CallType;
  defaultValues?: CallDetailsCallingInput;
  dates?: Date[];
  slotOptions?: ISelectOption[];
  countryOptions: ISelectOption[];
  timezoneOptions: ISelectOption[];
  hasDoubleSlots: boolean;
  formError?: string;
  onSubmit(
    values: CallDetailsCallingInput,
    setFormError: (e: FormikErrors<AudioCallScheduleFormModel>) => void,
  ): void;
  onClickBack(): void;
  onChangeCountry(urn: string, setField: FormikProps<{}>["setFieldValue"]): void;
  onChangeTimezone(urn: string): void;
  onChangeDate(date: string): void;
}

type Props = FormikProps<AudioCallScheduleFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const {
    type,
    dates,
    slotOptions,
    values,
    countryOptions,
    timezoneOptions,
    hasDoubleSlots,
    formError,
    onChangeTimezone,
    setFieldValue,
    submitForm,
    onChangeCountry,
    onChangeDate,
  } = props;

  const isValid = getValidationSchema(type).isValidSync(values);

  const handleChangeCountry = useCallback(
    (urn: string): void => onChangeCountry(urn, setFieldValue),
    [onChangeCountry, setFieldValue],
  );

  return (
    <Form>
      {formError && <Error>{formError}</Error>}

      <FormSectionCallCalling
        type={type}
        countryOptions={countryOptions}
        timezoneOptions={timezoneOptions}
        slotOptions={slotOptions}
        dates={dates}
        hasDoubleSlots={hasDoubleSlots}
        showMeetingIdNote={type === CallType.VIDEO}
        onChangeCountry={handleChangeCountry}
        onChangeTimezone={onChangeTimezone}
        onChangeDate={onChangeDate}
      />

      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickBack={props.onClickBack}
        onClickSubmit={submitForm}
      />
    </Form>
  );
};

export const CallsDetailsCallingForm = withFormik<IOwnProps, AudioCallScheduleFormModel>({
  validationSchema: ({ type }: IOwnProps) => getValidationSchema(type),
  mapPropsToValues: props => props.defaultValues || initialModel(props.type),
  isInitialValid: props =>
    getValidationSchema(props.type).isValidSync(props.defaultValues || initialModel),
  handleSubmit: async (values, { props: { onSubmit }, setErrors }) => onSubmit(values, setErrors),
})(C);
