import { Row, Col } from "react-grid-system";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { UrnResource, createUrn } from "@santa/common/lib/utils/urn";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";
import { DataLoadedContainerOld, checkData } from "../../../../control/data-loaded-container";
import { FormikFormInputSelect } from "../../form-input-select";
import { useAddressUsStatesQuery } from "../../../../../types/graphql";

interface IState {
  urn: string;
  name: string;
}

/**
 * Map US states from the API to select option
 * @param states Lisr of US states from the API
 */
const statesAsISelectOptions = (states: IState[]): ISelectOption[] =>
  states.reduce<ISelectOption[]>(
    (acc, s) => [
      ...acc,
      {
        value: s.urn,
        label: s.name,
      },
    ],
    [
      {
        value: "",
        label: "Please select",
      },
    ],
  );

const usUrn = createUrn(UrnResource.COUNTRY, "us");

interface IProps {
  countryUrn: string;
}

export const AddressFieldsFormPartial: React.FC<IProps> = ({ countryUrn }) => {
  const { t } = useTranslation();

  // graphql data
  const response = useAddressUsStatesQuery({
    fetchPolicy: "cache-and-network",
  });

  const translationCountryIdentifier = countryUrn === usUrn ? "us" : "other";

  let countyField: JSX.Element;

  if (countryUrn === usUrn) {
    countyField = (
      <DataLoadedContainerOld response={response}>
        {checkData(response.data) && (
          <FormFieldWrapper label={t(`form.address.state.label`)} name="addressStateUrn">
            <Field
              name="addressUsStateUrn"
              component={FormikFormInputSelect}
              options={statesAsISelectOptions(response.data.usStates)}
            />
          </FormFieldWrapper>
        )}
      </DataLoadedContainerOld>
    );
  } else {
    countyField = (
      <FormFieldWrapper label={t(`form.address.county.label`)} name="addressCounty">
        <Field name="addressCounty" component={FormikFormInputText} />
      </FormFieldWrapper>
    );
  }

  return (
    <>
      <Row>
        <Col xl={6}>
          <FormFieldWrapper label={t("form.address.address1.label")} name="addressLine1" required>
            <Field name="addressLine1" component={FormikFormInputText} />
          </FormFieldWrapper>
        </Col>
        <Col xl={6}>
          <FormFieldWrapper label={t("form.address.address2.label")} name="addressLine2">
            <Field name="addressLine2" component={FormikFormInputText} />
          </FormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormFieldWrapper
            label={t(`form.address.town-${translationCountryIdentifier}.label`)}
            name="addressTown"
            required
          >
            <Field name="addressTown" component={FormikFormInputText} />
          </FormFieldWrapper>
        </Col>
        <Col xl={6}>{countyField}</Col>
      </Row>
      <Row>
        <Col>
          <FormFieldWrapper
            label={t(`form.address.postcode-${translationCountryIdentifier}.label`)}
            name="addressPostcode"
            required
          >
            <Field name="addressPostcode" component={FormikFormInputText} />
          </FormFieldWrapper>
        </Col>
      </Row>
    </>
  );
};
