import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelect } from "../../form-input-select";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: ISelectOption[];
  required?: boolean;
}

export const FormFieldSignature: React.FC<IProps> = ({
  options,
  id = "signatureId",
  label = "How should Santa sign the letter?",
  placeholder = "Select signature",
  required = true,
}) => (
  <FormFieldWrapper label={label} required={required}>
    <Field
      name={id}
      placeholder={placeholder}
      component={FormikFormInputSelect}
      options={options}
    />
  </FormFieldWrapper>
);
