export interface ISelectOption {
  value: string;
  label: string;
}

/**
 * Map an array of objects to an array of objects compatible with select drop downs
 * specifying the keys to be used for the value and the label
 * @param items the object to map from
 * @param valueField The field in the object containing the value
 * @param labelField The field in the object containing the label
 */
export const mapObjectsToSelectOptions = <
  T extends { [key in VF | LF]?: string | null },
  VF extends keyof T,
  LF extends keyof T,
>(
  items: T[],
  valueField: VF,
  labelField: LF,
): ISelectOption[] => {
  if (!items) {
    return [];
  }

  return items.map<ISelectOption>(o => ({
    value: (o[valueField] || "") as string,
    label: (o[labelField] || "") as string,
  }));
};

export interface ISelectMultilineOption {
  value: string;
  label: string;
  description: string;
}

/**
 * Map an array of objects to an array of objects compatible with select drop downs
 * specifying the keys to be used for the value and the label
 * @param items the object to map from
 * @param valueField The field in the object containing the value
 * @param labelField The field in the object containing the label
 * @param descriptionField The field in the object containing the description
 */
export const mapObjectsToSelectMultilineOptions = <
  T extends { [key in VF | LF | DF]?: string | null },
  VF extends keyof T,
  LF extends keyof T,
  DF extends keyof T,
>(
  items: T[],
  valueField: VF,
  labelField: LF,
  descriptionField: DF,
): ISelectMultilineOption[] => {
  if (typeof items === "undefined") {
    return [];
  }

  return items.map<ISelectMultilineOption>(o => ({
    value: (o[valueField] || "") as string,
    label: (o[labelField] || "") as string,
    description: (o[descriptionField] || "") as string,
  }));
};
