import React, { useCallback } from "react";
import { Hidden } from "react-grid-system";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { HomeProductSection } from "../";
import { device } from "../../../../utils/media-queries";
import { routePaths } from "../../../../model/route";

import letterVideoImage from "./letter-video.png";
import reindeerImage from "./reindeer.png";
import sledImage from "./sled.png";

const ReindeerImage = styled.img`
  z-index: 6;
  position: absolute;

  right: 20px;
  top: 230px;
  width: 69px;
  height: 66px;

  @media ${device.laptopL} {
    left: 20px;
    top: 335px;
    width: 89px;
    height: 86px;
  }
`;

const SledImage = styled.img`
  z-index: -1;
  position: absolute;
  right: -142px;
  top: 76px;
  width: 393px;
  height: 290px;
`;

interface IProps {
  title: string;
  bullets: string;
  align: "left" | "right";
}

export const SantaLetterHomeSection: React.FC<IProps> = ({ title, bullets, align }) => {
  const history = useHistory();

  const handleClickLearnMore = useCallback(
    (): void => history.push(routePaths.letter.types.classic),
    [history],
  );
  const onClickBuy = useCallback((): void => history.push(routePaths.letter.content), [history]);

  return (
    <HomeProductSection
      title={title}
      videoId="letters"
      bullets={bullets}
      align={align}
      videoImage={letterVideoImage}
      onClickLearnMore={handleClickLearnMore}
      onClickBuy={onClickBuy}
    >
      <ReindeerImage src={reindeerImage} />
      <Hidden xs>
        <SledImage src={sledImage} />
      </Hidden>
    </HomeProductSection>
  );
};
