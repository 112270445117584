import { makeVar } from "@apollo/client";
import * as dateFns from "date-fns";

import { IAddressFormModel } from "../../../components/organisms/forms/address-form";
import {
  AudioCallScheduleFormModel,
  VideoCallScheduleFormModel,
} from "../../../components/organisms/forms/calls-details-calling";
import { CallContentFormModel } from "../../../components/organisms/forms/calls-details-recipient";
import { ProductAlphaId, CallType, BoyGirl } from "../../../types/graphql";
import { config } from "../../../config";

import { basketState, IBasketItemState } from ".";
import { setState } from "./utils";

const CACHE_KEY = "state.callCreate";

interface ICallCreateState {
  schedule?: AudioCallScheduleFormModel | VideoCallScheduleFormModel;
  content?: CallContentFormModel;
  callReservationUrn?: string;
  isSecondCallSlotEnabled?: boolean;
  secondCallReservationUrn?: string;
  addOns?: ProductAlphaId[];
}

const getDefaultData = (): ICallCreateState => {
  const storedData = localStorage.getItem(CACHE_KEY);

  if (!storedData) {
    return {};
  }

  const data: ICallCreateState = JSON.parse(storedData);

  return {
    ...data,
    ...(data.schedule &&
      data.schedule.date && {
        schedule: {
          ...data.schedule,
          date: dateFns.parseISO(data.schedule.date.toString()),
        },
      }),
  };
};

export const callCreateState = makeVar<ICallCreateState>(getDefaultData());

interface ISackState {
  setSchedule(data: Exclude<ICallCreateState["schedule"], undefined>): void;
  setContent(data: Exclude<ICallCreateState["content"], undefined>): void;
  setCallReservationUrn(urn: string): void;
  setSecondCallSlotEnabled(isEnabled: boolean): void;
  setSecondCallReservationUrn(urn: string): void;
  clear(): void;
  toggleAddOn(addOn: ProductAlphaId): void;
  addToBasket(type: CallType, address?: IAddressFormModel): void;
}

export const callState = (): ISackState => {
  const setCallState = (value: ICallCreateState): void =>
    setState(callCreateState, CACHE_KEY, value);

  return {
    setSchedule: schedule =>
      setCallState({
        ...callCreateState(),
        schedule,
      }),
    setContent: content =>
      setCallState({
        ...callCreateState(),
        content,
      }),
    setCallReservationUrn: callReservationUrn =>
      setCallState({ ...callCreateState(), callReservationUrn }),
    setSecondCallSlotEnabled: isSecondCallSlotEnabled =>
      setCallState({ ...callCreateState(), isSecondCallSlotEnabled }),
    setSecondCallReservationUrn: secondCallReservationUrn =>
      setCallState({ ...callCreateState(), secondCallReservationUrn }),
    clear: () => setCallState({}),
    toggleAddOn: addOn => {
      const existingAddOns = callCreateState().addOns || [];
      const addOns = existingAddOns.includes(addOn)
        ? existingAddOns.filter(i => i !== addOn)
        : [...existingAddOns, addOn];

      setCallState({ ...callCreateState(), addOns });
    },
    addToBasket: (type, address) => {
      const {
        content,
        schedule,
        callReservationUrn,
        secondCallReservationUrn,
        isSecondCallSlotEnabled,
      } = callCreateState();

      if (content && schedule && callReservationUrn) {
        const getCallItem = (c: typeof content["first"], urn: string): IBasketItemState => ({
          productAlphaId:
            type === CallType.AUDIO ? ProductAlphaId.SANTA_CALL : ProductAlphaId.SANTA_CALL_VIDEO,
          addOns: address ? [ProductAlphaId.CALL_CERTIFICATE] : [],
          call: {
            ...("phoneCountryUrn" in schedule && { phoneCountryUrn: schedule.phoneCountryUrn }),
            ...("phoneNumber" in schedule && { phoneNumber: schedule.phoneNumber }),
            timezoneUrn: schedule.timezoneUrn,
            time: schedule.time,
            ...(address && {
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
              addressTown: address.addressTown,
              addressCounty: address.addressCounty,
              addressPostcode: address.addressPostcode,
              addressCountryUrn: address.addressCountryUrn,
            }),
            callReservationUrn: urn,
            firstName: c.firstName,
            lastName: c.lastName,
            boyGirl: c.boyGirl as BoyGirl,
            ...(c.ageYears && { ageYears: c.ageYears }),
            ...(c.ageMonths && { ageMonths: c.ageMonths }),
            ...(c.hobby && { hobby: c.hobby }),
            ...(c.gift && { gift: c.gift }),
            ...(c.friend && { friend: c.friend }),
            ...(c.frontDoorId && { frontDoorId: c.frontDoorId }),
            ...(c.pet1Id && { pet1Id: c.pet1Id }),
            ...(c.pet1Name && { pet1Name: c.pet1Name }),
            ...(c.pet2Id && { pet2Id: c.pet2Id }),
            ...(c.pet2Name && { pet2Name: c.pet2Name }),
            ...(c.notes && { notes: c.notes }),
          },
        });

        const { first, second } = content;
        const { addToBasket } = basketState();

        addToBasket(getCallItem(first, callReservationUrn));

        if (isSecondCallSlotEnabled && secondCallReservationUrn && second) {
          addToBasket(getCallItem(second, secondCallReservationUrn));
        }

        if (config.features.clearCallAfterBasket) {
          setCallState({});
        }
      }
    },
  };
};
