import styled, { FlattenSimpleInterpolation } from "styled-components";

import { checkboxCheckedCss, checkboxCss, checkmarkCss } from "../form/form-input-checkbox";

interface IStyledCompProps {
  checked: boolean;
}

const Checkbox = styled.div<IStyledCompProps>`
  position: relative;

  ${({ theme }): FlattenSimpleInterpolation => checkboxCss(theme)}
  ${({ checked, theme }): FlattenSimpleInterpolation | string =>
    checked ? checkboxCheckedCss(theme) : ""}
`;

const Check = styled.span`
  ${checkmarkCss}
`;

interface IProps {
  isChecked: boolean;
}

export const FakeCheckbox: React.FC<IProps> = ({ isChecked: checked }) => (
  <Checkbox checked={checked}>{checked && <Check />}</Checkbox>
);
