import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { device } from "../../../../utils/media-queries";
import { routePaths } from "../../../../model/route";

import videoImage from "./button-background.png";
import pineconesImage from "./pinecones.png";

import { HomeProductSection } from "..";

const PineconesImage = styled.img`
  z-index: 6;
  position: absolute;

  transform: scaleX(-1);
  left: 180px;
  top: 255px;
  width: 161px;
  height: 57px;

  @media ${device.laptopL} {
    transform: scaleX(1);
    left: auto;
    right: -180px;
    top: 376px;
    width: 305px;
    height: 108px;
  }
`;

interface IProps {
  title: string;
  bullets: string;
  align: "left" | "right";
}

export const SantaZoomCallHomeSection: React.FC<IProps> = ({ title, bullets, align }) => {
  const history = useHistory();

  const handleClickLearnMore = useCallback(
    (): void => history.push(routePaths.calls.VIDEO.home),
    [history],
  );
  const onClickBuy = useCallback(
    (): void => history.push(routePaths.calls.VIDEO.calling),
    [history],
  );

  return (
    <HomeProductSection
      title={title}
      videoId="calls"
      bullets={bullets}
      align={align}
      videoImage={videoImage}
      onClickLearnMore={handleClickLearnMore}
      onClickBuy={onClickBuy}
    >
      <PineconesImage src={pineconesImage} />
    </HomeProductSection>
  );
};
