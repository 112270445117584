import { ApolloClient, isApolloError, useApolloClient } from "@apollo/client";
import { earliestCallDate, lastCallDate } from "@santa/common/lib/products/calls";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import React, { useCallback, useState, useEffect } from "react";
import { History } from "history";
import { useHistory } from "react-router-dom";
import { FormikErrors } from "formik";
import * as dateFns from "date-fns";
import addDuration from "date-fns-duration";
import { utcToZonedTime } from "date-fns-tz";

import {
  useCallsCallingQuery,
  CallType,
  useMakeCallReservationMutation,
  useCallsDatesLazyQuery,
  useCallsSlotsLazyQuery,
  ValidatePhoneNumberQuery,
  ValidatePhoneNumberQueryVariables,
  ValidatePhoneNumberDocument,
  AvailableCallSlotsResponse,
  CallsCallingQuery,
  ProductAlphaId,
} from "../../../../types/graphql";
import {
  CallsDetailsCallingForm,
  AudioCallScheduleFormModel,
} from "../../../organisms/forms/calls-details-calling";
import { callState } from "../../../../model/graphql/cache";
import { routePaths } from "../../../../model/route";
import { config } from "../../../../config";
import { getLocaleForApi } from "../../../../utils/graphql";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";

const handleSubmitFactory =
  (
    history: History,
    type: CallType,
    client: ApolloClient<object>,
    makeReservation: ReturnType<typeof useMakeCallReservationMutation>[0],
    setError: (message: string) => void,
  ) =>
  async (
    values: AudioCallScheduleFormModel,
    setFormError: (errors: FormikErrors<AudioCallScheduleFormModel>) => void,
  ): Promise<void> => {
    const { phoneNumber: number, phoneCountryUrn: countryUrn } = values;

    if (type === CallType.AUDIO && number && countryUrn) {
      const { data } = await client.query<
        ValidatePhoneNumberQuery,
        ValidatePhoneNumberQueryVariables
      >({
        query: ValidatePhoneNumberDocument,
        variables: { number, countryUrn },
      });

      if (!data.validatePhoneNumber?.number) {
        setFormError({ phoneNumber: "This phone number appears invalid" });
      } else {
        // eslint-disable-next-line require-atomic-updates
        values.phoneNumber = data.validatePhoneNumber.number;
      }
    }

    try {
      const { time, timezoneUrn } = values;

      const { data } = await makeReservation({
        variables: { time, timezoneUrn, type },
      });

      if (data) {
        callState().setSchedule(values);
        callState().setCallReservationUrn(data.makeCallReservation.reservationUrn);

        history.push(routePaths.calls[type].recipient);

        return;
      }
    } catch (error) {
      const message =
        error instanceof Error && isApolloError(error)
          ? error.graphQLErrors[0].message
          : "Sorry, an unexpected error occurred. Please try again.";

      setError(message);
    }
  };

type FormProps = React.ComponentProps<typeof CallsDetailsCallingForm>;

const getSlotProps = (
  data: AvailableCallSlotsResponse[] | undefined,
  timezoneUrn: string | undefined,
): Pick<FormProps, "slotOptions" | "hasDoubleSlots"> => {
  if (data && timezoneUrn) {
    const timezone = extractIdFromUrn(UrnResource.TIMEZONE, timezoneUrn);

    return (
      data
        .map(({ time, isNextSlotAvailable }) => ({
          time: dateFns.parseISO(time),
          localTime: utcToZonedTime(time, timezone),
          isNextSlotAvailable,
        }))
        // filter out slots outside of standard hours
        .filter(
          ({ localTime }) =>
            localTime >
              addDuration(dateFns.startOfDay(localTime), config.calls.displayTimes.startAt) &&
            localTime < addDuration(dateFns.startOfDay(localTime), config.calls.displayTimes.endAt),
        )
        // reduce to select options
        .reduce<Required<Pick<FormProps, "slotOptions" | "hasDoubleSlots">>>(
          (acc, s) => {
            return {
              hasDoubleSlots: acc.hasDoubleSlots || s.isNextSlotAvailable,
              slotOptions: [
                ...acc.slotOptions,
                {
                  label: `${dateFns.format(s.localTime, "hh:mm a")}${
                    s.isNextSlotAvailable ? " 2️⃣" : ""
                  }`,
                  value: s.time.toISOString(),
                },
              ],
            };
          },
          {
            hasDoubleSlots: false,
            slotOptions: [],
          },
        )
    );
  }

  return {
    hasDoubleSlots: false,
  };
};

type Countries = CallsCallingQuery["countries"];

const getCountryOptions = (countries?: Countries): ISelectOption[] =>
  (countries || []).map(c => ({
    label: `${c.iso?.toUpperCase()} (+${c.callingCode})`,
    value: c.urn,
  }));

type Timezones = CallsCallingQuery["timezones"];

const getTimezoneOptions = (timezones?: Timezones): ISelectOption[] =>
  (timezones || []).map(t => ({
    label: t.name,
    value: t.urn,
  }));

interface IData {
  formProps: FormProps;
  formError?: string;
  isLoading: boolean;
  refetch?(): void;
  faqs?: ReturnType<typeof mapCmsFaqsToAccordion>;
}

export const useData = (type: CallType): IData => {
  const history = useHistory();
  const client = useApolloClient();
  const [formError, setFormError] = useState<string>();
  const [selectedTimezone, setSelectedTimezone] = useState<string | undefined>();

  const {
    loading: isLoading,
    data,
    refetch,
  } = useCallsCallingQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      callAlphaId:
        type === CallType.AUDIO ? ProductAlphaId.SANTA_CALL : ProductAlphaId.SANTA_CALL_VIDEO,
      locale: getLocaleForApi(),
    },
  });
  const [loadDates, { data: dateData }] = useCallsDatesLazyQuery({ fetchPolicy: "network-only" });
  const [loadSlots, { data: slotData }] = useCallsSlotsLazyQuery({ fetchPolicy: "network-only" });

  const [makeReservation] = useMakeCallReservationMutation();
  const onSubmit = useCallback(
    handleSubmitFactory(history, type, client, makeReservation, setFormError),
    [history, type, makeReservation],
  );
  const onClickBack = useCallback(() => history.push(routePaths.calls[type].home), [history]);

  const onChangeTimezone = useCallback(
    async (timezoneUrn: string): Promise<void> => {
      setSelectedTimezone(timezoneUrn);
      await loadDates({
        variables: {
          startDate: earliestCallDate().toISOString(),
          endDate: lastCallDate().toISOString(),
          timezoneUrn,
          type,
        },
      });
    },
    [setSelectedTimezone, loadDates, type],
  );

  const onChangeCountry = useCallback(
    (countryUrn: string, setField: (field: string, value: string) => void): void => {
      if (!data?.timezones) {
        return;
      }

      const timezone = data.timezones.find(t => t.country.urn === countryUrn);

      if (timezone) {
        setField("timezoneUrn", timezone.urn);
      }
    },
    [],
  );

  const onChangeDate = useCallback(
    async (date: string): Promise<void> => {
      if (selectedTimezone) {
        await loadSlots({
          variables: {
            date,
            timezoneUrn: selectedTimezone,
            type,
          },
        });
      }
    },
    [selectedTimezone, loadSlots],
  );

  // run when function is loaded
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onChangeTimezone(config.defaultTimezone);
  }, []);

  return {
    formProps: {
      type,
      countryOptions: getCountryOptions(data?.countries),
      timezoneOptions: getTimezoneOptions(data?.timezones),
      ...(dateData && { dates: dateData.availableCallDates.map(d => dateFns.parseISO(d.date)) }),
      ...getSlotProps(slotData?.availableCallSlots, selectedTimezone),
      onSubmit,
      onClickBack,
      onChangeTimezone,
      onChangeCountry,
      onChangeDate,
    },
    formError,
    ...(data?.product?.faqs && { faqs: mapCmsFaqsToAccordion(data?.product?.faqs) }),
    isLoading,
    ...(!data && { refetch }),
  };
};
