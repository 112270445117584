import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelectSearch } from "../../form-input-select-search";

import { useSearch } from "./hooks/use-search";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getErrorMessage = (message: object): string => (message as any).value;

interface IProps {
  id?: string;
  required?: boolean;
}

export const FormFieldFirstName: React.FC<IProps> = ({ id = "firstName", required = true }) => {
  const onSearch = useSearch();

  return (
    <FormFieldWrapper
      label="Child's first name"
      required={required}
      name={id}
      getErrorMessage={getErrorMessage}
    >
      <Field
        name={id}
        placeholder="Select first name"
        component={FormikFormInputSelectSearch}
        loadOptions={onSearch}
        cacheOptions
      />
    </FormFieldWrapper>
  );
};
