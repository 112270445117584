import styled from "styled-components";

import { theme as baseTheme } from "../../../theme";
import { device } from "../../../utils/media-queries";
import { BackArrowIcon } from "../../atoms/icons/back-arrow-icon";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  color: ${({ theme }): string => theme.colours.textSubdued};
  margin-bottom: 0.1em;

  @media ${device.mobileS} {
    margin-left: 9px;
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    margin-left: 17px;
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }
`;

interface IProps {
  label: string;
  onClick(): void;
}

export const BackLink: React.FC<IProps> = ({ label, onClick }) => (
  <Container onClick={onClick}>
    <BackArrowIcon colour={baseTheme.colours.textSubdued} />
    <Text>{label}</Text>
  </Container>
);
