import { useCallback } from "react";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import * as yup from "yup";

import { TextDetailsMessagesMessageForm } from "../../forms/text-details-messages-message-form";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { ContentPanel } from "../../../atoms/content-panel";
import { Heading3 } from "../../../atoms/text";
import { FormikFormInputSelect } from "../../../molecules/form/form-input-select";
import { FormFieldBoyGirl } from "../../../molecules/form/form-fields/form-field-boy-girl";
import { FormFieldFirstNameFreeText } from "../../../molecules/form/form-fields/form-field-first-name-free-text";
import { FormikFormInputText } from "../../../molecules/form/form-input-text";

const Panel = styled(ContentPanel)`
  > h3 {
    :first-child {
      margin-top: 0;
      font-weight: normal;
    }
  }

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

/**
 * Get a field that compares against a previous date
 * @param validation Validation error
 * @param compareField field to compare
 */
const getDateCompareValidation = (validation: string, compareField: string): yup.DateSchema =>
  yup
    .date()
    .required(validation)
    .when(compareField, (prev: Date, schema: yup.DateSchema) => {
      if (prev instanceof Date && !Number.isNaN(prev.getTime())) {
        return schema.min(
          prev,
          "The date for the second text cannot be before the date for the first text",
        );
      }

      return schema;
    });

export const validationRules = {
  firstName: yup.string().required("Required"),
  ageYears: yup
    .number()
    .required()
    .min(0)
    .max(200, "Are they really that old?!")
    // eslint-disable-next-line func-names
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageMonths } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageMonths)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  boyGirl: yup.string().required(),
  town: yup.string().required(),
  petId: yup.string(),
  petName: yup.string(),
  templateId: yup.string().required(),
  text1TimeDate: yup.date().required(),
  text1TimeTime: yup.string().required(),
  text1Body: yup.string().required(),
  text2TimeDate: getDateCompareValidation("Text 2 date required", "text1TimeDate"),
  text2TimeTime: yup.string().required(),
  text2Body: yup.string().required(),
  text3TimeDate: getDateCompareValidation("Text 3 date required", "text2TimeDate"),
  text3TimeTime: yup.string().required(),
  text3Body: yup.string().required(),
};

export interface IFormSectionModel {
  firstName?: string;
  ageYears?: string;
  boyGirl?: string | null;
  town?: string;
  petId?: string;
  petName?: string;
  templateId?: string;
  text1TimeDate?: Date;
  text1TimeTime?: string;
  text1Body?: string;
  text2TimeDate?: Date;
  text2TimeTime?: string;
  text2Body?: string;
  text3TimeDate?: Date;
  text3TimeTime?: string;
  text3Body?: string;
}

interface IProps {
  petOptions: ISelectOption[];
  templateOptions: ISelectOption[];
  onChangeTemplate(id: string): void;
}

export const FormSectionTextTemplates: React.FC<IProps> = ({
  petOptions,
  templateOptions,
  onChangeTemplate,
}) => {
  const handleChangeTemplate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => onChangeTemplate(e.target.value),
    [onChangeTemplate],
  );

  return (
    <>
      <Panel>
        <Heading3>Info about child</Heading3>
        <Row>
          <Col xl={6}>
            <FormFieldFirstNameFreeText />
          </Col>
          <Col xl={6}>
            <FormFieldBoyGirl />
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <FormFieldWrapper label="Child's age" name="ageYears" required noLabel>
              <Field
                name="ageYears"
                component={FormikFormInputText}
                type="number"
                placeholder="Years"
              />
            </FormFieldWrapper>
          </Col>
          <Col xl={6}>
            <FormFieldWrapper label="Town or village?" name="town" required>
              <Field
                name="town"
                component={FormikFormInputText}
                placeholder="Please enter where they will be at time of text"
              />
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <FormFieldWrapper label="Pet or relative">
              <Field
                name="petId"
                component={FormikFormInputSelect}
                placeholder="Select pet or relative"
                options={petOptions}
              />
            </FormFieldWrapper>
          </Col>
          <Col xl={6}>
            <FormFieldWrapper
              label="Name of pet or relative"
              tooltip="If you are unsure, please leave blank and Santa will work around this."
            >
              <Field name="petName" component={FormikFormInputText} placeholder="Enter name" />
            </FormFieldWrapper>
          </Col>
        </Row>
      </Panel>

      <Panel>
        <Heading3>Messages must be sent at least 2 hours in advance</Heading3>

        <FormFieldWrapper label="Messages theme" name="templateId" required>
          <Field
            name="templateId"
            component={FormikFormInputSelect}
            placeholder="Please select"
            options={templateOptions}
            onChange={handleChangeTemplate}
          />
        </FormFieldWrapper>

        <TextDetailsMessagesMessageForm message={1} />
        <TextDetailsMessagesMessageForm message={2} />
        <TextDetailsMessagesMessageForm message={3} />
      </Panel>
    </>
  );
};
