import { makeVar } from "@apollo/client";
import * as dateFns from "date-fns";

import { SackRecipientValidatedFormModel } from "../../../components/organisms/forms/sack-recipient";
import { ProductAlphaId, SackCreateInput } from "../../../types/graphql";
import { config } from "../../../config";

import { basketState } from ".";
import { setState } from "./utils";

const CACHE_KEY = "state.sackCreate";

interface ISackCreateState {
  recipient?: SackRecipientValidatedFormModel;
  addOns?: ProductAlphaId[];
}

const getDefaultData = (): ISackCreateState => {
  const storedData = localStorage.getItem(CACHE_KEY);

  if (!storedData) {
    return {};
  }

  const data: ISackCreateState = JSON.parse(storedData);

  return {
    ...data,
    ...(data.recipient &&
      data.recipient.date && {
        recipient: {
          ...data.recipient,
          date: dateFns.parseISO(data.recipient.date.toString()),
        },
      }),
  };
};

export const sackCreateState = makeVar<ISackCreateState>(getDefaultData());

interface ISackState {
  setRecipient(data: SackRecipientValidatedFormModel): void;
  clear(): void;
  addToBasket(additionalAddOnIds?: ProductAlphaId[]): void;
}

export const sackState = (): ISackState => {
  const setSackState = (value: ISackCreateState): void =>
    setState(sackCreateState, CACHE_KEY, value);

  return {
    setRecipient: recipient =>
      setSackState({
        ...sackCreateState(),
        recipient,
      }),
    clear: () => setSackState({}),
    addToBasket: additionalAddOnIds => {
      const { recipient } = sackCreateState();

      if (recipient) {
        const sack: SackCreateInput = {
          name: recipient.name,
          addressLine1: recipient.addressLine1,
          ...(recipient.addressLine2 && { addressLine2: recipient.addressLine2 }),
          addressTown: recipient.addressTown,
          ...(recipient.addressCounty && { addressCounty: recipient.addressCounty }),
          ...(recipient.addressUsStateUrn && { addressUsStateUrn: recipient.addressUsStateUrn }),
          addressPostcode: recipient.addressPostcode,
          addressCountryUrn: recipient.addressCountryUrn,
          date: recipient.date.toISOString(),
        };

        basketState().addToBasket({
          productAlphaId: ProductAlphaId.SANTA_SACK,
          addOns: additionalAddOnIds ? additionalAddOnIds : [],
          sack,
        });

        if (config.features.clearSackAfterBasket) {
          setSackState({});
        }
      }
    },
  };
};
