import { FieldProps } from "formik";
import React, { useCallback } from "react";
import styled, {
  StyledComponentPropsWithRef,
  css,
  FlattenSimpleInterpolation,
  DefaultTheme,
} from "styled-components";

/**
 * Return the CSS for the checkbox checkmark
 */
export const checkmarkCss = css`
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

/**
 * Return the CSS for the checkbox
 * @param props props for styling
 */
export const checkboxCss = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  height: 25px;
  width: 25px;
  border: ${theme.colours.form.placeholder} 1px solid;
  background-color: white;
  border-radius: 5px;
`;

/**
 * Return the CSS for the checkbox when checked
 * @param props props for styling
 */
export const checkboxCheckedCss = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  border-color: ${theme.colours.spotGold};
  background-color: ${theme.colours.spotGold};
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  span {
    position: absolute;
    top: -4px;
    left: 0;
    ${({ theme }): FlattenSimpleInterpolation => checkboxCss(theme)}
  }

  /* When the checkbox is checked, add a background and colour the checkmark */
  input:checked ~ span {
    ${({ theme }): FlattenSimpleInterpolation => checkboxCheckedCss(theme)}
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  span:after {
    display: none;
    ${checkmarkCss}
  }

  /* Show the checkmark when checked */
  input:checked ~ span:after {
    display: block;
  }
`;

type InputProps = StyledComponentPropsWithRef<"input"> & {
  label?: string;
  onCheck(): void;
};

export const FormInputCheckbox: React.FC<InputProps> = props => {
  const handleCheckboxChange = useCallback((): void => {
    props.onCheck();
  }, [props]);

  return (
    <Label>
      {props.label}
      <input type="checkbox" checked={props.checked} onChange={handleCheckboxChange} />
      <span />
    </Label>
  );
};

type FormikInputProps = FieldProps &
  StyledComponentPropsWithRef<"input"> & {
    label?: string;
  };

export const FormikFormInputCheckbox: React.FC<FormikInputProps> = ({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) => {
  const setFieldFocussed = useCallback(
    (): void => setFieldTouched(field.name),
    [setFieldTouched, field],
  );
  const handleCheck = useCallback((): void => {
    setFieldValue(field.name, !field.value);
  }, [setFieldValue, field]);

  return (
    <FormInputCheckbox
      name={field.name}
      onBlur={field.onBlur}
      onFocus={setFieldFocussed}
      onCheck={handleCheck}
      placeholder={props.placeholder}
      value={props.value}
      label={props.label}
      checked={field.value}
    />
  );
};
