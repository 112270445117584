import * as yup from "yup";

export const sackValidationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  date: yup.date().required("Required"),
  addressLine1: yup.string().required("Required"),
  addressLine2: yup.string(),
  addressTown: yup.string().required("Required"),
  addressCounty: yup.string(),
  addressUsStateUrn: yup.string(),
  addressPostcode: yup.string().required("Required"),
  addressCountryUrn: yup.string().required("Required"),
});
