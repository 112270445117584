import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelect } from "../../form-input-select";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: ISelectOption[];
}

export const FormFieldPetType: React.FC<IProps> = ({
  options,
  id = "petId",
  label = "Pet or relative",
  placeholder = "Select pet or relative",
}) => (
  <FormFieldWrapper
    label={label}
    tooltip="If you are unsure, please leave blank and Santa will work around this."
  >
    <Field
      name={id}
      placeholder={placeholder}
      component={FormikFormInputSelect}
      options={options}
    />
  </FormFieldWrapper>
);
