import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";

import { ModalOverlay } from "../atoms/modal-overlay";
import { Basket } from "../ecosystems/basket";
import { Checkout } from "../ecosystems/checkout";
import { Home } from "../ecosystems/home";
import { MobileNavigation } from "../ecosystems/mobile-navigation";
import { OrderConfirmation } from "../ecosystems/order-confirmation";
import { SantaLetter } from "../ecosystems/santa-letter";
import { Calls } from "../ecosystems/calls";
import { SackHome } from "../ecosystems/sack/sack-home";
import { Footer } from "../organisms/footer";
import { Header } from "../organisms/header";
import { ShowNavContext } from "../contexts/show-nav-context";
import { AboutUs } from "../ecosystems/content/about-us";
import { ForgotPassword } from "../ecosystems/forgot-password";
import { Texts } from "../ecosystems/texts";
import { logout, IUserProfile, getUser } from "../../model/authentication";
import { PrivacyPolicy } from "../ecosystems/content/privacy-policy";
import { Support } from "../ecosystems/content/support";
import { LogIn } from "../ecosystems/log-in";
import { MyAccount } from "../ecosystems/my-account";
import { Terms } from "../ecosystems/content/terms";
import { ExternalRedirect } from "../atoms/external-redirect";
import { VideoContext } from "../contexts/video-context";
import { AuthContext } from "../contexts/auth-context";
import { Affiliates } from "../ecosystems/content/affiliates";
import { routePaths } from "../../model/route";
import { Videos } from "../ecosystems/video";
import { MyAccountLetterEdit } from "../ecosystems/my-account/my-account-letter-edit";
import { MyAccountCallEdit } from "../ecosystems/my-account/my-account-call-edit";
import { basketItemState } from "../../model/graphql/cache";
import { MyAccountTextEdit } from "../ecosystems/my-account/my-account-text-edit";

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 100;
`;

const Container = styled.div<{ isVideoOpen: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${({ isVideoOpen }): string => (isVideoOpen ? "filter: blur(6px);" : "")};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Layout: React.FC = () => {
  const client = useApolloClient();
  const { shouldShowNav } = useContext(ShowNavContext);
  const video = useContext(VideoContext);
  const basketItems = useReactiveVar(basketItemState);
  const isAuthenticated = useContext(AuthContext);
  const [profile, setProfile] = useState<IUserProfile | undefined>(undefined);
  const modalOverlayRef = useRef<ModalOverlay>(null);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setProfile(await getUser());
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load();
  }, [isAuthenticated]);

  const [isMobileNavShowing, setIsMobileNavShowing] = useState<boolean>(false);

  const basketCount = basketItems.length;

  /**
   * Handle opening the mobile navigation
   */
  const handleOpenMobileNavigation = useCallback((): void => {
    setIsMobileNavShowing(true);
    modalOverlayRef.current!.show();
  }, [setIsMobileNavShowing, modalOverlayRef]);

  /**
   * Handle closing the mobile navigation
   */
  const handleCloseMobileNavigation = useCallback((): void => {
    setIsMobileNavShowing(false);
    modalOverlayRef.current!.hide();
  }, [setIsMobileNavShowing, modalOverlayRef]);

  const handleClickLogOut = useCallback(async (): Promise<void> => {
    await logout(client);
    handleCloseMobileNavigation();
  }, [handleCloseMobileNavigation, client]);

  const header = shouldShowNav ? (
    <HeaderContainer>
      <Header
        onClickMobileMenu={handleOpenMobileNavigation}
        basketCount={basketCount}
        profile={profile}
      />
    </HeaderContainer>
  ) : undefined;

  return (
    <Container isVideoOpen={video.isOpen}>
      {header}

      <MobileNavigation
        onClickClose={handleCloseMobileNavigation}
        onClickLogOut={handleClickLogOut}
        isOpen={isMobileNavShowing}
        basketCount={basketCount}
      />

      <ModalOverlay ref={modalOverlayRef} />

      <Content>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route path={routePaths.letter.home} component={SantaLetter} />
          <Route path={routePaths.calls.home} component={Calls} />
          <Route path={routePaths.text.home} component={Texts} />
          <Route path={routePaths.video.home} component={Videos} />
          <Route path="/santa-sack" component={SackHome} />

          <Route path="/basket" component={Basket} />

          <Route exact path="/checkout/confirmation/:orderUrn" component={OrderConfirmation} />
          <Route path="/checkout" component={Checkout} />

          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/support" component={Support} />
          <Route exact path={routePaths.affiliates} component={Affiliates} />
          <Route exact path="/terms" component={Terms} />

          <Route exact path="/forgot-password" component={ForgotPassword} />

          <Route path="/login" component={LogIn} />
          <Route exact path={routePaths.myAccount.home} component={MyAccount} />
          <Route exact path={routePaths.myAccount.letters} component={MyAccountLetterEdit} />
          <Route exact path={routePaths.myAccount.calls} component={MyAccountCallEdit} />
          <Route exact path={routePaths.myAccount.texts} component={MyAccountTextEdit} />

          <Redirect from="/letter" to="/santa-letter" />
          <Redirect from="/call" to="/santa-call" />
          <Redirect from="/textmas" to="/santa-text" />
          <Redirect from="/myaccount" to="/my-account" />

          <Redirect from="/static/selfhelp-gb" to="/support" />
          <Redirect from="/editorial/about" to="/about" />

          <Route
            path="/editorial/:remainder"
            render={useCallback(
              (): JSX.Element => (
                <ExternalRedirect link="https://uk.santa.co.uk/editorial/" />
              ),
              [],
            )}
          />

          <Redirect to="/" />
        </Switch>
      </Content>

      <Footer />
    </Container>
  );
};
