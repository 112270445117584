import styled from "styled-components";

import { ReactComponent as FrameImage } from "./frame.svg";
import santaImage from "./santa-texting.jpg";

const Container = styled.div`
  width: 641px;
  height: 560px;
  position: relative;
`;

const Frame = styled(FrameImage)`
  width: 641px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Image = styled.img`
  left: 39px;
  top: 40px;
  position: absolute;
  z-index: 1;
  width: 576px;
  height: 502px;
`;

export const VideoFrameImage: React.FC = () => (
  <Container>
    <Frame />
    <Image src={santaImage} alt="Santa video" />
  </Container>
);
