enum Colour {
  BLACK = "black",
  WHITE = "white",
  GOLD = "#f8bf2f",
  PALE_GOLD = "#fdf6e7",
  RED = "#ff0a00",
  PALE_RED = "#feeaea",
  MID_GREY = "#b0bec5",
  BLUE_GREY = "#78909c",
  PALE_YELLOW = "#f9fbe7",
}

export const theme = {
  name: "Default",
  fonts: {
    main: "'Lato', sans-serif",
  },
  fontSizes: {
    size72: "72px",
    size64: "64px",
    size36: "36px",
    size28: "28px",
    size24: "24px",
    size22: "22px",
    size18: "18px",
    size16: "16px",
    size14: "14px",
  },
  colours: {
    red: Colour.RED,
    midGrey: Colour.MID_GREY,
    blueGrey: Colour.BLUE_GREY,

    icon: Colour.BLACK,
    loader: Colour.GOLD,
    text: Colour.BLACK,
    textSubdued: Colour.BLUE_GREY,
    alert: Colour.RED,
    alertPale: Colour.PALE_RED,
    spotGold: Colour.GOLD,
    contentBackground: "#fefbf6",
    form: {
      placeholder: Colour.MID_GREY,
    },
    navigation: {
      dark: Colour.BLACK,
      medium: Colour.BLUE_GREY,
      light: Colour.WHITE,
      spot: Colour.RED,
    },
    divider: {
      simple: Colour.MID_GREY,
      candy: Colour.RED,
    },
    link: Colour.RED,
    panels: {
      promo: {
        background: Colour.PALE_GOLD,
      },
      content: {
        white: Colour.WHITE,
        yellow: Colour.PALE_YELLOW,
      },
    },
  },
};
