import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import enGB from "date-fns/locale/en-GB";
import enUS from "date-fns/locale/en-US";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { Hidden, Visible } from "react-grid-system";

import { displayPrice } from "../../../utils/price";
import { CaretIcon } from "../../atoms/icons/caret-icon";
import { SiteCountryCode } from "../../../types/graphql";
import { MyAccountOrderItem } from "../my-account-order-item";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  background-color: ${({ theme }): string => theme.colours.alertPale};
  padding: 10px;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;

  @media ${device.laptopL} {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 6px;
  }
`;

const OverviewContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  margin: 0;
  flex: 1;
  text-align: center;
  font-size: ${({ theme }): string => theme.fontSizes.size22};
`;

const IdLabel = styled(Label)`
  flex: 2;
  display: flex;
  flex-direction: row;
  text-align: left;
`;

const StatusLabel = styled(Label)<{ bold: boolean }>`
  text-align: right;
  ${({ bold }): string => (bold ? "font-weight: bold;" : "")}
`;

const OrderDetailsContainer = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 30px 24px;

  > * {
    :not(:last-child) {
      border-bottom: ${({ theme }): string => theme.colours.midGrey} 1px solid;
      padding-bottom: 20px;
      margin-bottom: 24px;
    }
  }
`;

const DetailsMobile = styled.div`
  flex: 1;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
`;

const DetailsDesktop = styled(Hidden)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailsMobileHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MobileIdLabel = styled.div`
  font-weight: bold;
`;

const MobileStatusLabel = styled.div<{ bold: boolean }>`
  ${({ bold }): string => (bold ? "font-weight: bold;" : "")}
`;

const DetailsMobileLabel = styled.div`
  margin-top: 8px;
`;

export enum OrderStatus {
  SCHEDULED,
  IN_PROGRESS,
  COMPLETED,
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  total: number;
  date: Date;
  status: OrderStatus;
  site: SiteCountryCode;
  items: Omit<React.ComponentProps<typeof MyAccountOrderItem>, "site">[];
  isOpen: boolean;
  onClickHeader(id: string): void;
}

export const MyAccountOrder: React.FC<IProps> = ({
  id,
  total,
  date,
  status,
  className,
  items,
  site,
  isOpen,
  onClickHeader,
}) => {
  const { t } = useTranslation();
  const [contentHeight, setContentHeight] = useState(0);
  const [ref, { height }] = useMeasure();

  const locale = site === SiteCountryCode.GB ? enGB : enUS;

  useEffect(() => {
    setContentHeight(height);

    window.addEventListener("resize", () => setContentHeight(height));

    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  const expand = useSpring({
    height: isOpen ? `${contentHeight + 60}px` : "0px",
    opacity: isOpen ? 1 : 0,
    marginTop: isOpen ? "10px" : "0px",
    overflow: "hidden",
  });

  const fade = useSpring({
    opacity: isOpen ? 0 : 1,
  });

  const invert = useSpring({
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    marginRight: "15px",
    height: "13px",
  });

  const handleClickHeader = useCallback((): void => onClickHeader(id), [onClickHeader, id]);

  return (
    <Container className={className}>
      <OverviewContainer onClick={handleClickHeader}>
        <animated.div style={invert}>
          <CaretIcon colour="alert" />
        </animated.div>
        <Visible xs>
          <DetailsMobile>
            <DetailsMobileHeader>
              <MobileIdLabel>ID {id}</MobileIdLabel>
              <MobileStatusLabel bold={status === OrderStatus.COMPLETED}>
                {t(`myaccount.orders.order.status.${status}`)}
              </MobileStatusLabel>
            </DetailsMobileHeader>
            <DetailsMobileLabel>
              {format(date, "P", { locale })} • {displayPrice(total, site)}
              <animated.span style={fade}>
                {" "}
                • {t("myaccount.orders.order.itemCount", { count: items.length })}
              </animated.span>
            </DetailsMobileLabel>
          </DetailsMobile>
        </Visible>
        <DetailsDesktop xs>
          <IdLabel>ID {id}</IdLabel>
          <Label>{format(date, "P", { locale })}</Label>
          <Label>
            <animated.div style={fade}>
              {t("myaccount.orders.order.itemCount", { count: items.length })}
            </animated.div>
          </Label>
          <Label>{displayPrice(total, site)}</Label>
          <StatusLabel bold={status === OrderStatus.COMPLETED}>
            {t(`myaccount.orders.order.status.${status}`)}
          </StatusLabel>
        </DetailsDesktop>
      </OverviewContainer>
      <animated.div style={expand}>
        <OrderDetailsContainer ref={ref as unknown as React.RefObject<HTMLDivElement>}>
          {items.map((item, i) => (
            <MyAccountOrderItem key={i} {...item} site={site} />
          ))}
        </OrderDetailsContainer>
      </animated.div>
    </Container>
  );
};
