import { earliestLetterSendDate } from "@santa/common/lib/products/letter";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { ProductAlphaId, useLetterFaqsQuery } from "../../../../../../types/graphql";
import { letterCreateState, letterState } from "../../../../../../model/graphql/cache";
import { SantaLetterDetailsSendingForm } from "../../../../../organisms/forms/santa-letter-details-sending";
import { routePaths } from "../../../../../../model/route";
import { config } from "../../../../../../config";
import { displayPrice } from "../../../../../../utils/price";
import { getLocaleForApi } from "../../../../../../utils/graphql";

type FormProps = Pick<
  React.ComponentProps<typeof SantaLetterDetailsSendingForm>,
  "defaultValues" | "onChange" | "onSubmit" | "onClickBack" | "surchargeNotice" | "onChangeCountry"
>;

interface IUseData {
  formProps: FormProps;
  isLoading: boolean;
  refetch?(): void;
  faqs?: {
    id: string;
    title: string;
    description: string;
  }[];
}

export interface ISurcharge {
  price: number;
  title: string;
}

/**
 * Hook to get data for letter sending form
 */
export const useData = (
  onChangeHandler: () => void,
  internationalSurcharge?: ISurcharge,
): IUseData => {
  const history = useHistory();
  const savedFormData = letterCreateState();
  const [surchargeNotice, setSurchargeNotice] = useState<string>();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useLetterFaqsQuery({
    variables: {
      locale: getLocaleForApi(),
      letterAlphaId: ProductAlphaId.SANTA_LETTER,
    },
  });

  const defaultValues = useMemo((): FormProps["defaultValues"] => {
    const { recipient } = savedFormData;
    if (recipient) {
      return {
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        ageYears: recipient.ageYears?.toString(),
        ageMonths: recipient.ageMonths?.toString(),
        boyGirl: recipient.boyGirl,
        addressLine1: recipient.addressLine1,
        addressLine2: recipient.addressLine2,
        addressTown: recipient.addressTown,
        addressCounty: recipient.addressCounty,
        addressUsStateUrn: recipient.addressUsStateUrn,
        addressPostcode: recipient.addressPostcode,
        addressCountryUrn: recipient.addressCountryUrn,
        date: earliestLetterSendDate(),
      };
    }

    return {
      addressCountryUrn: config.defaultCountry,
      date: earliestLetterSendDate(),
    };
  }, [savedFormData]);

  const onChangeCountry = useCallback<FormProps["onChangeCountry"]>(
    countryUrn => {
      if (internationalSurcharge && countryUrn !== config.defaultCountry) {
        setSurchargeNotice(
          `${displayPrice(internationalSurcharge.price)} ${internationalSurcharge.title}`,
        );
      }
    },
    [internationalSurcharge],
  );

  const onChange = useCallback<FormProps["onChange"]>(
    values => {
      letterState().setRecipientDetails(values);
      onChangeHandler();
    },
    [onChangeHandler],
  );

  const onClickBack = useCallback(
    (): void => history.push(routePaths.letter.types.classic),
    [history],
  );

  return {
    formProps: {
      defaultValues,
      surchargeNotice,
      onChange,
      onChangeCountry,
      onSubmit: values => {
        letterState().setRecipientDetails(values);
        history.push(routePaths.letter.content);
      },
      onClickBack,
    },
    isLoading,
    ...(data?.product?.faqs && {
      faqs: data.product.faqs.map(faq => ({
        id: faq.id,
        title: faq.title || "",
        description: faq.description || "",
      })),
    }),
    ...(!data && { refetch }),
  };
};
