import { CallType } from "@santa/common/lib/products/calls";
import { Col, Container, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { CallsDetailsRecipientForm } from "../../../organisms/forms/calls-details-recipient";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { StageTitle } from "../../../atoms/stage-title";

import pineConeCrownImage from "./pinecone-crown.png";
import { useData } from "./use-data";

const PineConeCrown = styled.img`
  position: absolute;
  width: 425px;
  height: 820px;
  right: 0;
  top: 0;
`;

interface IProps {
  type: CallType;
}

export const CallsDetailsRecipient: React.FC<IProps> = ({ type }) => {
  const { formProps, loaderProps } = useData(type);

  return (
    <MainWrapper>
      <Visible md lg xl>
        <PineConeCrown src={pineConeCrownImage} alt="Pine cone wreath" />
      </Visible>

      <Container>
        <Row>
          <Col md={10}>
            <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

            <StageTitle totalStages={3} currentStage={2}>
              <Visible sm md lg xl>
                What shall Santa talk about?
              </Visible>
              <Visible xs>Santa needs more</Visible>
            </StageTitle>

            <DataLoadedContainer {...loaderProps}>
              <CallsDetailsRecipientForm {...formProps} />
            </DataLoadedContainer>
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  );
};
