import { Visible } from "react-grid-system";
import { ISelectOption, ISelectMultilineOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { useEffect } from "react";

import { Paragraph } from "../../../atoms/text";
import { PreviewButton } from "../../../molecules/buttons/preview-button";
import {
  FormSectionLetterRecipient,
  validationRules as recipientValidationRules,
  IDefaultValuesModel as RecipientDefaultValuesModel,
} from "../../form-sections/letter/form-section-letter-recipient";
import {
  FormSectionLetterContent,
  validationRules as contentValidationRules,
  IDefaultValuesModel as ContentDefaultValuesModel,
} from "../../form-sections/letter/form-section-letter-content";
import { FormSubmitRow } from "../../../molecules/form-submit-row";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const validationSchema = yup.object({
  ...recipientValidationRules,
  ...contentValidationRules,
});
export type LetterEditValidatedFormModel = yup.Asserts<typeof validationSchema>;
type FormModel = RecipientDefaultValuesModel & ContentDefaultValuesModel;

interface IOwnProps {
  defaultValues: FormModel;
  templateOptions: ISelectMultilineOption[];
  psOptions: ISelectOption[];
  signatureOptions: ISelectOption[];
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
  formError?: string;
  isSubmitting: boolean;
  onChange(values: LetterEditValidatedFormModel): void;
  onSubmit(values: LetterEditValidatedFormModel): void;
  onClickBack(): void;
  onClickOpenMobilePreview(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  templateOptions,
  psOptions,
  signatureOptions,
  petOptions,
  frontDoorOptions,
  touched,
  errors,
  formError,
  isSubmitting,
  submitForm,
  onChange,
  onClickOpenMobilePreview,
  onClickBack,
  setFieldValue,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as LetterEditValidatedFormModel);
    }
  }, [values]);

  return (
    <Form>
      {formError && <Error>{formError}</Error>}
      <FormSectionLetterRecipient
        touched={touched}
        errors={errors}
        values={values}
        setFieldValue={setFieldValue}
      />
      <FormSectionLetterContent
        values={values}
        templateOptions={templateOptions}
        petOptions={petOptions}
        frontDoorOptions={frontDoorOptions}
        signatureOptions={signatureOptions}
        psOptions={psOptions}
        onClickOpenMobilePreview={onClickOpenMobilePreview}
      />
      <FormSubmitRow
        isSubmitEnabled={isValid && !isSubmitting}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
        submitLabel="Save"
      >
        <Visible xs sm>
          <PreviewButton onClick={onClickOpenMobilePreview} />
        </Visible>
      </FormSubmitRow>
    </Form>
  );
};

export const SantaLetterEditForm = withFormik<IOwnProps, FormModel>({
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  validationSchema,
  validateOnMount: true,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as LetterEditValidatedFormModel),
})(C);
