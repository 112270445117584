import { ApolloClient, ApolloProvider } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import WebfontLoader from "@dr-kobros/react-webfont-loader";
import { setDefaultLocale, registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import enUS from "date-fns/locale/en-US";
import { FacebookProvider } from "react-facebook";

import { config } from "../../config";
import { theme } from "../../theme";
import { GlobalStyle } from "../../theme/global-style";
import { Loader } from "../atoms/loader";
import { AuthProvider } from "../contexts/auth-context";
import { ScrollToTop } from "../control/scroll-to-top";
import { Layout } from "../layout";
import { VideoProvider } from "../contexts/video-context";
import { ShowNavProvider } from "../contexts/show-nav-context";
import { getApolloClient } from "../../model/graphql/client";
import { setUserInSentry } from "../../model/authentication";

// configure react date-picker
registerLocale("gb", enGB);
registerLocale("us", enUS);
setDefaultLocale(config.site.country);

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

export interface IHistoryLocationState {
  loginReturnLocation?: string;
  forgotPasswordReturnLocation?: string;
}

const fontConfig = {
  google: {
    families: ["Homemade Apple", "Lato:300,300i,400,400i,700,700i"],
  },
};

export const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClient<unknown> | undefined>();

  useEffect(() => {
    setUserInSentry().catch(() => {
      // no catch
    });

    getApolloClient()
      .then(c => setClient(c))
      .catch(() => {
        // no catch
      });
  }, []);

  if (client) {
    return (
      <>
        <GlobalStyle />
        <WebfontLoader config={fontConfig}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
              <AuthProvider>
                <VideoProvider>
                  <ShowNavProvider>
                    <FacebookProvider appId={config.facebook.appId}>
                      <Router>
                        <ScrollToTop />
                        <Layout />
                      </Router>
                    </FacebookProvider>
                  </ShowNavProvider>
                </VideoProvider>
              </AuthProvider>
            </ApolloProvider>
          </ThemeProvider>
        </WebfontLoader>
      </>
    );
  }

  return (
    <Container>
      <Loader />;
    </Container>
  );
};
