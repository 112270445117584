import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import { TextsDetailsBonusForm } from "../../../organisms/forms/texts-details-bonus";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { useTextsBonusQuery } from "../../../..//types/graphql";
import { routePaths } from "../../../../model/route";
import { textCreateState, textState } from "../../../../model/graphql/cache";
import { getLocaleForApi } from "../../../../utils/graphql";

type FormProps = React.ComponentProps<typeof TextsDetailsBonusForm>;
type FormParams = FormProps["defaultValues"];
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapDataToForm = ({ bonus }: ReturnType<typeof textCreateState>): FormParams => {
  if (bonus) {
    return {
      bonusBody: bonus.bonusBody,
    };
  }

  return {
    bonusBody: "",
  };
};

interface IUseData {
  formProps?: FormProps;
  loaderProps: LoaderProps;
}

export const useData = (): IUseData => {
  const history = useHistory();

  const currentState = textCreateState();
  useEffect(() => {
    if (!currentState.details) {
      history.push(routePaths.text.details);
    }
  }, [history, currentState]);

  const {
    data,
    loading: isLoading,
    refetch: dataRefetch,
    error,
  } = useTextsBonusQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      locale: getLocaleForApi(),
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const onChange = useCallback<FormProps["onChange"]>(
    debounce(values => textState().setBonus(values), 500),
    [],
  );

  const onSubmit = useCallback<FormProps["onSubmit"]>(() => {
    textState().addToBasket(true);
    history.push(routePaths.basket);
  }, []);

  const onClickSkip = useCallback<FormProps["onClickSkip"]>(() => {
    textState().addToBasket(false);
    history.push(routePaths.basket);
  }, []);

  const onClickBack = useCallback<FormProps["onClickBack"]>(
    () => history.push(routePaths.text.messages),
    [history],
  );

  const defaultValues = useMemo(() => mapDataToForm(currentState), []);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && error && { refetch }),
    },
    ...(data && {
      formProps: {
        defaultValues,
        price: data.product?.price,
        onClickBack,
        onClickSkip,
        onChange,
        onSubmit,
      },
    }),
  };
};
