import React, { useCallback, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { textBase } from "../../atoms/text";
import { AuthContext } from "../../contexts/auth-context";
import { NumberBadge } from "../../atoms/number-badge";
import { routePaths } from "../../../model/route";
import { device } from "../../../utils/media-queries";

const Menu = styled.ul<{ level: number }>`
  transition: all 0.5s ease-in-out;
  position: absolute;
  list-style: none;
  z-index: 5;
  top: 110px;
  left: ${({ level }): number => 20 + 250 * level}px;
  width: 250px;
  padding: 0;

  li {
    margin-bottom: 10px;
  }

  @media ${device.mobileM} {
    li {
      margin-bottom: 20px;
    }
  }
`;

const itemStyle = css`
  ${textBase};
  position: relative;
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  text-decoration: none;
`;

const LevelForwardLink = styled.a`
  ${itemStyle}
  color: ${({ theme }): string => theme.colours.navigation.dark};

  span {
    margin-left: 10px;
  }

  span:after {
    content: ">";
  }
`;

const LevelBackLink = styled.a`
  ${itemStyle}
  color: ${({ theme }): string => theme.colours.navigation.medium};

  span {
    margin-right: 5px;
  }

  span:after {
    content: "<";
  }
`;

const Link = styled(NavLink)`
  ${itemStyle}
  color: ${({ theme }): string => theme.colours.navigation.dark};

  &.active {
    color: ${({ theme }): string => theme.colours.navigation.spot};
  }
`;

const StyledButton = styled.button`
  ${textBase};
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  color: ${({ theme }): string => theme.colours.navigation.dark};
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: inline-block;
  padding: 0;
  margin-top: 12px;

  @media ${device.mobileM} {
    li {
      margin-bottom: 20px;
    }
  }
`;

const Badge = styled(NumberBadge)`
  position: absolute;
  top: -8px;
  right: -15px;
  z-index: 2;
`;

interface IProps {
  basketCount: number;
  onClickLogOut(): void;
  onClickElement(): void;
}

export const MobileNavigationMenu: React.FC<IProps> = props => {
  const isAuthenticated = useContext(AuthContext);
  const [currentLevel, setCurrentLevel] = useState(0);

  const setLevel0 = useCallback(() => setCurrentLevel(0), []);
  const setLevel1 = useCallback(() => setCurrentLevel(1), []);

  const logOut = isAuthenticated ? (
    <li>
      <StyledButton onClick={props.onClickLogOut}>Log Out</StyledButton>
    </li>
  ) : null;

  return (
    <>
      <Menu level={currentLevel * -1}>
        <li>
          <Link exact to="/" onClick={props.onClickElement} activeClassName="active">
            Home
          </Link>
        </li>
        <li>
          <LevelForwardLink onClick={setLevel1}>
            Santa Letter
            <span />
          </LevelForwardLink>
        </li>
        <li>
          <Link
            exact
            to={routePaths.video.home}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            Santa Video
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.calls.AUDIO.home}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            Santa Audio Call
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.calls.VIDEO.home}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            Santa Zoom Call
          </Link>
        </li>
        <li>
          <Link exact to="/santa-text" onClick={props.onClickElement} activeClassName="active">
            Santa Text
          </Link>
        </li>
        <li>
          <Link exact to="/santa-sack" onClick={props.onClickElement} activeClassName="active">
            Santa Sack
          </Link>
        </li>
        <li>
          <Link exact to="/about" onClick={props.onClickElement} activeClassName="active">
            About Us
          </Link>
        </li>
        <li>
          <Link exact to="/basket" onClick={props.onClickElement} activeClassName="active">
            Basket
            {props.basketCount > 0 && <Badge small count={props.basketCount} />}
          </Link>
        </li>
        <li>
          <Link exact to="/my-account" onClick={props.onClickElement} activeClassName="active">
            My Account
          </Link>
        </li>
        {logOut}
      </Menu>

      <Menu level={currentLevel * -1 + 1}>
        <li>
          <LevelBackLink onClick={setLevel0}>
            <span /> Back
          </LevelBackLink>
        </li>
        <li>
          <Link
            exact
            to={routePaths.letter.types.classic}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            Santa Letter Classic
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.letter.types.cookieMix}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            With Cookie Mix
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.letter.types.plush}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            With Plush Teddy
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.letter.types.magicSnow}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            With Magic Snow
          </Link>
        </li>
        <li>
          <Link
            exact
            to={routePaths.letter.types.santaSack}
            onClick={props.onClickElement}
            activeClassName="active"
          >
            With Santa Sack
          </Link>
        </li>
      </Menu>
    </>
  );
};
