import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import { routePaths } from "../../../model/route";
import { CallsMenuDropdown } from "../calls-menu-dropdown";
import { LettersMenuDropdown } from "../letters-menu-dropdown";

const Container = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    margin-top: 6px; /* to compensate for underscores */
    position: relative;
    display: inline-block;

    :not(:first-child) {
      margin-left: 10px;
    }

    :not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const StyledLink = styled(NavLink)`
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }): string => theme.colours.navigation.dark};

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
    background-color: ${({ theme }): string => theme.colours.navigation.dark};
  }

  &:hover:after,
  &.active:after {
    width: 100%;
    left: 0;
  }
`;

export const TopNavigation: React.FC = () => {
  const { pathname } = useLocation();

  if (pathname.match(/^\/checkout/)) {
    return null;
  }

  return (
    <Container data-santa-id="top-navigation-menu">
      <li>
        <StyledLink exact to="/">
          Home
        </StyledLink>
      </li>
      <li>
        <LettersMenuDropdown />
      </li>
      <li>
        <StyledLink to={routePaths.video.home}>Santa Video</StyledLink>
      </li>
      <li>
        <CallsMenuDropdown />
      </li>
      <li>
        <StyledLink to="/santa-text">Santa Text</StyledLink>
      </li>
      <li>
        <StyledLink to="/santa-sack">Santa Sack</StyledLink>
      </li>
    </Container>
  );
};
