import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export const FormFieldGiftFreeText: React.FC<IProps> = ({
  id = "gift",
  label = "Most desired gift this Christmas",
  placeholder = "e.g. A new bike",
  required = false,
}) => (
  <FormFieldWrapper label={label} required={required} name={id}>
    <Field name={id} component={FormikFormInputText} placeholder={placeholder} />
  </FormFieldWrapper>
);
