import { SantaErrorCode } from "@santa/common/lib/errors";
import { createUrn, extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { isApolloError } from "@apollo/client";
import * as dateFns from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { TextEditForm } from "../../../organisms/forms/text-edit";
import { getLocaleForApi } from "../../../../utils/graphql";
import {
  BoyGirl,
  UpdateTextDocument,
  UpdateTextMutation,
  UpdateTextMutationVariables,
  useMyAccountTextQuery,
} from "../../../../types/graphql";
import { routePaths } from "../../../../model/route";
import { useTextPhone } from "../../../../hooks/text/use-text-phone";
import { useTextMessages } from "../../../../hooks/text/use-text-messages";
import { useCallValidateNumber } from "../../../../hooks/call/use-call-validate-number";
import { formatTextTime } from "../../../../model/text";

type FormProps = React.ComponentProps<typeof TextEditForm>;
type FormParams = FormProps["defaultValues"];
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapDataToForm = (
  data: NonNullable<NonNullable<ReturnType<typeof useMyAccountTextQuery>["data"]>["text"]>,
): FormParams => ({
  phoneCountryUrn: data.phoneCountry.urn,
  phoneNumber: data.phoneNumber,
  timezoneUrn: data.timezone.urn,
  firstName: data.firstName,
  boyGirl: data.boyGirl,
  ageYears: data.ageYears?.toString() || "",
  templateId: data.template.id,
  petId: data.pet?.id,
  town: data.town,
  petName: data.petName || "",
  text1TimeDate: dateFns.startOfDay(
    utcToZonedTime(dateFns.parseISO(data.text1Time), data.timezone.timezone),
  ),
  text1TimeTime: dateFns.format(
    utcToZonedTime(dateFns.parseISO(data.text1Time), data.timezone.timezone),
    "HH:mm",
  ),
  text1Body: data.text1Body,
  text2TimeDate: dateFns.startOfDay(
    utcToZonedTime(dateFns.parseISO(data.text2Time), data.timezone.timezone),
  ),
  text2TimeTime: dateFns.format(
    utcToZonedTime(dateFns.parseISO(data.text2Time), data.timezone.timezone),
    "HH:mm",
  ),
  text2Body: data.text2Body,
  text3TimeDate: dateFns.startOfDay(
    utcToZonedTime(dateFns.parseISO(data.text3Time), data.timezone.timezone),
  ),
  text3TimeTime: dateFns.format(
    utcToZonedTime(dateFns.parseISO(data.text3Time), data.timezone.timezone),
    "HH:mm",
  ),
  text3Body: data.text3Body,
});

interface IRouteParams {
  id: string;
}

interface IData {
  formProps?: FormProps;
  loaderProps: LoaderProps;
}

export const useData = (): IData => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<IRouteParams>();
  const [formError, setFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const textUrn = createUrn(UrnResource.TEXT, id);
  const {
    data,
    client,
    error,
    loading: isLoading,
    refetch: dataRefetch,
  } = useMyAccountTextQuery({
    fetchPolicy: "network-only",
    variables: {
      textUrn,
      locale: getLocaleForApi(),
    },
  });

  const validatePhoneNumber = useCallValidateNumber(client);
  const { onChangePhoneCountry, timezoneOptions, countryOptions } = useTextPhone({
    timezones: data?.timezones,
    countries: data?.countries,
  });
  const { onChangeSourceField, onChangeTemplate, petOptions, templateOptions } = useTextMessages({
    templates: data?.textTemplates,
    pets: data?.pets,
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const onSubmit = useCallback<FormProps["onSubmit"]>(
    async values => {
      try {
        setIsSubmitting(true);

        const phoneNumber = await validatePhoneNumber(values.phoneNumber, values.phoneCountryUrn);

        const formatTime = (date: Date, time: string): string =>
          formatTextTime(date, time, extractIdFromUrn(UrnResource.TIMEZONE, values.timezoneUrn));

        await client.mutate<UpdateTextMutation, UpdateTextMutationVariables>({
          mutation: UpdateTextDocument,
          variables: {
            textUrn,
            timezoneUrn: values.timezoneUrn,
            phoneCountryUrn: values.phoneCountryUrn,
            phoneNumber,
            firstName: values.firstName,
            ageYears: values.ageYears,
            boyGirl: values.boyGirl as BoyGirl,
            town: values.town,
            petId: values.petId || null,
            petName: values.petName || null,
            templateId: values.templateId,
            text1Time: formatTime(values.text1TimeDate, values.text1TimeTime),
            text1Body: values.text1Body,
            text2Time: formatTime(values.text2TimeDate as Date, values.text2TimeTime),
            text2Body: values.text2Body,
            text3Time: formatTime(values.text3TimeDate as Date, values.text3TimeTime),
            text3Body: values.text3Body,
          },
        });

        history.push(routePaths.myAccount.home);
      } catch (error) {
        if (error instanceof Error) {
          if (isApolloError(error)) {
            if (error.graphQLErrors) {
              const firstError = error.graphQLErrors[0].message;
              const errorsToTrap: string[] = [SantaErrorCode.TEXT_TIME_TOO_SOON];

              if (errorsToTrap.includes(firstError as SantaErrorCode)) {
                setFormError(t(`myaccount.text.errors.${firstError}`));
              } else {
                setFormError(firstError);
              }
            }
          } else {
            setFormError(error.message);
          }
        }

        setIsSubmitting(false);
      }
    },
    [client],
  );
  const onClickBack = useCallback((): void => history.push(routePaths.myAccount.home), [history]);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && { refetch }),
    },
    ...(data?.text && {
      formProps: {
        defaultValues: mapDataToForm(data.text),
        petOptions,
        templateOptions,
        countryOptions,
        timezoneOptions,
        formError,
        isSubmitting,
        onSubmit,
        onClickBack,
        onChangePhoneCountry,
        onChangeSourceField,
        onChangeTemplate,
      },
    }),
  };
};
