import styled from "styled-components";

import { theme as siteTheme } from "../../../../theme";

import { ReactComponent as CaretImage } from "./caret.svg";

interface IProps {
  colour?: keyof typeof siteTheme.colours;
}

export const CaretIcon = styled(CaretImage)<IProps>`
  width: 21px;
  height: 13px;

  path {
    fill: ${({ colour = "icon", theme }): string => theme.colours[colour].toString()};
  }
`;
