import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as yup from "yup";

import { FormSubmitRow } from "../../../molecules/form-submit-row";
import {
  FormSectionTextPhone,
  IFormSectionModel,
  validationRules,
} from "../../form-sections/text/form-section-text-phone";

const validationSchema = yup.object(validationRules);

export type TextTextingValidatedFormModel = yup.Asserts<typeof validationSchema>;

type FormModel = IFormSectionModel;

export interface IOwnProps {
  defaultValues: FormModel;
  countryOptions: ISelectOption[];
  timezoneOptions: ISelectOption[];
  onChangePhoneCountry(setTimezone: (value: string) => void, urn: string): void;
  onChange(values: TextTextingValidatedFormModel): void;
  onSubmit(setPhoneError: (value: string) => void, values: TextTextingValidatedFormModel): void;
  onClickBack(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  countryOptions,
  timezoneOptions,
  onChangePhoneCountry,
  setFieldValue,
  submitForm,
  onClickBack,
  onChange,
}) => {
  const isValid = validationSchema.isValidSync(values);

  const handleChangePhoneCountry = useCallback(
    (urn: string): void =>
      onChangePhoneCountry((u: string) => setFieldValue("timezoneUrn", u), urn),
    [onChangePhoneCountry, setFieldValue],
  );

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as TextTextingValidatedFormModel);
    }
  }, [values]);

  return (
    <Form>
      <FormSectionTextPhone
        timezoneOptions={timezoneOptions}
        countryOptions={countryOptions}
        onChangePhoneCountry={handleChangePhoneCountry}
      />

      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
      />
    </Form>
  );
};

export const TextsDetailsTextingForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(
      (phoneNumber: string) => formik.setErrors({ phoneNumber }),
      validationSchema.cast(values) as TextTextingValidatedFormModel,
    ),
})(C);
