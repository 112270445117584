import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelect } from "../../form-input-select";

interface IProps {
  id?: string;
  placeholder?: string;
  label?: string;
  options: ISelectOption[];
  required?: boolean;
}

export const FormFieldCountry: React.FC<IProps> = ({
  options,
  id = "countryUrn",
  placeholder = "Select country",
  label = "Child's country",
  required = false,
}) => (
  <FormFieldWrapper label={label} required={required}>
    <Field
      name={id}
      placeholder={placeholder}
      component={FormikFormInputSelect}
      options={options}
    />
  </FormFieldWrapper>
);
