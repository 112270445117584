import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import candyStripesImage from "./candy-stripes.png";

const BackgroundHeader = styled.img`
  z-index: 1;
  position: absolute;
  top: 0;

  @media ${device.mobileS} {
    height: 25px;
    width: 375px;
  }

  @media ${device.tablet} {
    height: 51px;
    width: 768px;
  }

  @media ${device.laptopL} {
    height: 96px;
    width: 1440px;
  }
`;

export const CandyStripeTop: React.FC<React.ComponentProps<"img">> = ({ className }) => (
  <BackgroundHeader src={candyStripesImage} className={className} />
);
