import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { PopularRibbon } from "../../atoms/popular-ribbon";
import { Heading2 } from "../../atoms/text";
import { BowPrice } from "../../molecules/bow-price";
import { device } from "../../../utils/media-queries";
import { BaubleList } from "../../molecules/bauble-list";

const Panel = styled.div`
  position: relative;
  min-height: 180px;
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
  border: white 3px 3px solid;
  display: flex;
  flex-direction: row;
  flex: 1;

  @media ${device.mobileS} {
    padding: 17px 10px;
  }

  @media ${device.laptopL} {
    padding: 27px;
  }
`;

interface IImageContainerProps {
  url: string;
}

const ImageContainer = styled.div<IImageContainerProps>`
  background-image: url(${({ url }): string => url});
  background-size: cover;
  background-position: center center;

  @media ${device.mobileS} {
    min-width: 88px;
    height: 123px;
  }

  @media ${device.laptopL} {
    min-width: 118px;
    height: 165px;
  }
`;

const Content = styled.div`
  margin-left: 30px;
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
`;

const PriceRow = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Ribbon = styled(PopularRibbon)`
  position: absolute;

  @media ${device.mobileS} {
    width: 91px;
    height: 91px;
    bottom: -10px;
    right: -10px;
  }

  @media ${device.laptopL} {
    width: 146px;
    height: 146px;
    bottom: -13px;
    right: -13px;
  }
`;

interface IProps {
  title: string;
  baubleContent: string;
  imageUrl: string;
  price: number;
  isPopular?: boolean;
  onClick(): void;
}

export const LetterTypePanel: React.FC<IProps> = ({
  isPopular,
  title,
  baubleContent,
  imageUrl,
  price,
  onClick,
}) => (
  <Panel onClick={onClick}>
    {isPopular && <Ribbon />}
    <ImageContainer url={`${imageUrl}?h=165`} />
    <Content>
      <HeadingRow>
        <Heading2>{title}</Heading2>
      </HeadingRow>
      <BaubleList>
        <ReactMarkdown source={baubleContent} />
      </BaubleList>
      <PriceRow>
        <BowPrice price={price} />
      </PriceRow>
    </Content>
  </Panel>
);
