import { LETTER_CONTENT_FORM_PS_OTHER_ID } from "@santa/common/lib/form-validation/letter";
import {
  getTemplateCompiledParagraphs,
  mapTemplateValues,
} from "@santa/common/lib/products/letter/template";
import { ApolloClient } from "@apollo/client";

import { ILetterPreviewUpdateArgs } from "../../components/organisms/letter-preview";
import {
  LetterSavedDataEnrichmentQuery,
  LetterSavedDataEnrichmentQueryVariables,
  LetterSavedDataEnrichmentDocument,
} from "../../types/graphql";
import { ILetterCreateState } from "../graphql/cache";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";

export const getLetterUpdatePreviewArgs = async (
  client: ApolloClient<{}>,
  details: ILetterCreateState,
): Promise<ILetterPreviewUpdateArgs | undefined> => {
  if (details) {
    const { content, recipient } = details;
    if (recipient) {
      const { addressCountryUrn, addressTown, boyGirl, date } = recipient;

      if (details && addressCountryUrn && addressTown && boyGirl && date) {
        const { data: enrichmentData } = await client.query<
          LetterSavedDataEnrichmentQuery,
          LetterSavedDataEnrichmentQueryVariables
        >({
          query: LetterSavedDataEnrichmentDocument,
          variables: {
            addressCountryUrn,
            usStateUrn: recipient.addressUsStateUrn || "",
            includeUsState: addressCountryUrn === createUrn(UrnResource.COUNTRY, "us"),
            signatureId: content?.signatureId,
            includeSignature: !!content?.signatureId,
            templateId: content?.template.value,
            includeTemplate: !!content?.template.value,
            pet1Id: content?.pet1Id,
            includePet1: !!content?.pet1Id,
            pet2Id: content?.pet2Id,
            includePet2: !!content?.pet2Id,
            frontDoorId: content?.frontDoorId,
            includeFrontDoor: !!content?.frontDoorId,
            psId: content?.psId,
            includePs: !!(content?.psId && content.psId !== LETTER_CONTENT_FORM_PS_OTHER_ID),
          },
        });

        const address = [
          `${recipient.firstName} ${recipient.lastName}`,
          recipient.addressLine1,
          recipient.addressLine2,
          recipient.addressTown,
          enrichmentData.usState ? enrichmentData.usState.name : recipient.addressCounty,
          recipient.addressPostcode,
          enrichmentData.country?.name.toUpperCase(),
        ]
          // filter out empty values
          .filter(v => !!v);

        let paragraphs: string[] = [];
        let templateName: string | undefined;
        let signature: string | undefined;
        let ps: string | undefined;
        const firstName = recipient.firstName;

        if (content) {
          if (enrichmentData.letterTemplate?.label && enrichmentData.letterTemplate?.template) {
            templateName = enrichmentData.letterTemplate.label;

            const templateValues = mapTemplateValues({
              addressTown,
              firstName,
              boyGirl,
              ageMonths: recipient.ageMonths || undefined,
              ageYears: recipient.ageYears || undefined,
              gift: content.gift || undefined,
              hobby: content.hobby || undefined,
              friend: content.friend || undefined,
              pet1: enrichmentData.pet1 || undefined,
              pet1Name: content.pet1Name || undefined,
              pet2: enrichmentData.pet2 || undefined,
              pet2Name: content.pet2Name || undefined,
              frontDoor: enrichmentData.frontDoor?.text || undefined,
            });

            paragraphs = getTemplateCompiledParagraphs(
              enrichmentData.letterTemplate.template,
              templateValues,
            );
          }

          if (enrichmentData.letterSignature?.text) {
            signature = enrichmentData.letterSignature.text;
          }

          if (content.psId === LETTER_CONTENT_FORM_PS_OTHER_ID && content.psCustom) {
            ps = content.psCustom;
          } else if (enrichmentData.letterPostscript?.text) {
            ps = enrichmentData.letterPostscript.text;
          }
        }

        return {
          signature,
          ps,
          templateName,
          name: firstName,
          date,
          address,
          paragraphs,
        };
      }
    }
  }
};
