import styled from "styled-components";

import {
  IBasketItem,
  getBasketItemDescription,
  getBasketItemPrice,
  getBasketTotal,
  ProductLookup,
} from "../../../model/basket";
import { displayPrice } from "../../../utils/price";
import { CheckoutBasketItem } from "../../molecules/checkout-basket-item";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  background: white;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  flex: 1;
  padding-top: 20px;
  margin-top: 40px;
  margin-left: -12px;
  margin-right: -12px;

  @media ${device.laptopL} {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 6px;
  }
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

const Heading = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size24};
`;

const Price = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size24};
  font-weight: bold;
  color: ${({ theme }): string => theme.colours.alert};
`;

const ItemsContainer = styled.div`
  border-top: ${({ theme }): string => theme.colours.textSubdued} 1px solid;
  padding: 0 20px;
`;

const DeductionLabel = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
`;

const DeductionValue = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
`;

interface IProps {
  items: IBasketItem[];
  productLookup: ProductLookup<{
    title?: string | null;
    price?: number | null;
  }>;
  firstNameLookup: Partial<Record<string, string>>;
  deduction: number;
}

export const CheckoutBasket: React.FC<IProps> = ({
  items,
  productLookup,
  deduction,
  firstNameLookup,
}) => {
  const rows = items.map((item, i) => {
    const title = (
      <>
        {productLookup[item.productAlphaId!]!.title!}{" "}
        <em>{displayPrice(productLookup[item.productAlphaId!]!.price!)}</em>
      </>
    );

    return (
      <CheckoutBasketItem
        key={i}
        title={title}
        price={getBasketItemPrice(item, productLookup)}
        description={getBasketItemDescription(item, {
          letterTemplate: {},
          text: {},
          firstName: firstNameLookup,
          phoneCountryCode: {},
        })}
        additionalItems={item.addOns!.map(a => (
          <>
            {productLookup[a!]!.title!} <em>{displayPrice(productLookup[a!]!.price!)}</em>
          </>
        ))}
      />
    );
  });

  const deductionComponent =
    deduction > 0 ? (
      <Header>
        <DeductionLabel>Promo code</DeductionLabel>
        <DeductionValue>{displayPrice(0 - deduction)}</DeductionValue>
      </Header>
    ) : undefined;

  return (
    <Container>
      <Header>
        <Heading>Your order</Heading>
        <Price>{displayPrice(getBasketTotal(items, productLookup) - deduction)}</Price>
      </Header>
      {deductionComponent}
      <ItemsContainer>{rows}</ItemsContainer>
    </Container>
  );
};
