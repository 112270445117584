import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";

import { config } from "../../config";

export const getApolloClient = async (): Promise<ApolloClient<{}>> => {
  const cache = new InMemoryCache();

  const link = new HttpLink({
    uri: config.graphql.endpoint,
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 10,
      retryIf: error => !!error,
    },
  });

  const authLink = setContext(async (__, { headers }) => {
    try {
      const session = await Auth.currentSession();

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        },
      };
    } catch (_e) {
      // no catch
    }

    return {};
  });

  const apolloClient = new ApolloClient({
    link: from([retryLink, authLink, link]),
    cache,
  });

  return apolloClient;
};
