import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";

import { FormInputAutosuggest } from "../../form-input-autosuggest";
import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import {
  fetchAddresses,
  SearchableCountry,
  IAutoCompleteAddress,
} from "../../../../../model/address-lookup";
import { FormFieldLink } from "../../../../atoms/form-field-link";

const SearchFormFieldWrapper = styled(FormFieldWrapper)`
  margin-bottom: 5px;
`;

/**
 * Render a search result
 * @param a Address to render
 */
const renderAddressSuggestion = (a: IAutoCompleteAddress): JSX.Element => <>{a.address}</>;

/**
 * Get the postcode from the search result
 * @param a Address to extract postcode from
 */
const getAddressPostcode = (a: IAutoCompleteAddress): string => a.address;

interface IProps {
  countryUrn: SearchableCountry;
  onSelectAddress(address: IAutoCompleteAddress): void;
  onSelectEnterManually(): void;
}

export const AddressSearchPartial: React.FC<IProps> = ({
  onSelectAddress,
  onSelectEnterManually,
  countryUrn,
}) => {
  const { t } = useTranslation();

  const [searchPostcode, setSearchPostcode] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState<IAutoCompleteAddress[]>([]);

  const countryCode = extractIdFromUrn(UrnResource.COUNTRY, countryUrn) as "gb" | "us";

  const fetchAddressSuggestions = debounce(async (search: string) => {
    if (search.length >= 4) {
      setAddressSuggestions(await fetchAddresses(countryCode, search));
    }
  }, 500);

  const clearAddressSuggestions = useCallback(
    (): void => setAddressSuggestions([]),
    [setAddressSuggestions],
  );

  return (
    <>
      <SearchFormFieldWrapper label={t(`form.address.search-${countryCode}.label`)}>
        <FormInputAutosuggest<IAutoCompleteAddress>
          name="postcodeSearch"
          placeholder={t(`form.address.search-${countryCode}.placeholder`)}
          onChange={setSearchPostcode}
          value={searchPostcode}
          suggestions={addressSuggestions}
          fetchSuggestions={fetchAddressSuggestions}
          clearSuggestions={clearAddressSuggestions}
          getSuggestionValue={getAddressPostcode}
          renderSuggestion={renderAddressSuggestion}
          onSelect={onSelectAddress}
        />
      </SearchFormFieldWrapper>
      <FormFieldLink onClick={onSelectEnterManually}>Enter address manually</FormFieldLink>
    </>
  );
};
