import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Heading3 } from "../../atoms/text";
import { letterCreateState } from "../../../model/graphql/cache";
import { device } from "../../../utils/media-queries";

const Heading = styled(Heading3)`
  margin-top: 10px;
  margin-bottom: 5px;

  @media ${device.laptopL} {
    margin-top: 0;
  }
`;

export const LetterSectionTitle = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { addOns } = letterCreateState();

  if (!addOns || addOns.length !== 1) {
    return null;
  }

  const [addOn] = addOns;

  return <Heading>{t(`letter.sectionTitle.${addOn}`)}</Heading>;
};
