import { useCallback } from "react";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { Col, Row } from "react-grid-system";
import * as yup from "yup";
import styled from "styled-components";
import { Field } from "formik";

import { FormFieldWrapper } from "../../form-field-wrapper";
import { Paragraph } from "../../../atoms/text";
import { FormikFormInputSelect } from "../../../molecules/form/form-input-select";
import { FormFieldPhoneNumber } from "../../../molecules/form/form-fields/form-field-phone-number";
import { FormFieldDate } from "../../../molecules/form/form-fields/form-field-date";
import { CallType } from "../../../../types/graphql";

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

export const videoValidationRules = {
  timezoneUrn: yup.string().required("Required"),
  date: yup.date().required("Required"),
  time: yup.string().required("Required"),
};

export const audioValidationRules = {
  ...videoValidationRules,
  phoneCountryUrn: yup.string().required("Required"),
  phoneNumber: yup.string().max(20, "Maximum 20 characters").required("Required"),
};

export interface IDefaultValuesModel {
  phoneCountryUrn?: string;
  phoneNumber?: string;
  timezoneUrn?: string;
  date?: Date;
  time?: string;
}

interface IProps {
  type: CallType;
  countryOptions: ISelectOption[];
  timezoneOptions: ISelectOption[];
  dates?: Date[];
  slotOptions?: ISelectOption[];
  hasDoubleSlots?: boolean;
  showMeetingIdNote?: boolean;
  onChangeCountry(urn: string): void;
  onChangeTimezone(urn: string): void;
  onChangeDate(date: string): void;
  layout?: "narrow" | "wide";
}

/**
 * Form section for letter content
 */
export const FormSectionCallCalling: React.FC<IProps> = ({
  type,
  countryOptions,
  timezoneOptions,
  slotOptions,
  dates,
  hasDoubleSlots = false,
  showMeetingIdNote = false,
  layout = "narrow",
  onChangeCountry,
  onChangeTimezone,
  onChangeDate,
}) => {
  const handleChangeTimezone = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void =>
      onChangeTimezone(e.target.options[e.target.selectedIndex].value),
    [onChangeTimezone],
  );

  const isNarrow = layout === "narrow";

  return (
    <>
      {type === CallType.AUDIO && (
        <Row>
          <Col>
            <FormFieldPhoneNumber
              onChangeCountry={onChangeCountry}
              countryOptions={countryOptions}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={isNarrow ? 12 : 6}>
          <FormFieldWrapper label="Country and Time zone?" name="timezone" required>
            <Field
              name="timezoneUrn"
              component={FormikFormInputSelect}
              options={timezoneOptions}
              onChange={handleChangeTimezone}
              required
            />
          </FormFieldWrapper>
        </Col>

        {dates && dates.length && (
          <>
            <Col xl={isNarrow ? 6 : 3}>
              <FormFieldDate label="Day to call?" dates={dates} onChange={onChangeDate} />
            </Col>

            {slotOptions && (
              <Col xl={isNarrow ? 6 : 3}>
                <FormFieldWrapper label="Time to call?" name="time" required>
                  <Field
                    name="time"
                    component={FormikFormInputSelect}
                    options={slotOptions}
                    placeholder="Select time"
                  />
                </FormFieldWrapper>

                {hasDoubleSlots && (
                  <Paragraph>
                    Times marked with{" "}
                    <span role="img" aria-label="A number 2 icon">
                      2️⃣
                    </span>{" "}
                    have Santa available to make this a linked call where he will talk with a second
                    child on the same call immediately after talking to the first child. NOTE: you
                    can also select these times for single calls.
                  </Paragraph>
                )}
              </Col>
            )}
          </>
        )}

        {dates && dates.length === 0 && (
          <Row>
            <Col>
              <Error>
                Unfortunately, no calls are available for this year. Please come back later!
              </Error>
            </Col>
          </Row>
        )}
      </Row>
      {showMeetingIdNote && (
        <Paragraph>A meeting ID will be sent to the purchase email used at checkout</Paragraph>
      )}
    </>
  );
};
