import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { Heading1 } from "../../../atoms/text";
import { device } from "../../../../utils/media-queries";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { LetterTypePanel } from "../../../organisms/letter-type-panel";
import { DataLoadedContainer } from "../../../control/data-loaded-container";

import { useData } from "./use-data";

const PanelCol = styled(Col)`
  display: flex;
  padding-bottom: 24px;
`;

const MainHeading = styled(Heading1)`
  @media ${device.mobileS} {
    margin-top: 0;
  }

  @media ${device.laptopL} {
    margin-top: 44px;
  }
`;

export const LetterHome = (): JSX.Element => {
  const { data, isLoading, refetch } = useData();

  return (
    <MainWrapper>
      <Container>
        <Row>
          <Col md={6}>
            <MainHeading>Santa Letter</MainHeading>
          </Col>
        </Row>
        <Row>
          <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
            {data &&
              data.map(type => (
                <PanelCol md={6} key={type.title}>
                  <LetterTypePanel
                    title={type.title}
                    baubleContent={type.baubleContent}
                    onClick={type.handleClick}
                    imageUrl={type.imageUUrl}
                    price={type.price}
                  />
                </PanelCol>
              ))}
          </DataLoadedContainer>
        </Row>
      </Container>
    </MainWrapper>
  );
};
