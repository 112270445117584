import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { daysUntilChristmas } from "../../../model/dates";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  font-family: ${({ theme }): string => theme.fonts.main};
`;

const Days = styled.span`
  font-weight: bold;
  color: #f8bf2f;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }
`;

const Description = styled.span`
  margin-left: 10px;
  color: white;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

export const DaysUntilChristmas: React.FC = () => {
  const { t } = useTranslation();
  const days = daysUntilChristmas();

  return (
    <Container>
      <Days>{days}</Days>
      <Description>{t("home.daysUntilChristmas")}</Description>
    </Container>
  );
};
