import { Route, Switch } from "react-router-dom";

import { routePaths } from "../../../model/route";

import { VideoDetails } from "./video-details";
import { VideoHome } from "./video-home";
import { VideoImages } from "./video-images";

export const Videos: React.FC = () => (
  <Switch>
    <Route exact path={routePaths.video.home} component={VideoHome} />
    <Route exact path={routePaths.video.details} component={VideoDetails} />
    <Route exact path={routePaths.video.images} component={VideoImages} />
  </Switch>
);
