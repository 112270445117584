import { FieldProps } from "formik";
import React, { useState } from "react";
import styled, { StyledComponentPropsWithRef, css } from "styled-components";
import { pick } from "lodash";

interface ICommonProps {
  isError?: boolean;
}

const textCommon = css`
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  ::placeholder {
    color: ${({ theme }): string => theme.colours.form.placeholder};
  }

  :focus {
    border-color: ${({ theme }): string => theme.colours.spotGold};
    outline: none;
  }
`;

const borderCommon = css<ICommonProps>`
  flex: 1;
  min-width: 50px;
  padding: 7px 16px;
  background-color: #ffffff;
  border: 1px solid
    ${(props): string =>
      props.isError ? props.theme.colours.alert : props.theme.colours.form.placeholder};
  border-radius: 6px;
`;

const SimpleTextarea = styled.textarea<ICommonProps>`
  ${textCommon}
  ${borderCommon}
`;

const TextareaWrapper = styled.div<ICommonProps>`
  ${borderCommon}

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const WrappedTextarea = styled.textarea`
  ${textCommon}

  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  resize: none;
`;

const Counter = styled.div`
  font-family: ${(props): string => props.theme.fonts.main};
  font-size: ${(props): string => props.theme.fontSizes.size14};
  color: ${(props): string => props.theme.colours.textSubdued};
`;

type Props = StyledComponentPropsWithRef<"textarea"> & ICommonProps;

export const FormInputTextarea: React.FC<Props> = props => {
  const initCounter = typeof props.maxLength !== "undefined" ? props.maxLength : 0;
  const [counter, setCounter] = useState<number>(initCounter);

  const commonProps = pick(props, ["isError"]);

  if (props.maxLength) {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      if (props.onChange) {
        props.onChange(event);
      }

      if (typeof props.maxLength !== "undefined") {
        setCounter(props.maxLength - event.target.value.length);
      }
    };

    /* eslint-disable react/jsx-no-bind */
    return (
      <TextareaWrapper {...commonProps}>
        <WrappedTextarea {...props} onChange={handleChange} />
        <Counter>{counter}</Counter>
      </TextareaWrapper>
    );
    /* eslint-enable react/jsx-no-bind */
  }

  return <SimpleTextarea {...props} />;
};

export const FormikFormInputTextarea: React.FC<
  FieldProps & StyledComponentPropsWithRef<"textarea">
> = ({ field, form: { touched, errors, setFieldValue, setFieldTouched }, ...props }) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = (): void => setFieldTouched(field.name);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setFieldValue(field.name, e.target.value);

  const inputProps: Props = {
    name: field.name,
    onBlur: field.onBlur,
    onFocus: setFieldFocussed,
    isError: isError,
    onChange: handleChange,
    placeholder: props.placeholder,
    value: field.value,
    rows: props.rows,
    maxLength: props.maxLength,
  };

  if (props.autoComplete) {
    inputProps.autoComplete = props.autoComplete;
  }

  return <FormInputTextarea {...inputProps} />;
};
