import React, { useCallback } from "react";
import styled from "styled-components";
import { Visible, Hidden } from "react-grid-system";

import { Heading2, Paragraph } from "../../atoms/text";
import { FakeCheckbox } from "../../molecules/fake-checkbox";
import { device } from "../../../utils/media-queries";
import { displayPrice } from "../../../utils/price";
import { BaubleList } from "../../molecules/bauble-list";
import { CallCertificateForm, IAddressFormModel } from "../forms/call-certificate";
import { BowPrice } from "../../molecules/bow-price";

interface IPanelProps {
  isSelected: boolean;
}

const Panel = styled.div<IPanelProps>`
  position: relative;
  min-height: 180px;
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
  border: ${({ isSelected, theme }): string => (isSelected ? theme.colours.spotGold : "white")} 3px
    3px solid;

  @media ${device.mobileS} {
    padding: 17px 10px;
  }

  @media ${device.laptopL} {
    padding: 27px;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

interface IImageContainerProps {
  url: string;
}

const ImageContainer = styled.div<IImageContainerProps>`
  cursor: zoom-in;
  background-image: url(${({ url }): string => url});
  background-size: cover;
  background-position: center center;

  @media ${device.mobileS} {
    min-width: 88px;
    height: 123px;
  }

  @media ${device.laptopL} {
    min-width: 118px;
    height: 165px;
  }
`;

const Content = styled.div`
  margin-left: 30px;
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Price = styled.div`
  font-weight: bold;
  font-size: ${({ theme }): string => theme.fontSizes.size24};
  margin-right: 20px;

  span {
    color: ${({ theme }): string => theme.colours.alert};
  }
`;

const Address = styled.div`
  margin-top: 20px;
`;

const PriceRow = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

interface IProps {
  title: string;
  description: string;
  isSelected: boolean;
  imageUrl: string;
  price: number;
  callQuantity: number;
  onClick(): void;
  onClickImage(): void;
  onChangeAddress(values: IAddressFormModel): void;
  onValidation(isValid: boolean): void;
}

export const CallCertificateAddOnPanel: React.FC<IProps> = props => {
  const onClickImage = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      e.stopPropagation();
      props.onClickImage();
    },
    [props],
  );

  const descriptionParagraphs = props.description.split(/\|/);
  const descriptionItems = descriptionParagraphs.map(p => <li key={p}>{p}</li>);
  const descriptions = descriptionParagraphs.map(p => <Paragraph key={p}>{p}</Paragraph>);
  const quantityDescription = `${props.callQuantity} certificate${
    props.callQuantity !== 1 ? "s" : ""
  }`;
  const price = props.price * props.callQuantity;

  const handleCLick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
  }, []);

  return (
    <Panel onClick={props.onClick} isSelected={props.isSelected}>
      <ProductInfo>
        <ImageContainer onClick={onClickImage} url={`${props.imageUrl}?h=165`} />
        <Content>
          <HeadingRow>
            <Heading2>{props.title}</Heading2>
            <CheckboxContainer>
              <Hidden xs sm>
                <Price>
                  <span>{displayPrice(price)}</span> for {quantityDescription}
                </Price>
              </Hidden>
              <FakeCheckbox isChecked={props.isSelected} />
            </CheckboxContainer>
          </HeadingRow>

          <Visible md lg xl>
            <BaubleList>
              <ul>{descriptionItems}</ul>
            </BaubleList>
          </Visible>

          <Visible xs sm>
            {descriptions}
            <PriceRow>
              <BowPrice price={props.price} />
            </PriceRow>
          </Visible>
        </Content>
      </ProductInfo>

      <Address onClick={handleCLick}>
        {props.isSelected && (
          <CallCertificateForm onChange={props.onChangeAddress} onValidation={props.onValidation} />
        )}
      </Address>
    </Panel>
  );
};
