import { ISelectMultilineOption } from "@santa/common/lib/utils/form";
import { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ValueType } from "react-select";

import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { ShowNavContext } from "../../../contexts/show-nav-context";
import { config } from "../../../../config";
import { useLetterFormData } from "../../../../hooks/letter/use-letter-form-data";
import { routePaths } from "../../../../model/route";
import { letterState } from "../../../../model/graphql/cache";
import { getLetterUpdatePreviewArgs } from "../../../../model/letter";
import { ILetterPreviewUpdateArgs } from "../../../organisms/letter-preview";

interface IUseData {
  loaderProps: React.ComponentProps<typeof DataLoadedContainer>;
  previewProps?: ILetterPreviewUpdateArgs;
  shouldShowMobilePreview: boolean;
  selectedTemplate: ISelectMultilineOption | undefined;
  templates?: ISelectMultilineOption[];
  handleShowMobilePreview(): void;
  handleHideMobilePreview(): void;
  handleClickBack(): void;
  handleClickCreate(): void;
  handleChangeTemplate(value: ValueType<ISelectMultilineOption, false>): Promise<void>;
}

export const useData = (): IUseData => {
  const history = useHistory();
  const { hideNav, showNav } = useContext(ShowNavContext);
  const [previewProps, setPreviewProps] = useState<ILetterPreviewUpdateArgs>();
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectMultilineOption>();
  const [shouldShowMobilePreview, setShouldShowMobilePreview] = useState(false);

  const { isLoading, refetch, data, isNetworkError, client } = useLetterFormData();

  const handleShowMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(true);
    hideNav();
  }, [setShouldShowMobilePreview, hideNav]);

  const handleHideMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(false);
    showNav();
  }, [setShouldShowMobilePreview, showNav]);

  const handleClickBack = useCallback(
    (): void => history.push(routePaths.letter.types.classic),
    [history],
  );

  const handleChangeTemplate = useCallback<IUseData["handleChangeTemplate"]>(
    async value => {
      if (!Array.isArray(value)) {
        const template = value as ISelectMultilineOption;
        setSelectedTemplate(template);

        const params = {
          ...config.letterSample,
          content: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(config.letterSample.content as any),
            template,
          },
          recipient: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(config.letterSample.recipient as any),
            date: new Date(),
          },
        };
        const props = await getLetterUpdatePreviewArgs(client, params);

        console.log({
          params,
          props,
        });

        if (props) {
          setPreviewProps(props);
        }
      }
    },
    [setSelectedTemplate, setPreviewProps],
  );

  useEffect(() => {
    if (data.templates) {
      const template = data.templates.find(
        t => t.value === config.letterSample.content?.template.value,
      );

      if (template) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleChangeTemplate(template);
      }
    }
  }, []);

  const handleClickCreate = useCallback((): void => {
    letterState().clear();
    history.push(routePaths.letter.content);
  }, [history]);

  return {
    loaderProps: {
      isLoading,
      isNetworkError,
      refetch,
    },
    ...(data.templates && { templates: data.templates }),
    previewProps,
    shouldShowMobilePreview,
    selectedTemplate,
    handleShowMobilePreview,
    handleHideMobilePreview,
    handleClickBack,
    handleChangeTemplate,
    handleClickCreate,
  };
};
