import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import { ReactComponent as ArrowSvg } from "./arrow.svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.span`
  font-family: ${(props): string => props.theme.fonts.main};
  color: #ff0a00;

  @media ${device.mobileS} {
    font-size: ${(props): string => props.theme.fontSizes.size24};
  }

  @media ${device.laptopL} {
    font-size: ${(props): string => props.theme.fontSizes.size36};
  }
`;

const Arrow = styled(ArrowSvg)`
  @media ${device.mobileS} {
    width: 24px;
    height: 17px;
    margin-left: 10px;
    margin-top: 7px;
  }

  @media ${device.laptopL} {
    width: 34px;
    height: 10px;
    margin-left: 17px;
    margin-top: 9px;
  }
`;

interface IProps {
  label: string;
  showArrow?: boolean;
  onClick(): void;
}

export const ButtonLink: React.FC<IProps> = ({ label, showArrow, onClick }) => (
  <Container onClick={onClick}>
    <Label>{label}</Label>
    {showArrow && <Arrow />}
  </Container>
);
