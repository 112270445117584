import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import { BubblesBackground } from "../../../atoms/bubbles-background";
import { CandyStripeTop } from "../../../atoms/candy-stripe-top";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  z-index: 3;

  @media ${device.mobileS} {
    padding: 36px 0;
  }

  @media ${device.tablet} {
    padding: 26px 0;
  }
`;

const CandyStripeBottom = styled(CandyStripeTop)`
  transform: scaleY(-1);
  top: auto;
  bottom: 0;
`;

const BubblesBackgroundBottom = styled(BubblesBackground)`
  transform: none;
  top: auto;
  bottom: 0;
`;

export const PageContentPanel: React.FC = ({ children }) => (
  <Container>
    <CandyStripeTop />
    <ContentContainer>{children}</ContentContainer>
    <BubblesBackgroundBottom />
    <CandyStripeBottom />
  </Container>
);
