import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;

    #app-root {
      overflow: hidden;
    }
  }
`;
