import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import { ReactComponent as BubblesImage } from "./bubbles.svg";

export const BubblesBackground = styled(BubblesImage)`
  transform: rotateX(180deg);
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;

  @media ${device.mobileS} {
    height: 219px;
    width: 694px;
  }

  @media ${device.laptopL} {
    height: 438px;
    width: 1388px;
  }
`;
