import { Route, Switch } from "react-router-dom";

import { TextsDetailsMessages } from "./texts-details-messages";
import { TextsDetailsTexting } from "./texts-details-texting";
import { TextsHome } from "./texts-home";
import { TextsDetailsBonus } from "./texts-details-bonus";
import { routePaths } from "../../..//model/route";

export const Texts: React.FC = () => (
  <Switch>
    <Route exact path={routePaths.text.home} component={TextsHome} />
    <Route exact path={routePaths.text.details} component={TextsDetailsTexting} />
    <Route path={routePaths.text.messages} component={TextsDetailsMessages} />
    <Route path={routePaths.text.bonus} component={TextsDetailsBonus} />
  </Switch>
);
