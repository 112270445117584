import { Col, Container, Row, Visible } from "react-grid-system";
import Bounce from "react-reveal/Bounce";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { device } from "../../../utils/media-queries";
import { Heading1, Heading2 } from "../../atoms/text";
import { UspItem } from "../../molecules/usp-item";

const Label = styled.h3`
  text-align: center;
  font-weight: normal;

  font-size: ${({ theme }): string => theme.fontSizes.size16};

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

export const HomeUspSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col>
          <Visible sm md lg xl>
            <Heading1>{t("home.usp.heading")}</Heading1>
          </Visible>
          <Visible xs>
            <Heading2>{t("home.usp.heading")}</Heading2>
          </Visible>
        </Col>
      </Row>
      <Row>
        <Col xl={3} xs={6}>
          <Bounce>
            <UspItem type="sleigh">
              <Label>{t("home.usp.list.1")}</Label>
            </UspItem>
          </Bounce>
        </Col>
        <Col xl={3} xs={6}>
          <Bounce delay={100}>
            <UspItem type="trophy">
              <Label>{t("home.usp.list.2")}</Label>
            </UspItem>
          </Bounce>
        </Col>
        <Col xl={3} xs={6}>
          <Bounce delay={200}>
            <UspItem type="envelope">
              <Label>{t("home.usp.list.3")}</Label>
            </UspItem>
          </Bounce>
        </Col>
        <Col xl={3} xs={6}>
          <Bounce delay={300}>
            <UspItem type="santa">
              <Label>{t("home.usp.list.4")}</Label>
            </UspItem>
          </Bounce>
        </Col>
      </Row>
    </Container>
  );
};
