import { Col, Container, Row, Hidden, Visible } from "react-grid-system";

import { StageTitle } from "../../../atoms/stage-title";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { TextsFrameImage } from "../../../atoms/texts-frame-image";
import { TextsDetailsTextingForm } from "../../../organisms/forms/texts-details-texting";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { Accordion } from "../../../molecules/accordion";

import { useData } from "./use-data";

export const TextsDetailsTexting: React.FC = () => {
  const { formProps, loaderProps, faqs } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      {formProps && (
        <MainWrapper>
          <Container>
            <Row>
              <Col md={6}>
                <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

                <Row>
                  <Col>
                    <StageTitle totalStages={3} currentStage={1}>
                      <Visible sm md lg xl>
                        Help Santa to Set Up His Messages
                      </Visible>
                      <Visible xs>Tell Santa...</Visible>
                    </StageTitle>
                  </Col>
                </Row>

                {faqs && <Accordion items={faqs} />}

                <TextsDetailsTextingForm {...formProps} />
              </Col>
              <Hidden xs>
                <Col md={6}>
                  <TextsFrameImage />
                </Col>
              </Hidden>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainer>
  );
};
