import * as dateFns from "date-fns";
import { IDescriptionAddOn } from "../common";
import { earliestLetterSendDate, lastLetterSendDate } from "../letter";

export interface ISackSearchDocument {
  urn: string;
  id: string;
  user: {
    urn: string;
    firstName?: string;
    lastName?: string;
    email: string;
  };
  name: string;
  date: string;
  address: {
    line1: string;
    line2?: string;
    town: string;
    county?: string;
    postcode: string;
    country: string;
  };
  suggest: string[];
}

interface IGetSackDescriptionParams {
  addOnProducts: IDescriptionAddOn[];
  recipientName: string;
  town: string;
  sendDate: Date;
}

/**
 * Return a description for a call given some parameters to describe it
 * @param params Params for call
 */
export const getSackDescription = (params: IGetSackDescriptionParams): string => `for \
${params.recipientName} to be sent on ${dateFns.format(params.sendDate, "PP")} to ${params.town}.`;

/**
 * Calculate the first date that sacks can be sent
 */
export const earliestSackSendDate = (): Date => earliestLetterSendDate();

/**
 * Calculate the last date that Santa Letters can be sent
 */
export const lastSackSendDate = (): Date => lastLetterSendDate();
