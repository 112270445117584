import { useState } from "react";
import { getDescription } from "@santa/common/lib/products";
import * as datefns from "date-fns";
import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { generatePath } from "react-router-dom";

import { getLocaleForApi } from "../../../../utils/graphql";
import { MyAccountOrder, OrderStatus } from "../../../molecules/my-account-order";
import {
  ProductAlphaId,
  SiteCountryCode,
  useMyAccountQuery,
  MyAccountQuery,
} from "../../../../types/graphql";
import { routePaths } from "../../../../model/route";

type OrderItem = MyAccountQuery["orders"]["edges"][number]["node"]["orderItems"][number];
type OrderProps = Omit<React.ComponentProps<typeof MyAccountOrder>, "onClickHeader" | "isOpen">;
type OrderItemProps = OrderProps["items"][0];

/**
 * Return link to edit item, if allowed
 * @param item Item to get link for
 * @returns id
 */
const getEditLink = (item: OrderItem): string | undefined => {
  switch (item.productAlphaId) {
    case ProductAlphaId.SANTA_LETTER:
      if (item.letter?.isEditable) {
        return generatePath(routePaths.myAccount.letters, {
          id: extractIdFromUrn(UrnResource.LETTER, item.letter?.urn || ""),
        });
      }
      break;
    case ProductAlphaId.SANTA_CALL:
    case ProductAlphaId.SANTA_CALL_VIDEO:
      if (item.call?.isEditable) {
        return generatePath(routePaths.myAccount.calls, {
          id: extractIdFromUrn(UrnResource.CALL, item.call?.urn || ""),
        });
      }
      break;
    case ProductAlphaId.SANTA_TEXT:
      if (item.text?.isEditable) {
        return generatePath(routePaths.myAccount.texts, {
          id: extractIdFromUrn(UrnResource.TEXT, item.text?.urn || ""),
        });
      }
      break;
  }

  return;
};

const getOrderStatus = (orderItems: OrderItemProps[]): OrderStatus => {
  if (orderItems.every(i => !i.editLink)) {
    return OrderStatus.COMPLETED;
  }

  if (orderItems.every(i => !!i.editLink)) {
    return OrderStatus.SCHEDULED;
  }

  return OrderStatus.IN_PROGRESS;
};

interface IOrder {
  props: OrderProps;
  cursor?: string | null;
}

interface IUseMyAccountData {
  isLoading: boolean;
  refetch?(): void;
  orders: IOrder[];
  loadMore(cursor: string): void;
  hasLoadedAll: boolean;
}

/**
 * Hook to get data for page
 */
export const useMyAccountData = (): IUseMyAccountData => {
  const [hasLoadedAll, setHasLoadedAll] = useState(false);

  const {
    data,
    refetch,
    loading: isLoading,
    fetchMore,
  } = useMyAccountQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      locale: getLocaleForApi(),
    },
  });

  const orders = (
    data?.orders.edges && data?.orders.edges.length ? data?.orders.edges : []
  ).map<IOrder>(a => {
    const items = a.node.orderItems
      .filter((i): i is OrderItem => typeof i !== "undefined")
      .map<OrderItemProps>(i => ({
        editLink: getEditLink(i),
        image: `${i.product.image?.url}?w=48`,
        title: i.product.title || "",
        price: i.price,
        description: getDescription(i),
      }));

    return {
      props: {
        id: extractIdFromUrn(UrnResource.ORDER, a.node.urn),
        total: a.node.total,
        date: datefns.parseISO(a.node.timeCreated),
        status: getOrderStatus(items),
        numberItems: 2,
        site: SiteCountryCode.GB,
        items,
      },
      cursor: a.cursor,
    };
  });

  /**
   * Load more data on scroll
   * @param cursor Data fetch cursor to use
   */
  const loadMore = async (cursor: string): Promise<void> => {
    await fetchMore({
      variables: { cursor },
      updateQuery: (previousOrders, { fetchMoreResult }) => {
        const newOrders = fetchMoreResult?.orders.edges;

        if (newOrders && newOrders.length === 0) {
          setHasLoadedAll(true);
        }

        return {
          orders: {
            totalCount: previousOrders.orders.totalCount,
            edges: [...previousOrders.orders.edges, ...(newOrders ? newOrders : [])],
            __typename: "OrdersSearchResponse",
          },
        };
      },
    });
  };

  return {
    isLoading,
    ...(!data && { refetch }),
    orders,
    loadMore,
    hasLoadedAll,
  };
};
