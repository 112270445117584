import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { PlayButton } from "../../atoms/play-button";

import { ReactComponent as BorderImage } from "./border.svg";

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media ${device.mobileS} {
    width: 268px;
    height: 369px;
  }

  @media ${device.laptopL} {
    width: 435px;
    height: 600px;
  }

  &:hover {
    > svg.play {
      transform: scale(1.5);
    }

    > img {
      opacity: 0.7;
    }
  }
`;

const Image = styled.img`
  position: absolute;
  left: 6px;
  top: 20px;
  z-index: 1;
  transition: all 0.5s;

  @media ${device.mobileS} {
    width: 240px;
    height: 322px;
  }

  @media ${device.laptopL} {
    width: 420px;
    height: 563px;
  }
`;

const OverlayPlayButton = styled(PlayButton)`
  z-index: 3;
  transition: all 0.5s;
`;

const Border = styled(BorderImage)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  @media ${device.mobileS} {
    width: 268px;
    height: 369px;
  }

  @media ${device.laptopL} {
    width: 435px;
    height: 600px;
  }
`;

interface IProps {
  image: string;
  onClick(): void;
}

export const VideoSquareButton: React.FC<IProps> = props => {
  return (
    <Container onClick={props.onClick}>
      <Image src={props.image} />
      <Border />
      <OverlayPlayButton className="play" />
    </Container>
  );
};
