import styled from "styled-components";

import { Heading1 } from "../../atoms/text";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  width: 100vw;
  background-color: #090d10;
  background-image: url(${({ image }: { image: string }): string => image});
  background-position-x: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    justify-content: center;
    height: 281px;
  }

  @media ${device.laptopL} {
    justify-content: flex-start;
    height: 415px;
  }
`;

const Title = styled(Heading1)`
  font-size: ${({ theme }): string => theme.fontSizes.size72};
  color: white;
  text-align: center;

  @media ${device.laptopL} {
    margin-top: 210px;
  }
`;

interface IProps {
  title: string;
  imageUrl: string;
}

export const ContentHeader: React.FC<IProps> = ({ title, imageUrl }) => (
  <Container image={`${imageUrl}?h=415`}>
    <Title>{title}</Title>
  </Container>
);
