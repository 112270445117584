import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { BasketIcon } from "../icons/basket-icon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled(BasketIcon)`
  @media ${device.mobileS} {
    width: 135px;
    height: 104px;
  }

  @media ${device.laptopL} {
    width: 180px;
    height: 164px;
  }
`;

const Text = styled.div`
  @media ${device.mobileS} {
    margin-top: 34px;
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    margin-top: 48px;
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

export const EmptyBasket: React.FC = () => (
  <Container>
    <Icon />
    <Text>Your Shopping Basket is Empty</Text>
  </Container>
);
