import { ErrorMessage } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { DivText } from "../text";

const Label = styled(DivText)`
  font-size: ${({ theme }): string => theme.fontSizes.size14};
  color: ${({ theme }): string => theme.colours.alert};
  margin-top: 6px;
  padding-left: 16px;
`;

const getMessageDefault = (message: string): string => message;

interface IProps {
  name: string;
  getMessage?(message: string | object): string;
}

export const FormError: React.FC<IProps> = ({ name, getMessage = getMessageDefault }) => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorMessage name={name}>
        {(message: string): JSX.Element => (
          <Label data-santa-id="error">{t(getMessage(message))}</Label>
        )}
      </ErrorMessage>
    </>
  );
};
