import styled from "styled-components";

import { ReactComponent as BubblesImage } from "../../../../images/svg/bubbles.svg";
import { device } from "../../../../utils/media-queries";

const OuterContainer = styled.div`
  position: relative;
  margin: 0 auto;

  @media ${device.mobileS} {
    width: 375px;
  }

  @media ${device.laptopL} {
    width: 1440px;
  }
`;

const Bubbles = styled(BubblesImage)<IProps>`
  ${({ bubbles }): string => (bubbles === "bottom" ? "" : "transform: rotateX(180deg);")};
  position: absolute;
  z-index: 1;
  ${({ bubbles }): string => (bubbles === "bottom" ? "bottom: 0;" : "top: 0;")};
  right: 0;

  @media ${device.mobileS} {
    height: 219px;
    width: 694px;
  }

  @media ${device.laptopL} {
    height: 438px;
    width: 1388px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 3;
  flex: 1;

  h1 {
    font-weight: 700;

    @media ${device.mobileS} {
      font-size: ${({ theme }): string => theme.fontSizes.size36};
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media ${device.laptopL} {
      font-size: ${({ theme }): string => theme.fontSizes.size64};
      margin-top: 40px;
      margin-bottom: 40px;
    }

    :first-child {
      margin-top: 0;
    }
  }

  a {
    color: ${({ theme }): string => theme.colours.link};

    &:hover {
      text-decoration: none;
    }
  }
`;

interface IProps {
  bubbles?: "top" | "bottom";
}

export const ContentSection: React.FC<IProps> = ({ children, bubbles }) => (
  <OuterContainer>
    <ContentContainer>{children}</ContentContainer>
    <Bubbles bubbles={bubbles} />
  </OuterContainer>
);
