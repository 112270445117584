import React, { useState, createContext } from "react";

interface IValue {
  shouldShowNav: boolean;
  hideNav(): void;
  showNav(): void;
}

export const ShowNavContext = createContext<IValue>({
  shouldShowNav: true,
  hideNav: (): void => {},
  showNav: (): void => {},
});

export const ShowNavProvider: React.FC = ({ children }) => {
  const [shouldShowNav, setShouldShowNav] = useState<boolean>(true);

  const hideNav = (): void => setShouldShowNav(false);
  const showNav = (): void => setShouldShowNav(true);

  const value: IValue = {
    shouldShowNav,
    hideNav,
    showNav,
  };

  return <ShowNavContext.Provider value={value}>{children}</ShowNavContext.Provider>;
};
