import * as React from "react";
import styled from "styled-components";
import { Visible } from "react-grid-system";

import { ProductCombinationListItem } from "../../molecules/product-combination-list-item";
import { BowButton } from "../../atoms/bow-button";
import { displayPrice } from "../../../utils/price";

const Container = styled.div`
  flex: 1;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DesktopTotalContainer = styled.div`
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Total = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-size: ${({ theme }): string => theme.fontSizes.size22};

  span {
    color: ${({ theme }): string => theme.colours.alert};
    font-weight: 700;
  }
`;

const MobileTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface IProps {
  products: {
    title: string;
    imageUrl: string;
    price: number;
  }[];
  onClick(): void;
}

export const ProductComboInfo: React.FC<IProps> = ({ products, onClick }) => {
  const items = products.map((p, i) => (
    <ProductCombinationListItem
      key={i}
      title={p.title}
      imageUrl={p.imageUrl}
      price={p.price}
      icon={i < products.length - 1 ? "plus" : "equals"}
    />
  ));

  const totalValue = products.reduce<number>((a, p) => a + p.price, 0);
  const total = (
    <Total>
      For only <span>{displayPrice(totalValue)}</span>
    </Total>
  );

  return (
    <Container>
      <ItemsContainer>
        {items}
        <Visible md lg xl>
          <DesktopTotalContainer>
            {total}
            <BowButton size={210} onClick={onClick}>
              Order Now
            </BowButton>
          </DesktopTotalContainer>
        </Visible>
      </ItemsContainer>
      <Visible xs sm>
        <MobileTotalContainer>
          {total}
          <BowButton size={330} onClick={onClick}>
            Order Now
          </BowButton>
        </MobileTotalContainer>
      </Visible>
    </Container>
  );
};
