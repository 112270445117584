import ReactMarkdown from "react-markdown";
import Bounce from "react-reveal/Bounce";
import { Container, Row, Col } from "react-grid-system";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ContentHeader } from "../../../molecules/content-header";
import { ContentSection } from "../../../organisms/content-sections/content-section";
import { BaubleList } from "../../../molecules/bauble-list";
import { CandyContentSection } from "../../../organisms/content-sections/candy-content-section";
import { Heading1, Heading2 } from "../../../atoms/text";
import { UspItem } from "../../../molecules/usp-item";
import { device } from "../../../../utils/media-queries";
import treeSprigImage from "../../../../images/png/tree-sprig.png";
import { MapSection } from "../../../organisms/content-sections/map-section";
import { MapOverlaySection } from "../../../organisms/content-sections/map-overlay-section";
import decoration from "../../../../images/png/deer-tree-sprig.png";
import { DataLoadedContainerOld, checkData } from "../../../control/data-loaded-container";
import { getLocaleForApi } from "../../../../utils/graphql";
import { useContentPageQuery } from "../../../../types/graphql";

const IntroContainer = styled.div`
  position: relative;
  margin: 0 auto;

  @media ${device.mobileS} {
    width: 375px;
  }

  @media ${device.laptopL} {
    width: 1440px;
  }
`;

const IntroContentContainer = styled(Container)`
  @media ${device.mobileS} {
    padding: 60px 0;
  }

  @media ${device.laptopL} {
    padding: 110px 0 116px 0;
  }
`;

const TreeSprig = styled.img`
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.laptopL} {
    display: block;
    position: absolute;
    z-index: 10;
    bottom: -350px;
    width: 245px;
    height: 676px;
  }
`;

const WhySection = styled(Container)`
  @media ${device.mobileS} {
    margin: 60px 0;
  }

  @media ${device.laptopL} {
    margin: 106px 0;
  }
`;

const WhyHeading = styled(Heading1)`
  @media ${device.laptopL} {
    text-align: center;
  }

  margin-top: 0;
`;

const UspTitle = styled.h3`
  margin: 29px 0 7px 0;
  text-align: center;
  font-weight: 700;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

const UspText = styled.p`
  margin: 7px 0;
  text-align: center;
  font-weight: normal;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }
`;

const ListContainer = styled(Container)`
  @media ${device.mobileS} {
    padding: 60px 0 90px 0;
  }

  @media ${device.laptopL} {
    padding: 110px 0 116px 0;
  }

  h2 {
    font-weight: normal;
    margin: 0;

    @media ${device.mobileS} {
      font-size: ${({ theme }): string => theme.fontSizes.size24};
    }

    @media ${device.laptopL} {
      font-size: ${({ theme }): string => theme.fontSizes.size36};
    }
  }

  p {
    @media ${device.mobileS} {
      font-size: ${({ theme }): string => theme.fontSizes.size22};
    }

    @media ${device.laptopL} {
      font-size: ${({ theme }): string => theme.fontSizes.size24};
    }
  }
`;

const DeerDecoration = styled.img`
  transform: rotateY(180deg);
  position: absolute;
  bottom: 0;
  right: 0;

  @media ${device.mobileS} {
    width: 275px;
    height: 95px;
  }

  @media ${device.laptopL} {
    width: 477px;
    height: 165px;
  }
`;

const MapOverlayContainer = styled(Container)`
  width: 100%;

  @media ${device.mobileS} {
    margin: 20px 0;
  }

  @media ${device.laptopL} {
    margin: 0;
  }
`;

export const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  const response = useContentPageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      alphaId: "ABOUT_US",
      locale: getLocaleForApi(),
    },
  });

  return (
    <DataLoadedContainerOld response={response}>
      {checkData(response.data) && (
        <>
          <ContentHeader
            title={response.data.page!.title!}
            imageUrl={response.data.page!.headerImage!.url!}
          />
          <IntroContainer>
            <TreeSprig src={treeSprigImage} />
            <ContentSection>
              <IntroContentContainer>
                <ReactMarkdown source={response.data.page!.sections![0]!.body!} />
              </IntroContentContainer>
            </ContentSection>
          </IntroContainer>
          <CandyContentSection>
            <WhySection>
              <Row>
                <Col>
                  <WhyHeading>{t("home.usp.heading")}</WhyHeading>
                </Col>
              </Row>
              <Row>
                <Col xl={3} xs={6}>
                  <Bounce>
                    <UspItem type="globe">
                      <UspTitle>Best site 2011</UspTitle>
                      <UspText>
                        Voted the best Christmas site 2011 by viewers of children&apos;s TV Channel,
                        CiTV
                      </UspText>
                    </UspItem>
                  </Bounce>
                </Col>
                <Col xl={3} xs={6}>
                  <Bounce delay={100}>
                    <UspItem type="sweet">
                      <UspTitle>Web Site of the Day</UspTitle>
                      <UspText>
                        Featured several times on Radio 2&apos;s Steve Wright&apos;s show,
                        &ldquo;Web Site of the Day&rdquo; feature
                      </UspText>
                    </UspItem>
                  </Bounce>
                </Col>
                <Col xl={3} xs={6}>
                  <Bounce delay={200}>
                    <UspItem type="stocking">
                      <UspTitle>Site of the Month</UspTitle>
                      <UspText>
                        Winner of &apos;Site of the Month&apos;, in both PC Advisor and Shop @ Home
                        magazine
                      </UspText>
                    </UspItem>
                  </Bounce>
                </Col>
                <Col xl={3} xs={6}>
                  <Bounce delay={300}>
                    <UspItem type="snowman">
                      <UspTitle>Editors Pick</UspTitle>
                      <UspText>
                        &apos;Editors Pick&apos; on numerous major portals including AOL and Yahoo!
                      </UspText>
                    </UspItem>
                  </Bounce>
                </Col>
              </Row>
            </WhySection>
          </CandyContentSection>
          <ContentSection bubbles="bottom">
            <ListContainer>
              <Row>
                <Col xl={6}>
                  <Heading2>
                    <strong>Father Christmas Ltd</strong> trademarks
                  </Heading2>
                  <BaubleList>
                    <ul>
                      <li>USA and EU trademark for Father Christmas™</li>
                      <li>USA trademark for Santa Claus™</li>
                      <li>USA trademark for Santa Claus™</li>
                    </ul>
                  </BaubleList>
                </Col>
                <Col xl={6}>
                  <Heading2>
                    <strong>Father Christmas Ltd</strong> websites
                  </Heading2>
                  <BaubleList>
                    <ul>
                      <li>Santa-Claus.com</li>
                      <li>Santa.co.uk</li>
                    </ul>
                  </BaubleList>
                </Col>
              </Row>
            </ListContainer>
            <DeerDecoration src={decoration} />
          </ContentSection>
          <MapSection>
            <MapOverlayContainer>
              <Row>
                <Col xl={5}>
                  <MapOverlaySection>
                    <ReactMarkdown source={response.data!.page!.sections![1]!.body!} />
                  </MapOverlaySection>
                </Col>
              </Row>
            </MapOverlayContainer>
          </MapSection>
        </>
      )}
    </DataLoadedContainerOld>
  );
};
