import { ProductAlphaId } from "@santa/common/lib/products/common";
import styled from "styled-components";

import { displayPrice } from "../../../utils/price";

const Container = styled.div`
  padding: 16px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
`;

export interface ISurcharge {
  price: number;
  title: string;
  alphaId: ProductAlphaId;
}

interface IProps {
  charges: ISurcharge[];
}

export const SurchargeNotice: React.FC<IProps> = ({ charges }) => {
  if (charges.length === 0) {
    return null;
  }

  const chargesString = charges
    .map(surcharge => `${displayPrice(surcharge.price)} ${surcharge.title}`)
    .join(" and ");

  return <Container>You will be charged an extra {chargesString} for this item.</Container>;
};
