const width = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1140px",
  desktop: "2560px",
};

const height = {
  mobileM: "812px",
};

export const device = {
  mobileS: `(min-width: ${width.mobileS})`,
  mobileM: `(min-width: ${width.mobileM}) and (min-height: ${height.mobileM})`,
  // mobileL: `(min-width: ${width.mobileL})`,
  tablet: `(min-width: ${width.tablet})`,
  // laptop: `(min-width: ${width.laptop})`,
  laptopL: `(min-width: ${width.laptopL})`,
  // desktop: `(min-width: ${width.desktop})`,
  // desktopL: `(min-width: ${width.desktop})`,
};
