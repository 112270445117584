import { earliestSackSendDate, lastSackSendDate } from "@santa/common/lib/products/sack";
import { sackValidationSchema } from "@santa/common/lib/products/sack/validation";
import { useEffect } from "react";
import { Field, Form, FormikProps, withFormik } from "formik";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { FormFieldWrapper } from "../../form-field-wrapper";
import { FormikFormInputText } from "../../../molecules/form/form-input-text";
import { AddressForm } from "../address-form";
import { BowButton } from "../../../atoms/bow-button";
import { FormikFormInputDate } from "../../../molecules/form/form-input-date";

const SubmitCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const validationRules = {
  name: yup.string().required("Required"),
  date: yup.date().required("Required"),
  addressLine1: yup.string().required("Required"),
  addressLine2: yup.string(),
  addressTown: yup.string().required("Required"),
  addressCounty: yup.string(),
  addressUsStateUrn: yup.string(),
  addressPostcode: yup.string().required("Required"),
  addressCountryUrn: yup.string().required("Required"),
};

const validationSchema = yup.object(validationRules);

export type SackRecipientValidatedFormModel = yup.Asserts<typeof validationSchema>;

interface IFormSectionModel {
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressTown: string;
  addressCounty: string;
  addressUsStateUrn: string;
  addressPostcode: string;
  addressCountryUrn: string;
  date: Date;
}

type FormModel = IFormSectionModel;

interface IOwnProps {
  defaultValues: FormModel;
  onSubmit(): void;
  onChange(values: SackRecipientValidatedFormModel): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({ setFieldValue, submitForm, onChange, values }) => {
  const { t } = useTranslation();
  const isValid = sackValidationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as SackRecipientValidatedFormModel);
    }
  }, [values]);

  return (
    <Form>
      <Row>
        <Col xl={6}>
          <FormFieldWrapper label={t("sack.form.field.name.label")} name="name" required>
            <Field
              name="name"
              component={FormikFormInputText}
              placeholder={t("sack.form.field.name.placeholder")}
            />
          </FormFieldWrapper>
        </Col>
        <Col xl={6}>
          <FormFieldWrapper label={t("sack.form.field.date.label")} name="date" required noLabel>
            <Field
              name="date"
              component={FormikFormInputDate}
              placeholder={t("sack.form.field.date.placeholder")}
              monthsShown={2}
              minDate={earliestSackSendDate()}
              maxDate={lastSackSendDate()}
              label={false}
            />
          </FormFieldWrapper>
        </Col>
      </Row>

      <AddressForm
        setFieldValue={setFieldValue}
        countryUrn={values.addressCountryUrn}
        addressIsFilled={!!(values.addressTown && values.addressPostcode)}
      />

      <Row>
        <SubmitCol>
          <BowButton size={330} onClick={submitForm} disabled={!isValid}>
            {t("sack.form.submit.label")}
          </BowButton>
        </SubmitCol>
      </Row>
    </Form>
  );
};

export const SackRecipientForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (__, formik) => formik.props.onSubmit(),
})(C);
