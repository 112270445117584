import { ISelectOption, mapObjectsToSelectOptions } from "@santa/common/lib/utils/form";
import { useCallback } from "react";

interface ICountry {
  urn: string;
  iso: string;
  callingCode: string;
}

const getCountryOptions = (countries?: ICountry[]): ISelectOption[] =>
  (countries || []).map(c => ({
    label: `${c.iso.toUpperCase()} (+${c.callingCode})`,
    value: c.urn,
  }));

interface ITimezone {
  urn: string;
  country: {
    urn: string;
  };
  name: string;
}

type UseTextPhone = (params: { countries?: ICountry[]; timezones?: ITimezone[] }) => {
  onChangePhoneCountry(setTimezone: (value: string) => void, countryUrn: string): void;
  countryOptions: ISelectOption[];
  timezoneOptions: ISelectOption[];
};

export const useTextPhone: UseTextPhone = ({ countries, timezones }) => ({
  onChangePhoneCountry: useCallback(
    (setTimezone, countryUrn: string): void => {
      if (timezones) {
        const timezone = timezones.find(t => t.country.urn === countryUrn);

        if (timezone) {
          setTimezone(timezone.urn);
        }
      }
    },
    [timezones],
  ),
  countryOptions: getCountryOptions(countries || []),
  timezoneOptions: mapObjectsToSelectOptions(timezones || [], "urn", "name"),
});
