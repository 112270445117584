import { useApolloClient } from "@apollo/client";
import React, { useCallback, useContext } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

import { device } from "../../../../utils/media-queries";
import { BowButton } from "../../../atoms/bow-button";
import { Tree } from "../../../atoms/images/decorations/tree";
import { Heading1, Heading3 } from "../../../atoms/text";
import { BowPrice } from "../../../molecules/bow-price";
import { VideoSquareButton } from "../../../molecules/video-square-button";
import { VideoContext } from "../../../contexts/video-context";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { BaubleList } from "../../../molecules/bauble-list";
import { getLocaleForApi } from "../../../../utils/graphql";
import { DataLoadedContainerOld, checkData } from "../../../control/data-loaded-container";
import { useTextsHomePageQuery } from "../../../..//types/graphql";
import { textState } from "../../../../model/graphql/cache";
import { Accordion } from "../../../molecules/accordion";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";

import sackVideoImage from "./texts-square.jpg";
import bauble from "./bauble.png";

const ButtonCol = styled(Col)`
  position: relative;

  @media ${device.tablet} {
    height: 1px;
  }
`;

const OverlayTree = styled(Tree)`
  position: absolute;
  z-index: 20;

  @media ${device.mobileS} {
    top: 90px;
    left: -110px;
    width: 180px;
    height: 333px;
  }

  @media ${device.laptopL} {
    top: 0;
    left: -230px;
    width: 389px;
    height: 720px;
  }
`;

const OverlayBauble = styled.img`
  position: absolute;
  z-index: 21;

  @media ${device.mobileS} {
    top: 340px;
    left: -35px;
    width: 94px;
    height: 67px;
  }

  @media ${device.laptopL} {
    top: 530px;
    left: -90px;
    width: 203px;
    height: 145px;
  }
`;

const MainHeading = styled(Heading1)`
  margin-bottom: 0;

  @media ${device.mobileS} {
    margin-top: 0;
  }

  @media ${device.laptopL} {
    margin-top: 44px;
  }
`;

const TextsBowPrice = styled(BowPrice)`
  @media ${device.mobileS} {
    margin-top: 13px;
  }

  @media ${device.laptopL} {
    margin-top: 60px;
  }
`;

const SubHeading = styled(Heading3)`
  margin-bottom: 35px;
  font-weight: normal;

  @media ${device.mobileS} {
    margin-top: 23px;
  }

  @media ${device.laptopL} {
    margin-top: 10px;
  }
`;

const OrderNowBowButton = styled(BowButton)`
  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

export const TextsHome: React.FC = () => {
  const video = useContext(VideoContext);
  const history = useHistory();
  const client = useApolloClient();
  const { t } = useTranslation();

  // graphql data
  const response = useTextsHomePageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      textAlphaId: ProductAlphaId.SANTA_TEXT,
      locale: getLocaleForApi(),
    },
  });

  const handleClickOpenVideo = useCallback((): void => video.show("texts"), [video]);

  const handleClickCreate = useCallback((): void => {
    textState().clear();
    history.push("/santa-text/details");
  }, [client, history]);

  const faqs = response?.data?.product?.faqs;

  return (
    <DataLoadedContainerOld response={response}>
      {checkData(response.data) && (
        <MainWrapper>
          <Container>
            <Row>
              <Visible md lg xl>
                <ButtonCol md={5}>
                  <OverlayTree />
                  <OverlayBauble src={bauble} alt="Bauble" />
                  <VideoSquareButton image={sackVideoImage} onClick={handleClickOpenVideo} />
                </ButtonCol>
              </Visible>
              <Col lg={5} md={4} xs={12}>
                <MainHeading>{response.data.product?.title}</MainHeading>
              </Col>
              <Col lg={2} md={3} xs={6}>
                <TextsBowPrice price={response.data.product?.price} />
              </Col>
              <Visible xs sm>
                <Col xs={6}>
                  <OrderNowBowButton size={210} onClick={handleClickCreate}>
                    Order Now
                  </OrderNowBowButton>
                </Col>
              </Visible>
            </Row>
            <Row>
              <Col offset={{ md: 5 }}>
                <SubHeading>{response.data.product?.description}</SubHeading>
              </Col>
            </Row>
            <Hidden md lg xl>
              <Row>
                <ButtonCol xs={9} offset={{ xs: 3 }}>
                  <OverlayTree />
                  <VideoSquareButton image={sackVideoImage} onClick={handleClickOpenVideo} />
                </ButtonCol>
              </Row>
            </Hidden>
            <Row>
              <Col md={7} offset={{ md: 5 }}>
                <BaubleList>
                  <ReactMarkdown source={response.data.product?.productPageDescription || ""} />
                </BaubleList>
                {faqs && <Accordion items={mapCmsFaqsToAccordion(faqs)} />}
                <BowButton size={430} onClick={handleClickCreate}>
                  {t("text.home.cta.label")}
                </BowButton>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainerOld>
  );
};
