import { Field, Form, FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Visible } from "react-grid-system";

import { Paragraph } from "../../../../atoms/text";
import { FormFieldWrapper } from "../../../form-field-wrapper";
import { FormikFormInputText } from "../../../../molecules/form/form-input-text";
import { BowButton } from "../../../../atoms/bow-button";
import { BackLink } from "../../../../molecules/back-link";

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
`;

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

export interface IFormModel {
  email: string;
}

interface IOwnProps {
  formError?: string;
  onSubmit(email: string): void;
  onClickBack?(): void;
}

type Props = FormikProps<IFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const { submitForm, isValid } = props;

  return (
    <Form>
      {props.formError && <Error>{props.formError}</Error>}
      <FormFieldWrapper label="Email address" name="email" required>
        <Field
          name="email"
          component={FormikFormInputText}
          placeholder="Enter your email address"
          maxlength={50}
          required
        />
      </FormFieldWrapper>

      <SubmitContainer>
        {props.onClickBack && (
          <Visible md lg xl>
            <BackLink label="Back" onClick={props.onClickBack} />
          </Visible>
        )}
        <BowButton size={210} onClick={submitForm} disabled={!isValid}>
          Continue
        </BowButton>
      </SubmitContainer>
    </Form>
  );
};

export const ForgotPasswordEmailForm = withFormik<IOwnProps, IFormModel>({
  validationSchema,
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values.email);
  },
})(C);
