import styled, { css } from "styled-components";

import { device } from "../../../utils/media-queries";

export const textBase = css`
  font-family: ${({ theme }): string => theme.fonts.main};

  a {
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

const headerBase = css`
  ${textBase}
  font-weight: bold;
`;

export const SpanText = styled.span`
  ${textBase}
`;

export const DivText = styled.div`
  ${textBase}
`;

export const Paragraph = styled("p")`
  ${textBase}
  margin: 7px 0;

  font-size: ${({ theme }): string => theme.fontSizes.size16};

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }
`;

export const Heading1 = styled("h1")`
  ${headerBase}
  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size64};
  }
`;

export const Heading2 = styled("h2")`
  ${headerBase}
  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }
`;

export const Heading3 = styled("h3")`
  ${headerBase}
  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

export const Heading4 = styled("h4")`
  ${headerBase}
  font-size: ${({ theme }): string => theme.fontSizes.size22};
`;
