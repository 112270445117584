import { ISelectOption } from "@santa/common/lib/utils/form";
import { isApolloError, useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  CallsDetailsRecipientForm,
  CallContentFormModel,
} from "../../../organisms/forms/calls-details-recipient";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { getLocaleForApi } from "../../../../utils/graphql";
import {
  // BoyGirl,
  // CallsRecipientFormQuery,
  CallType,
  useCallsRecipientFormQuery,
  useMakeSecondCallReservationMutation,
} from "../../../../types/graphql";
import {
  callCreateState,
  callState,
  // ICallCreateState,
} from "../../../../model/graphql/cache";
import { routePaths } from "../../../../model/route";

type FormProps = React.ComponentProps<typeof CallsDetailsRecipientForm>;
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapOptions = (options: { id: string; label?: string | null }[]): ISelectOption[] =>
  options.map(o => ({
    value: o.id,
    label: o.label || "",
  }));

interface IData {
  formProps: FormProps;
  loaderProps: LoaderProps;
  formError?: string;
}

export const useData = (type: CallType): IData => {
  const history = useHistory();
  const callDetails = useReactiveVar(callCreateState);
  const [isFetchingSecondRecipientUrn, setIsFetchingSecondRecipientUrn] = useState(false);

  useEffect(() => {
    if (!callDetails.schedule || !callDetails.callReservationUrn) {
      history.push(routePaths.calls[type].calling);
    }
  }, [history, callDetails]);

  const {
    data,
    loading: isLoading,
    refetch: dataRefetch,
    error,
  } = useCallsRecipientFormQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      reservationUrn: callDetails.callReservationUrn || "",
      locale: getLocaleForApi(),
      type,
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const [makeSecondReservation] = useMakeSecondCallReservationMutation();

  const onClickAddSecondRecipient = useCallback(async (): Promise<void> => {
    if (callDetails.secondCallReservationUrn) {
      // we already have a reservation urn for the second slot - just enable the form
      callState().setSecondCallSlotEnabled(true);

      return;
    }

    if (callDetails.callReservationUrn) {
      // we have an initial call reservation - lets get a reservation
      try {
        setIsFetchingSecondRecipientUrn(true);
        const makeSecondReservationResponse = await makeSecondReservation({
          variables: {
            reservationUrn: callDetails.callReservationUrn,
            type,
          },
          fetchPolicy: "no-cache",
        });
        setIsFetchingSecondRecipientUrn(false);

        if (makeSecondReservationResponse.data) {
          callState().setSecondCallReservationUrn(
            makeSecondReservationResponse.data.makeReservationFollowing.reservationUrn,
          );
          callState().setSecondCallSlotEnabled(true);
        }
      } catch (error) {
        if (error instanceof Error && isApolloError(error)) {
          if (
            error.graphQLErrors.some((e: Error) => e.message === "CALL_RESERVATION_NOT_AVAILABLE")
          ) {
            // second slot is not available
            // refetch query to confirm and disable second slot form
            await refetch();
            callState().setSecondCallSlotEnabled(false);
          }
        }
      }
    }
  }, [data, makeSecondReservation, type]);

  const onClickRemoveSecondRecipient = useCallback(
    (): void => callState().setSecondCallSlotEnabled(false),
    [],
  );

  const onClickBack = useCallback(
    (): void => history.push(routePaths.calls[type].calling),
    [history, type],
  );

  const onSubmit = useCallback(
    async (values: CallContentFormModel): Promise<void> => {
      callState().setContent(values);

      history.push(routePaths.calls[type].addOns);
    },
    [history, type],
  );

  const isSecondRecipientAvailable = useMemo(
    () => !!callDetails.secondCallReservationUrn || !!data?.checkSlotFollowingReservation,
    [data, callDetails],
  );

  return {
    formProps: {
      isSecondRecipientAvailable,
      isFetchingSecondRecipientUrn,
      isSecondRecipientSelected: !!callDetails.isSecondCallSlotEnabled,
      pets: mapOptions(data?.pets || []),
      frontDoors: mapOptions(data?.frontDoors || []),
      onSubmit,
      onClickBack,
      onClickAddSecondRecipient,
      onClickRemoveSecondRecipient,
    },
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && error && { refetch }),
    },
  };
};
