import React, { useCallback, useContext } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { device } from "../../../utils/media-queries";
import { ButtonLink } from "../../atoms/button-link";
import { Heading1 } from "../../atoms/text";
import { BaubleList } from "../../molecules/bauble-list";
import { VideoCircleButton } from "../../molecules/video-circle-button";
import { VideoContext } from "../../contexts/video-context";
import { BowButton } from "../../atoms/bow-button";
import { config } from "../../../config";

const ProductContainer = styled(Container)`
  margin: 80px 0;

  @media ${device.laptopL} {
    margin: 105px 0;
  }
`;

const ProductSectionRow = styled(Row)`
  position: relative;

  li {
    @media ${device.mobileS} {
      font-size: ${({ theme }): string => theme.fontSizes.size22};
    }

    @media ${device.laptopL} {
      font-size: ${({ theme }): string => theme.fontSizes.size24};
    }
  }
`;

const Heading = styled(Heading1)`
  margin: 0 0 24px 0;
`;

const VideoButtonCol = styled(Col)`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;

  > * {
    :last-child {
      margin-left: 28px;
    }
  }
`;

const Image = styled.img`
  @media ${device.mobileS} {
    width: 285px;
    height: 285px;
  }

  @media ${device.laptopL} {
    width: 376px;
    height: 376px;
  }
`;

interface IProps {
  title: string;
  bullets: string;
  align: "left" | "right";
  videoImage: string;
  videoId: keyof typeof config.videos;
  onClickBuy(): void;
  onClickLearnMore(): void;
}

export const HomeProductSection: React.FC<IProps> = props => {
  const video = useContext(VideoContext);

  const firstColPush = props.align === "left" ? { lg: 8 } : {};
  const thirdColPull = props.align === "left" ? { lg: 4 } : {};

  const handleClickOpenVideo = useCallback((): void => video.show(props.videoId), [video, props]);

  return (
    <ProductContainer>
      <Visible xs>
        <Row>
          <Col>
            <Heading>{props.title}</Heading>
          </Col>
        </Row>
      </Visible>
      <ProductSectionRow>
        {props.children}
        <VideoButtonCol xl={4} xs={6} offset={{ xs: 2, lg: 0 }} push={firstColPush}>
          <VideoCircleButton onClick={handleClickOpenVideo}>
            <Image src={props.videoImage} />
          </VideoCircleButton>
        </VideoButtonCol>
        <Col xl={7} offset={{ lg: 1 }} pull={thirdColPull}>
          <Hidden xs>
            <Heading>{props.title}</Heading>
          </Hidden>
          <BaubleList>
            <ReactMarkdown source={props.bullets} />
          </BaubleList>
          <ButtonContainer>
            <BowButton size={210} onClick={props.onClickBuy}>
              Buy Now
            </BowButton>
            <ButtonLink label="Learn More" showArrow onClick={props.onClickLearnMore} />
          </ButtonContainer>
        </Col>
      </ProductSectionRow>
    </ProductContainer>
  );
};
