import React, { useContext } from "react";
import styled from "styled-components";

import { Logo } from "../../atoms/logo";
import { CrossButton } from "../../molecules/buttons/cross";
import { MobileNavigationMenu } from "../../organisms/mobile-navigation-menu";
import { AuthContext } from "../../contexts/auth-context";

import branchImage from "./branch.png";
import { ReactComponent as BubblesImage } from "./bubbles.svg";
import CandyImage from "./candy.svg";
import { ReactComponent as CircleImage } from "./circle.svg";

interface IContainerProps {
  isOpen: boolean;
}

const Container = styled.div<IContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  transition: transform ease-out 0.3s;
  ${({ isOpen }): string => (isOpen ? "" : "transform: translateX(-100%);")}
  z-index: 5000;
  width: 250px;
  height: 100vh;
  background-color: ${({ theme }): string => theme.colours.contentBackground};
  overflow: hidden;

  [data-santa-id="mobile-navigation-red-logo"] {
    position: absolute;
    width: 120px;
    height: 28px;
    top: 33px;
    left: 7px;
  }
`;

const Candy = styled.div`
  position: absolute;
  right: 0;
  width: 18px;
  height: 100vh;
  background-image: url(${CandyImage});
`;

const Bubbles = styled(BubblesImage)`
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 56px;
  width: 172px;
  height: 554px;
`;

const Circle = styled(CircleImage)`
  position: absolute;
  width: 168px;
  height: 120px;
  left: 0px;
  top: 0px;
  z-index: 10;
`;

interface IBranchProps {
  orientation: "right" | "bottom";
}

const branchRightCss = `
  transform-origin: left bottom;
  transform: translate(100%) rotate(-90deg);
  right: 18px;
`;

const branchBottomCss = `
  right: 0;
`;

const Branch = styled.img`
  position: absolute;
  z-index: 2;
  width: 250px;
  height: 81px;
  ${({ orientation }: IBranchProps): string => (orientation === "right" ? branchRightCss : "")}
  ${({ orientation }: IBranchProps): string => (orientation === "bottom" ? branchBottomCss : "")}
  bottom: 0;
`;

const CrossButtonPositioned = styled(CrossButton)`
  position: absolute;
  top: 38px;
  right: 40px;
`;

interface IProps {
  basketCount: number;
  isOpen: boolean;
  onClickClose(): void;
  onClickLogOut(): void;
}

export const MobileNavigation: React.FC<IProps> = ({
  onClickClose,
  onClickLogOut,
  isOpen,
  basketCount,
}) => {
  const isAuthenticated = useContext(AuthContext);

  return (
    <Container isOpen={isOpen}>
      <CrossButtonPositioned onClick={onClickClose} />
      <Candy />
      <Logo id="mobile-navigation-red-logo" />
      <Bubbles />
      <Branch src={branchImage} orientation={isAuthenticated ? "right" : "bottom"} />
      <Circle />
      <MobileNavigationMenu
        onClickElement={onClickClose}
        onClickLogOut={onClickLogOut}
        basketCount={basketCount}
      />
    </Container>
  );
};
