import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?(l: number): string;
  length?: number;
  required?: boolean;
}

export const FormFieldPsOther: React.FC<IProps> = ({
  id = "psCustom",
  label = "Type your own P.S. message",
  placeholder = (l): string => `Message (max ${l} characters)`,
  length = 105,
  required = true,
}) => (
  <FormFieldWrapper label={label} required={required}>
    <Field
      name={id}
      component={FormikFormInputText}
      placeholder={placeholder(length)}
      maxLength={length}
    />
  </FormFieldWrapper>
);
