import { FieldProps } from "formik";
import React, { useCallback, useRef, useState } from "react";
import Autosuggest, {
  ChangeEvent,
  OnSuggestionSelected,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import styled from "styled-components";

interface IOnErrorProp {
  isError?: boolean;
}

interface IDropDownWidthProp {
  dropDownWidth: number;
}

interface IProps<T> extends IOnErrorProp {
  placeholder?: string;
  value: string;
  name: string;
  suggestions: T[];
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onChange(value: string): void;
  // we have to use '| {}' here as we cannot pass the generic down through styled-components
  fetchSuggestions(value: T | {}): void;
  clearSuggestions(): void;
  // we have to use '| unknown' here as we cannot pass the generic down through styled-components
  getSuggestionValue(value: T | unknown): string;
  renderSuggestion(value: T | unknown): JSX.Element;
  onSelect(value: T | {}): void;
}

type StyledCompProps = IOnErrorProp & IDropDownWidthProp;

const AutoSuggestContainer = styled.div<StyledCompProps>`
  flex: 1;

  .react-autosuggest__container {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
  }

  .react-autosuggest__input {
    flex: 1;
    min-width: 100px;
    padding: 7px 16px;
    background-color: #ffffff;
    border: 1px solid ${({ isError, theme }): string => (isError ? theme.colours.alert : "#b0bec5")};
    border-radius: 6px;
    font-family: ${({ theme }): string => theme.fonts.main};
    font-size: ${({ theme }): string => theme.fontSizes.size16};

    ::placeholder {
      color: #b0bec5;
    }

    :focus {
      border-color: #f8bf2f;
      outline: none;
    }
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 34px;
    width: ${({ dropDownWidth }): number => dropDownWidth}px;
    border: 1px solid ${({ theme }): string => theme.colours.spotGold};
    border-top: none;
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 7px 16px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #eee;
  }
`;

export const FormInputAutosuggest: <T>(p: IProps<T>) => React.ReactElement<IProps<T>> = props => {
  // get a ref to autosuggest so we can query the input width
  const autoSuggestElement = useRef<Autosuggest>(null);
  const [dropDownWidth, setDropDownWidth] = useState(200);

  const onChange = (__e: React.ChangeEvent<HTMLInputElement>, { newValue }: ChangeEvent): void =>
    props.onChange(newValue);

  const handleFetchRequested = useCallback(
    (params: SuggestionsFetchRequestedParams): void => {
      // set the width according to the input width - - 2px for the border
      setDropDownWidth(autoSuggestElement.current!.input!.offsetWidth - 2);

      props.fetchSuggestions(params.value);
    },
    [setDropDownWidth, props],
  );

  const onSelect: OnSuggestionSelected<{}> = useCallback(
    (__e, { suggestion }) => props.onSelect(suggestion),
    [props],
  );

  const inputProps = {
    onChange,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    value: props.value,
    name: props.name,
    placeholder: props.placeholder,
    autoComplete: "disabled",
  };

  return (
    <AutoSuggestContainer dropDownWidth={dropDownWidth}>
      <Autosuggest
        ref={autoSuggestElement}
        suggestions={props.suggestions}
        onSuggestionsFetchRequested={handleFetchRequested}
        onSuggestionsClearRequested={props.clearSuggestions}
        getSuggestionValue={props.getSuggestionValue}
        renderSuggestion={props.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSelect}
      />
    </AutoSuggestContainer>
  );
};

export const FormikFormInputAutosuggest: <T>(
  p: FieldProps & IProps<T>,
) => React.ReactElement<FieldProps & IProps<T>> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = useCallback(
    (): void => setFieldTouched(field.name),
    [setFieldTouched, field],
  );
  const onChange = useCallback(
    (value: string): void => setFieldValue(field.name, value),
    [setFieldValue, field],
  );

  return (
    <FormInputAutosuggest
      suggestions={props.suggestions}
      fetchSuggestions={props.fetchSuggestions}
      clearSuggestions={props.clearSuggestions}
      getSuggestionValue={props.getSuggestionValue}
      renderSuggestion={props.renderSuggestion}
      name={props.name}
      onChange={onChange}
      onBlur={field.onBlur}
      onFocus={setFieldFocussed}
      isError={isError}
      placeholder={props.placeholder}
      value={field.value}
      onSelect={props.onSelect}
    />
  );
};
