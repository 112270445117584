import { getDescription } from "@santa/common/lib/products";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { ProductCombinationListItem } from "../../../molecules/product-combination-list-item";
import { getLocaleForApi } from "../../../../utils/graphql";
import { trackGtmConversion } from "../../../../model/tracking";
import { OrderConfirmationQuery, useOrderConfirmationQuery } from "../../../../types/graphql";

type Order = NonNullable<OrderConfirmationQuery["order"]>;

type ItemProps = React.ComponentProps<typeof ProductCombinationListItem>;

const mapOrderItems = (order: Order): ItemProps[] =>
  order.orderItems.map<ItemProps>((o, i) => ({
    title: o.product.title!,
    description: getDescription(o),
    imageUrl: o.product.image!.url!!,
    addOns: o.addOnProducts.map<string>(a => a.title!),
    ...(i < order.orderItems.length - 1 && { icon: "plus" }),
  }));

interface IParams {
  orderUrn: string;
}

interface IData {
  items: ItemProps[];
  pixels?: string[];
}

type UseDataFunction = () => {
  isLoading: boolean;
  refetch?(): void;
  data?: IData;
};

/**
 * Hook to get data for letter content form
 */
export const useData: UseDataFunction = () => {
  const { orderUrn } = useParams<IParams>();

  const {
    data,
    loading: isLoading,
    refetch,
  } = useOrderConfirmationQuery({
    fetchPolicy: "no-cache",
    variables: { orderUrn, locale: getLocaleForApi() },
  });

  useEffect(() => {
    if (data?.order) {
      trackGtmConversion(data.order);
    }
  }, [data?.order]);

  return {
    isLoading,
    ...(!data && { refetch }),
    ...(data?.order && {
      data: {
        items: mapOrderItems(data.order),
      },
    }),
  };
};
