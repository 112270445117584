import React from "react";

import { Accordion } from "../components/molecules/accordion";

interface ICmsFaq {
  id: unknown;
  title?: string | null | undefined;
  description?: string | null | undefined;
}

export const mapCmsFaqsToAccordion = (
  faqs: ICmsFaq[],
): React.ComponentProps<typeof Accordion>["items"] =>
  faqs.map(f => ({
    id: f.id as string,
    title: f.title || "",
    description: f.description || "",
  }));
