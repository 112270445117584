import { ISelectMultilineOption, ISelectOption } from "@santa/common/lib/utils/form";
import { Col, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import { LETTER_CONTENT_FORM_PS_OTHER_ID } from "@santa/common/lib/form-validation/letter";
import * as yup from "yup";

import { FormFieldTemplate } from "../../../../molecules/form/form-fields/form-field-template";
import { Paragraph } from "../../../../atoms/text";
import { FormFieldHobbyFreeText } from "../../../../molecules/form/form-fields/form-field-hobby-free-text";
import { FormFieldGiftFreeText } from "../../../../molecules/form/form-fields/form-field-gift-free-text";
import { FormFieldFriendName } from "../../../../molecules/form/form-fields/form-field-friend-name";
import { FormFieldPetType } from "../../../../molecules/form/form-fields/form-field-pet-type";
import { FormFieldPetName } from "../../../../molecules/form/form-fields/form-field-pet-name";
import { FormFieldFrontDoor } from "../../../../molecules/form/form-fields/form-field-front-door";
import { FormFieldSignature } from "../../../../molecules/form/form-fields/form-field-signature";
import { FormFieldPs } from "../../../../molecules/form/form-fields/form-field-ps";
import { FormFieldPsOther } from "../../../../molecules/form/form-fields/form-field-ps-other";

const PreviewLink = styled(Paragraph)`
  margin-top: 5px;
  color: ${({ theme }): string => theme.colours.alert};
`;

export const validationRules = {
  hobby: yup.string(),
  gift: yup.string(),
  friend: yup.string().max(50, "Maximum 50 characters"),
  template: yup.object({
    value: yup.string().required("Required"),
    label: yup.string(),
    description: yup.string(),
  }),
  signatureId: yup.string().required("Required"),
  frontDoorId: yup.string(),
  pet1Id: yup.string(),
  pet1Name: yup.string(),
  pet2Id: yup.string(),
  pet2Name: yup.string(),
  psId: yup.string(),
  psCustom: yup
    .string()
    .max(105)
    .test("requiredTest", "Required", (value: string | undefined, context): boolean =>
      context.parent.psId === LETTER_CONTENT_FORM_PS_OTHER_ID ? !!value : true,
    ),
};

export interface IDefaultValuesModel {
  hobby?: string;
  gift?: string;
  friend?: string;
  template?: ISelectMultilineOption;
  signatureId?: string;
  frontDoorId?: string;
  pet1Id?: string;
  pet1Name?: string;
  pet2Id?: string;
  pet2Name?: string;
  psId?: string;
  psCustom?: string;
}

interface IProps {
  values: IDefaultValuesModel;
  templateOptions: ISelectMultilineOption[];
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
  signatureOptions: ISelectOption[];
  psOptions: ISelectOption[];
  onClickOpenMobilePreview?(): void;
}

/**
 * Form section for letter content
 */
export const FormSectionLetterContent: React.FC<IProps> = ({
  values,
  templateOptions,
  signatureOptions,
  psOptions,
  petOptions,
  frontDoorOptions,
  onClickOpenMobilePreview,
}) => {
  const fieldTemplateProps = {
    options: templateOptions,
    ...(onClickOpenMobilePreview && {
      info: () => (
        <Visible xs>
          <PreviewLink onClick={onClickOpenMobilePreview}>
            To view story template, tap here
          </PreviewLink>
        </Visible>
      ),
    }),
  };
  return (
    <>
      <Row>
        <Col>
          <FormFieldHobbyFreeText />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldGiftFreeText />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldFriendName />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormFieldPetType id="pet1Id" options={petOptions} label="Pet or relative 1" />
        </Col>
        <Col xl={6}>
          <FormFieldPetName id="pet1Name" label="Name of pet or relative 1" />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormFieldPetType id="pet2Id" options={petOptions} label="Pet or relative 2" />
        </Col>
        <Col xl={6}>
          <FormFieldPetName id="pet2Name" label="Name of pet or relative 2" />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormFieldFrontDoor options={frontDoorOptions} />
        </Col>
        <Col xl={6}>
          <FormFieldSignature options={signatureOptions} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldPs
            options={[
              { value: LETTER_CONTENT_FORM_PS_OTHER_ID, label: "Type your own" },
              ...psOptions,
            ]}
          />
        </Col>
      </Row>

      {values.psId === LETTER_CONTENT_FORM_PS_OTHER_ID && (
        <Row>
          <Col xl={12}>
            <FormFieldPsOther />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <FormFieldTemplate {...fieldTemplateProps} />
        </Col>
      </Row>
    </>
  );
};
