import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { routePaths } from "../../../model/route";
import { DropDownMenu } from "../../atoms/drop-down-menu";
import { DropDownMenuItem } from "../../atoms/drop-down-menu-item";
import { CaretIcon } from "../../atoms/icons/caret-icon";

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  color: ${({ theme }): string => theme.colours.text};
`;

const DropDown = styled(CaretIcon)`
  height: 8px;
  width: 15px;
  margin-top: 3px;
  margin-left: 10px;
`;

export const CallsMenuDropdown: React.FC<IProps> = () => {
  const history = useHistory();

  const handleClickVoice = useCallback(
    (): void => history.push(routePaths.calls.AUDIO.home),
    [history],
  );
  const handleClickZoom = useCallback(
    (): void => history.push(routePaths.calls.VIDEO.home),
    [history],
  );

  const items = [
    <DropDownMenuItem key={1} onClick={handleClickVoice} label="Voice Calls" />,
    <DropDownMenuItem key={2} onClick={handleClickZoom} label="Zoom Calls" />,
  ];

  return (
    <DropDownMenu items={items} id={"dropdown-call"}>
      <LabelWrapper>
        <Label data-santa-id="dropdown-label-call">Santa Calls</Label>
        <DropDown colour="midGrey" />
      </LabelWrapper>
    </DropDownMenu>
  );
};
