import * as React from "react";
import styled from "styled-components";

import { displayPrice } from "../../../utils/price";
import { ProductAddOnList } from "../product-add-on-list";

const Container = styled.div`
  padding: 18px 0;

  :not(:last-child) {
    border-bottom: ${({ theme }): string => theme.colours.textSubdued} 1px solid;
  }

  ul {
    font-size: ${({ theme }): string => theme.fontSizes.size14};
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.h2`
  margin: 0;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  font-weight: bold;

  em {
    margin-left: 8px;
    font-style: normal;
    font-weight: 300;
  }
`;

const Price = styled.span`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  font-weight: normal;
`;

const Description = styled.p`
  margin: 9px 0;
  font-size: ${({ theme }): string => theme.fontSizes.size14};
`;

interface IProps {
  title: JSX.Element;
  price: number;
  description: string;
  additionalItems: JSX.Element[];
}

export const CheckoutBasketItem: React.FC<IProps> = props => (
  <Container>
    <HeaderRow>
      <Title>{props.title}</Title>
      <Price>{displayPrice(props.price)}</Price>
    </HeaderRow>
    <Description>{props.description}</Description>
    {props.additionalItems.length > 0 && <ProductAddOnList items={props.additionalItems} />}
  </Container>
);
