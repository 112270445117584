import styled from "styled-components";

import { EyeIcon } from "../../../atoms/icons/eye-icon";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.span`
  margin-left: 10px;
  color: ${({ theme }): string => theme.colours.alert};
  font-size: ${({ theme }): string => theme.fontSizes.size24};
`;

interface IProps {
  onClick(): void;
  label?: string;
}

export const PreviewButton: React.FC<IProps> = ({ onClick, label = "Preview" }) => (
  <Container onClick={onClick}>
    <EyeIcon />
    <Label>{label}</Label>
  </Container>
);
