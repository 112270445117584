import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { FormInputTextWithSubmit } from "../../molecules/form/form-input-text-with-submit";
import { Paragraph } from "../../atoms/text";
import { IPromoCode } from "../../../model/checkout";
import { device } from "../../../utils/media-queries";
import { CallOutPanel } from "../../atoms/callout-panel";
import { useApplyPromoCodeMutation } from "../../../types/graphql";
import { isApolloError } from "@apollo/client";

const Label = styled.span`
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  color: ${({ theme }): string => theme.colours.textSubdued};

  @media ${device.mobileS} {
    margin-bottom: 10px;
  }

  @media ${device.tablet} {
    margin-bottom: 0;
    margin-right: 17px;
  }

  a {
    color: ${({ theme }): string => theme.colours.textSubdued};

    :hover {
      text-decoration: none;
    }
  }
`;

const Input = styled(FormInputTextWithSubmit)`
  display: block;

  @media ${device.tablet} {
    width: 300px;
  }
`;

const ErrorParagraph = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const RemoveButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: inline-block;
  text-align: left;
  margin-top: 5px;
  text-decoration: underline;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  &:hover {
    text-decoration: none;
  }
`;

interface IProps {
  total: number;
  currentPromoCode?: IPromoCode;
  onSuccess(code: IPromoCode | undefined, deduction: number): void;
}

export const PromoCodeBox: React.FC<IProps> = ({ currentPromoCode, total, onSuccess }) => {
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);

  const [applyCode, { loading: isLoading }] = useApplyPromoCodeMutation();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => setCode(e.target.value),
    [setCode],
  );

  const handleClickSubmit = useCallback(async (): Promise<void> => {
    setCode("");

    try {
      const { data } = await applyCode({ variables: { code, total } });

      setError(undefined);
      onSuccess(
        {
          code: data!.applyPromoCode!.promoCode.code,
          urn: data!.applyPromoCode.promoCode.urn,
        },
        data!.applyPromoCode!.deduction,
      );
    } catch (e) {
      if (e instanceof Error && isApolloError(e) && typeof e.graphQLErrors !== "undefined") {
        setError(e.graphQLErrors[0].message);
      }
    }
  }, [setCode, onSuccess, setError, applyCode, code, total]);

  const handleRemoveCode = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();

      onSuccess(undefined, 0);
    },
    [onSuccess],
  );

  const errorComponent =
    typeof error !== "undefined" ? <ErrorParagraph>{error}</ErrorParagraph> : undefined;

  const content =
    typeof currentPromoCode !== "undefined" ? (
      <Label>
        Code <strong>{currentPromoCode.code}</strong> applied &ndash;{" "}
        <RemoveButton onClick={handleRemoveCode}>Remove</RemoveButton>
      </Label>
    ) : (
      <>
        <Label>Got a promotional code?</Label>
        <Input
          submitLabel="Submit"
          isLoading={isLoading}
          onChange={handleChange}
          clickSubmit={handleClickSubmit}
          placeholder="Enter code"
        />
      </>
    );

  return (
    <>
      {errorComponent}
      <CallOutPanel>{content}</CallOutPanel>
    </>
  );
};
