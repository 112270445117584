import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { device } from "../../../../utils/media-queries";
import { Tree } from "../../../atoms/images/decorations/tree";
import { Heading1, Heading3 } from "../../../atoms/text";
import { BowPrice } from "../../../molecules/bow-price";
import { VideoSquareButton } from "../../../molecules/video-square-button";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { SackRecipientForm } from "../../../organisms/forms/sack-recipient";
import { BaubleList } from "../../../molecules/bauble-list";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { Accordion } from "../../../molecules/accordion";

import { useData } from "./use-data";
import sackVideoImage from "./sack-square.jpg";
import bauble from "./bauble.png";

const ButtonCol = styled(Col)`
  position: relative;

  @media ${device.tablet} {
    height: 1px;
  }
`;

const OverlayTree = styled(Tree)`
  position: absolute;
  z-index: 20;

  @media ${device.mobileS} {
    top: 90px;
    left: -110px;
    width: 180px;
    height: 333px;
  }

  @media ${device.laptopL} {
    top: 0;
    left: -230px;
    width: 389px;
    height: 720px;
  }
`;

const OverlayBauble = styled.img`
  position: absolute;
  z-index: 21;

  @media ${device.mobileS} {
    top: 340px;
    left: -35px;
    width: 94px;
    height: 67px;
  }

  @media ${device.laptopL} {
    top: 560px;
    left: -140px;
    width: 203px;
    height: 145px;
  }
`;

const MainHeading = styled(Heading1)`
  margin-bottom: 0;

  @media ${device.mobileS} {
    margin-top: 0;
  }

  @media ${device.laptopL} {
    margin-top: 44px;
  }
`;

const SackBowPrice = styled(BowPrice)`
  @media ${device.mobileS} {
    margin-top: 13px;
  }

  @media ${device.laptopL} {
    margin-top: 60px;
  }
`;

const SubHeading = styled(Heading3)`
  margin-bottom: 35px;
  font-weight: normal;

  @media ${device.mobileS} {
    margin-top: 23px;
  }

  @media ${device.laptopL} {
    margin-top: 10px;
  }
`;

const ContentCol = styled(Col)`
  > p {
    :last-of-type {
      margin-bottom: 35px;
    }
  }
`;

export const SackHome: React.FC = () => {
  const { loaderProps, product, handleClickOpenVideo, formProps } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      {product && (
        <MainWrapper>
          <Container>
            <Row>
              <Visible md lg xl>
                <ButtonCol md={5}>
                  <OverlayTree />
                  <OverlayBauble src={bauble} alt="Bauble" />
                  <VideoSquareButton image={sackVideoImage} onClick={handleClickOpenVideo} />
                </ButtonCol>
              </Visible>
              <Col lg={5} md={4} xs={12}>
                <MainHeading>{product.title}</MainHeading>
              </Col>
              <Col lg={2} md={3} xs={6}>
                <SackBowPrice price={product.price} />
              </Col>
            </Row>
            <Row>
              <Col offset={{ md: 5 }}>
                <SubHeading>{product.description}</SubHeading>
              </Col>
            </Row>
            <Hidden md lg xl>
              <Row>
                <ButtonCol xs={9} offset={{ xs: 3 }}>
                  <OverlayTree />
                  <VideoSquareButton image={sackVideoImage} onClick={handleClickOpenVideo} />
                </ButtonCol>
              </Row>
            </Hidden>
            <Row>
              <ContentCol md={7} offset={{ md: 5 }}>
                <BaubleList>
                  <ReactMarkdown source={product.productPageDescription || ""} />
                </BaubleList>
                <Accordion items={product.faqs} />
                <SackRecipientForm {...formProps} />
              </ContentCol>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainer>
  );
};
