import * as dateFns from "date-fns";

export interface ILetterPreviewParams {
  ctx: CanvasRenderingContext2D;
  width: number;
  horizontalMargin: number;
  addressYIndex: number;
  mainYIndex: number;
  bottomMargin: number;
  bodyFontFace: string;
  bodyFontSize: number;
  signatureFontFace: string;
  signatureFontSize: number;
  headerImage: HTMLImageElement;
  footerImage?: HTMLImageElement;
  templateName: string | undefined;
  signature?: string;
  ps?: string;
}

export interface IRenderParams {
  intro: string;
  paragraphs: string[];
  address: string[];
  date: Date;
}

type TextType = "body" | "signature";

type LetterPreview = (params: ILetterPreviewParams) => (params: IRenderParams) => void;

/**
 * A class for rendering a letter inside a canvas context.
 * The control character \0 is used to start and end highlighting
 */
export const letterPreview: LetterPreview = params => {
  const templateInfo =
    typeof params.templateName !== "undefined"
      ? `\0The selected story is "${params.templateName}" (this notice will not appear in the mailed letter)\0`
      : undefined;

  const lineHeight = Math.floor(params.bodyFontSize + 1.6);
  const paragraphSpacing = Math.floor(params.bodyFontSize * 0.9);

  const setFont = (type: TextType): void => {
    params.ctx.font =
      type === "body"
        ? `${params.bodyFontSize}px ${params.bodyFontFace}`
        : `${params.signatureFontSize}px ${params.signatureFontFace}`;
    params.ctx.textBaseline = "top";
  };

  const calculateWidth = (type: TextType, text: string): number => {
    setFont(type);
    return params.ctx.measureText(text).width;
  };

  /**
   * Return the offset of the string that fits in the objects max width
   * @param text The text to get substring of
   */
  const getNextLinePosition = (type: TextType, text: string, indent = 0): number => {
    let offset: number;
    const maxWidth = params.width - (params.horizontalMargin + indent);

    // find first part of string that fits just below max width
    for (offset = text.length; calculateWidth(type, text.substr(0, offset)) > maxWidth; offset--);

    // if the offset is less than the end of the string, fnd the last space and return that
    if (offset < text.length) {
      const newOffset = text.substr(0, offset).lastIndexOf(" ");
      if (newOffset !== -1) {
        return newOffset;
      }
    }

    return offset;
  };

  /**
   * Break single text string upo into lines which render to no longer than object's max length
   * @param text Text to break up
   */
  const breakTextIntoLines = (type: TextType, text: string, indent = 0): string[] => {
    let tempText = text;
    const lines: string[] = [];

    while (tempText.length > 0) {
      const offset = getNextLinePosition(type, tempText, indent);

      lines.push(tempText.substr(0, offset));

      // remove leading space
      tempText = tempText.substr(offset).replace(/^ +/, "");
    }

    return lines;
  };

  enum HighlightColour {
    PINK = "#ffeeee",
    BLUE = "#cceeff",
  }

  enum Alignment {
    LEFT,
    RIGHT,
  }

  /**
   * Render a series of lines as a paragraph
   * @param lines Lines to render
   * @param startY Start Y position
   * @param alignment Left or right alignment
   */
  const renderParagraph = (
    type: TextType,
    hightlightColour: HighlightColour,
    lines: string[],
    startY: number,
    alignment = Alignment.LEFT,
    indent = 0,
  ): number => {
    let currentY = startY;
    let isHighlightOn = false;
    const fontSize = type === "body" ? params.bodyFontSize : params.signatureFontSize;

    lines.forEach(line => {
      currentY += lineHeight;
      let x = params.horizontalMargin + indent;

      if (alignment === Alignment.RIGHT) {
        x = params.width - params.horizontalMargin - calculateWidth(type, line) - indent;
      }

      const letters = line.split("");

      // draw letters one by one
      letters.forEach(l => {
        if (l === "\0") {
          isHighlightOn = !isHighlightOn;
        } else {
          const letterWidth = calculateWidth(type, l);

          if (isHighlightOn) {
            /// draw background rect assuming height of font
            params.ctx.fillStyle = hightlightColour;
            params.ctx.fillRect(x, currentY, letterWidth, fontSize);
          }

          params.ctx.fillStyle = "black";
          params.ctx.fillText(l, x, currentY);

          x += letterWidth;
        }
      });
    });

    return currentY + paragraphSpacing;
  };

  /**
   * Get the total canvas height
   * @param renderParams Render parameters
   */
  const getCanvasHeight = (renderParams: IRenderParams): number => {
    const paragraphSets = renderParams.paragraphs.map(p => breakTextIntoLines("body", p));

    let height = params.mainYIndex + params.bottomMargin;

    // date height
    height += lineHeight + paragraphSpacing;

    // dear... height
    height += lineHeight + paragraphSpacing;

    // intro height
    if (templateInfo) {
      const lines = breakTextIntoLines("body", templateInfo);
      height += lines.length * lineHeight + paragraphSpacing;
    }

    // paragraphs height
    height += paragraphSets.reduce<number>(
      (acc, lines) => acc + lines.length * lineHeight + paragraphSpacing,
      0,
    );

    // signoff height
    if (params.signature) {
      height += lineHeight + paragraphSpacing;
      height += params.signatureFontSize + paragraphSpacing;
    }

    // ps height
    if (params.ps) {
      const lines = breakTextIntoLines("body", params.ps, 50);
      height += lines.length * lineHeight + paragraphSpacing * 1.5;
    }

    return height;
  };

  /**
   * Render the letter
   * @param params Render params
   */
  return renderParams => {
    params.ctx.canvas.width = params.width;
    params.ctx.canvas.height = getCanvasHeight(renderParams);

    // draw header image
    params.ctx.drawImage(
      params.headerImage,
      0,
      0,
      params.headerImage.naturalWidth,
      params.headerImage.naturalHeight,
    );

    if (typeof params.footerImage !== "undefined") {
      // draw footer image
      params.ctx.drawImage(
        params.footerImage,
        0,
        params.ctx.canvas.height - params.footerImage.naturalHeight,
        params.footerImage.naturalWidth,
        params.footerImage.naturalHeight,
      );
    }

    let yPosition = params.addressYIndex;

    // set font
    setFont("body");

    // address
    renderParagraph("body", HighlightColour.PINK, renderParams.address, yPosition);

    // set explicit position for date
    yPosition = params.mainYIndex;

    // date
    yPosition = renderParagraph(
      "body",
      HighlightColour.PINK,
      [`\0${dateFns.format(renderParams.date, "do MMMM yyyy")}\0`],
      yPosition,
      Alignment.RIGHT,
    );

    // intro
    yPosition = renderParagraph("body", HighlightColour.PINK, [renderParams.intro], yPosition);

    if (templateInfo) {
      yPosition = renderParagraph(
        "body",
        HighlightColour.BLUE,
        breakTextIntoLines("body", templateInfo),
        yPosition,
      );
    }

    // main body
    renderParams.paragraphs
      .map(p => breakTextIntoLines("body", p))
      .forEach(p => {
        yPosition = renderParagraph("body", HighlightColour.PINK, p, yPosition);
      });

    if (params.signature) {
      // sign off
      yPosition = renderParagraph(
        "body",
        HighlightColour.PINK,
        ["Lots of love from,"],
        yPosition,
        Alignment.LEFT,
        30,
      );

      yPosition += paragraphSpacing;

      yPosition = renderParagraph(
        "signature",
        HighlightColour.PINK,
        [params.signature],
        yPosition,
        Alignment.LEFT,
        30,
      );
    }

    if (typeof params.ps !== "undefined") {
      // ps
      yPosition += paragraphSpacing * 1.5;

      yPosition = renderParagraph(
        "body",
        HighlightColour.PINK,
        breakTextIntoLines("body", `P.S. ${params.ps}`, 50),
        yPosition,
        Alignment.LEFT,
        50,
      );
    }
  };
};
