import { customAlphabet } from "nanoid";

const NANO_ALPHABET = "0123456789ABCDEFGHJKLMNPQRTUVWXYZ";
const ID_LENGTH = 12;
const URN_PREFIX = "urn:santa";

const nanoid = customAlphabet(NANO_ALPHABET, ID_LENGTH);

export enum UrnResource {
  CALL = "call",
  CALL_RESERVATION = "call-reservation",
  CALL_SHIFT = "call-shift",
  CALL_SHIFT_DATE = "call-shift-date",
  CONTROL_VALUE = "control-value",
  COUNTRY = "country",
  LETTER = "letter",
  ORDER = "order",
  ORDER_ITEM = "order-item",
  PROMO_CODE = "promo-code",
  SACK = "sack",
  SACK_DATE = "sack-date",
  SYSTEM = "system",
  TABLE_NAMESPACE = "table-namespace",
  TEXT = "text",
  TEXT_DATE = "text-date",
  TIMEZONE = "timezone",
  TRANSACTION = "transaction",
  US_STATE = "us-state",
  USER = "user",
  VIDEO_LOGIN = "video-login",
  VIDEO_MESSAGE = "video-message",
}

/**
 * Create an URN for given resource and id
 * @param resource Resource of URN to create
 * @param identifier ID to use in urn
 */
export const createUrn = (resource: UrnResource, identifier: string): string =>
  `${URN_PREFIX}:${resource}:${identifier}`;

/**
 * Create an URN for given resource
 * @param resource Resource of URN to create
 */
export const createUrnWithId = (resource: UrnResource): string =>
  `${URN_PREFIX}:${resource}:${nanoid()}`;

/**
 * Return the id part of a given URN
 * @param resource Resource of thr URN
 * @param urn URN to extract the ID from
 */
export const extractIdFromUrn = (resource: UrnResource, urn: string): string => {
  // eslint-disable-next-line @rushstack/security/no-unsafe-regexp
  const urnRegex = new RegExp(`${URN_PREFIX}:${resource}:(.+)$`);

  const match = urn.match(urnRegex);
  if (match === null) {
    throw new Error(`URN ${urn} is not compatible with resource ${resource}`);
  }

  return match[1];
};

/**
 * Return the reosurce part of a given URN
 * @param urn URN to extract the resource part form
 */
export const extractResourceFromUrn = (urn: string): UrnResource => {
  // eslint-disable-next-line @rushstack/security/no-unsafe-regexp
  const resourceRegex = new RegExp(`^${URN_PREFIX}:(.+):.+$`);
  const match = urn.match(resourceRegex);
  if (!match || match.length === 0) {
    throw new Error(`Could not extract resource from urn of: ${urn}`);
  }

  return match[1] as unknown as UrnResource;
};

/**
 * Returns true if the given URN is of the given resource
 * @param urn URN to check
 * @param resource Resource to check if URN is of
 */
export const isUrnOfResource = (urn: string, resource: UrnResource): boolean => {
  // eslint-disable-next-line @rushstack/security/no-unsafe-regexp
  const urnRegex = new RegExp(`^${URN_PREFIX}:${resource}:.+$`);

  return urnRegex.test(urn);
};
