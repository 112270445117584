import { Field } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import { FormFieldLabel } from "../../../../molecules/form-field-label";
import { ImageWithClose } from "../../../../molecules/image-with-close";
import { ImageUploader } from "../../../../organisms/image-uploader";

const Wrapper = styled.div`
  margin-bottom: 19px;
`;

const StyledField = styled(Field)`
  display: none;
`;

export interface IFormSectionVideoImagesModel {
  childImageId: string;
  houseImageId: string;
}

interface IProps {
  id: string;
  setFieldValue(field: string, value: string, shouldValidate?: boolean): void;
  label: string;
  tooltip?: string;
  imageData: string;
}

/**
 * Form section for video message images
 */
export const FormFieldImage: React.FC<IProps> = ({
  setFieldValue,
  id,
  label,
  tooltip,
  imageData,
}) => {
  // const [imageData, setImageData] = useState<string>();

  const handleComplete = useCallback(
    (image: string): void => {
      // setImageData(image);
      setFieldValue(id, image, true);
    },
    [setFieldValue, id],
  );

  const handleCloseImage = useCallback((): void => {
    // setImageData(undefined);
    setFieldValue(id, "", true);
  }, [setFieldValue, id]);

  return (
    <Wrapper>
      <FormFieldLabel label={label} tooltip={tooltip} />
      {!imageData && <ImageUploader onComplete={handleComplete} />}
      {imageData && <ImageWithClose src={imageData} onClick={handleCloseImage} />}
      <StyledField name={id} />
    </Wrapper>
  );
};
