import { Route, Switch } from "react-router-dom";

import { routePaths } from "../../../model/route";
import { DataLoadedContainer } from "../../control/data-loaded-container";

import { SantaLetterAddOns } from "./santa-letter-add-ons";
import { SantaLetterDetails } from "./santa-letter-details";
import { SantaLetterType } from "./santa-letter-type";
import { SantaLetterTemplates } from "./santa-letter-templates";
import { useData } from "./use-data";
import { LetterHome } from "./letter-home";

export const SantaLetter: React.FC = () => {
  const { loaderProps, enabled } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      {typeof enabled !== "undefined" && (
        <Switch>
          <Route exact path={routePaths.letter.home} component={LetterHome} />
          <Route
            exact
            path={routePaths.letter.types.classic}
            // eslint-disable-next-line react/jsx-no-bind
            component={() => <SantaLetterType enabled={enabled} type="classic" />}
          />
          <Route
            exact
            path={routePaths.letter.types.cookieMix}
            // eslint-disable-next-line react/jsx-no-bind
            component={() => <SantaLetterType enabled={enabled} type="cookieMix" />}
          />
          <Route
            exact
            path={routePaths.letter.types.plush}
            // eslint-disable-next-line react/jsx-no-bind
            component={() => <SantaLetterType enabled={enabled} type="plush" />}
          />
          <Route
            exact
            path={routePaths.letter.types.santaSack}
            // eslint-disable-next-line react/jsx-no-bind
            component={() => <SantaLetterType enabled={enabled} type="santaSack" />}
          />
          <Route
            exact
            path={routePaths.letter.types.magicSnow}
            // eslint-disable-next-line react/jsx-no-bind
            component={() => <SantaLetterType enabled={enabled} type="magicSnow" />}
          />
          <Route exact path={routePaths.letter.templates} component={SantaLetterTemplates} />
          <Route path={routePaths.letter.detailsRoute} component={SantaLetterDetails} />
          <Route path={routePaths.letter.addOns} component={SantaLetterAddOns} />
        </Switch>
      )}
    </DataLoadedContainer>
  );
};
