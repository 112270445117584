import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Hidden } from "react-grid-system";

import { device } from "../../../../utils/media-queries";
import { routePaths } from "../../../../model/route";

import bauble from "./bauble.png";
import videoImage from "./button-background.png";
import toySanta from "./toy-santa.png";

import { HomeProductSection } from "..";

type Align = React.ComponentProps<typeof HomeProductSection>["align"];

const TreeBauble = styled.img<{ align: Align }>`
  z-index: 6;
  position: absolute;
  right: 20px;
  top: 230px;
  width: 60px;
  height: 54px;

  @media ${device.laptopL} {
    ${({ align }): string =>
      align === "right" ? "right: auto; left: 20px;" : "left: auto; right: 40px;"}
    top: 335px;
    width: 101px;
    height: 94px;
  }
`;

const ToySanta = styled.img<{ align: Align }>`
  z-index: 5;
  position: absolute;

  ${({ align }): string =>
    align === "right" ? "right: -142px;" : "transform: scaleX(-1); left: -190px;"}
  top: 156px;
  width: 311px;
  height: 251px;
`;

interface IProps {
  title: string;
  bullets: string;
  align: Align;
}

export const SantaTextHomeSection: React.FC<IProps> = ({ title, bullets, align }) => {
  const history = useHistory();

  const handleClickLearnMore = useCallback(
    (): void => history.push(routePaths.text.home),
    [history],
  );
  const onClickBuy = useCallback((): void => history.push(routePaths.text.details), [history]);

  return (
    <HomeProductSection
      title={title}
      videoId="texts"
      bullets={bullets}
      align={align}
      videoImage={videoImage}
      onClickLearnMore={handleClickLearnMore}
      onClickBuy={onClickBuy}
    >
      <TreeBauble src={bauble} align={align} />
      <Hidden xs>
        <ToySanta src={toySanta} align={align} />
      </Hidden>
    </HomeProductSection>
  );
};
