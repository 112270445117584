import { earliestCallDate, lastCallDate } from "@santa/common/lib/products/calls";
import { ISelectOption } from "@santa/common/lib/utils/form";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { useParams, useHistory } from "react-router-dom";
import { ApolloClient, isApolloError, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import * as dateFns from "date-fns";
import { History } from "history";
import addDuration from "date-fns-duration";
import { utcToZonedTime } from "date-fns-tz";

import {
  CallEditForm,
  VideoFormModel,
  AudioFormModel,
} from "../../../organisms/forms/call-edit-form";
import { config } from "../../../../config";
import { getLocaleForApi } from "../../../../utils/graphql";
import { BoyGirl } from "../../../../types/graphql";
import {
  AvailableCallDatesResponse,
  AvailableCallSlotsResponse,
  CallType,
  MyAccountCallQuery,
  useCallsDatesLazyQuery,
  useCallsSlotsLazyQuery,
  useMyAccountCallQuery,
  UpdateCallMutation,
  UpdateCallMutationVariables,
  UpdateCallDocument,
} from "../../../../types/graphql";
import { routePaths } from "../../../../model/route";
import { useCallValidateNumber } from "../../../../hooks/call/use-call-validate-number";

type FormProps = React.ComponentProps<typeof CallEditForm>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapOptions = <T extends Record<string, any>>(
  options: T[],
  valueKey: keyof T,
  labelKey: keyof T,
): ISelectOption[] =>
  options.map(o => ({
    value: o[valueKey] || "",
    label: o[labelKey] || "",
  }));

type Countries = MyAccountCallQuery["countries"];

const getCountryOptions = (countries?: Countries): ISelectOption[] =>
  (countries || []).map(c => ({
    label: `${c.iso?.toUpperCase()} (+${c.callingCode})`,
    value: c.urn,
  }));

type FormDefaultValues = FormProps["defaultValues"];

const mapDataToForm = (
  data: NonNullable<NonNullable<ReturnType<typeof useMyAccountCallQuery>["data"]>["call"]>,
): FormDefaultValues => ({
  ...(data.phoneCountry?.urn && { phoneCountryUrn: data.phoneCountry.urn }),
  ...(data.phoneNumber && { phoneNumber: data.phoneNumber }),
  timezoneUrn: data.timezone.urn,
  firstName: data.firstName,
  lastName: data.lastName,
  boyGirl: data.boyGirl,
  ageMonths: data.ageMonths?.toString() || "",
  ageYears: data.ageYears?.toString() || "",
  date: dateFns.startOfDay(dateFns.parseISO(data.time)),
  time: data.time,
  friend: data.friend || "",
  frontDoorId: data.frontDoor?.id,
  pet1Id: data.pet1?.id,
  pet1Name: data.pet1Name || "",
  pet2Id: data.pet2?.id,
  pet2Name: data.pet2Name || "",
  hobby: data.hobby || "",
  gift: data.gift || "",
  notes: data.notes || "",
});

const isAudioCall = (
  type: CallType,
  __values: AudioFormModel | VideoFormModel,
): __values is AudioFormModel => type === CallType.AUDIO;

const getSubmitFunction =
  (
    client: ApolloClient<object>,
    history: History,
    callUrn: string,
    setFormError: (error: string) => void,
    setIsSubmitting: (state: boolean) => void,
    validatePhoneNumber: ReturnType<typeof useCallValidateNumber>,
    type?: CallType,
  ) =>
  async (values: AudioFormModel | VideoFormModel): Promise<void> => {
    if (type) {
      try {
        setIsSubmitting(true);
        const variables: UpdateCallMutationVariables = {
          timezoneUrn: values.timezoneUrn,
          time: values.time,
          firstName: values.firstName,
          lastName: values.lastName,
          boyGirl: values.boyGirl as BoyGirl,
          ageYears: values.ageYears,
          ageMonths: values.ageMonths,
          hobby: values.hobby,
          gift: values.gift,
          friend: values.friend,
          frontDoorId: values.frontDoorId,
          pet1Id: values.pet1Id,
          pet1Name: values.pet1Name,
          pet2Id: values.pet2Id,
          pet2Name: values.pet2Name,
          notes: values.notes,
          callUrn,
        };

        if (isAudioCall(type, values)) {
          variables.phoneNumber = await validatePhoneNumber(
            values.phoneNumber,
            values.phoneCountryUrn,
          );
          variables.phoneCountryUrn = values.phoneCountryUrn;
        }

        await client.mutate<UpdateCallMutation, UpdateCallMutationVariables>({
          mutation: UpdateCallDocument,
          variables,
        });

        history.push(routePaths.myAccount.home);
      } catch (error) {
        if (error instanceof Error) {
          if (isApolloError(error)) {
            if (error.graphQLErrors) {
              setFormError(error.graphQLErrors[0].message);
            }
          } else {
            setFormError(error.message);
          }
        }

        setIsSubmitting(false);
      }
    }
  };

const mpaSlotOptions = (
  data: AvailableCallSlotsResponse[],
  timezone: string,
  originalTimeString: string,
): ISelectOption[] => {
  const times = data.map(d => dateFns.parseISO(d.time));
  const originalTime = dateFns.parseISO(originalTimeString);

  if (!times.some(t => dateFns.isEqual(originalTime, t))) {
    times.push(originalTime);
  }

  return (
    times
      // filter out slots outside of standard hours
      .filter(
        d =>
          d > addDuration(dateFns.startOfDay(d), config.calls.displayTimes.startAt) &&
          d < addDuration(dateFns.startOfDay(d), config.calls.displayTimes.endAt),
      )
      .sort()
      .map<ISelectOption>(s => ({
        label: dateFns.format(utcToZonedTime(s, timezone), "hh:mm a"),
        value: s.toISOString(),
      }))
  );
};

const getDateOfTime = (time: string): Date => dateFns.startOfDay(dateFns.parseISO(time));

const mapDates = (dateData: AvailableCallDatesResponse[], originalTime: string): Date[] => {
  const dates = dateData.map(d => dateFns.parseISO(d.date));
  const originalDate = getDateOfTime(originalTime);

  if (!dates.some(d => dateFns.isEqual(originalDate, d))) {
    dates.push(originalDate);
  }

  return dates.sort();
};

const doLoadDates = async (
  query: ReturnType<typeof useCallsDatesLazyQuery>[0],
  timezoneUrn: string,
  type: CallType,
): Promise<void> => {
  await query({
    variables: {
      startDate: earliestCallDate().toISOString(),
      endDate: lastCallDate().toISOString(),
      timezoneUrn,
      type,
    },
  });
};

const doLoadSlots = async (
  query: ReturnType<typeof useCallsSlotsLazyQuery>[0],
  date: string,
  timezoneUrn: string,
  type: CallType,
): Promise<void> => {
  await query({
    variables: {
      date,
      timezoneUrn,
      type,
    },
  });
};

interface IData {
  isLoading: boolean;
  formProps?: FormProps;
  refetch?(): void;
}

export const useData = (): IData => {
  interface IRouteParams {
    id: string;
  }

  const { id } = useParams<IRouteParams>();
  const callUrn = createUrn(UrnResource.CALL, id);

  const apolloClient = useApolloClient();
  const history = useHistory();
  const [formError, setFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string | undefined>();

  const {
    loading: isLoading,
    data: callData,
    refetch,
    client,
  } = useMyAccountCallQuery({
    fetchPolicy: "network-only",
    variables: {
      callUrn,
      locale: getLocaleForApi(),
    },
  });

  const [loadDates, { loading: isDatesLoading, data: dateData, refetch: refetchDates }] =
    useCallsDatesLazyQuery({ fetchPolicy: "network-only" });
  const [loadSlots, { loading: isSlotsLoading, data: slotData, refetch: refetchSlots }] =
    useCallsSlotsLazyQuery({ fetchPolicy: "network-only" });

  const validatePhoneNumber = useCallValidateNumber(client);

  const value: IData = {
    isLoading,
    ...(!callData && { refetch }),
  };

  const onSubmit = useCallback(
    getSubmitFunction(
      apolloClient,
      history,
      callUrn,
      setFormError,
      setIsSubmitting,
      validatePhoneNumber,
      callData?.call?.type,
    ),
    [apolloClient, callData],
  );

  const onClickBack = useCallback((): void => history.push(routePaths.myAccount.home), []);

  const onChangeCountry = useCallback(
    (countryUrn: string, setField: (field: string, value: string) => void): void => {
      if (!callData?.timezones) {
        return;
      }

      const timezone = callData.timezones.find(t => t.country.urn === countryUrn);

      if (timezone) {
        setField("timezoneUrn", timezone.urn);
      }
    },
    [],
  );

  const onChangeTimezone = useCallback(
    async (timezoneUrn: string): Promise<void> => {
      if (callData?.call) {
        setSelectedTimezone(timezoneUrn);

        await doLoadDates(loadDates, timezoneUrn, callData.call.type);
      }
    },
    [setSelectedTimezone, loadDates],
  );

  const onChangeDate = useCallback(
    async (date: string): Promise<void> => {
      if (callData?.call && selectedTimezone) {
        await doLoadSlots(loadSlots, date, selectedTimezone, callData.call.type);
      }
    },
    [selectedTimezone, loadSlots],
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (callData?.call) {
        setSelectedTimezone(callData.call.timezone.urn);

        await doLoadDates(loadDates, callData.call.timezone.urn, callData.call.type);
        await doLoadSlots(
          loadSlots,
          getDateOfTime(callData.call.time).toISOString(),
          callData.call.timezone.urn,
          callData.call.type,
        );
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load();
  }, [callData?.call]);

  if (callData?.call) {
    value.formProps = {
      type: callData.call.type,
      defaultValues: mapDataToForm(callData.call),
      countryOptions: getCountryOptions(callData.countries),
      timezoneOptions: mapOptions(callData.timezones || [], "urn", "name"),
      frontDoorOptions: mapOptions(callData.frontDoors || [], "id", "label"),
      petOptions: mapOptions(callData?.pets || [], "id", "label"),
      ...(dateData && { dates: mapDates(dateData.availableCallDates, callData.call.time) }),
      ...(slotData?.availableCallSlots &&
        selectedTimezone && {
          slotOptions: mpaSlotOptions(
            slotData.availableCallSlots,
            selectedTimezone,
            callData.call.time,
          ),
        }),
      formError,
      isSubmitting,
      isDatesLoading,
      isSlotsLoading,
      onChangeTimezone,
      onChangeCountry,
      onChangeDate,
      onSubmit,
      onClickBack,
      refetchDates,
      refetchSlots,
    };
  }

  return value;
};
