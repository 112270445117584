/**
 * Stringify a list in English
 * @param list List of items to stringify
 */
export const stringifyList = (list: string[]): string => {
  // check if more than 1 item in list
  if (list.length > 1) {
    return [
      // take all but the last two items of the list if length > 2
      ...(list.length > 2 ? list.slice(0, list.length - 2) : []),
      // join the last two items
      list.slice(list.length - 2).join(" and "),
    ].join(", ");
  }

  // just return the only item
  return list[0];
};
