import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
}

export const FormFieldAgeYears: React.FC<IProps> = ({
  id = "ageYears",
  label = "Child's age",
  placeholder = "Years",
}) => (
  <FormFieldWrapper label={label} required>
    <Field name={id} component={FormikFormInputText} type="number" placeholder={placeholder} />
  </FormFieldWrapper>
);
