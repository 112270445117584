import Bounce from "react-reveal/Bounce";
import styled from "styled-components";

import { NumberBadge } from "../../../atoms/number-badge";

import basket from "./cart.png";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 38px;
  height: 38px;

  img {
    width: 38px;
    height: 38px;
  }
`;

const Badge = styled(NumberBadge)`
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 2;
`;

interface IProps {
  count: number;
  onClick(): void;
}

export const BasketButton: React.FC<IProps> = ({ count, onClick }) => (
  <Container onClick={onClick}>
    {count > 0 && (
      <Bounce>
        <Badge count={count} />
      </Bounce>
    )}
    <img src={basket} alt="A shopping cart" />
  </Container>
);
