import { useState, useMemo, useEffect, useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import { useHistory } from "react-router-dom";

import { getLocaleForApi } from "../../../../utils/graphql";
import { useCallsAddOnsQuery, CallsAddOnsQuery, CallType } from "../../../../types/graphql";
import { callCreateState, callState } from "../../../../model/graphql/cache";
import { routePaths } from "../../../../model/route";
import { useAddOnsLightbox } from "../../../../hooks/add-ons/use-add-ons-lightbox";
import { IAddressFormModel } from "../../../organisms/forms/address-form";

export interface IAddOn {
  alphaId: ProductAlphaId;
  title: string;
  description: string;
  imageUrl: string;
  price: number;
  isPopular: boolean;
  isSelected: boolean;
}

interface IData {
  addOns: IAddOn[];
  images: string[];
}

type NotNulled<T> = { [P in keyof T]: NonNullable<T[P]> };

const filterOutNulled = <T extends object>(o: T): o is NotNulled<T> => {
  for (const value in o) {
    if (value === null) {
      return false;
    }
  }

  return true;
};

const mapData = (
  product: CallsAddOnsQuery["product"],
  selectedAddons: ProductAlphaId[] | undefined,
): IData | undefined => {
  if (!product) {
    return undefined;
  }

  const addOns = product.addOns
    .map(({ alphaId, title, description, image, price, isPopular }) => ({
      alphaId,
      title,
      description,
      image,
      price,
      isPopular,
    }))
    .filter(filterOutNulled)
    .map(({ alphaId, title, description, image, price, isPopular }) => ({
      alphaId: alphaId as ProductAlphaId,
      title,
      description,
      imageUrl: image.url,
      price,
      isPopular,
      isSelected: selectedAddons?.includes(alphaId as ProductAlphaId) || false,
    }));

  return {
    addOns,
    images: addOns.map(p => `${p!.imageUrl!}?w=600`),
  };
};

interface IUseData {
  isLoading: boolean;
  data?: IData;
  isLightboxOpen: boolean;
  lightboxImageIndex: number;
  callQuantity: number;
  isContinueDisabled: boolean;
  refetch?(): void;
  handleClickBack(): void;
  handleToggleAddOn(a: ProductAlphaId): void;
  handleAddToBasketWithAddOns(): void;
  handleAddToBasketWithoutAddOns(): void;
  handleOpenLightbox(index: number): void;
  handleCloseLightbox(): void;
  handleClickPreviousImage(): void;
  handleClickNextImage(): void;
  setAddress(address: IAddressFormModel): void;
  setIsAddressValid(isValid: boolean): void;
}

/**
 * Hook to get data for letter addons
 */
export const useData = (type: CallType): IUseData => {
  const history = useHistory();
  const currentState = useReactiveVar(callCreateState);
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
  const [address, setAddress] = useState<IAddressFormModel | undefined>(undefined);

  useEffect(() => {
    if (!currentState.content) {
      history.push(routePaths.calls[type].recipient);
    }
  }, [currentState]);

  const {
    data: queryData,
    loading: isLoading,
    refetch,
  } = useCallsAddOnsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      alphaId: ProductAlphaId.SANTA_CALL,
      locale: getLocaleForApi(),
    },
  });

  const data = useMemo(
    () => mapData(queryData?.product, currentState.addOns),
    [queryData, currentState.addOns],
  );

  const {
    isLightboxOpen,
    lightboxImageIndex,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
  } = useAddOnsLightbox(data?.images);

  const handleAddToBasketWithAddOns = useCallback(async (): Promise<void> => {
    callState().addToBasket(type, isAddressValid ? address : undefined);

    history.push("/basket");
  }, [history, address, isAddressValid]);
  const handleAddToBasketWithoutAddOns = useCallback(async (): Promise<void> => {
    callState().addToBasket(type);

    history.push("/basket");
  }, [history]);

  const handleClickBack = useCallback(
    () => history.push(routePaths.calls[type].recipient),
    [history],
  );

  return {
    isLoading,
    data,
    isLightboxOpen,
    lightboxImageIndex,
    callQuantity: currentState.isSecondCallSlotEnabled ? 2 : 1,
    isContinueDisabled: !!(
      currentState.addOns?.includes(ProductAlphaId.CALL_CERTIFICATE) && !isAddressValid
    ),
    handleAddToBasketWithAddOns,
    handleAddToBasketWithoutAddOns,
    handleToggleAddOn: callState().toggleAddOn,
    handleClickBack,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
    setAddress,
    setIsAddressValid,
    ...(!data && { refetch }),
  };
};
