import ReactMarkdown from "react-markdown";
import { Container } from "react-grid-system";
import styled from "styled-components";

import { ContentHeader } from "../../../molecules/content-header";
import { ContentSection } from "../../../organisms/content-sections/content-section";
import { device } from "../../../../utils/media-queries";
import { DataLoadedContainerOld, checkData } from "../../../control/data-loaded-container";
import { getLocaleForApi } from "../../../../utils/graphql";
import { useContentPageQuery } from "../../../../types/graphql";

const IntroContentContainer = styled(Container)`
  @media ${device.mobileS} {
    padding: 60px 0;
  }

  @media ${device.laptopL} {
    padding: 60px 0 60px 0;
  }
`;

export const Terms: React.FC = () => {
  const response = useContentPageQuery({
    variables: { alphaId: "TERMS", locale: getLocaleForApi() },
    fetchPolicy: "cache-and-network",
  });

  return (
    <DataLoadedContainerOld response={response}>
      {checkData(response.data) && (
        <>
          <ContentHeader
            title={response.data.page!.title!}
            imageUrl={response.data.page!.headerImage!.url!}
          />
          <ContentSection>
            <IntroContentContainer>
              <ReactMarkdown source={response.data.page!.sections![0]!.body!} />
            </IntroContentContainer>
          </ContentSection>
        </>
      )}
    </DataLoadedContainerOld>
  );
};
