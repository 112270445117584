import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { PlayButton } from "../../atoms/play-button";

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media ${device.mobileS} {
    width: 285px;
    height: 285px;
  }

  @media ${device.laptopL} {
    width: 376px;
    height: 376px;
  }

  > img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transition: all 0.5s;
  }

  &:hover {
    > svg {
      transform: scale(1.5);
    }

    > img {
      opacity: 0.7;
    }
  }
`;

const OverlayPlayButton = styled(PlayButton)`
  z-index: 2;
  transition: all 0.5s;
`;

interface IProps {
  onClick(): void;
}

export const VideoCircleButton: React.FC<IProps> = ({ onClick, children }) => (
  <Container onClick={onClick}>
    {children}
    <OverlayPlayButton />
  </Container>
);
