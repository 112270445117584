import React, { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import { Col, Container, Row, Visible, Hidden } from "react-grid-system";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { LocationDescriptor } from "history";

import {
  getBasketItemDescription,
  getBasketItemPrice,
  getBasketTotal,
  getProductLookup,
  getTemplateLookup,
} from "../../../model/basket";
import { getLocaleForApi } from "../../../utils/graphql";
import { displayPrice } from "../../../utils/price";
import { BowButton } from "../../atoms/bow-button";
import { MainWrapper } from "../../atoms/containers/main-wrapper";
import { EmptyBasket } from "../../atoms/empty-basket";
import { Heading2, Paragraph } from "../../atoms/text";
import { BackLink } from "../../molecules/back-link";
import { BasketItemRow } from "../../organisms/basket-item-row";
import { MobileBackLink } from "../../molecules/mobile-back-link";
import { device } from "../../../utils/media-queries";
import { CallOutPanel } from "../../atoms/callout-panel";
import { ILocationState } from "../santa-letter/santa-letter-details/santa-letter-details-sending";
import { routePaths } from "../../../model/route";
import { useBasketQuery } from "../../../types/graphql";
import { basketItemState, basketState } from "../../../model/graphql/cache";
import { DataLoadedContainer } from "../../control/data-loaded-container";

const BackLinkCol = styled(Col)`
  span {
    margin-left: 9px;
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }
`;

const HeaderRow = styled(Row)`
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  color: ${({ theme }): string => theme.colours.textSubdued};
  margin-bottom: 34px;
`;

const Products = styled.div`
  @media ${device.mobileS} {
    margin-top: 10px;
  }

  > * {
    :not(:last-child) {
      [data-santa-id="border-bottom"] {
        height: 0;
      }
    }

    :last-child {
      [data-santa-id="border-bottom"] {
        height: 3px;
      }
    }
  }
`;

const TotalCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
    padding: 18px 0;
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size22};
    padding: 25px 0;
  }

  span {
    margin-left: 5px;
    font-weight: 600;
    color: ${({ theme }): string => theme.colours.alert};
    font-size: ${({ theme }): string => theme.fontSizes.size24};
  }
`;

const CheckoutButtonCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const Basket: React.FC = () => {
  const history = useHistory();
  const basketItems = useReactiveVar(basketItemState);

  // fetch basket data
  const { data, refetch } = useBasketQuery({
    variables: {
      locale: getLocaleForApi(),
      alphaIds: basketItems.reduce<ProductAlphaId[]>(
        (acc, i) => [...acc, ...i.addOns, i.productAlphaId],
        [],
      ),
      letterTemplateIds: basketItems
        .filter(b => b.productAlphaId === ProductAlphaId.SANTA_LETTER)
        .map<string>(i => i.letter?.templateId || ""),
      textTemplateIds: basketItems
        .filter(b => b.productAlphaId === ProductAlphaId.SANTA_TEXT)
        .map<string>(i => i.text?.templateId || ""),
      firstNameIds: [
        ...basketItems
          .filter(b => b.productAlphaId === ProductAlphaId.SANTA_VIDEO_MESSAGE)
          .map<string>(i => i.video?.firstNameId || ""),
      ],
    },
  });

  const handleClickContinueShopping = useCallback((): void => history.push("/"), [history]);

  const handleClickGoToCheckout = useCallback((): void => history.push("/checkout"), [history]);

  let element: JSX.Element;
  let headerLink: JSX.Element | null = null;

  if (basketItems.length > 0 && data) {
    // get lookups from data
    const productLookup = getProductLookup(data.products);
    const letterTemplateLookup = getTemplateLookup(data.letterTemplates);
    const textTemplateLookup = getTemplateLookup(data.textTemplates);
    const firstNameLookup = getTemplateLookup(data.firstNames);

    // get last letter from basket to shown 'book again to ...' hint
    let bookAgain: JSX.Element | null = null;

    const letters = basketItems.filter(i => i.productAlphaId === ProductAlphaId.SANTA_LETTER);

    if (letters.length > 0) {
      const lastLetter = letters[letters.length - 1];
      const link: LocationDescriptor<ILocationState> = {
        pathname: "/santa-letter/details",
        state: {
          address: {
            line1: lastLetter.letter!.addressLine1,
            line2: lastLetter.letter!.addressLine2!,
            town: lastLetter.letter!.addressTown,
            county: lastLetter.letter!.addressCounty!,
            usStateUrn: lastLetter.letter!.addressUsStateUrn!,
            postcode: lastLetter.letter!.addressPostcode,
            countryUrn: lastLetter.letter!.addressCountryUrn,
          },
        },
      };

      bookAgain = (
        <CallOutPanel shadow>
          <Link to={link}>
            Book another letter to {lastLetter.letter!.addressLine1},{" "}
            {lastLetter.letter!.addressTown}
          </Link>
        </CallOutPanel>
      );
    }

    const items = basketItems.map((item, i) => {
      const subTitle = getBasketItemDescription(item, {
        letterTemplate: letterTemplateLookup,
        text: textTemplateLookup,
        firstName: firstNameLookup,
        phoneCountryCode: {},
      });

      const handleRemoveItem = async (): Promise<void> => {
        basketState().removeFromBasket(i);
      };

      const additionalItems = item.addOns.map(a => (
        <>
          {productLookup[a!]!.title!} <em>{displayPrice(productLookup[a!]!.price!)}</em>
        </>
      ));

      const title = (
        <>
          {productLookup[item.productAlphaId!]!.title!}{" "}
          <em>{displayPrice(productLookup[item.productAlphaId!]!.price!)}</em>
        </>
      );

      return (
        <BasketItemRow
          key={i}
          subTitle={subTitle}
          additionalItems={additionalItems}
          title={title}
          price={getBasketItemPrice(item, productLookup)}
          imageUrl={productLookup[item.productAlphaId!]!.image!.url!}
          // eslint-disable-next-line react/jsx-no-bind
          onClickRemove={handleRemoveItem}
        />
      );
    });

    element = (
      <>
        {bookAgain}

        <CallOutPanel shadow noDecoration={Boolean(bookAgain)}>
          <Paragraph>
            Continue shopping for <Link to={"/santa-letter"}>Santa Letter</Link>,{" "}
            <Link to={routePaths.video.home}>Santa Video Message</Link>,{" "}
            <Link to={routePaths.calls.VIDEO.home}>Santa Zoom Call</Link>,{" "}
            <Link to={routePaths.calls.AUDIO.home}>Santa Phone Call</Link>,{" "}
            <Link to={"/santa-text"}>Santa Text</Link> or <Link to={"/santa-sack"}>Santa Sack</Link>
            .
          </Paragraph>
        </CallOutPanel>

        <Visible xl>
          <HeaderRow>
            <Col lg={10}>Item</Col>
            <Col lg={2}>Price</Col>
          </HeaderRow>
        </Visible>

        <Products>{items}</Products>

        <Row>
          <TotalCol>
            Total: <span>{displayPrice(getBasketTotal(basketItems, productLookup))}</span>
          </TotalCol>
        </Row>

        <Row align="center">
          <Hidden xs>
            <Col lg={6}>
              <BackLink label="Continue Shopping" onClick={handleClickContinueShopping} />
            </Col>
          </Hidden>
          <CheckoutButtonCol lg={6}>
            <BowButton size={430} onClick={handleClickGoToCheckout}>
              Proceed to Checkout
            </BowButton>
          </CheckoutButtonCol>
        </Row>
      </>
    );
  } else {
    element = <EmptyBasket />;

    headerLink = (
      <Visible xl>
        <Row>
          <BackLinkCol>
            <BackLink label="Back to Home" onClick={handleClickContinueShopping} />
          </BackLinkCol>
        </Row>
      </Visible>
    );
  }

  return (
    <MainWrapper>
      <Container>
        <MobileBackLink onClick={handleClickContinueShopping}>Back to Home</MobileBackLink>
        <DataLoadedContainer isLoading={!data} refetch={!data ? refetch : undefined}>
          {headerLink}
          <Row>
            <Col>
              <Heading2>Shopping Basket</Heading2>
            </Col>
          </Row>

          {element}
        </DataLoadedContainer>
      </Container>
    </MainWrapper>
  );
};
