import { ISelectMultilineOption } from "@santa/common/lib/utils/form";
import * as CSS from "csstype";
import { FieldProps } from "formik";
import React, { CSSProperties, useCallback } from "react";
import Select from "react-select";
import { IndicatorProps } from "react-select/src/components/indicators";
import { OptionProps } from "react-select/src/components/Option";
import { Props as SelectProps } from "react-select/src/Select";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import styled from "styled-components";

import { theme } from "../../../../theme";

const DropdownIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 3px;
  }
`;

const OptionContainer = styled.div`
  padding: 5px 10px;
  cursor: default;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const OptionLabel = styled.div``;

const OptionDescription = styled.div`
  margin-top: 2px;
  font-size: ${({ theme }): string => theme.fontSizes.size14};
  color: ${({ theme }): string => theme.colours.textSubdued};
`;

type Props = SelectProps<ISelectMultilineOption> & {
  isError?: boolean;
};

const DropdownIndicator: React.FC<IndicatorProps<ISelectMultilineOption, false>> = props => {
  const { innerProps } = props;

  return (
    <DropdownIndicatorContainer {...innerProps}>
      <img
        alt="caret"
        src="data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.81088 4.8764C4.41175 5.42946 3.58825 5.42946 3.18912 4.87641L0.813896 1.58521C0.336597 0.923842 0.809173 0 1.62478 0L6.37522 0C7.19083 0 7.6634 0.923841 7.1861 1.58521L4.81088 4.8764Z' fill='%2390A4AE'/%3E%3C/svg%3E%0A"
      />
    </DropdownIndicatorContainer>
  );
};

const IndicatorSeparator = (): JSX.Element => <div />;

const Option: React.FC<OptionProps<ISelectMultilineOption, false>> = props => {
  const { innerProps, innerRef } = props;

  return (
    <OptionContainer ref={innerRef} {...innerProps}>
      <OptionLabel>{props.label}</OptionLabel>
      <OptionDescription>{props.data.description}</OptionDescription>
    </OptionContainer>
  );
};

export const FormInputSelectMultiline: React.FC<Props> = props => {
  const selectProps: SelectProps<ISelectMultilineOption> = {
    ...props,
    blurInputOnSelect: true,
    styles: {
      container: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        flex: 1,
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control: (provided: CSSProperties, state: any): CSSProperties => {
        let borderColor = theme.colours.form.placeholder;

        if (state.isFocused) {
          borderColor = theme.colours.spotGold;
        } else if (props.isError) {
          borderColor = theme.colours.alert;
        }

        const styles: CSSProperties & {
          "&:hover"?: CSS.Properties<string | number>;
        } = {
          ...provided,
          borderColor,
          minWidth: "100px",
          padding: "5px 11px 6px 7px",
          boxSizing: "border-box",
          fontFamily: `${theme.fonts.main}`,
          fontSize: `${theme.fontSizes.size16}`,
          boxShadow: undefined,
          minHeight: undefined,
          "&:hover": {
            borderColor,
          },
        };

        return styles;
      },
    },
    theme: t => ({ ...t, borderRadius: 6 }),
    components: { DropdownIndicator, IndicatorSeparator, Option },
  };

  return <Select {...selectProps} />;
};

export const FormikFormInputSelectMultiline: React.FC<
  FieldProps &
    SelectProps<ISelectMultilineOption> & {
      options: ISelectMultilineOption[];
    }
> = ({
  field: { name, value, onBlur },
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const isError = Boolean(touched[name] && errors[name]);
  const setFieldFocussed = useCallback((): void => setFieldTouched(name), [setFieldTouched, name]);
  const handleChange = useCallback(
    (option: ValueType<OptionTypeBase, false>): void => setFieldValue(name, option),
    [setFieldValue, name],
  );

  return (
    <FormInputSelectMultiline
      name={name}
      onBlur={onBlur}
      onFocus={setFieldFocussed}
      isError={isError}
      onChange={handleChange}
      placeholder={props.placeholder}
      value={value}
      options={props.options}
      isSearchable={false}
    />
  );
};
