import * as dateFns from "date-fns";

import { IDescriptionAddOn, getAddOnDescription } from "../common";

export interface ITextSearchDocument {
  urn: string;
  id: string;
  user: {
    urn: string;
    firstName?: string;
    lastName?: string;
    email: string;
  };
  pet: {
    type?: string;
    name?: string;
  };
  firstName: string;
  boyGirl: string;
  age: {
    years?: number;
  };
  phoneCountry: {
    urn: string;
    name: string;
  };
  timezone: {
    urn: string;
    timezone: string;
    country: string;
  };
  phoneNumber: string;
  template: {
    id: string;
    label?: string;
  };
  text1: {
    time: string;
    body: string;
  };
  text2: {
    time: string;
    body: string;
  };
  text3: {
    time: string;
    body: string;
  };
  bonusBody?: string;
  suggest: string[];
}

interface IGetTextDescriptionParams {
  addOnProducts: IDescriptionAddOn[];
  recipientName?: string | null;
  phoneNumber: string;
  timezone: string;
  textTime: Date;
}

/**
 * Return a description for a letter given some parameters to describe it
 * @param params Params for letter
 */
export const getTextDescription = (params: IGetTextDescriptionParams): string => `for ${
  params.recipientName
} to be texted starting at \
${dateFns.format(params.textTime, "h:mm a")} on ${dateFns.format(params.textTime, "PP")} \
to number ${params.phoneNumber} in ${params.timezone}.\
${getAddOnDescription(params.addOnProducts)}`;

/**
 * Round time to nearest x minutes
 * From: https://github.com/date-fns/date-fns/issues/787#issuecomment-405834116
 * @param date Date to round
 * @param interval Minutes to round to
 * @returns New date, after rounding
 */
const roundToNearestMinutes = (date: Date, interval: number): Date => {
  const roundedMinutes = Math.floor(dateFns.getMinutes(date) / interval) * interval;
  return dateFns.setMinutes(dateFns.startOfMinute(date), roundedMinutes);
};

const FIRST_TEXT_MONTH = 9;
const FIRST_TEXT_DAY = 2;
const LAST_TEXT_MONTH = 11;
const LAST_TEXT_DAY = 31;

/**
 * Calculate the earliest time that Santa Texts can be sent
 */
export const earliestTextSendTime = (): Date => {
  const nextTextTime = roundToNearestMinutes(dateFns.add(Date.now(), { hours: 2 }), 5);

  const nextTextTimeMonth = dateFns.getMonth(nextTextTime);
  const nextTextTimeDay = dateFns.getDate(nextTextTime);
  const nextTextTimeYear = dateFns.getYear(nextTextTime);

  if (nextTextTimeMonth >= LAST_TEXT_MONTH && nextTextTimeDay > LAST_TEXT_DAY) {
    // date is after last text time - set to first call time next year
    return dateFns.addHours(new Date(nextTextTimeYear + 1, FIRST_TEXT_MONTH, FIRST_TEXT_DAY), 7);
  }

  if (
    nextTextTimeMonth < FIRST_TEXT_MONTH ||
    (nextTextTimeMonth === FIRST_TEXT_MONTH && nextTextTimeDay < FIRST_TEXT_DAY)
  ) {
    // date is before first text time - set to first send text time this year
    return dateFns.addHours(new Date(nextTextTimeYear, FIRST_TEXT_MONTH, FIRST_TEXT_DAY), 7);
  }

  // the next text time is OK
  return nextTextTime;
};

/**
 * Calculate the last time that Santa Texts can be made
 */
export const lastTextSendTime = (): Date => {
  const nextTextTime = dateFns.startOfDay(Date.now());

  const nextTextTimeMonth = dateFns.getMonth(nextTextTime);
  const nextTextTimeDay = dateFns.getDate(nextTextTime);
  const nextTextTimeYear = dateFns.getYear(nextTextTime);

  // Set year to this year unless next call date is after the last call date
  const year =
    nextTextTimeMonth >= LAST_TEXT_MONTH && nextTextTimeDay > LAST_TEXT_DAY
      ? nextTextTimeYear + 1
      : nextTextTimeYear;

  return dateFns.addHours(new Date(year, LAST_TEXT_MONTH, LAST_TEXT_DAY), 23);
};
