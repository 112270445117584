import React, { useCallback, useState } from "react";
import ModalVideo from "react-modal-video";
import styled from "styled-components";
import "react-modal-video/css/modal-video.min.css";

import { config } from "../../../config";

const Container = styled.div`
  .modal-video {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

type VideoType = keyof typeof config.videos;

interface IVideoContext {
  show(id: VideoType): void;
  isOpen: boolean;
}

export const VideoContext = React.createContext<IVideoContext>({
  show: () => {
    // empty
  },
  isOpen: false,
});

export const VideoProvider: React.FC = ({ children }) => {
  const [videoId, setVideoId] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseVideo = useCallback((): void => setIsOpen(false), [setIsOpen]);

  const handleShowVideo = useCallback(
    (id: VideoType): void => {
      setVideoId(config.videos[id]);
      setIsOpen(true);
    },
    [setVideoId, setIsOpen],
  );

  const context: IVideoContext = {
    show: handleShowVideo,
    isOpen,
  };

  return (
    <VideoContext.Provider value={context}>
      <Container>
        <ModalVideo
          videoId={videoId}
          isOpen={isOpen}
          onClose={handleCloseVideo}
          youtube={{
            autoplay: 1,
            mute: 1,
          }}
        />
        {children}
      </Container>
    </VideoContext.Provider>
  );
};
