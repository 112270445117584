import React from "react";
import styled from "styled-components";

const DELAY_SECONDS = 0.5;

interface IModalDivProps {
  isShowing: boolean;
  isTransitioning: boolean;
}

const ModalDiv = styled.div<IModalDivProps>`
  position: fixed;
  z-index: 4000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(
    0,
    0,
    0,
    ${(props: IModalDivProps): number => (props.isTransitioning ? 0.8 : 0)}
  );
  ${({ isShowing }): string => (isShowing ? "" : "transform: translateX(-100%);")}
  transition: background-color ease-out ${DELAY_SECONDS}s;
`;

interface IState {
  isTransitioning: boolean;
  isShowing: boolean;
}

export class ModalOverlay extends React.Component<{}, IState> {
  public state: IState = {
    isTransitioning: false,
    isShowing: false,
  };

  public show = (): void => {
    this.setState({ ...this.state, isTransitioning: true, isShowing: true });
  };

  public hide = (): void => {
    this.setState({ ...this.state, isTransitioning: false });
    setTimeout(() => {
      this.setState({ ...this.state, isShowing: false });
    }, DELAY_SECONDS * 1000);
  };

  public render(): JSX.Element {
    return <ModalDiv {...this.state} />;
  }
}
