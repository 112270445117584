import { earliestTextSendTime } from "@santa/common/lib/products/texts";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import * as dateFns from "date-fns";

import { TextsDetailsMessagesForm } from "../../../organisms/forms/texts-details-messages";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { useTextsMessagesQuery } from "../../../..//types/graphql";
import { routePaths } from "../../../../model/route";
import { textCreateState, textState } from "../../../../model/graphql/cache";
import { getLocaleForApi } from "../../../../utils/graphql";
import { useTextMessages } from "../../../../hooks/text/use-text-messages";

type FormProps = React.ComponentProps<typeof TextsDetailsMessagesForm>;
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapDataToForm = ({
  messages,
}: ReturnType<typeof textCreateState>): FormProps["defaultValues"] => {
  if (messages) {
    return {
      firstName: messages.firstName,
      ageYears: messages.ageYears?.toString() || "",
      boyGirl: messages.boyGirl,
      town: messages.town || "",
      petId: messages.petId || "",
      petName: messages.petName || "",
      templateId: messages.templateId,
      text1TimeDate: messages.text1TimeDate,
      text1TimeTime: messages.text1TimeTime,
      text1Body: messages.text1Body,
      text2TimeDate: messages.text2TimeDate as Date,
      text2TimeTime: messages.text2TimeTime,
      text2Body: messages.text2Body,
      text3TimeDate: messages.text3TimeDate as Date,
      text3TimeTime: messages.text3TimeTime,
      text3Body: messages.text3Body,
    };
  }

  return {
    text1TimeDate: dateFns.startOfDay(dateFns.add(earliestTextSendTime(), { hours: 1 })),
    text1TimeTime: dateFns.format(dateFns.add(earliestTextSendTime(), { hours: 1 }), "HH:mm"),
    text2TimeDate: dateFns.startOfDay(dateFns.add(earliestTextSendTime(), { hours: 5 })),
    text2TimeTime: dateFns.format(dateFns.add(earliestTextSendTime(), { hours: 5 }), "HH:mm"),
    text3TimeDate: dateFns.startOfDay(dateFns.add(earliestTextSendTime(), { hours: 9 })),
    text3TimeTime: dateFns.format(dateFns.add(earliestTextSendTime(), { hours: 9 }), "HH:mm"),
  };
};

interface IUseData {
  formProps?: FormProps;
  loaderProps: LoaderProps;
}

export const useData = (): IUseData => {
  const history = useHistory();
  const goBack = (): void => history.push(routePaths.text.details);

  const currentState = textCreateState();
  useEffect(() => {
    if (!currentState.details) {
      goBack();
    }
  }, [history, currentState]);

  const {
    data,
    loading: isLoading,
    refetch: dataRefetch,
    error,
  } = useTextsMessagesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      locale: getLocaleForApi(),
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const { onChangeSourceField, onChangeTemplate, templateOptions, petOptions } = useTextMessages({
    templates: data?.textTemplates,
    pets: data?.pets,
  });

  const onChange = useCallback<FormProps["onChange"]>(
    debounce(values => textState().setMessages(values), 500),
    [],
  );

  const onSubmit = useCallback<FormProps["onSubmit"]>(
    values => {
      textState().setMessages(values);
      history.push(routePaths.text.bonus);
    },
    [history],
  );

  const onClickBack = useCallback(goBack, [history]);

  const defaultValues = useMemo(() => mapDataToForm(currentState), []);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && error && { refetch }),
    },
    ...(data && {
      formProps: {
        defaultValues,
        templateOptions,
        petOptions,
        onSubmit,
        onClickBack,
        onChange,
        onChangeSourceField,
        onChangeTemplate,
      },
    }),
  };
};
