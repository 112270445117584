import { useParams } from "react-router-dom";

interface IProps {
  link: string;
}

export const ExternalRedirect: React.FC<IProps> = ({ link }) => {
  const { remainder } = useParams<{ remainder: string }>();

  window.location.replace(`${link}${remainder}`);

  return null;
};
