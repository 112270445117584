import { Field, Form, FormikProps, withFormik } from "formik";
import { Col, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import * as Yup from "yup";

import { BowButton } from "../../../atoms/bow-button";
import { Paragraph } from "../../../atoms/text";
import { FormikFormInputText } from "../../../molecules/form/form-input-text";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { FormFieldLink } from "../../../atoms/form-field-link";
import { BackLink } from "../../../molecules/back-link";

const SubmitCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FormSchema = Yup.object().shape({
  email: Yup.string().max(50, "Maximum 50 characters").email().required("Required"),

  password: Yup.string().required("Required"),
});

export interface IFormModel {
  email: string;
  password: string;
}

interface IOwnProps {
  formError?: string;
  onSubmit(values: IFormModel): void;
  onClickBack(): void;
  onClickForgotPassword(): void;
}

type Props = FormikProps<IFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const { submitForm } = props;

  return (
    <Form>
      {props.formError && <Error>{props.formError}</Error>}
      <Row>
        <Col>
          <FormFieldWrapper label="Email address" name="email" required>
            <Field
              name="email"
              component={FormikFormInputText}
              placeholder="Enter your email address"
              required
            />
          </FormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldWrapper label="Password" name="password" required>
            <FieldWrapper>
              <Field
                name="password"
                type="password"
                component={FormikFormInputText}
                placeholder="Enter your password"
                required
              />
              <FormFieldLink onClick={props.onClickForgotPassword}>
                Forgot your password?
              </FormFieldLink>
            </FieldWrapper>
          </FormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <SubmitCol>
          <Visible md lg xl>
            <BackLink label="Back" onClick={props.onClickBack} />
          </Visible>
          <BowButton size={210} onClick={submitForm}>
            Log In
          </BowButton>
        </SubmitCol>
      </Row>
    </Form>
  );
};

export const LogInForm = withFormik<IOwnProps, IFormModel>({
  validationSchema: FormSchema,
  mapPropsToValues: (): IFormModel => ({
    email: "",
    password: "",
  }),
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values);
  },
})(C);
