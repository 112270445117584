import styled from "styled-components";
import { rgba } from "polished";

import { CarouselArrow } from "../../../atoms/icons/carousel-arrow";
import { device } from "../../../../utils/media-queries";

const border = (direction: string, colour: string): string =>
  `border-${direction}: ${rgba(colour, 0.5)} 4px dashed;`;

const borderRadius = (direction: string): string =>
  `border-top-${direction}-radius: 6px; border-bottom-${direction}-radius: 6px;`;

const Container = styled.div<IProps>`
  cursor: pointer;

  @media ${device.mobileS} {
    width: 40px;
    height: 22px;
  }

  @media ${device.tablet} {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 284px;
    ${({ direction }): string => borderRadius(direction)}
    ${({ theme }): string => border("top", theme.colours.spotGold)}
    ${({ theme }): string => border("bottom", theme.colours.spotGold)}
    ${({ direction, theme }): string => border(direction, theme.colours.spotGold)}
  }
`;

interface IProps {
  direction: "left" | "right";
  onClick?(): void;
}

export const CarouselScrollButton: React.FC<IProps> = ({ direction, onClick }) => (
  <Container direction={direction} onClick={onClick}>
    <CarouselArrow direction={direction} />
  </Container>
);
