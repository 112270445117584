import { useEffect, useState, useContext, useCallback } from "react";
import { Col, Container, Row, Hidden } from "react-grid-system";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import { debounce } from "lodash";

import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { LetterPreview, ILetterPreviewUpdateArgs } from "../../../organisms/letter-preview";
import { MobileOverlay } from "../../../organisms/mobile-overlay";
import { ShowNavContext } from "../../../contexts/show-nav-context";
import { useLetterFormData } from "../../../../hooks/letter/use-letter-form-data";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { LetterPreviewPlaceholder } from "../../../atoms/letter-preview-placeholder";
import { routePaths } from "../../../../model/route";

import { SantaLetterDetailsContent } from "./santa-letter-details-content";
import { SantaLetterDetailsSending } from "./santa-letter-details-sending";

const PreviewPlaceholder = styled(LetterPreviewPlaceholder)`
  margin-top: 30px;
`;

const MobilePreviewContainer = styled.div`
  canvas {
    width: 100vw;
  }
`;

const PreviewWrapper = styled.div`
  position: sticky;
  top: 0;

  canvas {
    width: 100%;
  }
`;

export const SantaLetterDetails: React.FC = () => {
  const { hideNav, showNav } = useContext(ShowNavContext);
  const [shouldShowMobilePreview, setShouldShowMobilePreview] = useState(false);
  const [previewProps, setPreviewProps] = useState<ILetterPreviewUpdateArgs>();
  const { data, isLoading, refetch, getPreview } = useLetterFormData();

  const updatePreview = async (): Promise<void> => setPreviewProps(await getPreview());

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updatePreview();
  }, [getPreview]);

  const handleUpdateDetails = useCallback(updatePreview, [getPreview]);

  const handleShowMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(true);
    hideNav();
  }, [setShouldShowMobilePreview, hideNav]);

  const handleHideMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(false);
    showNav();
  }, [setShouldShowMobilePreview, showNav]);

  const sendingForm = useCallback(
    (): JSX.Element => (
      <SantaLetterDetailsSending
        internationalSurcharge={data.internationalSurcharge}
        onClickOpenMobilePreview={handleShowMobilePreview}
        updateSendingDetails={debounce(handleUpdateDetails, 500)}
      />
    ),
    [handleShowMobilePreview, handleUpdateDetails, data.internationalSurcharge],
  );

  const contentForm = useCallback(
    (): JSX.Element => (
      <SantaLetterDetailsContent
        templateOptions={data.templates}
        psOptions={data.ps}
        signatureOptions={data.signatures}
        petOptions={data.pets}
        frontDoorOptions={data.frontDoors}
        onClickOpenMobilePreview={handleShowMobilePreview}
        onChange={debounce(handleUpdateDetails, 500)}
      />
    ),
    [
      handleShowMobilePreview,
      handleUpdateDetails,
      data.templates,
      data.ps,
      data.signatures,
      data.pets,
      data.frontDoors,
    ],
  );

  return (
    <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
      <MainWrapper>
        <Container>
          <Row>
            <Col md={6}>
              <Switch>
                <Route exact path={routePaths.letter.recipient} render={sendingForm} />
                <Route exact path={routePaths.letter.content} render={contentForm} />
                <Redirect to={routePaths.letter.types.classic} />
              </Switch>
            </Col>
            <Hidden xs>
              <Col md={6}>
                <PreviewWrapper>
                  {previewProps ? <LetterPreview params={previewProps} /> : <PreviewPlaceholder />}
                </PreviewWrapper>
              </Col>
            </Hidden>
          </Row>
        </Container>
        {shouldShowMobilePreview && previewProps && (
          <MobileOverlay onClickClose={handleHideMobilePreview}>
            <MobilePreviewContainer>
              <LetterPreview params={previewProps} />
            </MobilePreviewContainer>
          </MobileOverlay>
        )}
      </MainWrapper>
    </DataLoadedContainer>
  );
};
