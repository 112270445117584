import { ProductAlphaId } from "@santa/common/lib/products/common";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { LetterHomePageQuery, useLetterHomePageQuery } from "../../../../types/graphql";
import { LetterHomeOption, routePaths } from "../../../../model/route";
import { getLocaleForApi } from "../../../../utils/graphql";

const mapProductIdToOption = (type: ProductAlphaId): LetterHomeOption => {
  switch (type) {
    case ProductAlphaId.COOKIE_MIX:
      return "cookieMix";
    case ProductAlphaId.MAGIC_SNOW:
      return "magicSnow";
    case ProductAlphaId.SANTA_PLUSH:
      return "plush";
    case ProductAlphaId.SANTA_SACK:
      return "santaSack";
    case ProductAlphaId.SANTA_LETTER:
      return "classic";
    default:
      throw new Error("Product not recognised");
  }
};

const alphaIds = [
  ProductAlphaId.SANTA_LETTER,
  ProductAlphaId.SANTA_PLUSH,
  ProductAlphaId.COOKIE_MIX,
  ProductAlphaId.MAGIC_SNOW,
  ProductAlphaId.SANTA_SACK,
];

type UseData = () => {
  data?: {
    baubleContent: string;
    title: string;
    price: number;
    handleClick(): void;
    imageUUrl: string;
  }[];
  isLoading: boolean;
  refetch?(): void;
};

const mapData = (
  history: ReturnType<typeof useHistory>,
  data: LetterHomePageQuery["allProducts"] | undefined,
): ReturnType<UseData>["data"] => {
  if (!data) {
    return undefined;
  }

  const classicPrice = data.find(p => p.alphaId === ProductAlphaId.SANTA_LETTER)?.price;

  if (!classicPrice) {
    throw new Error("Classic price not found");
  }

  return data
    .filter((p): p is LetterHomePageQuery["allProducts"][number] => !!p)
    .sort(
      (a, b) =>
        alphaIds.indexOf(a.alphaId as ProductAlphaId) -
        alphaIds.indexOf(b.alphaId as ProductAlphaId),
    )
    .map(p => {
      const isClassic = p.alphaId === ProductAlphaId.SANTA_LETTER;

      return {
        title: p.title ? (isClassic ? `${p.title} Classic` : `with ${p.title}`) : "",
        baubleContent: p.letterHomePageDescription || "",
        price: isClassic ? classicPrice : p.price + classicPrice,
        imageUUrl: p.image?.url || "",
        handleClick: () =>
          history.push(
            routePaths.letter.types[mapProductIdToOption(p.alphaId as ProductAlphaId)] || "",
          ),
      };
    });
};

export const useData: UseData = () => {
  const history = useHistory();

  // graphql data
  const {
    data: queryData,
    loading: isLoading,
    refetch,
  } = useLetterHomePageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      alphaIds,
      locale: getLocaleForApi(),
    },
  });

  const data = useMemo(() => mapData(history, queryData?.allProducts), [queryData]);

  return {
    data,
    isLoading,
    ...(!data && { refetch }),
  };
};
