import { ISelectOption } from "@santa/common/lib/utils/form";
import * as yup from "yup";
import { Col, Row } from "react-grid-system";

import { FormFieldPhoneNumber } from "../../../molecules/form/form-fields/form-field-phone-number";
import { FormikFormInputSelect } from "../../../molecules/form/form-input-select";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { Field } from "formik";

export const validationRules = {
  phoneCountryUrn: yup.string().required("Required"),
  phoneNumber: yup.string().max(25).required("Required"),
  timezoneUrn: yup.string().required("Required"),
};

export interface IFormSectionModel {
  phoneCountryUrn: string;
  phoneNumber: string;
  timezoneUrn: string;
}

interface IProps {
  timezoneOptions: ISelectOption[];
  countryOptions: ISelectOption[];
  onChangePhoneCountry(urn: string): void;
}

export const FormSectionTextPhone: React.FC<IProps> = ({
  timezoneOptions,
  countryOptions,
  onChangePhoneCountry,
}) => (
  <>
    <Row>
      <Col>
        <FormFieldPhoneNumber
          onChangeCountry={onChangePhoneCountry}
          countryOptions={countryOptions}
        />
      </Col>
    </Row>
    {timezoneOptions.length && (
      <Row>
        <Col>
          <FormFieldWrapper label="Country and Time zone?" required name="timezoneUrn">
            <Field
              name="timezoneUrn"
              component={FormikFormInputSelect}
              options={timezoneOptions}
              required
            />
          </FormFieldWrapper>
        </Col>
      </Row>
    )}
  </>
);
