import { earliestSackSendDate } from "@santa/common/lib/products/sack";
import { useCallback, useContext, useMemo } from "react";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import { useHistory } from "react-router-dom";
import { VideoContext } from "../../../contexts/video-context";
import { debounce } from "lodash";
import * as dateFns from "date-fns";

import { getLocaleForApi } from "../../../../utils/graphql";
import { useSackHomePageQuery } from "../../../../types/graphql";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { VideoSquareButton } from "../../../molecules/video-square-button";
import { SackRecipientForm } from "../../../organisms/forms/sack-recipient";
import { sackCreateState, sackState } from "../../../../model/graphql/cache";
import { config } from "../../../../config";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";

type VideoSquareProps = React.ComponentProps<typeof VideoSquareButton>;
type FormProps = React.ComponentProps<typeof SackRecipientForm>;

const mapDataToForm = (): FormProps["defaultValues"] => {
  const { recipient } = sackCreateState();

  if (recipient) {
    return {
      name: recipient.name,
      addressLine1: recipient.addressLine1,
      addressLine2: recipient.addressLine2 || "",
      addressTown: recipient.addressTown,
      addressCounty: recipient.addressCounty || "",
      addressUsStateUrn: recipient.addressUsStateUrn || "",
      addressPostcode: recipient.addressPostcode,
      addressCountryUrn: recipient.addressCountryUrn,
      date: recipient.date,
    };
  }

  return {
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressTown: "",
    addressCounty: "",
    addressUsStateUrn: "",
    addressPostcode: "",
    addressCountryUrn: config.defaultCountry,
    date: dateFns.startOfDay(earliestSackSendDate()),
  };
};

interface IUseData {
  loaderProps: React.ComponentProps<typeof DataLoadedContainer>;
  formProps: FormProps;
  handleClickOpenVideo: VideoSquareProps["onClick"];
  product?: {
    price: number;
    title: string;
    description: string;
    productPageDescription: string;
    faqs: ReturnType<typeof mapCmsFaqsToAccordion>;
  };
}

export const useData = (): IUseData => {
  const history = useHistory();
  const video = useContext(VideoContext);

  // graphql data
  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useSackHomePageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      sackAlphaId: ProductAlphaId.SANTA_SACK,
      locale: getLocaleForApi(),
    },
  });

  const handleClickOpenVideo = useCallback<VideoSquareProps["onClick"]>(
    () => video.show("sack"),
    [video],
  );

  const onChange = useCallback<FormProps["onChange"]>(
    debounce(data => {
      sackState().setRecipient(data);
    }, 500),
    [],
  );

  const onSubmit = useCallback<FormProps["onSubmit"]>(() => {
    if (data) {
      if (
        data.product?.internationalSurcharge?.alphaId &&
        sackCreateState().recipient?.addressCountryUrn !== config.defaultCountry
      ) {
        sackState().addToBasket([data.product.internationalSurcharge.alphaId as ProductAlphaId]);
      } else {
        sackState().addToBasket();
      }

      history.push("/basket");
    }
  }, [history, data]);

  const defaultValues = useMemo(mapDataToForm, []);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && { refetch }),
    },
    formProps: {
      onSubmit,
      onChange,
      defaultValues,
    },
    handleClickOpenVideo,
    ...(data?.product && {
      product: {
        price: data.product.price || 0,
        title: data.product.title || "",
        description: data.product.description || "",
        productPageDescription: data.product.productPageDescription || "",
        faqs: mapCmsFaqsToAccordion(data.product.faqs),
      },
    }),
  };
};
