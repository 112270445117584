import { ProductAlphaId } from "@santa/common/lib/products/common";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { History } from "history";

import { letterState } from "../../model/graphql/cache";
import { routePaths, LetterHomeOption } from "../../model/route";

type UseLetterInit = (type: LetterHomeOption) => {
  history: History;
  handleClick(): void;
};

const getAddOn = (type: LetterHomeOption): ProductAlphaId[] => {
  switch (type) {
    case "classic":
      return [];
    case "magicSnow":
      return [ProductAlphaId.MAGIC_SNOW];
    case "plush":
      return [ProductAlphaId.SANTA_PLUSH];
    case "santaSack":
      return [ProductAlphaId.SANTA_SACK];
    case "cookieMix":
      return [ProductAlphaId.COOKIE_MIX];
    default:
      throw new Error(`Type ${type} not recognised`);
  }
};

export const useLetterInit: UseLetterInit = type => {
  const history = useHistory();

  const startLetter = (): void => {
    letterState().clear();
    letterState().setAddOns(getAddOn(type));

    history.push(routePaths.letter.recipient);
  };

  return {
    history,
    handleClick: useCallback(() => startLetter(), []),
  };
};
