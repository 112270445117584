import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import { PageContentPanel } from "../../../organisms/panels/page-content-panel";
import { PageWrapper } from "../page-wrapper";

const OuterContainer = styled.div`
  flex: 1;
  display: flex;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 50;

  @media ${device.laptopL} {
    padding-bottom: 80px;
  }
`;

export const MainWrapper: React.FC = props => (
  <OuterContainer>
    <PageWrapper>
      <PageContentPanel>
        <ContentContainer>{props.children}</ContentContainer>
      </PageContentPanel>
    </PageWrapper>
  </OuterContainer>
);
