import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import ReactMarkdown from "react-markdown";

import { CaretIcon } from "../../atoms/icons/caret-icon";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  background-color: ${({ theme }): string => theme.colours.alertPale};
  padding: 10px 20px;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;

  @media ${device.laptopL} {
    margin-left: 0;
    margin-right: 0;
    border-radius: 6px;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const TitleLabel = styled.h2`
  margin: 0;
  flex: 1;
  text-align: center;
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  flex: 2;
  display: flex;
  flex-direction: row;
  text-align: left;
`;

const DescriptionContainer = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 10px 24px;

  > p:first-child {
    margin-top: 0;
  }
  > p:last-child {
    margin-bottom: 0;
  }
`;

export enum OrderStatus {
  SCHEDULED,
  IN_PROGRESS,
  COMPLETED,
}

interface IProps {
  id: string;
  title: string;
  description: string;
  isOpen: boolean;
  className?: string;
  onClickHeader(id: string): void;
}

export const AccordionItem: React.FC<IProps> = ({
  id,
  title,
  description,
  className,
  isOpen,
  onClickHeader,
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [ref, { height }] = useMeasure();

  useEffect(() => {
    setContentHeight(height);

    window.addEventListener("resize", () => setContentHeight(height));

    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  const expand = useSpring({
    height: isOpen ? `${contentHeight + 20}px` : "0px",
    opacity: isOpen ? 1 : 0,
    marginTop: isOpen ? "10px" : "0px",
    overflow: "hidden",
  });

  const invert = useSpring({
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    marginRight: "15px",
    height: "13px",
  });

  const handleClickHeader = useCallback((): void => onClickHeader(id), [onClickHeader, id]);

  return (
    <Container className={className}>
      <OverviewContainer onClick={handleClickHeader}>
        <animated.div style={invert}>
          <CaretIcon colour="alert" />
        </animated.div>
        <TitleLabel>{title}</TitleLabel>
      </OverviewContainer>
      <animated.div style={expand}>
        <DescriptionContainer ref={ref as unknown as React.RefObject<HTMLDivElement>}>
          <ReactMarkdown source={description!} />
        </DescriptionContainer>
      </animated.div>
    </Container>
  );
};
