import { LETTER_CONTENT_FORM_PS_OTHER_ID } from "@santa/common/lib/form-validation/letter";
import { ISelectMultilineOption, ISelectOption } from "@santa/common/lib/utils/form";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { useParams, useHistory } from "react-router-dom";
import { ApolloClient, isApolloError } from "@apollo/client";
import { useCallback, useState, Dispatch, SetStateAction, useContext } from "react";
import * as dateFns from "date-fns";
import { History } from "history";
import { debounce } from "lodash";

import { DataLoadedContainer } from "../../../control/data-loaded-container";
import {
  SantaLetterEditForm,
  LetterEditValidatedFormModel,
} from "../../../organisms/forms/santa-letter-edit";
import { ILetterPreviewUpdateArgs } from "../../../organisms/letter-preview";
import { getLocaleForApi } from "../../../../utils/graphql";
import { getLetterUpdatePreviewArgs } from "../../../../model/letter";
import { BoyGirl } from "../../../../types/graphql";
import {
  useMyAccountLetterQuery,
  UpdateLetterMutation,
  UpdateLetterMutationVariables,
  UpdateLetterDocument,
} from "../../../../types/graphql";
import { routePaths } from "../../../../model/route";
import { ShowNavContext } from "../../../contexts/show-nav-context";

type FormProps = React.ComponentProps<typeof SantaLetterEditForm>;
type FormParams = FormProps["defaultValues"];
type LoaderProps = React.ComponentProps<typeof DataLoadedContainer>;

interface IQueryOptions {
  id: string;
  label?: string | null;
}

const mapOptions = (options: IQueryOptions[]): ISelectOption[] =>
  options.map(o => ({
    value: o.id,
    label: o.label || "",
  }));

interface IQueryOptionsWithDescription extends IQueryOptions {
  id: string;
  label?: string | null;
  description?: string | null;
}

const mapTemplates = (options: IQueryOptionsWithDescription[]): ISelectMultilineOption[] =>
  options.map(o => ({
    value: o.id,
    label: o.label || "",
    description: o.description || "",
  }));

const mapDataToForm = (
  data: NonNullable<NonNullable<ReturnType<typeof useMyAccountLetterQuery>["data"]>["letter"]>,
): FormParams => ({
  firstName: data.firstName,
  lastName: data.lastName || "",
  boyGirl: data.boyGirl,
  ageMonths: data.ageMonths?.toString() || "",
  ageYears: data.ageYears?.toString() || "",
  date: dateFns.parseISO(data.date),
  friend: data.friend || "",
  template: {
    value: data.template.id,
    label: data.template.label || "",
    description: data.template.description || "",
  },
  signatureId: data.signature.id,
  frontDoorId: data.frontDoor?.id,
  pet1Id: data.pet1?.id,
  pet1Name: data.pet1Name || "",
  pet2Id: data.pet2?.id,
  pet2Name: data.pet2Name || "",
  psId: data.psCustom ? LETTER_CONTENT_FORM_PS_OTHER_ID : data.ps?.id,
  psCustom: data.psCustom || undefined,
  hobby: data.hobby || "",
  gift: data.gift || "",
  addressCountryUrn: data.addressCountry.urn,
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2 || "",
  addressTown: data.addressTown,
  addressCounty: data.addressCounty || "",
  addressPostcode: data.addressPostcode,
  addressUsStateUrn: data.addressUsState?.urn || "",
});

const getHandleUpdateFunction =
  (
    client: ApolloClient<{}>,
    setPreviewProps: Dispatch<SetStateAction<ILetterPreviewUpdateArgs | undefined>>,
  ) =>
  async (letter: LetterEditValidatedFormModel): Promise<void> => {
    const details: Parameters<typeof getLetterUpdatePreviewArgs>[1] = {
      recipient: {
        firstName: letter.firstName,
        lastName: letter.lastName,
        boyGirl: letter.boyGirl as BoyGirl,
        ageYears: letter.ageYears,
        ageMonths: letter.ageMonths,
        addressLine1: letter.addressLine1,
        addressLine2: letter.addressLine2,
        addressTown: letter.addressTown,
        addressCounty: letter.addressCounty,
        addressPostcode: letter.addressPostcode,
        addressCountryUrn: letter.addressCountryUrn,
        addressUsStateUrn: letter.addressUsStateUrn,
        date: letter.date,
      },
      content: {
        signatureId: letter.signatureId,
        template: letter.template,
        pet1Id: letter.pet1Id,
        pet1Name: letter.pet1Name,
        pet2Id: letter.pet2Id,
        pet2Name: letter.pet2Name,
        hobby: letter.hobby,
        gift: letter.gift,
        friend: letter.friend,
        frontDoorId: letter.frontDoorId,
        psId: letter.psId,
        psCustom: letter.psCustom,
      },
    };

    setPreviewProps(await getLetterUpdatePreviewArgs(client, details));
  };

const getSubmitFunction =
  (
    client: ApolloClient<object>,
    history: History,
    letterUrn: string,
    setFormError: (error: string) => void,
    setIsSubmitting: (state: boolean) => void,
  ) =>
  async (values: LetterEditValidatedFormModel): Promise<void> => {
    try {
      setIsSubmitting(true);

      await client.mutate<UpdateLetterMutation, UpdateLetterMutationVariables>({
        mutation: UpdateLetterDocument,
        variables: {
          ...values,
          psId: values.psId !== LETTER_CONTENT_FORM_PS_OTHER_ID ? values.psId : "",
          psCustom: values.psId === LETTER_CONTENT_FORM_PS_OTHER_ID ? values.psCustom : "",
          templateId: values.template.value,
          date: values.date.toISOString(),
          boyGirl: values.boyGirl as BoyGirl,
          letterUrn,
        },
      });

      history.push(routePaths.myAccount.home);
    } catch (error) {
      if (error instanceof Error && isApolloError(error)) {
        setFormError(error.graphQLErrors[0].message);
      }
      setIsSubmitting(false);
    }
  };

interface IRouteParams {
  id: string;
}

interface IData {
  previewParams?: ILetterPreviewUpdateArgs;
  formProps?: FormProps;
  onClickCloseMobilePreview(): void;
  loaderProps: LoaderProps;
  shouldShowMobilePreview: boolean;
}

export const useData = (): IData => {
  const { hideNav, showNav } = useContext(ShowNavContext);
  const history = useHistory();
  const { id } = useParams<IRouteParams>();
  const [formError, setFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewParams, setPreviewParams] = useState<ILetterPreviewUpdateArgs>();
  const [shouldShowMobilePreview, setShouldShowMobilePreview] = useState(false);

  const letterUrn = createUrn(UrnResource.LETTER, id);
  const {
    loading: isLoading,
    data,
    client,
    refetch: dataRefetch,
  } = useMyAccountLetterQuery({
    fetchPolicy: "network-only",
    variables: {
      letterUrn,
      locale: getLocaleForApi(),
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const onSubmit = useCallback(
    getSubmitFunction(client, history, letterUrn, setFormError, setIsSubmitting),
    [client],
  );
  const onClickBack = useCallback((): void => history.push(routePaths.myAccount.home), [history]);
  const onChange = useCallback(debounce(getHandleUpdateFunction(client, setPreviewParams), 500), [
    client,
  ]);

  const onClickOpenMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(true);
    hideNav();
  }, [setShouldShowMobilePreview, hideNav]);

  const onClickCloseMobilePreview = useCallback((): void => {
    setShouldShowMobilePreview(false);
    showNav();
  }, [setShouldShowMobilePreview, showNav]);

  return {
    loaderProps: {
      isLoading,
      ...(!data && { refetch }),
    },
    previewParams,
    onClickCloseMobilePreview,
    shouldShowMobilePreview,
    ...(data?.letter && {
      formProps: {
        defaultValues: mapDataToForm(data.letter),
        frontDoorOptions: mapOptions(data?.allFrontDoors || []),
        psOptions: mapOptions(data?.allLetterPostscripts || []),
        signatureOptions: mapOptions(data?.allLetterSignatures || []),
        petOptions: mapOptions(data?.allPets || []),
        templateOptions: mapTemplates(data?.allLetterTemplates || []),
        formError,
        isSubmitting,
        onChange,
        onSubmit,
        onClickBack,
        onClickOpenMobilePreview,
      },
    }),
  };
};
