import * as dateFns from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Like } from "react-facebook";

import { config } from "../../../config";
import { routePaths } from "../../../model/route";

import background from "./background.png";
import signature from "./signature.png";
import topBorder from "./top-border.png";

const FooterContainer = styled.div`
  width: 100%;
  background-image: url(${background});
  background-repeat: repeat-x;
  background-color: #370403;
`;

const TopBorder = styled.div`
  width: 100%;
  height: 10px;
  background-image: url(${topBorder});
  background-repeat: repeat-x;
`;

const ContentContainer = styled(Container)`
  margin: 35px 0;
  color: ${({ theme }): string => theme.colours.navigation.light};
`;

const FacebookCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Signature = styled(Col)`
  display: flex;
  justify-content: center;
`;

const Links = styled(Col)`
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  > ul {
    list-style: none;
    padding: 0;

    > li {
      margin-top: 5px; /* to compensate for underscores */
      position: relative;
      display: inline-block;

      :not(:first-child) {
        margin-left: 12px;
      }

      :not(:last-child) {
        margin-right: 12px;
      }

      a {
        color: white;
        text-decoration: none;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          display: block;
          margin-top: 5px;
          right: 0;
          transition: width 0.2s ease;
          -webkit-transition: width 0.2s ease;
          background-color: white;
        }

        &:hover:after,
        &.active:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }
`;

const Address = styled(Col)`
  padding-top: 24px;
  font-size: ${({ theme }): string => theme.fontSizes.size14};

  span {
    margin: 0 10px;
  }
`;

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [showVersion, setShowVersion] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [location, setLocation] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    const { protocol, hostname } = window.location;
    setLocation(`${protocol}://${hostname}${history.location.pathname}`);
  }, [history.location.pathname]);

  const handleClickLogo = useCallback((): void => {
    if (clickCount === 9) {
      setShowVersion(true);
    } else if (clickCount === 17) {
      throw new Error("Testing throwing exception");
    }

    setClickCount(clickCount + 1);
  }, [clickCount, setShowVersion, setClickCount]);

  return (
    <FooterContainer>
      <TopBorder />
      <ContentContainer>
        <Row>
          <FacebookCol>
            {location && (
              <>
                <Like href={location} layout="button" share />
              </>
            )}
          </FacebookCol>
        </Row>
        <Row align="center">
          <Signature xl={2}>
            <img
              width={87}
              height={58}
              src={signature}
              alt="Santa's signature"
              onClick={handleClickLogo}
            />
          </Signature>
          <Links xl={10}>
            <ul>
              <li>
                <Link to={routePaths.about}>About</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to={routePaths.affiliates}>Affiliates</Link>
              </li>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </Links>
        </Row>
        <Row>
          <Address>
            {t("main.footer.address")} <span>&bull;</span>
            {t("main.footer.telephone")} <span>&bull;</span> © Copyright 1999-
            {dateFns.getYear(new Date())}
          </Address>
        </Row>
        {showVersion && (
          <Row>
            <Address>App: v{config.version}</Address>
          </Row>
        )}
      </ContentContainer>
    </FooterContainer>
  );
};
