import styled, { DefaultTheme } from "styled-components";

type PanelColour = "white" | "yellow" | "transparent";

interface IProps {
  colour?: PanelColour;
  className?: string;
  onClick?(): void;
}

const getColour = (colour: PanelColour | undefined, theme: DefaultTheme): string => {
  switch (colour) {
    case "yellow":
      return theme.colours.panels.content.yellow;
    default:
      return theme.colours.panels.content.white;
  }
};

const Panel = styled.div<IProps>`
  background-color: ${({ colour, theme }): string => getColour(colour, theme)};
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  border-radius: 6px;
  padding: 19px 24px;
`;

export const ContentPanel: React.FC<IProps> = ({ colour, children, className, onClick }) => (
  <Panel className={className} colour={colour} onClick={onClick}>
    {children}
  </Panel>
);
