import * as dateFns from "date-fns";

import { surchargeAddOns, ProductAlphaId, IDescriptionAddOn, getAddOnDescription } from "../common";
import { stringifyList } from "../../utils/string";
import { displayPrice } from "../../utils/price";

/**
 * Check if two lists arrays the same items, even if in a different order
 * @param arrayA First array
 * @param arrayB Second array
 */
const compare = (arrayA: ProductAlphaId[], arrayB: ProductAlphaId[]): boolean =>
  JSON.stringify(arrayA.sort()) === JSON.stringify(arrayB.sort());

export type LetterDescriptionCode =
  | "L"
  | "SMS"
  | "SLS"
  | "LSK"
  | "SLDX"
  | "SLSCMX"
  | "SLDXCMX"
  | "SLCMX"
  | "SLCMXSNW"
  | "SMSSK"
  | "SLSSK"
  | "SLDXSK"
  | "SLSCMXSK"
  | "SLDXCMXSK"
  | "SLCMXSK"
  | "SLCMXSNWSK";

/**
 * Get a string description code for a letter according to its addons
 * @param allAddOns Addon list
 */
export const getLetterDescriptionCode = (allAddOns: ProductAlphaId[]): LetterDescriptionCode => {
  const { MAGIC_SNOW, SANTA_PLUSH, COOKIE_MIX, SANTA_SACK } = ProductAlphaId;

  const addOns = allAddOns.filter(
    a =>
      a !== ProductAlphaId.INTERNATIONAL_SURCHARGE_LETTER &&
      a !== ProductAlphaId.INTERNATIONAL_SURCHARGE_PRODUCT &&
      a !== ProductAlphaId.PANDP_SURCHARGE &&
      a !== ProductAlphaId.VIDEO_MESSAGE,
  );

  if (compare(addOns, [])) {
    return "L";
  }
  if (compare(addOns, [MAGIC_SNOW])) {
    return "SMS";
  }
  if (compare(addOns, [SANTA_PLUSH])) {
    return "SLS";
  }
  if (compare(addOns, [COOKIE_MIX])) {
    return "SLCMX";
  }
  if (compare(addOns, [SANTA_SACK])) {
    return "LSK";
  }
  if (compare(addOns, [SANTA_PLUSH, MAGIC_SNOW])) {
    return "SLDX";
  }
  if (compare(addOns, [SANTA_PLUSH, SANTA_SACK])) {
    return "SLSSK";
  }
  if (compare(addOns, [SANTA_PLUSH, COOKIE_MIX])) {
    return "SLSCMX";
  }
  if (compare(addOns, [MAGIC_SNOW, SANTA_SACK])) {
    return "SMSSK";
  }
  if (compare(addOns, [MAGIC_SNOW, COOKIE_MIX])) {
    return "SLCMXSNW";
  }
  if (compare(addOns, [SANTA_SACK, COOKIE_MIX])) {
    return "SLCMXSK";
  }
  if (compare(addOns, [SANTA_PLUSH, MAGIC_SNOW, COOKIE_MIX])) {
    return "SLDXCMX";
  }
  if (compare(addOns, [SANTA_PLUSH, MAGIC_SNOW, SANTA_SACK])) {
    return "SLDXSK";
  }
  if (compare(addOns, [SANTA_PLUSH, COOKIE_MIX, SANTA_SACK])) {
    return "SLSCMXSK";
  }
  if (compare(addOns, [COOKIE_MIX, MAGIC_SNOW, SANTA_SACK])) {
    return "SLCMXSNWSK";
  }
  if (compare(addOns, [SANTA_PLUSH, MAGIC_SNOW, COOKIE_MIX, SANTA_SACK])) {
    return "SLDXCMXSK";
  }

  throw new Error(`Unexpected addon combo: ${addOns.join(", ")}`);
};

interface IGetLetterDescriptionParams {
  addOnProducts: IDescriptionAddOn[];
  templateName?: string | null;
  recipientName?: string | null;
  sendDate: Date;
}

/**
 * Return a description for a letter given some parameters to describe it
 * @param params Params for letter
 */
export const getLetterDescription = (params: IGetLetterDescriptionParams): string => {
  // filter out surcharges
  const productAddOns = params.addOnProducts.filter(
    a => typeof surchargeAddOns.find(id => a.alphaId === id) === "undefined",
  );

  const surcharges: string[] = [];

  // check international surcharge
  const internationalSurcharge = params.addOnProducts.find(
    a =>
      a.alphaId === ProductAlphaId.INTERNATIONAL_SURCHARGE_LETTER ||
      a.alphaId === ProductAlphaId.INTERNATIONAL_SURCHARGE_PRODUCT,
  );
  if (internationalSurcharge && internationalSurcharge.price) {
    surcharges.push(
      `${displayPrice(internationalSurcharge.price, "GBP")} ${internationalSurcharge.title}`,
    );
  }

  // check p&p surcharge
  const postagePackingSurcharge = params.addOnProducts.find(
    a => a.alphaId === ProductAlphaId.PANDP_SURCHARGE,
  );
  if (postagePackingSurcharge && postagePackingSurcharge.price) {
    surcharges.push(
      `${displayPrice(postagePackingSurcharge.price, "GBP")} \
${postagePackingSurcharge.title}`,
    );
  }

  // add on surcharges if present
  const surcharge = surcharges.length > 0 ? ` Includes ${stringifyList(surcharges)}.` : "";

  const template = params.templateName ? `with template '${params.templateName}' ` : "";

  return `${template}for ${params.recipientName} to be sent on \
${dateFns.format(params.sendDate, "PP")}.${getAddOnDescription(productAddOns)}${surcharge}`;
};

const NEXT_SEND_DAY_OFFSET = 1;
const FIRST_SEND_MONTH = 10; // zero indexed - November
const FIRST_SEND_DAY = 15;
const LAST_SEND_MONTH = 11; // zero indexed - December
const LAST_SEND_DAY = 22;

/**
 * Calculate the first date that Santa Letters can be sent
 */
export const earliestLetterSendDate = (): Date => {
  const nextSendDate = dateFns.startOfDay(dateFns.add(new Date(), { days: NEXT_SEND_DAY_OFFSET }));

  const nextSendDateMonth = dateFns.getMonth(nextSendDate);
  const nextSendDateDay = dateFns.getDate(nextSendDate);
  const nextSendDateYear = dateFns.getYear(nextSendDate);

  if (nextSendDateMonth >= LAST_SEND_MONTH && nextSendDateDay > LAST_SEND_DAY) {
    // date is after last send date - set to first send date next year
    return new Date(nextSendDateYear + 1, FIRST_SEND_MONTH, FIRST_SEND_DAY);
  }

  if (
    nextSendDateMonth < FIRST_SEND_MONTH ||
    (nextSendDateMonth === FIRST_SEND_MONTH && nextSendDateDay < FIRST_SEND_DAY)
  ) {
    // date is before first send date - set to first send date this year
    return new Date(nextSendDateYear, FIRST_SEND_MONTH, FIRST_SEND_DAY);
  }

  // the next send date is OK
  return nextSendDate;
};

/**
 * Calculate the last date that Santa Letters can be sent
 */
export const lastLetterSendDate = (): Date => {
  const nextSendDate = dateFns.startOfDay(dateFns.add(new Date(), { days: NEXT_SEND_DAY_OFFSET }));

  const nextSendDateMonth = dateFns.getMonth(nextSendDate);
  const nextSendDateDay = dateFns.getDate(nextSendDate);
  const nextSendDateYear = dateFns.getYear(nextSendDate);

  // Set year to this year unless next send date is after the last send date
  const year =
    nextSendDateMonth >= LAST_SEND_MONTH && nextSendDateDay > LAST_SEND_DAY
      ? nextSendDateYear + 1
      : nextSendDateYear;

  return new Date(year, LAST_SEND_MONTH, LAST_SEND_DAY);
};
