import { mapObjectsToSelectOptions } from "@santa/common/lib/utils/form";
import { useHistory } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash";

import { getLocaleForApi } from "../../../../utils/graphql";
import { ProductAlphaId, useVideoDetailsQuery } from "../../../../types/graphql";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { routePaths } from "../../../../model/route";
import {
  VideoDetailsForm,
  VideoDetailsValidatedFormModel,
} from "../../../organisms/forms/video/video-details-form";
import { videoCreateState, videoState } from "../../../../model/graphql/cache";
import { config } from "../../../../config";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";

type FormProps = React.ComponentProps<typeof VideoDetailsForm>;
type FormParams = FormProps["defaultValues"];
type LoadingProps = React.ComponentProps<typeof DataLoadedContainer>;

const mapDataToForm = (): FormParams => {
  const { details } = videoCreateState();

  if (details) {
    return {
      firstName: {
        label: details.firstName.label || "",
        value: details.firstName.value,
      },
      ageYears: details.ageYears?.toString() || "",
      frontDoorId: details.frontDoorId || "",
      petId: details.petId || "",
      countryUrn: details.countryUrn || "",
    };
  }

  return {
    countryUrn: config.defaultCountry,
  };
};

interface IUseData {
  loadingProps: LoadingProps;
  formProps?: FormProps;
  faqs?: ReturnType<typeof mapCmsFaqsToAccordion>;
}

/**
 * Hook to get data for video details form
 */
export const useData = (): IUseData => {
  const history = useHistory();

  const {
    data,
    refetch: dataRefetch,
    loading: isLoading,
  } = useVideoDetailsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      locale: getLocaleForApi(),
      videoAlphaId: ProductAlphaId.SANTA_VIDEO_MESSAGE,
    },
  });

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  const handleChange = useCallback(
    debounce((data: VideoDetailsValidatedFormModel) => {
      videoState().setDetails(data);
    }, 500),
    [],
  );

  const handleSubmit = useCallback(
    (data: VideoDetailsValidatedFormModel) => {
      videoState().setDetails(data);
      history.push(routePaths.video.images);
    },
    [history],
  );

  const handleClickBack = useCallback((): void => history.push(routePaths.video.home), [history]);

  const value: IUseData = {
    loadingProps: {
      isLoading,
      ...(!data && { refetch }),
    },
    ...(data?.product?.faqs && { faqs: mapCmsFaqsToAccordion(data.product.faqs) }),
  };

  const defaultValues = useMemo(mapDataToForm, []);

  if (data) {
    value.formProps = {
      pets: mapObjectsToSelectOptions(data.pets, "id", "label"),
      countries: mapObjectsToSelectOptions(data.countries, "urn", "name"),
      frontDoors: mapObjectsToSelectOptions(data.frontDoors, "id", "label"),
      onChange: handleChange,
      onClickBack: handleClickBack,
      onSubmit: handleSubmit,
      defaultValues,
    };
  }

  return value;
};
