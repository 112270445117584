import { useEffect, useRef } from "react";
import styled from "styled-components";

import { letterPreview, ILetterPreviewParams } from "../../../model/letter/letter-preview";

import header from "./header.jpg";
import footer from "./footer.jpg";

const headerImage = new Image();
headerImage.src = header;

const footerImage = new Image();
footerImage.src = footer;

const Canvas = styled.canvas`
  background-color: white;
`;

export interface ILetterPreviewUpdateArgs {
  name: string;
  templateName?: string;
  signature?: string;
  ps?: string | null | undefined;
  paragraphs: string[];
  address: (string | undefined | null)[];
  date: Date;
}

interface IProps {
  params?: ILetterPreviewUpdateArgs;
}

export const LetterPreview: React.FC<IProps> = ({ params }) => {
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (params && canvas.current) {
      const ctx = canvas.current!.getContext("2d") as CanvasRenderingContext2D;

      const p: ILetterPreviewParams = {
        ctx,
        headerImage,
        bottomMargin: 50,
        horizontalMargin: 45,
        width: 546,
        addressYIndex: 120,
        mainYIndex: 245,
        bodyFontFace: "Cursiv",
        bodyFontSize: 17,
        signatureFontFace: "'Homemade Apple'",
        signatureFontSize: 25,
        templateName: params.templateName,
      };

      if (params.signature) {
        p.signature = `\0${params.signature}\0`;
        p.footerImage = footerImage;
        p.bottomMargin += 175;
      }

      if (params.ps) {
        p.ps = `\0${params.ps}\0`;
        p.bottomMargin += 30;
      }

      const { date, paragraphs, address } = params;

      letterPreview(p)({
        paragraphs,
        date,
        address: address.map<string>(l => `\0${l}\0`),
        intro: `Dear \0${params.name}\0,`,
      });
    }
  }, [canvas, params]);

  return <Canvas ref={canvas} />;
};
