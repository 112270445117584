import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Visible } from "react-grid-system";

import { BowButton } from "../../atoms/bow-button";
import { MainWrapper } from "../../atoms/containers/main-wrapper";
import { Heading2, Paragraph } from "../../atoms/text";
import { ProductCombinationListItem } from "../../molecules/product-combination-list-item";
import { device } from "../../../utils/media-queries";
import { DataLoadedContainer } from "../../control/data-loaded-container";

import { useData } from "./hooks/use-data";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    padding: 0 20px;
    align-items: flex-start;
  }

  @media ${device.laptopL} {
    padding: 60px 20px 20px 20px;
    align-items: center;
  }
`;

const Heading = styled(Heading2)`
  margin: 0;

  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }
`;

const SubHeading = styled(Paragraph)`
  @media ${device.mobileS} {
    margin: 30px 0 0 0;
  }

  @media ${device.laptopL} {
    margin: 25px 0 0 0;
  }

  font-size: ${({ theme }): string => theme.fontSizes.size24};
`;

const ItemsContainer = styled.div`
  width: 100%;
  margin: 30px 0 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > * {
    float: left;
  }
`;

export const OrderConfirmation: React.FC = () => {
  const history = useHistory();
  const { data, isLoading, refetch } = useData();

  const handleClickContinue = useCallback((): void => history.push("/"), [history]);

  const items = data?.items || [];

  return (
    <MainWrapper>
      <Container>
        <Heading>Thank You For Your Purchase</Heading>
        <SubHeading>Your order:</SubHeading>
        <ItemsContainer>
          <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
            {items.map((itemProps, i) => (
              <ProductCombinationListItem key={i} {...itemProps} />
            ))}
          </DataLoadedContainer>
        </ItemsContainer>
        <Visible xl>
          <BowButton size={330} onClick={handleClickContinue}>
            Continue Shopping
          </BowButton>
        </Visible>
        <Visible xs>
          <BowButton size={430} onClick={handleClickContinue}>
            Continue Shopping
          </BowButton>
        </Visible>
      </Container>
    </MainWrapper>
  );
};
