import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export const FormFieldFriendName: React.FC<IProps> = ({
  id = "friend",
  label = "Child's best friend",
  placeholder = "Enter one best friend's name",
  required = false,
}) => (
  <FormFieldWrapper
    label={label}
    required={required}
    tooltip="If you are unsure, please leave blank and Santa will work around this."
  >
    <Field name={id} component={FormikFormInputText} placeholder={placeholder} />
  </FormFieldWrapper>
);
