import { useApolloClient } from "@apollo/client";
import { ISelectOption } from "@santa/common/lib/utils/form";

import {
  HobbySearchQuery,
  HobbySearchQueryVariables,
  HobbySearchDocument,
} from "../../../../../../types/graphql";

type UseSearch = () => (search: string) => Promise<ISelectOption[]>;

export const useSearch: UseSearch = () => {
  const client = useApolloClient();

  return async (search: string): Promise<ISelectOption[]> => {
    const { data } = await client.query<HobbySearchQuery, HobbySearchQueryVariables>({
      query: HobbySearchDocument,
      variables: { search },
    });

    return data.allHobbies.map(o => ({
      value: o.id,
      label: o.label || "",
    }));
  };
};
