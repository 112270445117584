import { Col, Container, Row, Hidden, Visible } from "react-grid-system";
import styled from "styled-components";

import { LetterPreview } from "../../../organisms/letter-preview";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { LetterPreviewPlaceholder } from "../../../atoms/letter-preview-placeholder";
import { MobileOverlay } from "../../../organisms/mobile-overlay";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { FormFieldWrapper } from "../../../organisms/form-field-wrapper";
import { FormInputSelectMultiline } from "../../../molecules/form/form-input-select-multiline";
import { BackLink } from "../../../molecules/back-link";
import { PreviewButton } from "../../../molecules/buttons/preview-button";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { Heading2, Paragraph } from "../../../atoms/text";
import { BowButton } from "../../../atoms/bow-button";

import { useData } from "./use-data";

const SubmitCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const PreviewPlaceholder = styled(LetterPreviewPlaceholder)`
  margin-top: 30px;
`;

const MobilePreviewContainer = styled.div`
  canvas {
    width: 100vw;
  }
`;

const PreviewWrapper = styled.div`
  position: sticky;
  top: 0;

  canvas {
    width: 100%;
  }
`;

export const SantaLetterTemplates: React.FC = () => {
  const {
    loaderProps,
    previewProps,
    shouldShowMobilePreview,
    selectedTemplate,
    templates,
    handleShowMobilePreview,
    handleHideMobilePreview,
    handleClickBack,
    handleClickCreate,
    handleChangeTemplate,
  } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      <MainWrapper>
        <Container>
          <Row>
            <Col md={6}>
              <MobileBackLink onClick={handleClickBack}>Back</MobileBackLink>

              <Row>
                <Col>
                  <Heading2>Preview Santa&apos;s letters</Heading2>
                  <Paragraph>
                    See how Santa weaves customization in to his letters with age related stories
                    from the North Pole. You can change this template up to midnight before
                    it&apos;s mailed.
                  </Paragraph>
                </Col>
              </Row>

              <FormFieldWrapper
                label="Choose letter story"
                name="templateId"
                required
                noLabel
                noError
              >
                <FormInputSelectMultiline
                  name="templateId"
                  placeholder="Select story"
                  options={templates}
                  onChange={handleChangeTemplate}
                  noLabel={true}
                  value={selectedTemplate}
                />
              </FormFieldWrapper>
              <Row>
                <SubmitCol>
                  <Visible md lg xl>
                    <BackLink label="Back" onClick={handleClickBack} />
                  </Visible>
                  <Visible xs sm>
                    <PreviewButton onClick={handleShowMobilePreview} />
                  </Visible>
                  <Visible xs sm>
                    <BowButton size={210} onClick={handleClickCreate}>
                      Create
                    </BowButton>
                  </Visible>
                  <Visible md lg xl>
                    <BowButton size={330} onClick={handleClickCreate}>
                      Create Letter
                    </BowButton>
                  </Visible>
                </SubmitCol>
              </Row>
            </Col>
            <Hidden xs>
              <Col md={6}>
                <PreviewWrapper>
                  {previewProps ? <LetterPreview params={previewProps} /> : <PreviewPlaceholder />}
                </PreviewWrapper>
              </Col>
            </Hidden>
          </Row>
        </Container>
        {shouldShowMobilePreview && (
          <MobileOverlay onClickClose={handleHideMobilePreview}>
            <MobilePreviewContainer>
              {previewProps ? <LetterPreview params={previewProps} /> : <PreviewPlaceholder />}
            </MobilePreviewContainer>
          </MobileOverlay>
        )}
      </MainWrapper>
    </DataLoadedContainer>
  );
};
