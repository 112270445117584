import { zonedTimeToUtc } from "date-fns-tz";
import * as dateFns from "date-fns";

import { TextCreateInput } from "../../types/graphql";

export type TextDetailsTextingInput = Pick<
  TextCreateInput,
  "phoneCountryUrn" | "phoneNumber" | "timezoneUrn"
>;

export type TextDetailsMessagesInput = Pick<
  TextCreateInput,
  | "firstName"
  | "boyGirl"
  | "ageYears"
  | "town"
  | "petId"
  | "petName"
  | "templateId"
  | "text1Time"
  | "text1Body"
  | "text2Time"
  | "text2Body"
  | "text3Time"
  | "text3Body"
>;

export type TextDetailsBonusInput = Pick<TextCreateInput, "bonusBody">;

export const formatTextTime = (date: Date, time: string, zone: string): string =>
  zonedTimeToUtc(dateFns.parse(time, "HH:mm", date.valueOf()), zone).toISOString();
