import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { device } from "../../../utils/media-queries";
import { GetMySantaLetterButton } from "../../atoms/get-my-santa-letter-button";
import { GetMySantaZoomCallButton } from "../../atoms/get-my-santa-zoom-call-button";
import { GetMySantaVideoButton } from "../../atoms/get-my-santa-video-button";
import { Loader } from "../../atoms/loader";
import { Heading1, Heading2 } from "../../atoms/text";
import { DaysUntilChristmas } from "../days-until-christmas";

import wave from "./wave.png";

const MainContainer = styled.div`
  background-color: white;
  position: relative;
  margin: 0 auto;

  @media ${device.mobileS} {
    height: 510px;
  }

  @media ${device.laptopL} {
    height: 900px;
    width: 1440px;
  }
`;

const VideoFallback = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: -300px;
  width: 907px;
  height: 510px;

  @media ${device.mobileS} {
    display: none;
  }
`;

const videoCss = `
  position: absolute;
  z-index: 1;
  top: 0;

  @media ${device.mobileS} {
    display: block;
    left: -300px;
    width: 907px;
    height: 510px;
  }

  @media ${device.tablet} {
    display: block;
    left: -69px;
    width: 906px;
    height: 510px;
  }

  @media ${device.laptopL} {
    left: 0;
    width: 1440px;
    height: 810px;
  }
`;

const VideoLoading = styled.div`
  ${videoCss}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
`;

const Video = styled.video`
  ${videoCss}
`;

const Content = styled(Col)`
  z-index: 2;

  @media ${device.laptopL} {
    width: 648px;
  }
`;

const Title = styled(Heading1)`
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media ${device.mobileS} {
    margin: 125px 0 0 0;
  }

  @media ${device.laptopL} {
    margin: 205px 0 0 0;
    width: 600px;
  }
`;

const SubTitle = styled(Heading2)`
  color: white;
  font-weight: normal;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media ${device.mobileS} {
    margin: 22px 0 0 0;
    width: 100%;
  }

  @media ${device.laptopL} {
    margin: 31px 0 0 0;
    width: 500px;
  }
`;

const DaysUntilChristmasContainer = styled.div`
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media ${device.mobileS} {
    margin-top: 60px;
  }

  @media ${device.laptopL} {
    margin-top: 82px;
  }
`;

const Wave = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.laptopL} {
    display: block;
  }
`;

interface IProps {
  videoUrl?: string;
  imageUrl?: string;
  cta: "SANTA_LETTER" | "SANTA_CALL_VIDEO" | "SANTA_VIDEO";
}

export const HomeWave: React.FC<IProps> = ({ videoUrl, imageUrl, cta }) => {
  const { t } = useTranslation();

  const video = !videoUrl ? (
    <VideoLoading>
      <Loader />
    </VideoLoading>
  ) : (
    <Video autoPlay={true} loop={true} playsInline muted>
      <source src={videoUrl} />
    </Video>
  );

  const image = imageUrl ? <VideoFallback src={imageUrl} alt="Santa" /> : null;

  return (
    <MainContainer>
      {video}
      {image}
      <Container>
        <Row>
          <Content xl={12}>
            <Title>{t("home.title")}</Title>
            <SubTitle>{t("home.subtitle")}</SubTitle>
            {cta === "SANTA_LETTER" && <GetMySantaLetterButton />}
            {cta === "SANTA_CALL_VIDEO" && <GetMySantaZoomCallButton />}
            {cta === "SANTA_VIDEO" && <GetMySantaVideoButton />}
            <DaysUntilChristmasContainer>
              <DaysUntilChristmas />
            </DaysUntilChristmasContainer>
          </Content>
        </Row>
      </Container>
      <Wave src={wave} alt="wave" />
    </MainContainer>
  );
};
