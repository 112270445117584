import styled from "styled-components";

import { theme } from "../../../theme";

interface IStyleCompProps {
  colour: string;
  width: number;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div<IStyleCompProps>`
  display: inline-block;
  position: relative;
  width: ${({ width }): number => width}px;
  height: ${({ width }): number => width}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ width }): number => width * 0.8}px;
    height: ${({ width }): number => width * 0.8}px;
    margin: ${({ width }): number => width * 0.1}px;
    border-width: ${({ width }): number => width * 0.1}px;
    border-style: solid;
    border-color: ${({ colour }): string => colour} transparent transparent transparent;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface IProps {
  width?: number;
  colour?: string;
}

export const Loader: React.FC<IProps> = ({ colour = theme.colours.loader, width = 80 }) => {
  const circles = [1, 2, 3, 4].map(i => <div key={i} />);

  return (
    <Container>
      <Spinner colour={colour} width={width} data-santa-id="spinner">
        {circles}
      </Spinner>
    </Container>
  );
};
