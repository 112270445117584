import { Col, Container, Row, Visible } from "react-grid-system";

import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { BowButton } from "../../../atoms/bow-button";
import { ButtonLink } from "../../../atoms/button-link";
import { BackLink } from "../../../molecules/back-link";
import { ProductAddOnPanel } from "../../../organisms/product-add-on-panel";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { device } from "../../../../utils/media-queries";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { SurchargeNotice } from "../../../molecules/surcharge-notice";
import { StageTitle } from "../../../atoms/stage-title";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { LetterSectionTitle } from "../../../molecules/letter-section-title";
import { ProductAlphaId } from "../../../../types/graphql";

import { useData } from "./hooks/use-data";

const PanelCol = styled(Col)`
  display: flex;
  padding-bottom: 24px;
`;

const LinksRow = styled(Row)`
  margin-top: 31px;
`;

const ButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:only-child > * {
    margin-left: auto;
  }

  @media ${device.laptopL} {
    justify-content: flex-end;

    & > * {
      margin-left: 30px;
    }
  }
`;

export const SantaLetterAddOns: React.FC = () => {
  const {
    isLoading,
    refetch,
    data,
    handleAddToBasketWithAddOns,
    handleAddToBasketWithoutAddOns,
    handleToggleAddOn,
    handleClickBack,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
    isLightboxOpen,
    lightboxImageIndex,
  } = useData();

  const handleClickPanel = (alphaId: ProductAlphaId) => () => handleToggleAddOn(alphaId);
  const handleClickImage = (index: number) => () => handleOpenLightbox(index);

  const addOnsSelected = (data && data.addOns.some(a => a.isSelected)) ?? false;

  return (
    <MainWrapper>
      <Container>
        <MobileBackLink onClick={handleClickBack}>Back</MobileBackLink>
        <Row>
          <Col>
            <LetterSectionTitle />
            <StageTitle totalStages={3} currentStage={3}>
              <Visible sm md lg xl>
                Choose additional items to make the letter more special
              </Visible>
              <Visible xs>Magical add-ons</Visible>
            </StageTitle>
          </Col>
        </Row>

        <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
          {data && (
            <>
              <Row>
                {data.addOns.map((p, index) => (
                  <PanelCol md={6} key={p.alphaId}>
                    <ProductAddOnPanel
                      title={p.title}
                      description={p.description}
                      isSelected={p.isSelected}
                      onClick={handleClickPanel(p.alphaId)}
                      imageUrl={p.imageUrl!}
                      price={p.price}
                      isPopular={p.isPopular}
                      onClickImage={handleClickImage(index)}
                    />
                  </PanelCol>
                ))}
              </Row>
              <SurchargeNotice charges={data.surcharges} />
              <LinksRow align="center">
                <Visible md lg xl>
                  <Col md={6}>
                    <BackLink label="Back" onClick={handleClickBack} />
                  </Col>
                </Visible>
                <ButtonCol md={6}>
                  {!addOnsSelected && (
                    <ButtonLink label="Skip" onClick={handleAddToBasketWithoutAddOns} />
                  )}
                  <BowButton size={330} onClick={handleAddToBasketWithAddOns}>
                    Add to Basket
                  </BowButton>
                </ButtonCol>
              </LinksRow>

              {isLightboxOpen && (
                <Lightbox
                  mainSrc={data.images[lightboxImageIndex]}
                  nextSrc={data.images[(lightboxImageIndex + 1) % data.images.length]}
                  prevSrc={
                    data.images[(lightboxImageIndex + data.images.length - 1) % data.images.length]
                  }
                  onCloseRequest={handleCloseLightbox}
                  enableZoom={false}
                  onMovePrevRequest={handleClickPreviousImage}
                  onMoveNextRequest={handleClickNextImage}
                />
              )}
            </>
          )}
        </DataLoadedContainer>
      </Container>
    </MainWrapper>
  );
};
