import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import { BubblesBackground } from "../../../atoms/bubbles-background";
import { CandyDividerNarrow } from "../../../atoms/candy-divider-narrow";
import { CandyStripeTop } from "../../../atoms/candy-stripe-top";

const SectionContainer = styled.div`
  background-color: ${({ theme }): string => theme.colours.contentBackground};
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${device.mobileS} {
    width: 375px;
  }

  @media ${device.laptopL} {
    width: 1440px;
  }
`;

const DividerAndContentContainer = styled.div`
  z-index: 3;
`;

const ContentContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const CandyStripeBottom = styled(CandyStripeTop)`
  transform: scaleY(-1);
  top: auto;
  bottom: 0;
`;

interface IProps {
  noBubbles?: boolean;
  noTopStripes?: boolean;
  noBottomStripes?: boolean;
  className?: string;
  backgroundContent?: () => JSX.Element;
}

export const CandyContentSection: React.FC<IProps> = ({
  noBubbles = false,
  noTopStripes = false,
  noBottomStripes = false,
  children,
  className,
  backgroundContent,
}) => (
  <SectionContainer className={className}>
    {backgroundContent && backgroundContent()}
    {!noTopStripes && <CandyStripeTop />}
    {!noBubbles && <BubblesBackground />}
    <DividerAndContentContainer>
      <CandyDividerNarrow />
      <ContentContainer>{children}</ContentContainer>
      <CandyDividerNarrow />
    </DividerAndContentContainer>
    {!noBottomStripes && <CandyStripeBottom />}
  </SectionContainer>
);
