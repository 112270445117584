import { ISelectMultilineOption, ISelectOption } from "@santa/common/lib/utils/form";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import { ApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { getLocaleForApi } from "../../utils/graphql";
import { ILetterPreviewUpdateArgs } from "../../components/organisms/letter-preview";
import { getLetterUpdatePreviewArgs } from "../../model/letter";
import { useSantaLetterFormQuery, SantaLetterFormQuery } from "../../types/graphql";
import { letterCreateState } from "../../model/graphql/cache";

const mapOptions = (
  options: Pick<SantaLetterFormQuery["allPets"][0], "id" | "label">[],
): ISelectOption[] =>
  options.map(o => ({
    value: o.id,
    label: o.label || "",
  }));

const mapTemplates = (
  options: SantaLetterFormQuery["allLetterTemplates"][0][],
): ISelectMultilineOption[] =>
  options.map(o => ({
    value: o.id,
    label: o.label || "",
    description: o.description || "",
  }));

type GetPreview = () => Promise<ILetterPreviewUpdateArgs | undefined>;

type GetPreviewFactory = (c: ApolloClient<object>) => GetPreview;

const getPreviewFunction: GetPreviewFactory =
  client => async (): Promise<ILetterPreviewUpdateArgs | undefined> => {
    const state = letterCreateState();

    if (state) {
      return getLetterUpdatePreviewArgs(client, state);
    }
  };

interface IUseLetterFormData {
  client: ApolloClient<{}>;
  isLoading: boolean;
  refetch?(): void;
  isNetworkError: boolean;
  data: {
    frontDoors: ISelectOption[];
    ps: ISelectOption[];
    signatures: ISelectOption[];
    templates: ISelectMultilineOption[];
    pets: ISelectOption[];
    internationalSurcharge?: {
      title: string;
      price: number;
    };
  };
  getPreview: GetPreview;
}

/**
 * Hook to get data for letter form
 */
export const useLetterFormData = (): IUseLetterFormData => {
  const {
    client,
    data,
    refetch,
    loading: isLoading,
    error,
  } = useSantaLetterFormQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      letterAlphaId: ProductAlphaId.SANTA_LETTER,
      locale: getLocaleForApi(),
    },
  });

  const getPreview = useCallback(getPreviewFunction(client), [client]);

  return {
    client,
    isLoading,
    ...(!data && { refetch }),
    isNetworkError: !!error?.networkError,
    data: {
      frontDoors: mapOptions(data?.allFrontDoors || []),
      ps: mapOptions(data?.allLetterPostscripts || []),
      signatures: mapOptions(data?.allLetterSignatures || []),
      pets: mapOptions(data?.allPets || []),
      templates: mapTemplates(data?.allLetterTemplates || []),
      ...(data?.product?.internationalSurcharge?.title && {
        internationalSurcharge: {
          title: data.product.internationalSurcharge.title,
          price: data.product.internationalSurcharge.price,
        },
      }),
    },
    getPreview,
  };
};
