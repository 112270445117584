import styled from "styled-components";

interface IStyledProps {
  colour: string;
}

const Container = styled.div<IStyledProps>`
  cursor: pointer;
  border-radius: 6px;
  padding: 8px 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-color: ${({ colour }): string => colour};
  border-width: 1px;
  border-style: solid;

  :hover {
    background-color: ${({ colour }): string => colour};

    span {
      color: white;
    }
  }
`;

const Label = styled.span<IStyledProps>`
  color: ${({ colour }): string => colour};
`;

interface IProps {
  label: string;
  colour: string;
  onClick(): void;
}

export const SimpleButton: React.FC<IProps> = ({ label, onClick, colour }) => {
  return (
    <Container onClick={onClick} colour={colour}>
      <Label colour={colour}>{label}</Label>
    </Container>
  );
};
