import * as dot from "dot";

/**
 * Return a string representation of the years and months
 * @param ageYears Age in years
 * @param ageMonths Age in months
 */
export const getAge = (ageYears: number | undefined, ageMonths: number | undefined): string => {
  let age = "";

  if (ageYears && ageYears > 0) {
    age += `${ageYears} year${ageYears > 1 ? "s" : ""}`;
  }

  if (ageMonths && ageMonths > 0) {
    if (age !== "") {
      age += " and ";
    }

    age += `${ageMonths} month${ageMonths > 1 ? "s" : ""}`;
  }

  return age;
};

/**
 * Return a string to describe child's hobby
 * @param hobby Raw version of hobby
 */
export const getHobby = (hobby: string | undefined): string => {
  if (hobby) {
    return ` I have also heard that you really like ${hobby}.`;
  }

  return "";
};

/**
 * Return a string to describe a gift for the child
 * @param gift Raw version of gift
 */
export const getGift = (gift: string | undefined): string => {
  return `, ${gift || "a surprise, "} I believe.`;
};

interface IPetType {
  text?: string | null;
  textPlural?: string | null;
}

/**
 * Return a string identifying the pets selected
 * @param pet1Type ID of pet 1
 * @param pet1Name name of pet 1
 * @param pet2Type ID of pet 2
 * @param pet2Name name of pet 2
 */
export const getPets = (
  pet1Type: IPetType | undefined,
  pet1Name: string | undefined,
  pet2Type: IPetType | undefined,
  pet2Name: string | undefined,
): string => {
  if (!pet1Type && !pet2Type) {
    return "";
  }

  let pets = " And how";

  if (pet1Type && pet2Type && pet1Type.text === pet2Type.text) {
    // both pets are the same
    pets += ` are your ${pet1Type.textPlural}`;

    if (pet1Name && pet2Name) {
      pets += `, ${pet1Name} and ${pet2Name}`;
    }
  } else {
    if (pet1Type) {
      // pet 1 is supplied
      pets += ` is your ${pet1Type.text}`;

      if (pet1Name) {
        pets += `, ${pet1Name}`;
      }

      if (pet2Type) {
        // we also have pet 2 so add an ' and '
        pets += " and ";
      }
    }

    if (pet2Type) {
      // we did have pet 1 so add ' is '
      if (!pet1Type) {
        pets += " is ";
      }

      pets += `your ${pet2Type.text}`;

      if (pet2Name) {
        pets += `, ${pet2Name}`;
      }
    }
  }

  return `${pets}?`;
};

/**
 * Return a string identifying the front door
 * @param doorType Type of the door
 */
const getDoor = (doorType: string | undefined): string => {
  if (doorType) {
    return `The one with the ${doorType} front door? `;
  }

  return "";
};

/**
 * Get a sentence describing the log in to cybersanta site
 * @param boyGirl Boy or girl
 * @param username Username for login
 * @param password Password for login
 */
const getSecretAccess = (boyGirl: string, username: string, password: string): string =>
  `Now, I must give you a password to my top-secret website for good ${boyGirl.toLowerCase()}s. \
Simply visit www.cyber-santa.com and type in '${username}' and '${password}' - Enjoy!.`;

export interface ILetterTemplateData {
  firstName: string;
  boyGirl: string;
  ageYears?: number;
  ageMonths?: number;
  addressTown: string;
  hobby?: string;
  gift?: string;
  friend?: string;
  pet1?: IPetType;
  pet1Name?: string;
  pet2?: IPetType;
  pet2Name?: string;
  frontDoor?: string;
  login?: {
    username: string;
    password: string;
  };
}

interface ITemplateValues {
  town: string;
  name: string;
  boyGirl: string;
  age: string;
  gift: string;
  hobby: string;
  friend: string;
  pets: string;
  secret: string;
  frontDoor: string;
}

/**
 * Map the selected form values to values to use in the template
 * @param letter Data of letter
 */
export const mapTemplateValues = (letter: ILetterTemplateData): ITemplateValues => {
  const mappedValues: ITemplateValues = {
    // required values
    name: letter.firstName,
    boyGirl: letter.boyGirl.toLowerCase(),
    age: getAge(letter.ageYears, letter.ageMonths),
    town: letter.addressTown,
    hobby: getHobby(letter.hobby),
    gift: getGift(letter.gift),
    friend: letter.friend ? letter.friend.trim() : "",
    pets: getPets(letter.pet1, letter.pet1Name, letter.pet2, letter.pet2Name),
    secret: letter.login
      ? getSecretAccess(letter.boyGirl, letter.login.username, letter.login.password)
      : "",
    frontDoor: getDoor(letter.frontDoor),
  };

  return mappedValues;
};

/**
 * Return a compiled template, split into paragraphs
 * @param template Dot template string
 * @param values Values from the form
 */
export const getTemplateCompiledParagraphs = (
  template: string,
  values: ITemplateValues,
): string[] => {
  // add control characters to the templated values so that we can turn on highlighting
  const valuesWithControl = (Object.keys(values) as (keyof ITemplateValues)[]).reduce<
    Partial<ITemplateValues>
  >((a, k) => {
    // eslint-disable-next-line no-param-reassign
    a[k] = `\0${values[k]}\0`;

    return a;
  }, {});

  const templateFunction = dot.template(template, { ...dot.templateSettings, strip: false });
  const body = templateFunction(valuesWithControl);

  return body.split(/\n/);
};
