import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { PanelDiscHeading } from "../../molecules/panel-disc-heading";

const Container = styled.div<IStateProp>`
  ${({ state }): string => (state !== "todo" ? "background: white;" : "")}
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);
  flex: 1;

  @media ${device.mobileS} {
    margin-left: -12px;
    margin-right: -12px;
    ${({ state, theme }): string =>
      state === "todo" ? `border-top: ${theme.colours.textSubdued} 1px solid` : ""}
    ${({ state, theme }): string =>
      state === "todo" ? `border-bottom: ${theme.colours.textSubdued} 1px solid` : ""}
  }

  @media ${device.laptopL} {
    margin-left: 0;
    margin-right: 0;
    border-radius: 6px;
    ${({ state, theme }): string =>
      state === "todo" ? `border: ${theme.colours.textSubdued} 1px solid` : ""}
  }
`;

const Heading = styled(PanelDiscHeading)`
  padding: ${({ active }): string => (active ? "20px 20px 19px 20px" : "20px")};
  ${({ active, theme }): string =>
    active ? `border-bottom: ${theme.colours.textSubdued} 1px solid;` : ""}

  [data-santa-id="panel-disc-heading-title"] {
    font-weight: bold;
  }
`;

interface IStateProp {
  state: "todo" | "current" | "completed";
}

interface IProps extends IStateProp {
  count: number;
  title: string;
}

export const CheckoutPanel: React.FC<IProps> = ({ title, count, state, children }) => (
  <Container state={state}>
    <Heading active={state === "current"} icon={count.toString()} showCaret={state === "completed"}>
      {title}
    </Heading>
    {state === "current" && children}
  </Container>
);
