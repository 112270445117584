import React from "react";
import styled from "styled-components";
import { Hidden } from "react-grid-system";

import { displayPrice } from "../../../utils/price";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media ${device.mobileS} {
    width: 80px;

    img {
      width: 64px;
      height: 86px;
    }
  }

  @media ${device.laptopL} {
    width: 126px;

    img {
      width: 96px;
      height: 129px;
    }
  }
`;

const Title = styled.h3`
  font-weight: bold;
  margin-bottom: 0.2em;

  @media ${device.mobileS} {
    white-space: nowrap;
    font-size: ${({ theme }): string => theme.fontSizes.size18};
    text-align: center;
    margin-top: 13px;
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
    margin-top: 14px;
  }
`;

const Price = styled.div`
  @media ${device.mobileS} {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }

  @media ${device.laptopL} {
    font-size: ${({ theme }): string => theme.fontSizes.size22};
  }
`;

const AddOns = styled.div`
  white-space: nowrap;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
`;

const Icon = styled.span`
  margin-top: 40px;
  color: ${({ theme }): string => theme.colours.spotGold};
  font-weight: bold;

  @media ${device.mobileS} {
    font-size: 35px;
    padding: 0 10px;
  }

  @media ${device.laptopL} {
    font-size: 70px;
    padding: 0 15px;
  }
`;

interface IProps {
  title: string;
  imageUrl: string;
  description?: string;
  price?: number;
  addOns?: string[];
  icon?: "plus" | "equals";
}

export const ProductCombinationListItem: React.FC<IProps> = props => {
  const price =
    typeof props.price !== "undefined" ? <Price>{displayPrice(props.price)}</Price> : undefined;

  let icon: JSX.Element | undefined;
  if (typeof props.icon !== "undefined") {
    if (props.icon === "plus") {
      icon = <Icon>+</Icon>;
    } else if (props.icon === "equals") {
      icon = (
        <Hidden xs>
          <Icon>=</Icon>
        </Hidden>
      );
    }
  }

  let addOns: JSX.Element | undefined;
  if (typeof props.addOns !== "undefined") {
    const items = props.addOns.map((a, i) => (
      <React.Fragment key={i}>
        + {a}
        <br />
      </React.Fragment>
    ));
    addOns = <AddOns>{items}</AddOns>;
  }

  return (
    <Container>
      <ItemContainer>
        <img src={`${props.imageUrl}?w=96`} alt={props.title} />
        <Title>{props.title}</Title>
        {price}
        {props.description}
        <br />
        {addOns}
      </ItemContainer>
      {icon}
    </Container>
  );
};
