import { useState, useCallback } from "react";

interface IAddOnsLightboxData {
  isLightboxOpen: boolean;
  lightboxImageIndex: number;
  handleOpenLightbox: (index: number) => void;
  handleCloseLightbox: () => void;
  handleClickPreviousImage: () => void;
  handleClickNextImage: () => void;
}

export const useAddOnsLightbox = (images?: string[]): IAddOnsLightboxData => {
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState<number>(0);

  const handleCloseLightbox = useCallback(
    (): void => setIsLightboxOpen(false),
    [setIsLightboxOpen],
  );

  const handleOpenLightbox = (index: number): void => {
    console.log(index);
    setLightboxImageIndex(index);
    setIsLightboxOpen(true);
  };

  const handleClickPreviousImage = useCallback((): void => {
    if (images) {
      setLightboxImageIndex((lightboxImageIndex + images.length - 1) % images.length);
    }
  }, [setLightboxImageIndex, images, lightboxImageIndex]);

  const handleClickNextImage = useCallback((): void => {
    if (images) {
      setLightboxImageIndex((lightboxImageIndex + 1) % images.length);
    }
  }, [setLightboxImageIndex, images, lightboxImageIndex]);

  return {
    isLightboxOpen,
    lightboxImageIndex,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
  };
};
