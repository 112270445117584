import { Auth } from "aws-amplify";
import { ApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";

import { config } from "../../config";
import {
  basketState,
  callState,
  letterState,
  sackState,
  textState,
  videoState,
} from "../graphql/cache";

export const passwordWarning =
  "Your password must be 8 characters or greater and contain a number, a lowercase letter and an uppercase letter";

const isCognitoError = (e: unknown): e is { message: string; code?: string } =>
  e instanceof Object && "message" in e;

interface ISignUpParams {
  email: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
}

/**
 * Sign up a new user
 * @param params Object containing details for sign up
 */
export const signup = async (params: ISignUpParams): Promise<string> => {
  if (params.password !== params.passwordConfirm) {
    throw new Error("You must confirm your password");
  }

  try {
    const newUser = await Auth.signUp({
      username: params.email,
      password: params.password,
      attributes: {
        given_name: params.firstName,
        family_name: params.lastName,
      },
    });

    // wait for 1 second for lambda to auto confirm user
    await new Promise(resolve => setTimeout(resolve, 1000));

    return newUser.userSub;
  } catch (error) {
    if (isCognitoError(error)) {
      throw new Error(error.message);
    }
    throw new Error("Sorry, an unexpected error occurred");
  }
};

/**
 * Login an existing user
 * @param email Email of user
 * @param password Password of user
 */
export const login = async (email: string, password: string): Promise<string> => {
  try {
    const user = await Auth.signIn({
      password,
      username: email,
    });

    Sentry.configureScope(scope => scope.setUser({ email }));

    return user.userSub;
  } catch (error) {
    if (isCognitoError(error)) {
      throw new Error(error.message);
    }
    throw new Error("Sorry, an unexpected error occurred");
  }
};

/**
 * Log current user out
 */
export const logout = async (client: ApolloClient<unknown>): Promise<void> => {
  await Auth.signOut();

  if (config.features.clearCacheAfterLogout) {
    await client.clearStore();
    await client.cache.reset();
    basketState().clear();
    letterState().clear();
    callState().clear();
    textState().clear();
    sackState().clear();
    videoState().clear();
  }

  Sentry.configureScope(scope => scope.setUser(null));
};

export interface IUserProfile {
  email: string;
  firstName: string;
  lastName: string;
}

/**
 * Return an object representing the user of undefined if not logged in
 */
export const getUser = async (): Promise<IUserProfile | undefined> => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    return {
      email: user.attributes.email,
      firstName: user.attributes.given_name,
      lastName: user.attributes.family_name,
    };
  } catch (_e) {
    return;
  }
};

export const setUserInSentry = async (): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    Sentry.configureScope(scope => scope.setUser({ email: user.attributes.email }));
  } catch {
    // do nothing
  }
};

/**
 * Send a rest password code to the given email
 * @param email Email address to send code to
 */
export const sendResetPasswordCode = async (email: string): Promise<void> => {
  try {
    await Auth.forgotPassword(email);
  } catch (error) {
    if (isCognitoError(error)) {
      if (error.code === "UserNotFoundException") {
        return;
      }

      throw new Error(error.message);
    }

    throw new Error("There was a problem. Please try again later.");
  }
};

/**
 * Reset the password for a user
 * @param email The email of the user to set password for
 * @param code The code sent the the user's email
 * @param newPassword The new password
 */
export const resetPassword = async (
  email: string,
  code: string,
  newPassword: string,
): Promise<void> => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    if (isCognitoError(error)) {
      throw new Error(error.message);
    }

    throw new Error("Sorry, an unexpected error occurred");
  }
};

/**
 * Checks if a user is authenticated
 */
export const checkAuthenticated = async (): Promise<boolean> => {
  try {
    await Auth.currentSession();

    return true;
  } catch (_error) {
    // eslint-disable-next-line no-empty
  }

  return false;
};
