import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MainWrapper } from "../../atoms/containers/main-wrapper";
import { Heading2 } from "../../atoms/text";
import { MobileBackLink } from "../../molecules/mobile-back-link";
import { LogInForm, IFormModel } from "../../organisms/forms/log-in-form";
import { login } from "../../../model/authentication";
import { IHistoryLocationState } from "../../app";

export const LogIn: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<IHistoryLocationState>();

  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (history.location.state && history.location.state.loginReturnLocation) {
      setRedirectTo(history.location.state.loginReturnLocation);
    }
  }, [history.location.state]);

  const handleClickBack = useCallback(
    (): void => history.push(redirectTo ? redirectTo : "/"),
    [history, redirectTo],
  );

  const handleSubmit = useCallback(
    async (values: IFormModel): Promise<void> => {
      setFormError(undefined);

      try {
        await login(values.email.toLowerCase(), values.password);
        history.push("/my-account");
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message === "Incorrect username or password." ||
            error.message === "UserMigration failed with error Bad password."
          ) {
            setFormError("Incorrect email or password");
          } else {
            setFormError(error.message);
          }
        }
      }
    },
    [setFormError, history],
  );

  const handleClickForgotPassword = useCallback(
    (): void =>
      history.push("/forgot-password", { forgotPasswordReturnLocation: history.location.pathname }),
    [history],
  );

  return (
    <MainWrapper>
      <Container>
        <MobileBackLink onClick={handleClickBack}>Back</MobileBackLink>

        <Row>
          <Col xl={6} offset={{ xl: 3 }}>
            <Heading2>{t("login.title")}</Heading2>

            <LogInForm
              onSubmit={handleSubmit}
              formError={formError}
              onClickBack={handleClickBack}
              onClickForgotPassword={handleClickForgotPassword}
            />
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  );
};
