import * as React from "react";
import styled from "styled-components";

const Circle = styled.div<{ small?: boolean }>`
  width: ${({ small }): string => (small ? "18px" : "28px")};
  height: ${({ small }): string => (small ? "18px" : "28px")};
  border: 2px solid #8cc63f;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);

  span {
    margin-top: -0.1em;
    font-size: ${({ theme, small }): string =>
      small ? theme.fontSizes.size14 : theme.fontSizes.size16};
  }
`;

interface IProps {
  count: number;
  className?: string;
  small?: boolean;
}

export const NumberBadge: React.FC<IProps> = ({ count, className, small }) => (
  <Circle className={className} small={small}>
    <span>{count}</span>
  </Circle>
);
