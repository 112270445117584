import ReactMarkdown from "react-markdown";
import { Container } from "react-grid-system";
import styled from "styled-components";

import { Loader } from "../../../atoms/loader";
import { ContentHeader } from "../../../molecules/content-header";
import { ContentSection } from "../../../organisms/content-sections/content-section";
import { device } from "../../../../utils/media-queries";
import { getLocaleForApi } from "../../../../utils/graphql";
import { useContentPageQuery } from "../../../../types/graphql";

const IntroContentContainer = styled(Container)`
  @media ${device.mobileS} {
    padding: 60px 0;
  }

  @media ${device.laptopL} {
    padding: 60px 0 60px 0;
  }
`;

export const Support: React.FC = () => {
  const { data, loading } = useContentPageQuery({
    variables: { alphaId: "SUPPORT", locale: getLocaleForApi() },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ContentHeader title={data!.page!.title!} imageUrl={data!.page!.headerImage!.url!} />
      <ContentSection>
        <IntroContentContainer>
          <ReactMarkdown source={data!.page!.sections![0]!.body!} />
          <ReactMarkdown source={data!.page!.sections![1]!.body!} />
        </IntroContentContainer>
      </ContentSection>
    </>
  );
};
