import styled from "styled-components";

import { ReactComponent as CandyStripe } from "../../../images/svg/narrow-candy-divider.svg";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  height: 10px;
  background-color: ${({ theme }): string => theme.colours.divider.candy};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media ${device.mobileS} {
    width: 375px;
  }

  @media ${device.laptopL} {
    width: 1440px;
  }
`;

export const CandyDividerNarrow: React.FC = () => (
  <Container>
    <CandyStripe />
  </Container>
);
