import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { DropDownMenu } from "../../atoms/drop-down-menu";
import { DropDownMenuItem } from "../../atoms/drop-down-menu-item";
import { CaretIcon } from "../../atoms/icons/caret-icon";
import { LetterHomeOption, routePaths } from "../../../model/route";

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  color: ${({ theme }): string => theme.colours.text};
`;

const DropDown = styled(CaretIcon)`
  height: 8px;
  width: 15px;
  margin-top: 3px;
  margin-left: 10px;
`;

export const LettersMenuDropdown: React.FC<IProps> = () => {
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClick = (path: LetterHomeOption) =>
    useCallback((): void => history.push(routePaths.letter.types[path]), [history]);

  const handleClickMain = useCallback((): void => history.push(routePaths.letter.home), [history]);

  const items = [
    <DropDownMenuItem key={1} onClick={handleClick("classic")} label="Classic" />,
    <DropDownMenuItem key={2} onClick={handleClick("cookieMix")} label="With Cookie Mix" />,
    <DropDownMenuItem key={3} onClick={handleClick("plush")} label="With Plush Teddy" />,
    <DropDownMenuItem key={4} onClick={handleClick("magicSnow")} label="With Magic Snow" />,
    <DropDownMenuItem key={5} onClick={handleClick("santaSack")} label="With Santa Sack" />,
  ];

  return (
    <DropDownMenu items={items} id={"dropdown-letter"}>
      <LabelWrapper>
        <Label data-santa-id="dropdown-label-letter" onClick={handleClickMain}>
          Santa Letter
        </Label>
        <DropDown colour="midGrey" />
      </LabelWrapper>
    </DropDownMenu>
  );
};
