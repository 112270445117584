import styled from "styled-components";

import { Heading2 } from "../text";

const Prefix = styled.span`
  font-weight: normal;
`;

interface IProps {
  totalStages: number;
  currentStage: number;
}

export const StageTitle: React.FC<IProps> = ({ children, currentStage, totalStages }) => (
  <Heading2>
    <Prefix>
      Step {currentStage} of {totalStages}:
    </Prefix>{" "}
    {children}
  </Heading2>
);
