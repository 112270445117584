import { ISelectOption } from "@santa/common/lib/utils/form";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";
import { Field } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";

import { FormFieldWrapper } from "../../form-field-wrapper";
import {
  SearchableCountry,
  IAutoCompleteAddress,
  getAddress,
} from "../../../../model/address-lookup";
import { FormikFormInputSelect } from "../../../molecules/form/form-input-select";
import { AddressSearchPartial } from "../../../molecules/form/partials/address-seaarch-partial";
import { AddressFieldsFormPartial } from "../../../molecules/form/partials/address-fields-form-partial";
import { DataLoadedContainerOld, checkData } from "../../../control/data-loaded-container";
import { useAddressCountriesQuery } from "../../../../types/graphql";

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export interface IAddressFormModel {
  addressLine1: string;
  addressLine2: string;
  addressTown: string;
  addressCounty: string;
  addressPostcode: string;
  addressCountryUrn: string;
}

const shouldShowSearch = (countryUrn: string | undefined): countryUrn is SearchableCountry =>
  countryUrn === createUrn(UrnResource.COUNTRY, "gb") ||
  countryUrn === createUrn(UrnResource.COUNTRY, "us");

/**
 * Map countries from the API to select option
 * @param countries Lisr of US states from the API
 */
const countriesISelectOptions = (countries: { urn: string; name?: string }[]): ISelectOption[] =>
  countries.map(s => ({
    value: s.urn,
    label: s.name || "",
  }));

interface IProps {
  countryUrn?: string;
  addressIsFilled?: boolean;
  onChangeCountry?(urn: string): void;
  setFieldValue(field: string, value: string, shouldValidate?: boolean): void;
}

export const AddressForm: React.FC<IProps> = ({
  setFieldValue,
  onChangeCountry,
  countryUrn,
  addressIsFilled,
}) => {
  const [addressAlwaysVisible, setAddressAlwaysVisible] = useState(false);

  // graphql data
  const response = useAddressCountriesQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleSetManualAddress = useCallback(
    (): void => setAddressAlwaysVisible(true),
    [setAddressAlwaysVisible],
  );

  const setSelectedAddress = useCallback(
    async (selectedAddress: IAutoCompleteAddress): Promise<void> => {
      const address = await getAddress(selectedAddress);

      if (address) {
        if (address.line1) {
          setFieldValue("addressLine1", address.line1);
        }
        if (address.line2) {
          setFieldValue("addressLine2", address.line2);
        }
        setFieldValue("addressTown", address.town);
        if (address.stateUrn) {
          setFieldValue("addressUsStateUrn", address.stateUrn);
        }
        if (address.county) {
          setFieldValue("addressCounty", address.county);
        }

        setFieldValue("addressPostcode", address.postcode);

        setAddressAlwaysVisible(true);
      }
    },
    [setFieldValue],
  );

  // detect display search based on country
  useEffect(() => {
    setAddressAlwaysVisible(!shouldShowSearch(countryUrn));
  }, [countryUrn, setAddressAlwaysVisible]);

  const showAllFields = countryUrn && (addressIsFilled || addressAlwaysVisible);

  return (
    <>
      <Row>
        <Col>
          <DataLoadedContainerOld response={response}>
            {checkData(response.data) && (
              <FormFieldWrapper label="Country" name="addressCountryUrn" required>
                <FieldWrapper>
                  <Field
                    name="addressCountryUrn"
                    component={FormikFormInputSelect}
                    placeholder="Select country"
                    options={countriesISelectOptions(response.data.countries)}
                    onChange={onChangeCountry}
                  />
                </FieldWrapper>
              </FormFieldWrapper>
            )}
          </DataLoadedContainerOld>
        </Col>
      </Row>

      {!showAllFields && shouldShowSearch(countryUrn) && (
        <Row>
          <Col>
            <AddressSearchPartial
              onSelectAddress={setSelectedAddress}
              onSelectEnterManually={handleSetManualAddress}
              countryUrn={countryUrn}
            />
          </Col>
        </Row>
      )}

      {showAllFields && countryUrn && <AddressFieldsFormPartial countryUrn={countryUrn} />}
    </>
  );
};
