import * as dateFns from "date-fns";

import { IDescriptionAddOn, getAddOnDescription } from "../common";

export enum CallType {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
}

interface IGetCallDescriptionParams {
  addOnProducts: IDescriptionAddOn[];
  recipientName?: string | null;
  phoneNumber?: string;
  timezone: string;
  callTime: Date;
  includeEmailDetails: boolean;
}

/**
 * Return a description for an audio call given some parameters to describe it
 * @param params Params for call
 */
export const getAudioCallDescription = (params: IGetCallDescriptionParams): string => {
  let addOns = "";

  const productAddOns = params.addOnProducts
    .filter((a): a is Omit<IDescriptionAddOn, "title"> & { title: string } => a.title !== null)
    .map(a => a.title);

  if (productAddOns.length > 0) {
    addOns = `${getAddOnDescription(params.addOnProducts)}.`;
  }

  return `for ${params.recipientName} to be called at \
${dateFns.format(params.callTime, "h:mm a")} on ${dateFns.format(params.callTime, "PP")} \
to number ${params.phoneNumber} in ${params.timezone}.\
${addOns}`;
};

/**
 * Return a description for a video call given some parameters to describe it
 * @param params Params for call
 */
export const getVideoCallDescription = (params: IGetCallDescriptionParams): string => {
  const emailDetails = params.includeEmailDetails
    ? ". Meeting ID will be emailed, 1 hour before scheduled Video meeting with Santa"
    : "";

  return `for ${params.recipientName} to be called at \
${dateFns.format(params.callTime, "h:mm a")} on ${dateFns.format(params.callTime, "PP")} \
in ${params.timezone}${emailDetails}.\
${getAddOnDescription(params.addOnProducts)}`;
};

const FIRST_CALL_MONTH = 11; // zero indexed - December
const FIRST_CALL_DAY = 1;
const LAST_CALL_MONTH = 11; // zero indexed - December
const LAST_CALL_DAY = 31;

/**
 * Calculate the first date that Santa Calls can be made
 */
export const earliestCallDate = (): Date => {
  const nextCallDate = dateFns.startOfDay(new Date());

  const nextCallDateMonth = dateFns.getMonth(nextCallDate);
  const nextCallDateDay = dateFns.getDate(nextCallDate);
  const nextCallDateYear = dateFns.getYear(nextCallDate);

  if (nextCallDateMonth >= LAST_CALL_MONTH && nextCallDateDay > LAST_CALL_DAY) {
    return new Date(nextCallDateYear + 1, FIRST_CALL_MONTH - 1, FIRST_CALL_DAY);
  }

  if (
    nextCallDateMonth < FIRST_CALL_MONTH ||
    (nextCallDateMonth === FIRST_CALL_MONTH && nextCallDateDay < FIRST_CALL_DAY)
  ) {
    return new Date(nextCallDateYear, FIRST_CALL_MONTH, FIRST_CALL_DAY);
  }

  return nextCallDate;
};

/**
 * Calculate the last date that Santa Calls can be made
 */
export const lastCallDate = (): Date => {
  const nextCallDate = dateFns.startOfDay(new Date());

  const nextCallDateMonth = dateFns.getMonth(nextCallDate);
  const nextCallDateDay = dateFns.getDate(nextCallDate);
  const nextCallDateYear = dateFns.getYear(nextCallDate);

  // Set year to this year unless next call date is after the last call date
  const year =
    nextCallDateMonth >= LAST_CALL_MONTH && nextCallDateDay > LAST_CALL_DAY
      ? nextCallDateYear + 1
      : nextCallDateYear;

  return new Date(year, LAST_CALL_MONTH, LAST_CALL_DAY);
};
