import styled from "styled-components";

import { FormError } from "../../atoms/form-error";
import { FormFieldLabel } from "../../molecules/form-field-label";

const DivContainer = styled.div`
  display: block;
  margin-bottom: 19px;
`;

const LabelContainer = styled.label`
  display: block;
  margin-bottom: 19px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    :not(:first-child) {
      margin-left: 5px;
    }

    :not(:last-child) {
      margin-right: 5px;
    }
  }
`;

export interface IProps {
  tooltip?: string;
  label: string;
  required?: boolean;
  className?: string;
  noLabel?: boolean;
  name?: string;
  noError?: boolean;
  // customError?(): JSX.Element | undefined;
  info?(): JSX.Element | undefined;
  getErrorMessage?(message: string | object): string;
}

export const FormFieldWrapper: React.FC<IProps> = props => {
  const content = (
    <>
      <FormFieldLabel label={props.label} tooltip={props.tooltip} required={props.required} />
      <InputContainer>{props.children}</InputContainer>
      {!props.noError && (
        <>
          {/* {props.customError && props.customError()} */}
          {/* {!props.customError && props.name && <FormError name={props.name} />} */}
          {props.name && <FormError name={props.name} getMessage={props.getErrorMessage} />}
        </>
      )}
      {props.info && props.info()}
    </>
  );

  return props.noLabel ? (
    <DivContainer className={props.className}>{content}</DivContainer>
  ) : (
    <LabelContainer className={props.className}>{content}</LabelContainer>
  );
};
