import { Col, Container, Hidden, Row } from "react-grid-system";

import { TextsFrameImage } from "../../../atoms/texts-frame-image";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { TextEditForm } from "../../../organisms/forms/text-edit";

import { useData } from "./use-data";

export const MyAccountTextEdit: React.FC = () => {
  const { formProps, loaderProps } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      <MainWrapper>
        <Container>
          <Row>
            <Col md={6}>{formProps && <TextEditForm {...formProps} />}</Col>
            <Hidden xs>
              <Col md={6}>
                <TextsFrameImage />
              </Col>
            </Hidden>
          </Row>
        </Container>
      </MainWrapper>
    </DataLoadedContainer>
  );
};
