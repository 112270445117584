import { ISelectOption } from "@santa/common/lib/utils/form";
import { Col, Row } from "react-grid-system";
import { FormikErrors, FormikTouched } from "formik";
import * as yup from "yup";

import { FormFieldHobbyFreeText } from "../../../molecules/form/form-fields/form-field-hobby-free-text";
import { FormFieldGiftFreeText } from "../../../molecules/form/form-fields/form-field-gift-free-text";
import { FormFieldFriendName } from "../../../molecules/form/form-fields/form-field-friend-name";
import { FormFieldPetType } from "../../../molecules/form/form-fields/form-field-pet-type";
import { FormFieldPetName } from "../../../molecules/form/form-fields/form-field-pet-name";
import { FormFieldFrontDoor } from "../../../molecules/form/form-fields/form-field-front-door";
import { FormFieldFirstNameFreeText } from "../../../molecules/form/form-fields/form-field-first-name-free-text";
import { FormFieldLastName } from "../../../molecules/form/form-fields/form-field-last-name";
import { FormFieldBoyGirl } from "../../../molecules/form/form-fields/form-field-boy-girl";
import { FormFieldAge } from "../../../molecules/form/form-fields/form-field-age";
import { FormFieldNotes } from "../../../molecules/form/form-fields/form-field-notes";

export const validationRules = {
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  ageYears: yup
    .number()
    .min(0)
    .max(200, "Are they really that old?!")
    // eslint-disable-next-line func-names
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageMonths } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageMonths)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  ageMonths: yup
    .number()
    .min(0)
    .max(36, "It will help Santa if you use years")
    // eslint-disable-next-line func-names
    .test(
      "requiredTest",
      "Enter at least one of years or months",
      function (value?: number): boolean {
        const { ageYears } = this.parent;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(ageYears)) {
          return Boolean(value);
        }

        return true;
      },
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  boyGirl: yup.string().nullable().required("Required"),
  hobby: yup.string(),
  gift: yup.string(),
  friend: yup.string().max(50, "Maximum 50 characters"),
  frontDoorId: yup.string(),
  pet1Id: yup.string(),
  pet1Name: yup.string(),
  pet2Id: yup.string(),
  pet2Name: yup.string(),
  notes: yup.string(),
};

export interface IDefaultValuesModel {
  firstName: string;
  lastName: string;
  ageYears: string;
  ageMonths: string;
  boyGirl: string;
  hobby?: string;
  gift?: string;
  friend: string;
  frontDoorId: string;
  pet1Id: string;
  pet1Name: string;
  pet2Id: string;
  pet2Name: string;
  notes: string;
}

interface IProps {
  prefix?: "first" | "second";
  errors: FormikErrors<IDefaultValuesModel>;
  touched: FormikTouched<IDefaultValuesModel>;
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
}

/**
 * Form section for letter content
 */
export const FormSectionCallRecipient: React.FC<IProps> = ({
  prefix,
  errors,
  touched,
  petOptions,
  frontDoorOptions,
}) => {
  const getId = (id: string): string => (prefix ? `${prefix}.${id}` : id);

  return (
    <>
      <Row>
        <Col xl={3}>
          <FormFieldFirstNameFreeText id={getId("firstName")} />
        </Col>
        <Col xl={3}>
          <FormFieldLastName id={getId("lastName")} />
        </Col>
        <Col xl={6}>
          <FormFieldBoyGirl id={getId("boyGirl")} />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormFieldAge
            errors={errors}
            touched={touched}
            yearsId={getId("ageYears")}
            monthsId={getId("ageMonths")}
          />
        </Col>
        <Col xl={6}>
          <FormFieldHobbyFreeText id={getId("hobby")} />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <FormFieldGiftFreeText id={getId("gift")} />
        </Col>
        <Col xl={4}>
          <FormFieldFriendName id={getId("friend")} />
        </Col>
        <Col xl={4}>
          <FormFieldFrontDoor options={frontDoorOptions} id={getId("frontDoorId")} />
        </Col>
      </Row>
      <Row>
        <Col xl={3}>
          <FormFieldPetType id={getId("pet1Id")} options={petOptions} label="Pet or relative 1" />
        </Col>
        <Col xl={3}>
          <FormFieldPetName id={getId("pet1Name")} label="Name of pet or relative 1" />
        </Col>
        <Col xl={3}>
          <FormFieldPetType id={getId("pet2Id")} options={petOptions} label="Pet or relative 2" />
        </Col>
        <Col xl={3}>
          <FormFieldPetName id={getId("pet2Name")} label="Name of pet or relative 2" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldNotes id={getId("notes")} />
        </Col>
      </Row>
    </>
  );
};
