import * as CSS from "csstype";
import { FieldProps } from "formik";
import React, { CSSProperties, useCallback } from "react";
import Select from "react-select/async";
import { components, InputProps } from "react-select";
import { IndicatorProps } from "react-select/src/components/indicators";
import { Props as SelectProps } from "react-select/src/Async";
import { ValueType, OptionTypeBase } from "react-select/src/types";
import styled from "styled-components";

import { theme } from "../../../../theme";

const DropdownIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 3px;
  }
`;

type Props = SelectProps<OptionTypeBase, false> & {
  isError?: boolean;
};

const DropdownIndicator: React.FC<IndicatorProps<OptionTypeBase, false>> = props => {
  const { innerProps } = props;

  return (
    <DropdownIndicatorContainer {...innerProps}>
      <img
        alt="caret"
        src="data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.81088 4.8764C4.41175 5.42946 3.58825 5.42946 3.18912 4.87641L0.813896 1.58521C0.336597 0.923842 0.809173 0 1.62478 0L6.37522 0C7.19083 0 7.6634 0.923841 7.1861 1.58521L4.81088 4.8764Z' fill='%2390A4AE'/%3E%3C/svg%3E%0A"
      />
    </DropdownIndicatorContainer>
  );
};

const IndicatorSeparator = (): JSX.Element => <div />;

// cannot extend react-select component.Input typings to include autocomplete property so need to do this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectInput: any = components.Input;
const Input = (props: InputProps): JSX.Element => (
  <SelectInput as any {...props} autocomplete="chrome-off" />
);

export const FormInputSelectSearch: React.FC<Props> = props => {
  const selectProps: SelectProps<OptionTypeBase, false> = {
    ...props,
    noOptionsMessage: (): string => "Type to search",
    blurInputOnSelect: true,
    inputProps: { autoComplete: "chrome-off" },
    styles: {
      input: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        margin: 0,
        padding: 0,
      }),
      container: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        flex: 1,
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control: (provided: CSSProperties, state: any): CSSProperties => {
        let borderColor = theme.colours.form.placeholder;

        if (state.isFocused) {
          borderColor = theme.colours.spotGold;
        } else if (props.isError) {
          borderColor = theme.colours.alert;
        }

        const styles: CSSProperties & {
          "&:hover"?: CSS.Properties<string | number>;
        } = {
          ...provided,
          borderColor,
          minWidth: "100px",
          padding: "4px 11px 5px 7px",
          boxSizing: "border-box",
          fontFamily: `${theme.fonts.main}`,
          fontSize: `${theme.fontSizes.size16}`,
          boxShadow: undefined,
          minHeight: undefined,
          "&:hover": {
            borderColor,
          },
        };

        return styles;
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme: (t: any) => ({
      ...t,
      borderRadius: 6,
    }),
    components: { DropdownIndicator, IndicatorSeparator, Input },
  };

  return <Select {...selectProps} />;
};

export const FormikFormInputSelectSearch: React.FC<
  FieldProps &
    SelectProps<OptionTypeBase, false> & {
      options: { label: string; value: string }[];
    }
> = ({ field, form: { touched, errors, setFieldValue, setFieldTouched }, ...props }) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = useCallback(
    (): void => setFieldTouched(field.name),
    [setFieldTouched, field],
  );
  const handleChange = useCallback(
    (option: ValueType<OptionTypeBase, false>): void => setFieldValue(field.name, option),
    [setFieldValue, field.name],
  );

  return (
    <FormInputSelectSearch
      name={field.name}
      onBlur={field.onBlur}
      onFocus={setFieldFocussed}
      isError={isError}
      onChange={handleChange}
      placeholder={props.placeholder}
      value={field.value}
      isSearchable={true}
      loadOptions={props.loadOptions}
      cacheOptions={props.cacheOptions}
    />
  );
};
