import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
}

export const FormFieldPetName: React.FC<IProps> = ({
  id = "petName",
  label = "Name of pet or relative",
  placeholder = "Enter name",
}) => (
  <FormFieldWrapper label={label}>
    <Field name={id} placeholder={placeholder} component={FormikFormInputText} />
  </FormFieldWrapper>
);
