import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import { ReactComponent as PlaySvg } from "./play.svg";

export const PlayButton = styled(PlaySvg)`
  @media ${device.mobileS} {
    width: 30px;
    height: 35px;
  }

  @media ${device.laptopL} {
    width: 40px;
    height: 46px;
  }
`;
