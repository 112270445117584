import styled from "styled-components";

const List = styled.ul`
  margin: 8px 0;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  list-style: none;
  padding-inline-start: 0px;

  li {
    :before {
      content: "+";
      font-size: 1.2em;
      display: inline-block;
      vertical-align: -1px;
      margin-right: 7px;
    }

    :not(:first-child) {
      margin-top: 2px;
    }

    :not(:last-child) {
      margin-bottom: 2px;
    }

    em {
      margin-left: 8px;
      font-style: normal;
      font-weight: 300;
    }
  }
`;

interface IProps {
  items: JSX.Element[];
}

export const ProductAddOnList: React.FC<IProps> = ({ items }) => {
  const listItems = items.map((a, i) => <li key={i}>{a}</li>);

  return <List>{listItems}</List>;
};
