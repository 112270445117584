import dot from "dot";

import { BoyGirl } from "../../../types/graphql";
import { IPetLookup } from "../../common";

interface ITemplateLookup {
  id: string;
  label?: string | null;
  template1?: string | null;
  template2?: string | null;
  template3?: string | null;
}

interface ITextLookups {
  templates: ITemplateLookup[];
  pets: IPetLookup[];
}

interface ITextTemplateInputValues {
  templateId: string;
  name: string;
  boyGirl: BoyGirl;
  age: number;
  town: string;
  petId?: string | null;
  petName?: string | null;
}

interface ITextTemplateValues {
  name: string;
  boyGirl: string;
  age: string;
  town: string;
  pet?: string;
}

/**
 * Return a string identifying the pet selected
 * @param lookup Lookup of pets
 * @param petId ID of pet
 * @param petName name of pet
 */
const getPet = (
  lookup: IPetLookup[],
  petId: string | undefined | null,
  petName: string | undefined | null,
): string => {
  if (!petId) {
    return "";
  }

  // pet 1 is supplied
  const pet = lookup.find(v => v.id === petId);

  if (!pet) {
    return "";
  }

  let petDescription = `I hope your ${pet.text!}`;

  if (petName) {
    petDescription += `, ${petName},`;
  }

  return `${petDescription} is OK.`;
};

/**
 * Map the input values to values for the templates
 * @param petLookup Lookup of pets
 * @param values Input values
 */
const mapInputValues = (
  petLookup: IPetLookup[],
  values: ITextTemplateInputValues,
): ITextTemplateValues => ({
  name: values.name.trim(),
  boyGirl: values.boyGirl === BoyGirl.BOY ? "boy" : "girl",
  age: values.age.toString(),
  town: values.town,
  pet: getPet(petLookup, values.petId, values.petName),
});

/**
 * Get the compiled text message templates
 * @param lookups Lookup of values for templates and pets
 * @param values Input values for templates
 */
export const getTextMessageTemplates = (
  lookups: ITextLookups,
  values: ITextTemplateInputValues,
): [string, string, string] | undefined => {
  if (values.templateId && values.age && values.boyGirl && values.name && values.town) {
    const templates = lookups.templates.find(t => t !== null && t.id === values.templateId);

    if (templates) {
      const renderTemplate = (template?: string | null): string => {
        if (!template) {
          return "";
        }

        const renderTemplate = dot.template(template);
        return renderTemplate(mapInputValues(lookups.pets, values));
      };

      return [
        renderTemplate(templates.template1),
        renderTemplate(templates.template2),
        renderTemplate(templates.template3),
      ];
    }
  }
};
