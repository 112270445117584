import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { initialsFromString } from "../../../utils/string";
import { AvatarPlaceholder } from "../../atoms/avatar-placeholder";
import { CirclesWrapper } from "../../atoms/circles-wrapper";
import { Heading1, Heading2, Heading3 } from "../../atoms/text";
import { BaubleList } from "../../molecules/bauble-list";
import { CandyContentSection } from "../content-sections/candy-content-section";

import baublesImage from "./fabric-baubles.png";

const StyledCandyContentSection = styled(CandyContentSection)`
  @media ${device.laptopL} {
    background-color: white;
  }
`;

const ContentWrapper = styled.div`
  padding: 60px 0 24px 0;

  @media ${device.laptopL} {
    padding: 102px 0 69px 0;
  }
`;

const StyledHeading = styled(Heading1)`
  margin: 0 0 20px 0;
`;

const Baubles = styled.img`
  width: 75px;
  height: 195px;
  position: absolute;
  right: 0;
  top: -20px;

  @media ${device.laptopL} {
    width: 149px;
    height: 388px;
    top: 0;
  }
`;

interface IProps {
  name: string;
  email: string;
}

const Avatar = styled(CirclesWrapper)`
  margin-left: 30px;
`;

const Initials = styled(AvatarPlaceholder)`
  > span {
    font-size: ${({ theme }): string => theme.fontSizes.size36};
  }
`;

const Attributes = styled(BaubleList)`
  ul > li {
    font-size: ${({ theme }): string => theme.fontSizes.size22};

    @media ${device.laptopL} {
      font-size: ${({ theme }): string => theme.fontSizes.size16};
    }
  }
`;

const MobileName = styled(Heading2)`
  margin-left: 10px;
`;

const getBackground = (): JSX.Element => <Baubles src={baublesImage} />;

export const MyProfilePanel: React.FC<IProps> = ({ name, email }) => (
  <StyledCandyContentSection noBottomStripes backgroundContent={getBackground}>
    <ContentWrapper>
      <Container>
        <Row>
          <Col>
            <StyledHeading>My Profile</StyledHeading>
          </Col>
        </Row>
        <Row align="center">
          <Col lg={3} xs={6}>
            <Avatar tree="left" size="small">
              <Initials initials={initialsFromString(name)} />
            </Avatar>
          </Col>
          <Visible xs>
            <Col xs={6}>
              <MobileName>{name}</MobileName>
            </Col>
          </Visible>
          <Col lg={6}>
            <Hidden xs>
              <Heading3>{name}</Heading3>
            </Hidden>
            <Attributes>
              <ul>
                <li>{email}</li>
              </ul>
            </Attributes>
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  </StyledCandyContentSection>
);
