import React, { useCallback, useState } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "../modal";
import { ButtonLink } from "../../atoms/button-link";
import { Paragraph } from "../../atoms/text";

const Wrapper = styled.div``;

const CropWrapper = styled.div`
  max-width: 500px;

  img {
    width: 100%;
  }
`;

const getColor = (props: DropzoneRootProps): string => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Dropzone = styled.div<DropzoneRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props): string => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    :first-of-type {
      margin-top: 0;
    }
  }
`;

interface IProps {
  onComplete(fileUrl: string): void;
}

export const ImageUploader: React.FC<IProps> = ({ onComplete }) => {
  const [imageData, setImageData] = useState<string>();
  const [croppedImageData, setCroppedImageData] = useState<string>();
  const [imageRef, setImageRef] = useState<HTMLImageElement>();
  const [crop, setCrop] = useState<ReactCrop.Crop>({ aspect: 16 / 9 });

  const onDrop = ([file]: File[]): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (): void => {
      if (typeof reader.result === "string") {
        setImageData(reader.result);
      }
    };
  };

  const onImageLoaded = useCallback(
    (image: HTMLImageElement): void => setImageRef(image),
    [setImageRef],
  );
  const onCropChange = useCallback((crop: ReactCrop.Crop): void => setCrop(crop), [setCrop]);

  const getCroppedImage = (
    image: HTMLImageElement,
    crop: ReactCrop.Crop,
  ): Promise<string | void> => {
    if (crop.width && crop.height && crop.x && crop.y) {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }

            const reader = new FileReader();
            reader.onloadend = (): void => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }, "image/jpeg");
        });
      }
    }

    return Promise.resolve();
  };

  const onCropComplete = useCallback(
    async (crop: ReactCrop.Crop): Promise<void> => {
      if (imageRef && crop.width && crop.height) {
        const croppedImageUrl = await getCroppedImage(imageRef, crop);
        if (croppedImageUrl) {
          setCroppedImageData(croppedImageUrl);
        }
      }
    },
    [imageRef, setCroppedImageData],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDrop,
  });

  const handleClickClose = useCallback((): void => setImageData(undefined), [setImageData]);
  const handleClickDone = useCallback((): void => {
    if (croppedImageData) {
      onComplete(croppedImageData);
    }
  }, [croppedImageData, onComplete]);

  return (
    <Wrapper>
      {!imageData && (
        <>
          <Dropzone {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <p>Drag and drop your photo here, or click to select</p>
            <em>(Only *.jpeg and *.png images allowed)</em>
          </Dropzone>
        </>
      )}

      {imageData && (
        <Modal onClickClose={handleClickClose}>
          <Paragraph>Select part of your photo below to use in Santa&apos;s video</Paragraph>
          <CropWrapper>
            <ReactCrop
              src={imageData}
              crop={crop}
              onChange={onCropChange}
              onComplete={onCropComplete}
              onImageLoaded={onImageLoaded}
            />
          </CropWrapper>
          {croppedImageData && <ButtonLink label="Done" onClick={handleClickDone} />}
        </Modal>
      )}
    </Wrapper>
  );
};
