import { Hub } from "aws-amplify";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

import { checkAuthenticated } from "../../../model/authentication";
import { Loader } from "../../atoms/loader";

export const AuthContext = React.createContext<boolean | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

  // check if user is logged in on mount
  useAsyncEffect(async () => {
    setIsAuthenticated(await checkAuthenticated());
    setIsAuthenticating(false);
  }, []);

  // listen to sign in and out events and set session appropriately
  Hub.listen("auth", (data): void => {
    switch (data.payload.event) {
      case "signIn":
        setIsAuthenticated(true);
        break;
      case "signOut":
        setIsAuthenticated(false);
        break;
      default:
        break;
    }
  });

  return (
    <AuthContext.Provider value={isAuthenticated}>
      {isAuthenticating ? <Loader /> : children}
    </AuthContext.Provider>
  );
};
