import { Field, Form, FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import { Col, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import * as Yup from "yup";

import { BowButton } from "../../../atoms/bow-button";
import { Paragraph } from "../../../atoms/text";
import { FormikFormInputRadio } from "../../../molecules/form/form-input-radio";
import { FormikFormInputText } from "../../../molecules/form/form-input-text";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { device } from "../../../../utils/media-queries";
import { passwordWarning } from "../../../../model/authentication";
import { FormikFormInputCheckbox } from "../../../molecules/form/form-input-checkbox";
import { FormFieldLink } from "../../../atoms/form-field-link";
import { config } from "../../../../config";

const SubmitCol = styled(Col)`
  display: flex;
  padding-top: 20px;

  @media ${device.mobileS} {
    justify-content: center;
  }

  @media ${device.laptopL} {
    justify-content: flex-end;
  }
`;

const Error = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export enum SignupFormStatus {
  EXISTING = "EXISTING",
  NEW = "NEW",
}

declare module "yup" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface StringSchema {
    requiredIfExisting(msg: string): StringSchema;
    requiredIfNew(msg: string): StringSchema;
  }
}

function test(context: Yup.TestContext, statusCheck: SignupFormStatus, value: string): boolean {
  const { status } = context.parent;
  if (status === statusCheck) {
    return typeof value !== "undefined";
  }

  return true;
}

function requiredIfExisting(this: Yup.StringSchema, message: string): Yup.StringSchema {
  return this.test("requiredIfExisting", message, function (value?: string): boolean {
    return value ? test(this, SignupFormStatus.EXISTING, value) : true;
  });
}

function requiredIfNew(this: Yup.StringSchema, message: string): Yup.StringSchema {
  return this.test("requiredIfNew", message, function (value?: string): boolean {
    return value ? test(this, SignupFormStatus.NEW, value) : true;
  });
}

Yup.addMethod(Yup.string, "requiredIfExisting", requiredIfExisting);
Yup.addMethod(Yup.string, "requiredIfNew", requiredIfNew);

const FormSchema = Yup.object().shape({
  status: Yup.string().required("Required"),

  email: Yup.string().max(50, "Maximum 50 characters").email().required("Required"),

  loginPassword: Yup.string().requiredIfExisting("Required"),

  registerFirstName: Yup.string().max(50, "Maximum 50 characters").requiredIfNew("Required"),
  registerLastName: Yup.string().max(50, "Maximum 50 characters").requiredIfNew("Required"),
  registerPassword: Yup.string()
    .min(8, "Minimum 8 characters")
    .matches(/\d/, passwordWarning)
    .matches(/[a-z]/, passwordWarning)
    .matches(/[A-Z]/, passwordWarning)
    .requiredIfNew("Required"),
  registerPasswordConfirm: Yup.string().test(
    "matchesTest",
    "Passwords must match",
    function (value?: string): boolean {
      const { status } = this.parent;
      if (status === SignupFormStatus.NEW) {
        return this.parent.registerPassword === "" || value === this.parent.registerPassword;
      }

      return true;
    },
  ),
  registerMailingList: Yup.boolean(),
});

export interface IFormModel {
  status: SignupFormStatus | undefined;
  email: string;
  registerFirstName: string;
  registerLastName: string;
  registerPassword: string;
  registerPasswordConfirm: string;
  registerMailingList: boolean;
  loginPassword: string;
}

interface IOwnProps {
  formError?: string;
  onSubmit(values: IFormModel): void;
  onChangeStatus(type: SignupFormStatus): void;
  onClickForgotPassword(): void;
}

type Props = FormikProps<IFormModel> & IOwnProps;

const C: React.FC<Props> = props => {
  const { values, submitForm, isValid } = props;

  let fields: JSX.Element;

  if (values.status === SignupFormStatus.NEW) {
    fields = (
      <>
        <Row>
          <Col xl={12}>
            <FormFieldWrapper label="Email address" name="email" required>
              <Field
                name="email"
                component={FormikFormInputText}
                placeholder="Enter your email address"
                required
              />
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <FormFieldWrapper label="First name" name="registerFirstName" required>
              <Field
                name="registerFirstName"
                component={FormikFormInputText}
                placeholder="Enter your first name"
                maxlength={50}
                required
              />
            </FormFieldWrapper>
          </Col>
          <Col xl={6}>
            <FormFieldWrapper label="Last name" name="registerLastName" required>
              <Field
                name="registerLastName"
                component={FormikFormInputText}
                placeholder="Enter your last name"
                maxlength={50}
                required
              />
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <FormFieldWrapper
              label="Create a Password"
              name="registerPassword"
              tooltip={passwordWarning}
              required
            >
              <Field
                name="registerPassword"
                type="password"
                component={FormikFormInputText}
                placeholder="Enter a new password"
                required
              />
            </FormFieldWrapper>
          </Col>
          <Col xl={6}>
            <FormFieldWrapper label="Confirm password" name="registerPasswordConfirm" required>
              <Field
                name="registerPasswordConfirm"
                type="password"
                component={FormikFormInputText}
                placeholder="Confirm your password"
                required
              />
            </FormFieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="registerMailingList"
              component={FormikFormInputCheckbox}
              label={`Tick this box to receive offers and reminders from ${config.site.name} (max 3 emails per year)`}
            />
          </Col>
        </Row>
      </>
    );
  } else {
    fields = (
      <>
        <Row>
          <Col xl={6}>
            <FormFieldWrapper label="Email address" name="email" required>
              <Field
                name="email"
                component={FormikFormInputText}
                placeholder="Enter your email address"
                required
              />
            </FormFieldWrapper>
          </Col>
          <Col xl={6}>
            <FormFieldWrapper label="Password" name="loginPassword" required>
              <FieldWrapper>
                <Field
                  name="loginPassword"
                  type="password"
                  component={FormikFormInputText}
                  placeholder="Enter your password"
                  required
                />
                <FormFieldLink onClick={props.onClickForgotPassword}>
                  Forgot your password?
                </FormFieldLink>
              </FieldWrapper>
            </FormFieldWrapper>
          </Col>
        </Row>
      </>
    );
  }

  // we do now disable on login form  becuase of
  // https://github.com/facebook/react/issues/1159#issuecomment-506584346
  const isSubmitDisabled = !isValid && values.status !== SignupFormStatus.EXISTING;

  return (
    <Form>
      {props.formError && <Error>{props.formError}</Error>}
      <Row>
        <Col xl={6}>
          <FormFieldWrapper label="Returning customer?" name="status" required>
            <Field
              name="status"
              component={FormikFormInputRadio}
              value={SignupFormStatus.NEW}
              label="New"
              required
              onClick={useCallback((): void => props.onChangeStatus(SignupFormStatus.NEW), [props])}
            />
            <Field
              name="status"
              component={FormikFormInputRadio}
              value={SignupFormStatus.EXISTING}
              label="Existing"
              required
              onClick={useCallback(
                (): void => props.onChangeStatus(SignupFormStatus.EXISTING),
                [props],
              )}
            />
          </FormFieldWrapper>
        </Col>
      </Row>

      {fields}

      <Row>
        <SubmitCol>
          <Visible md lg xl>
            <BowButton size={210} onClick={submitForm} disabled={isSubmitDisabled}>
              Continue
            </BowButton>
          </Visible>
          <Visible xs sm>
            <BowButton size={330} onClick={submitForm} disabled={isSubmitDisabled}>
              Continue
            </BowButton>
          </Visible>
        </SubmitCol>
      </Row>
    </Form>
  );
};

export const CheckoutSignupForm = withFormik<IOwnProps, IFormModel>({
  validationSchema: FormSchema,
  mapPropsToValues: (): IFormModel => ({
    status: SignupFormStatus.NEW,
    registerFirstName: "",
    registerLastName: "",
    email: "",
    registerPassword: "",
    registerPasswordConfirm: "",
    registerMailingList: false,
    loginPassword: "",
  }),
  handleSubmit: (values, formik) => {
    formik.props.onSubmit(values);
  },
})(C);
