import styled from "styled-components";

export const Disc = styled.div<{ active: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  font-weight: bold;
  background-color: ${({ active, theme }): string =>
    active ? theme.colours.spotGold : theme.colours.textSubdued};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white;
    margin-top: -2px;
  }
`;
