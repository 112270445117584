import styled from "styled-components";
import { Visible } from "react-grid-system";

import { BackLink } from "../back-link";
import { BowButton } from "../../atoms/bow-button";

const SubmitRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IProps {
  onClickBack?(): void;
  onClickSubmit(): void;
  isSubmitEnabled: boolean;
  submitLabel?: string;
  size?: 430 | 330 | 210;
}

/**
 * Component for form submit buttons
 */
export const FormSubmitRow: React.FC<IProps> = ({
  children,
  isSubmitEnabled,
  onClickBack,
  onClickSubmit,
  submitLabel = "Next Step",
  size = 210,
}) => (
  <SubmitRow>
    <Visible md lg xl>
      {onClickBack && <BackLink label="Back" onClick={onClickBack} />}
    </Visible>
    {children}
    <SubmitWrapper>
      <BowButton size={size} onClick={onClickSubmit} disabled={!isSubmitEnabled}>
        {submitLabel}
      </BowButton>
    </SubmitWrapper>
  </SubmitRow>
);
