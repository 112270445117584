import styled from "styled-components";

import { ReactComponent as FrameImage } from "./frame.svg";
import santaImage from "./santa-on-phone.jpg";
import santaVideoImage from "./santa-on-zoom.jpg";

const Container = styled.div`
  width: 641px;
  height: 560px;
  position: relative;
`;

const Frame = styled(FrameImage)`
  width: 641px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Image = styled.img`
  left: 39px;
  top: 40px;
  position: absolute;
  z-index: 1;
  width: 576px;
  height: 502px;
`;

export const CallsFrameImage: React.FC = () => (
  <Container>
    <Frame />
    <Image src={santaImage} alt="Santa on the phone" />
  </Container>
);

export const CallVideoFrameImage: React.FC = () => (
  <Container>
    <Frame />
    <Image src={santaVideoImage} alt="Santa on video call" />
  </Container>
);
