import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { SantaLetterDetailsContentForm } from "../../../../organisms/forms/santa-letter-details-content";
import { letterCreateState, letterState } from "../../../../../model/graphql/cache";
import { routePaths } from "../../../../../model/route";
import { config } from "../../../../../config";

type FormProps = Omit<
  React.ComponentProps<typeof SantaLetterDetailsContentForm>,
  | "templateOptions"
  | "psOptions"
  | "signatureOptions"
  | "petOptions"
  | "frontDoorOptions"
  | "onClickOpenMobilePreview"
>;

interface IUseData {
  formProps: FormProps;
}

const mapDataToForm = ({
  content,
}: ReturnType<typeof letterCreateState>): FormProps["defaultValues"] => {
  if (content) {
    return {
      hobby: content.hobby,
      gift: content.gift,
      friend: content.friend || "",
      template: {
        label: content.template.label || "",
        value: content.template.value || "",
        description: content.template.description || "",
      },
      signatureId: content.signatureId,
      frontDoorId: content.frontDoorId || "",
      pet1Id: content.pet1Id || "",
      pet1Name: content.pet1Name || "",
      pet2Id: content.pet2Id || "",
      pet2Name: content.pet2Name || "",
      psId: content.psId || "",
      psCustom: content.psCustom || "",
    };
  }

  return {
    signatureId: config.formDefaults.letterSignature.toString(),
  };
};

/**
 * Hook to get data for letter content form
 */
export const useData = (onChange: () => void): IUseData => {
  const history = useHistory();
  const currentState = letterCreateState();

  useEffect(() => {
    onChange();
  }, []);

  const goBack = (): void => history.push(routePaths.letter.recipient);

  useEffect(() => {
    if (!currentState.recipient) {
      goBack();
    }
  }, [history, currentState]);

  const onClickBack = useCallback(goBack, [history]);

  const defaultValues = useMemo(() => mapDataToForm(currentState), []);

  return {
    formProps: {
      onChange: data => {
        letterState().setContentDetails(data);
        onChange();
      },
      onSubmit: data => {
        letterState().setContentDetails(data);
        history.push(routePaths.letter.addOns);
      },
      defaultValues,
      onClickBack,
    },
  };
};
