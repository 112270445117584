import { Visible } from "react-grid-system";
import { useEffect } from "react";
import { ISelectOption, ISelectMultilineOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";

import { PreviewButton } from "../../../molecules/buttons/preview-button";
import {
  FormSectionLetterContent,
  validationRules,
  IDefaultValuesModel as FormModel,
} from "../../form-sections/letter/form-section-letter-content";
import { FormSubmitRow } from "../../../molecules/form-submit-row";

const validationSchema = yup.object(validationRules);
export type LetterContentValidatedFormModel = yup.Asserts<typeof validationSchema>;

interface IOwnProps {
  defaultValues: FormModel;
  templateOptions: ISelectMultilineOption[];
  psOptions: ISelectOption[];
  signatureOptions: ISelectOption[];
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
  onChange(values: LetterContentValidatedFormModel): void;
  onSubmit(values: LetterContentValidatedFormModel): void;
  onClickBack(): void;
  onClickOpenMobilePreview(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  templateOptions,
  psOptions,
  signatureOptions,
  petOptions,
  frontDoorOptions,
  submitForm,
  onChange,
  onClickOpenMobilePreview,
  onClickBack,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as LetterContentValidatedFormModel);
    }
  }, [values, isValid]);

  const formProps = {
    values,
    templateOptions,
    petOptions,
    frontDoorOptions,
    signatureOptions,
    psOptions,
    ...(isValid && { onClickOpenMobilePreview }),
  };

  return (
    <Form>
      <FormSectionLetterContent {...formProps} />
      <FormSubmitRow isSubmitEnabled={isValid} onClickSubmit={submitForm} onClickBack={onClickBack}>
        {isValid && (
          <Visible xs sm>
            <PreviewButton onClick={onClickOpenMobilePreview} />
          </Visible>
        )}
      </FormSubmitRow>
    </Form>
  );
};

export const SantaLetterDetailsContentForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as LetterContentValidatedFormModel),
})(C);
