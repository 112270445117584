import styled from "styled-components";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

import { device } from "../../../../utils/media-queries";
import { config } from "../../../../config";

const OuterContainer = styled.div`
  background-color: #ece4e4;
  position: relative;
  margin: 0 auto;

  @media ${device.mobileS} {
    width: 375px;
    height: 625px;
  }

  @media ${device.laptopL} {
    width: 1440px;
    height: 630px;
  }
`;

const ChildrenContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MapContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

export const MapSection: React.FC = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMaps.apiKey,
  });

  const map = isLoaded ? (
    <GoogleMap
      zoom={11}
      center={{ lat: 52.240935, lng: 0.751005 }}
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      clickableIcons={false}
      options={{
        draggable: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
              {
                hue: "#ff0000",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                saturation: "-50",
              },
              {
                lightness: "50",
              },
              {
                gamma: "0.9",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "water",
            stylers: [
              {
                hue: "#0077ff",
              },
              {
                saturation: "-50",
              },
              {
                lightness: "50",
              },
              {
                gamma: "0.9",
              },
            ],
          },
        ],
      }}
    >
      <Marker
        position={{
          lat: 52.552204,
          lng: -0.281604,
        }}
        icon={{
          path: "M18 2c1.1 0 2 0.9 2 2l0 13c0 1.1-0.9 2-2 2l-4 0 -3 3 -3-3 -4 0c-1.1 0-2-0.9-2-2l0-13c0-1.1 0.9-2 2-2l14 0Zm-7 10.9l2.8 1.7 -0.7-3.2 2.5-2.1 -3.2-0.3 -1.3-3 -1.3 3 -3.2 0.3 2.5 2.1 -0.7 3.2 2.8-1.7Z",
          scale: 1.6363636363636363636363636364,
          anchor: { x: 11, y: 22 },
          fillOpacity: 1,
          fillColor: "#f44336",
          strokeOpacity: 0,
        }}
      />
    </GoogleMap>
  ) : undefined;

  return (
    <OuterContainer>
      <MapContainer>{map}</MapContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </OuterContainer>
  );
};
