import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getLocaleForApi } from "../../../utils/graphql";
import { useLetterHomeQuery } from "../../../types/graphql";
import { DataLoadedContainer } from "../../control/data-loaded-container";
import { routePaths } from "../../../model/route";

type UseData = () => {
  enabled?: boolean;
  loaderProps: React.ComponentProps<typeof DataLoadedContainer>;
};

export const useData: UseData = () => {
  const {
    data,
    error,
    loading: isLoading,
    refetch: dataRefetch,
  } = useLetterHomeQuery({
    fetchPolicy: "network-only",
    variables: {
      locale: getLocaleForApi(),
    },
  });
  const location = useLocation();
  const history = useHistory();

  if (
    data?.featureFlag?.lettersEnabled === false &&
    location.pathname !== routePaths.letter.types.classic
  ) {
    history.push(routePaths.letter.types.classic);
  }

  const refetch = useCallback(async () => dataRefetch(), [dataRefetch]);

  return {
    loaderProps: {
      isLoading,
      isNetworkError: !!error?.networkError,
      ...(!data && { refetch }),
    },
    ...(data?.featureFlag && { enabled: data.featureFlag.lettersEnabled }),
  };
};
