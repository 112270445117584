import styled from "styled-components";

import { theme } from "../../../theme";
import { WarningIcon } from "../../atoms/icons/warning-icon";
import { DivText } from "../../atoms/text";
import { SimpleButton } from "../buttons/simple-button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const InfoText = styled(DivText)`
  text-align: center;
  margin: 10px 0;
`;

interface IProps {
  isNetworkError?: boolean;
  onPressReload(): void;
}

export const LoadingError: React.FC<IProps> = ({ onPressReload, isNetworkError = false }) => (
  <Container>
    <WarningIcon colour={theme.colours.alert} />
    <InfoText>
      {isNetworkError ? (
        <>
          You are currently not connected to the internet.
          <br /> Please reconnect and try again.
        </>
      ) : (
        <>
          Whoops! There was a problem loading some data.
          <br />
          Please try again later
        </>
      )}
    </InfoText>
    <SimpleButton label="Try again" onClick={onPressReload} colour={theme.colours.alert} />
  </Container>
);
