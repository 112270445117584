import { makeVar } from "@apollo/client";

import {
  CallCreateInput,
  LetterCreateInput,
  ProductAlphaId,
  SackCreateInput,
  TextCreateInput,
  VideoCreateInput,
} from "../../../types/graphql";
import { setState } from "./utils";

const CACHE_KEY = "state.basketItems";

export interface ICallCreateInputWithTime extends CallCreateInput {
  time: string;
}

export interface IBasketItemState {
  productAlphaId: ProductAlphaId;
  letter?: LetterCreateInput;
  call?: ICallCreateInputWithTime;
  text?: TextCreateInput;
  sack?: SackCreateInput;
  video?: VideoCreateInput;
  addOns: ProductAlphaId[];
}

export const basketItemState = makeVar<IBasketItemState[]>(
  JSON.parse(localStorage.getItem(CACHE_KEY) || "[]"),
);

interface IBasketState {
  addToBasket(item: IBasketItemState): void;
  removeFromBasket(index: number): void;
  clear(): void;
}

export const basketState = (): IBasketState => {
  const setBasketState = (value: IBasketItemState[]): void =>
    setState(basketItemState, CACHE_KEY, value);

  return {
    addToBasket: item => setBasketState([...basketItemState(), item]),
    removeFromBasket: index => setBasketState(basketItemState().filter((__, i) => i !== index)),
    clear: () => setBasketState([]),
  };
};
