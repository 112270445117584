import styled from "styled-components";

import { DropDownMenu } from "../../atoms/drop-down-menu";
import { DropDownMenuItem } from "../../atoms/drop-down-menu-item";
import { AccountLink } from "../../molecules/account-link";

import { ReactComponent as LogOutImage } from "./log-out-icon.svg";
import { ReactComponent as UserIconImage } from "./user-icon.svg";

const UserIcon = styled(UserIconImage)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }): string => theme.colours.spotGold};
`;

const LogOutIcon = styled(LogOutImage)`
  width: 14px;
  height: 16px;
  margin: 0 1px;
  fill: ${({ theme }): string => theme.colours.spotGold};
`;

export interface IProps {
  name: string;
  initials: string;
  imageUrl?: string;
  onClickLogOut(): void;
  onClickProfile(): void;
}

export const AccountWidget: React.FC<IProps> = props => {
  const items = [
    <DropDownMenuItem
      key={1}
      onClick={props.onClickProfile}
      icon={<UserIcon />}
      label="My Account"
    />,
    <DropDownMenuItem
      key={2}
      onClick={props.onClickLogOut}
      icon={<LogOutIcon />}
      label="Log Out"
    />,
  ];

  const linkProps = {
    name: props.name,
    avatarInitials: props.initials,
    ...(props.imageUrl && { avatarImage: props.imageUrl }),
  };

  return (
    <DropDownMenu items={items} id={"account"}>
      <AccountLink {...linkProps} />
    </DropDownMenu>
  );
};
