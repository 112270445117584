import ReactDOM from "react-dom";
import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { CrossButton } from "../../molecules/buttons/cross";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  background: white;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
  width: 90%;
  border-radius: 10px;
  padding: 20px 30px;

  @media ${device.tablet} {
    left: 15%;
    width: 70%;
  }

  @media ${device.laptopL} {
    left: 25%;
    width: 50%;
  }
`;

const StyledCloseButton = styled(CrossButton)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

interface IProps {
  onClickClose(): void;
}

export const Modal: React.FC<IProps> = ({ onClickClose, children }) => {
  const modalElement = document.getElementById("modal-root");

  if (modalElement) {
    return ReactDOM.createPortal(
      <Wrapper>
        <ModalContainer>
          <StyledCloseButton onClick={onClickClose} />
          {children}
        </ModalContainer>
      </Wrapper>,
      modalElement,
    );
  }

  return null;
};
