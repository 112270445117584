import styled from "styled-components";

const Wrapper = styled.div`
  padding: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  margin-left: 5px;
  font-size: ${({ theme }): string => theme.fontSizes.size14};
  color: ${({ theme }): string => theme.colours.textSubdued};
  text-shadow: none !important;
  white-space: nowrap;
`;

interface IProps {
  label: string;
  icon?: JSX.Element;
  onClick(): void;
}

export const DropDownMenuItem: React.FC<IProps> = ({ label, icon = null, onClick }) => (
  <Wrapper onClick={onClick}>
    {icon}
    <Label>{label}</Label>
  </Wrapper>
);
