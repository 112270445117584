import { OrderItemInput, ProductAlphaId, SiteCountryCode } from "../../types/graphql";
import { PlaceOrderMutationVariables } from "../../types/graphql";
import { config } from "../../config";
import { basketItemState } from "../graphql/cache";

export interface IPromoCode {
  code: string;
  urn: string;
}

export const getCheckoutOrderVariables = async (
  paymentToken: string | undefined,
  promoCode?: IPromoCode,
): Promise<PlaceOrderMutationVariables> => {
  const basketItems = basketItemState();

  const items = basketItems.map(i => {
    const item: OrderItemInput = {
      alphaId: i.productAlphaId,
      addOns: i.addOns,
    };

    const { letter, call, text, sack, video } = i;

    switch (i.productAlphaId!) {
      case ProductAlphaId.SANTA_LETTER: {
        if (letter) {
          item.letterDetails = letter;
        }
        break;
      }
      case ProductAlphaId.SANTA_CALL_VIDEO:
      case ProductAlphaId.SANTA_CALL: {
        if (call) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { time, ...basketCall } = call;
          item.callDetails = basketCall;
        }
        break;
      }
      case ProductAlphaId.SANTA_TEXT: {
        if (text) {
          item.textDetails = text;
        }
        break;
      }
      case ProductAlphaId.SANTA_SACK: {
        if (sack) {
          item.sackDetails = sack;
        }
        break;
      }
      case ProductAlphaId.SANTA_VIDEO_MESSAGE: {
        if (video) {
          item.videoDetails = video;
        }
        break;
      }
      default:
        throw new Error("Unrecognised product alphaId");
    }

    return item;
  });

  const variables: PlaceOrderMutationVariables = {
    paymentToken,
    items,
    siteCountryCode: config.site.country.toUpperCase() as SiteCountryCode,
  };

  if (typeof promoCode !== "undefined") {
    variables.promoCodeUrn = promoCode.urn;
  }

  return variables;
};
