import styled from "styled-components";

import { CrossButton } from "../../molecules/buttons/cross";

const Container = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
`;

const Header = styled.div`
  box-sizing: border-box;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;

  svg {
    path {
      fill: ${({ theme }): string => theme.colours.navigation.light};
    }
  }
`;

const Content = styled.div`
  overflow-y: auto;
`;

interface IProps {
  onClickClose(): void;
}

export const MobileOverlay: React.FC<IProps> = ({ onClickClose, children }) => (
  <Container>
    <Header>
      <CrossButton onClick={onClickClose} />
    </Header>
    <Content>{children}</Content>
  </Container>
);
