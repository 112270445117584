import { Col, Container, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { TextsDetailsMessagesForm } from "../../../organisms/forms/texts-details-messages";
import { StageTitle } from "../../../atoms/stage-title";

import skisImage from "./skis.png";
import { useData } from "./use-data";

const Skis = styled.img`
  position: absolute;
  width: 491px;
  height: 1316px;
  right: 0;
  top: 0;
`;

export const TextsDetailsMessages: React.FC = () => {
  const { formProps, loaderProps } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      {formProps && (
        <MainWrapper>
          <Visible md lg xl>
            <Skis src={skisImage} alt="Skis" />
          </Visible>

          <Container>
            <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

            <StageTitle totalStages={3} currentStage={2}>
              <Visible sm md lg xl>
                Help Santa to Personalise His Messages
              </Visible>
              <Visible xs>Santa needs more</Visible>
            </StageTitle>

            <Row>
              <Col md={7}>
                <TextsDetailsMessagesForm {...formProps} />
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainer>
  );
};
