import { ISelectOption } from "@santa/common/lib/utils/form";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as yup from "yup";

import { FormSubmitRow } from "../../../molecules/form-submit-row";
import {
  FormSectionTextTemplates,
  IFormSectionModel,
  validationRules,
} from "../../form-sections/text/form-section-text-messages";

const validationSchema = yup.object(validationRules);
export type TextMessagesValidatedFormModel = yup.Asserts<typeof validationSchema>;

type FormModel = IFormSectionModel;

type SetFields = (fields: [string, string, string]) => void;

interface IOwnProps {
  defaultValues: FormModel;
  templateOptions: ISelectOption[];
  petOptions: ISelectOption[];
  onSubmit(values: TextMessagesValidatedFormModel): void;
  onClickBack(): void;
  onChangeSourceField(update: SetFields, details: FormModel): void;
  onChange(details: TextMessagesValidatedFormModel): void;
  onChangeTemplate(update: SetFields, templateId: string): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  templateOptions,
  petOptions,
  submitForm,
  onClickBack,
  onChangeSourceField,
  onChange,
  onChangeTemplate,
  setFieldValue,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  const setFields: SetFields = ([field1, field2, field3]) => {
    setFieldValue("text1Body", field1);
    setFieldValue("text2Body", field2);
    setFieldValue("text3Body", field3);
  };

  const handleChangeTemplate = useCallback(
    (templateId: string) => onChangeTemplate(setFields, templateId),
    [setFieldValue, onChangeTemplate],
  );

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as TextMessagesValidatedFormModel);
    }
  }, [values]);

  const { templateId, firstName, ageYears, town, boyGirl, petId, petName } = values;
  useEffect(() => {
    onChangeSourceField(setFields, values);
  }, [templateId, firstName, ageYears, town, boyGirl, petId, petName]);

  return (
    <Form>
      <FormSectionTextTemplates
        onChangeTemplate={handleChangeTemplate}
        petOptions={petOptions}
        templateOptions={templateOptions}
      />
      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
      />
    </Form>
  );
};

export const TextsDetailsMessagesForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: IOwnProps) => props.defaultValues,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as TextMessagesValidatedFormModel),
})(C);
