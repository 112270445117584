import React, { useState } from "react";
import ReactCarousel, { CarouselProps } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import styled from "styled-components";

import { CarouselScrollButton } from "../../molecules/buttons/carousel-scroll-button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

interface IProps {
  autoplay?: boolean;
  className?: string;
  arrowsAbove?: boolean;
}

export const Carousel: React.FC<IProps> = ({
  children,
  className,
  arrowsAbove,
  autoplay = false,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const props: CarouselProps = {
    infinite: true,
  };

  let arrows: JSX.Element | undefined;

  if (arrowsAbove) {
    props.value = currentSlide;
    props.onChange = (i: number): void => setCurrentSlide(i);

    /* eslint-disable react/jsx-no-bind */
    arrows = (
      <ArrowContainer>
        <CarouselScrollButton
          direction="left"
          onClick={(): void => setCurrentSlide(currentSlide - 1)}
        />
        <CarouselScrollButton
          direction="right"
          onClick={(): void => setCurrentSlide(currentSlide + 1)}
        />
      </ArrowContainer>
    );
    /* eslint-enable react/jsx-no-bind */
  } else {
    props.addArrowClickHandler = true;
    props.arrowLeft = <CarouselScrollButton direction="left" />;
    props.arrowRight = <CarouselScrollButton direction="right" />;
  }

  if (autoplay) {
    props.autoPlay = 5000;
  }

  return (
    <Container className={className}>
      {arrows}
      <ReactCarousel {...props}>{children}</ReactCarousel>
    </Container>
  );
};
