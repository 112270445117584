import { Form, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";

import { AddressForm } from "../address-form";
import { config } from "../../../../config";

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string(),
  addressTown: Yup.string().required("Required"),
  addressCounty: Yup.string(),
  addressUsStateUrn: Yup.string(),
  addressPostcode: Yup.string().required("Required"),
  addressCountryUrn: Yup.string().required("Required"),
});

export interface IAddressFormModel {
  addressLine1: string;
  addressLine2: string;
  addressTown: string;
  addressCounty: string;
  addressUsStateUrn: string;
  addressPostcode: string;
  addressCountryUrn: string;
}

const initialModel: IAddressFormModel = {
  addressLine1: "",
  addressLine2: "",
  addressTown: "",
  addressCounty: "",
  addressUsStateUrn: "",
  addressPostcode: "",
  addressCountryUrn: config.defaultCountry,
};

interface IOwnProps {
  onChange(values: IAddressFormModel): void;
  onValidation(isValid: boolean): void;
}

type Props = FormikProps<IAddressFormModel> & IOwnProps;

const Component: React.FC<Props> = ({ setFieldValue, values, onValidation, onChange }) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => onValidation(isValid), [isValid, onValidation]);

  if (isValid) {
    onChange(values);
  }

  const addressIsFilled = Boolean(values.addressTown && values.addressPostcode);

  return (
    <Form>
      <AddressForm
        setFieldValue={setFieldValue}
        countryUrn={values.addressCountryUrn}
        addressIsFilled={addressIsFilled}
      />
    </Form>
  );
};

export const CallCertificateForm = withFormik<IOwnProps, IAddressFormModel>({
  validationSchema,
  mapPropsToValues: () => initialModel,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSubmit: () => {}, // we do not actually submit this form!
})(Component);
