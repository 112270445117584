import styled from "styled-components";
import { Visible, Row, Col } from "react-grid-system";

import { CaretIcon } from "../../atoms/icons/caret-icon";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    width: 17px;
    height: 11px;
    transform: rotate(90deg);
  }
`;

const Label = styled.span`
  margin-left: 10px;
  font-weight: 600;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  color: ${({ theme }): string => theme.colours.textSubdued};
`;

interface IProps {
  onClick(): void;
}

export const MobileBackLink: React.FC<IProps> = ({ onClick, children }) => (
  <Visible xs>
    <Row>
      <Col>
        <Container onClick={onClick}>
          <CaretIcon colour="blueGrey" />
          <Label>{children}</Label>
        </Container>
      </Col>
    </Row>
  </Visible>
);
