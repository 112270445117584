import { ISelectOption } from "@santa/common/lib/utils/form";
import { FieldProps } from "formik";
import React, { useCallback } from "react";
import styled, { StyledComponentPropsWithRef } from "styled-components";

type Props = StyledComponentPropsWithRef<"select"> & {
  isError?: boolean;
  options: ISelectOption[];
};

const Select = styled.select<Props>`
  flex: 1;
  min-width: 50px;
  padding: 7px 26px 7px 16px;
  display: block;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid ${({ isError, theme }): string => (isError ? theme.colours.alert : "#b0bec5")};
  border-radius: 6px;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.81088 4.8764C4.41175 5.42946 3.58825 5.42946 3.18912 4.87641L0.813896 1.58521C0.336597 0.923842 0.809173 0 1.62478 0L6.37522 0C7.19083 0 7.6634 0.923841 7.1861 1.58521L4.81088 4.8764Z' fill='%2390A4AE'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 55%, 0 0;

  > option {
    font-weight: normal;
  }

  :focus {
    border-color: #f8bf2f;
    outline: none;
  }
`;

export const FormInputSelect: React.FC<Props> = props => {
  const options = props.options.map((option, i) => (
    <option value={option.value} key={i}>
      {option.label}
    </option>
  ));

  return <Select {...props}>{options}</Select>;
};

export const FormikFormInputSelect: React.FC<
  FieldProps & StyledComponentPropsWithRef<"select"> & { options: ISelectOption[] }
> = ({ field, form: { touched, errors, setFieldValue, setFieldTouched }, onChange, ...props }) => {
  const options = [...props.options!];
  // prepend placeholder as an empty option
  if (typeof props.placeholder !== "undefined") {
    options.unshift({ value: "", label: props.placeholder });
  }

  const isError = Boolean(touched[field.name] && errors[field.name]);
  const handleFocus = useCallback((): void => setFieldTouched(field.name), [setFieldTouched]);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      if (onChange) {
        onChange(e);
      }
      setFieldValue(field.name, e.target.value);
    },
    [onChange, setFieldValue],
  );

  return (
    <FormInputSelect
      name={field.name}
      onBlur={field.onBlur}
      onFocus={handleFocus}
      isError={isError}
      onChange={handleChange}
      placeholder={props.placeholder}
      value={field.value}
      options={options}
    />
  );
};
