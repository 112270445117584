import { earliestTextSendTime, lastTextSendTime } from "@santa/common/lib/products/texts";
import { flatMap, range } from "lodash";
import * as dateFns from "date-fns";
import { Field } from "formik";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";

import { ContentPanel } from "../../../atoms/content-panel";
import { Heading3 } from "../../../atoms/text";
import { FormFieldWrapper } from "../../form-field-wrapper";
import { FormikFormInputDate } from "../../../molecules/form/form-input-date";
import { FormikFormInputSelect } from "../../../molecules/form/form-input-select";
import { FormikFormInputTextarea } from "../../../molecules/form/form-input-textarea";
import { config } from "../../../../config";

const MessagePanel = styled(ContentPanel)`
  > h3 {
    :first-child {
      margin-top: 0;
      font-weight: normal;
    }
  }

  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: -9px;
  }
`;

const timeOptions = flatMap(
  range(24).map(hours => {
    return range(0, 55, 5).map(minutes => {
      const time = dateFns.add(dateFns.startOfDay(new Date()), { hours, minutes });
      return {
        value: dateFns.format(time, "HH:mm"),
        label: dateFns.format(time, "p"),
      };
    });
  }),
);

interface IProps {
  message: number;
}

export const TextDetailsMessagesMessageForm: React.FC<IProps> = ({ message }) => {
  const ordinal = ((i: number): string => {
    switch (i) {
      case 1:
        return "First";
      case 2:
        return "Second";
      case 3:
        return "Third";
      default:
        throw new Error("Unexpected index");
    }
  })(message);

  return (
    <MessagePanel colour="yellow">
      <Heading3>{ordinal} Message</Heading3>
      <Row>
        <Col xl={6}>
          <FormFieldWrapper
            label="Date to send message?"
            name={`text${message}TimeDate`}
            required
            noLabel
          >
            <Field
              name={`text${message}TimeDate`}
              component={FormikFormInputDate}
              placeholder="Select a date"
              minDate={earliestTextSendTime()}
              maxDate={lastTextSendTime()}
              dateFormat="MMMM dd, yyyy"
            />
          </FormFieldWrapper>
        </Col>
        <Col xl={6}>
          <FormFieldWrapper label="Time to send message?" name={`text${message}TimeTime`} required>
            <Field
              name={`text${message}TimeTime`}
              component={FormikFormInputSelect}
              placeholder="Select time"
              options={timeOptions}
            />
          </FormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldWrapper label="Message to send" name={`text${message}Body`} required>
            <Field
              name={`text${message}Body`}
              component={FormikFormInputTextarea}
              placeholder="Type your message here"
              maxLength={config.texts.messageMaxLength}
              rows={4}
            />
          </FormFieldWrapper>
        </Col>
      </Row>
    </MessagePanel>
  );
};
