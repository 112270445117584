import { useApolloClient } from "@apollo/client";
import { ISelectOption } from "@santa/common/lib/utils/form";

import {
  GiftSearchQuery,
  GiftSearchQueryVariables,
  GiftSearchDocument,
} from "../../../../../../types/graphql";

type UseSearch = () => (search: string) => Promise<ISelectOption[]>;

export const useSearch: UseSearch = () => {
  const client = useApolloClient();

  return async (search: string): Promise<ISelectOption[]> => {
    const { data: giftData } = await client.query<GiftSearchQuery, GiftSearchQueryVariables>({
      query: GiftSearchDocument,
      variables: { search },
    });

    return giftData.allGifts.map(o => ({
      value: o.id,
      label: o.label || "",
    }));
  };
};
