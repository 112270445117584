import { Col, Row, Visible } from "react-grid-system";
import { ISelectMultilineOption, ISelectOption } from "@santa/common/lib/utils/form";

import { SantaLetterDetailsContentForm } from "../../../../organisms/forms/santa-letter-details-content";
import { MobileBackLink } from "../../../../molecules/mobile-back-link";
import { StageTitle } from "../../../../atoms/stage-title";
import { LetterSectionTitle } from "../../../../molecules/letter-section-title";

import { useData } from "./use-data";

interface IProps {
  templateOptions: ISelectMultilineOption[];
  psOptions: ISelectOption[];
  signatureOptions: ISelectOption[];
  petOptions: ISelectOption[];
  frontDoorOptions: ISelectOption[];
  onChange(): void;
  onClickOpenMobilePreview(): void;
}

export const SantaLetterDetailsContent: React.FC<IProps> = ({
  templateOptions,
  psOptions,
  signatureOptions,
  petOptions,
  frontDoorOptions,
  onChange,
  onClickOpenMobilePreview,
}) => {
  const { formProps } = useData(onChange);

  return (
    <>
      <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

      <Row>
        <Col>
          <LetterSectionTitle />
          <StageTitle totalStages={3} currentStage={2}>
            <Visible sm md lg xl>
              Help Santa to Personalise His Letter
            </Visible>
            <Visible xs>Santa needs more</Visible>
          </StageTitle>
        </Col>
      </Row>

      <SantaLetterDetailsContentForm
        {...formProps}
        onClickOpenMobilePreview={onClickOpenMobilePreview}
        templateOptions={templateOptions}
        psOptions={psOptions}
        signatureOptions={signatureOptions}
        petOptions={petOptions}
        frontDoorOptions={frontDoorOptions}
      />
    </>
  );
};
