/* eslint-disable */
import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A ISO 8601 compliant datetime value */
  DateTime: any;
  /** Represents `true` or `false` values. */
  BooleanType: any;
  ItemId: any;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  IntType: any;
  UploadId: any;
  /** Represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatType: any;
  CustomData: any;
  MetaTagAttributes: any;
};

/** The query root for this schema */
export type Query = {
  __typename?: "Query";
  /** Returns meta information regarding a record collection */
  _allFirstNamesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFrontDoorsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGiftsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allHobbiesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterPostscriptsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterSignaturesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterTemplatesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPageSectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPetsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductFaqsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTextTemplatesMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta?: Maybe<CollectionMetadata>;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allFirstNames: Array<FirstNameRecord>;
  /** Returns a collection of records */
  allFrontDoors: Array<FrontDoorRecord>;
  /** Returns a collection of records */
  allGifts: Array<GiftRecord>;
  /** Returns a collection of records */
  allHobbies: Array<HobbyRecord>;
  /** Returns a collection of records */
  allLetterPostscripts: Array<LetterPostscriptRecord>;
  /** Returns a collection of records */
  allLetterSignatures: Array<LetterSignatureRecord>;
  /** Returns a collection of records */
  allLetterTemplates: Array<LetterTemplateRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allPageSections: Array<PageSectionRecord>;
  /** Returns a collection of records */
  allPets: Array<PetRecord>;
  /** Returns a collection of records */
  allProductFaqs: Array<ProductFaqRecord>;
  /** Returns a collection of records */
  allProducts: Array<ProductRecord>;
  /** Returns a collection of records */
  allTextTemplates: Array<TextTemplateRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  availableCallDates: Array<AvailableCallDatesResponse>;
  availableCallSlots: Array<AvailableCallSlotsResponse>;
  braintreeToken: Scalars["String"];
  call?: Maybe<Call>;
  calls: CallsSearchResponse;
  checkSlotFollowingReservation: Scalars["Boolean"];
  checkSlotHasSantaWithFollowingAvailable: Scalars["Boolean"];
  countries: Array<Country>;
  country?: Maybe<Country>;
  /** Returns the single instance record */
  featureFlag?: Maybe<FeatureFlagRecord>;
  /** Returns a specific record */
  firstName?: Maybe<FirstNameRecord>;
  /** Returns a specific record */
  frontDoor?: Maybe<FrontDoorRecord>;
  /** Returns a specific record */
  gift?: Maybe<GiftRecord>;
  /** Returns a specific record */
  hobby?: Maybe<HobbyRecord>;
  letter?: Maybe<Letter>;
  /** Returns a specific record */
  letterPostscript?: Maybe<LetterPostscriptRecord>;
  letters: LettersSearchResponse;
  /** Returns a specific record */
  letterSignature?: Maybe<LetterSignatureRecord>;
  /** Returns a specific record */
  letterTemplate?: Maybe<LetterTemplateRecord>;
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  orders: OrdersSearchResponse;
  /** Returns a specific record */
  page?: Maybe<PageRecord>;
  /** Returns a specific record */
  pageSection?: Maybe<PageSectionRecord>;
  /** Returns a specific record */
  pet?: Maybe<PetRecord>;
  /** Returns a specific record */
  product?: Maybe<ProductRecord>;
  /** Returns a specific record */
  productFaq?: Maybe<ProductFaqRecord>;
  promoCode?: Maybe<PromoCode>;
  sack?: Maybe<Sack>;
  sacks: SacksSearchResponse;
  santas: UsersSearchResponse;
  text?: Maybe<Text>;
  texts: TextsSearchResponse;
  /** Returns a specific record */
  textTemplate?: Maybe<TextTemplateRecord>;
  timezone?: Maybe<Timezone>;
  timezones: Array<Timezone>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  user?: Maybe<User>;
  usState?: Maybe<UsState>;
  usStates: Array<UsState>;
  validatePhoneNumber?: Maybe<ValidatePhoneNumberResponse>;
  video?: Maybe<Video>;
  videoByLogin?: Maybe<Video>;
  videos: VideosSearchResponse;
};

/** The query root for this schema */
export type Query_AllFirstNamesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FirstNameModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllFrontDoorsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FrontDoorModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllGiftsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GiftModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllHobbiesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<HobbyModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllLetterPostscriptsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterPostscriptModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllLetterSignaturesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterSignatureModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllLetterTemplatesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterTemplateModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPageSectionsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageSectionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPetsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PetModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductFaqsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductFaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTextTemplatesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TextTemplateModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAllFirstNamesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FirstNameModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FirstNameModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllFrontDoorsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FrontDoorModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FrontDoorModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllGiftsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GiftModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GiftModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllHobbiesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<HobbyModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<HobbyModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllLetterPostscriptsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterPostscriptModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterPostscriptModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllLetterSignaturesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterSignatureModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterSignatureModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllLetterTemplatesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterTemplateModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterTemplateModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPageSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageSectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageSectionModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPetsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PetModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PetModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductFaqsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductFaqModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductFaqModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllTextTemplatesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TextTemplateModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TextTemplateModelOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAvailableCallDatesArgs = {
  end: Scalars["String"];
  start: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryAvailableCallSlotsArgs = {
  date: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryBraintreeTokenArgs = {
  siteCountryCode: SiteCountryCode;
};

/** The query root for this schema */
export type QueryCallArgs = {
  callUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryCallsArgs = {
  filter?: InputMaybe<CallSearchFilterInput>;
  from?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryCheckSlotFollowingReservationArgs = {
  reservationUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryCheckSlotHasSantaWithFollowingAvailableArgs = {
  time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryCountriesArgs = {
  isTextAccepted?: InputMaybe<Scalars["Boolean"]>;
};

/** The query root for this schema */
export type QueryCountryArgs = {
  countryUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryFeatureFlagArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryFirstNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FirstNameModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FirstNameModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFrontDoorArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FrontDoorModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FrontDoorModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryGiftArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<GiftModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GiftModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryHobbyArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<HobbyModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<HobbyModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLetterArgs = {
  letterUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryLetterPostscriptArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterPostscriptModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterPostscriptModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLettersArgs = {
  filter?: InputMaybe<LetterSearchFilterInput>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

/** The query root for this schema */
export type QueryLetterSignatureArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterSignatureModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterSignatureModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLetterTemplateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LetterTemplateModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LetterTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryOrderArgs = {
  orderUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryOrderItemArgs = {
  orderItemUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderSearchFilterInput>;
  from?: InputMaybe<Scalars["Int"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

/** The query root for this schema */
export type QueryPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPageSectionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageSectionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPetArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PetModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PetModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductFaqArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductFaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductFaqModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPromoCodeArgs = {
  promoCodeUrn: Scalars["String"];
};

/** The query root for this schema */
export type QuerySackArgs = {
  sackUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QuerySacksArgs = {
  filter?: InputMaybe<SackSearchFilterInput>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

/** The query root for this schema */
export type QuerySantasArgs = {
  searchAfter?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryTextArgs = {
  textUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryTextsArgs = {
  filter?: InputMaybe<TextSearchFilterInput>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

/** The query root for this schema */
export type QueryTextTemplateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TextTemplateModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TextTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTimezoneArgs = {
  timezoneUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryUserArgs = {
  userUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryUsStateArgs = {
  usStateUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryValidatePhoneNumberArgs = {
  countryUrn: Scalars["String"];
  number: Scalars["String"];
};

/** The query root for this schema */
export type QueryVideoArgs = {
  userUrn?: InputMaybe<Scalars["String"]>;
  videoUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryVideoByLoginArgs = {
  password: Scalars["String"];
  username: Scalars["String"];
};

/** The query root for this schema */
export type QueryVideosArgs = {
  filter?: InputMaybe<VideoSearchFilterInput>;
  query?: InputMaybe<Scalars["String"]>;
  searchAfter?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export enum SiteLocale {
  EN_GB = "en_GB",
  EN_US = "en_US",
}

export type FirstNameModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<FirstNameModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

export enum ItemStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  UPDATED = "updated",
}

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars["ItemId"]>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars["ItemId"]>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["String"]>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars["BooleanType"]>;
  pattern: Scalars["String"];
  regexp?: InputMaybe<Scalars["BooleanType"]>;
};

export type CollectionMetadata = {
  __typename?: "CollectionMetadata";
  count: Scalars["IntType"];
};

export type FrontDoorModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<FrontDoorModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type GiftModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<GiftModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type HobbyModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<HobbyModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type LetterPostscriptModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<LetterPostscriptModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type LetterSignatureModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<LetterSignatureModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type LetterTemplateModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<LetterTemplateModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  sortGroup?: InputMaybe<IntegerFilter>;
  template?: InputMaybe<TextFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type PageSectionModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  body?: InputMaybe<TextFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  identifier?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<PageSectionModelFilter>>>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type PageModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  alphaId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  headerImage?: InputMaybe<FileFilter>;
  id?: InputMaybe<ItemIdFilter>;
  OR?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  sections?: InputMaybe<LinksFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
  videoFallbackImageUrl?: InputMaybe<StringFilter>;
  videoUrl?: InputMaybe<StringFilter>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars["UploadId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars["UploadId"]>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

export type PetModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<PetModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  text?: InputMaybe<StringFilter>;
  textPlural?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type ProductFaqModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  OR?: InputMaybe<Array<InputMaybe<ProductFaqModelFilter>>>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type ProductModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  addOns?: InputMaybe<LinksFilter>;
  alphaId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StringFilter>;
  faqs?: InputMaybe<LinksFilter>;
  homePageDescription?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  internationalSurcharge?: InputMaybe<LinkFilter>;
  isAddOn?: InputMaybe<BooleanFilter>;
  isPopular?: InputMaybe<BooleanFilter>;
  letterHomePageDescription?: InputMaybe<TextFilter>;
  OR?: InputMaybe<Array<InputMaybe<ProductModelFilter>>>;
  postagePackingSurcharge?: InputMaybe<LinkFilter>;
  price?: InputMaybe<FloatFilter>;
  productPageDescription?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars["ItemId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars["ItemId"]>;
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

/** Specifies how to filter Floating-point fields */
export type FloatFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["FloatType"]>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars["FloatType"]>;
};

export type TextTemplateModelFilter = {
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  label?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<InputMaybe<TextTemplateModelFilter>>>;
  showOnSite?: InputMaybe<BooleanFilter>;
  template1?: InputMaybe<TextFilter>;
  template2?: InputMaybe<TextFilter>;
  template3?: InputMaybe<TextFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type UploadFilter = {
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

export enum ColorBucketType {
  BLACK = "black",
  BLUE = "blue",
  BROWN = "brown",
  CYAN = "cyan",
  GREEN = "green",
  GREY = "grey",
  ORANGE = "orange",
  PINK = "pink",
  PURPLE = "purple",
  RED = "red",
  WHITE = "white",
  YELLOW = "yellow",
}

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars["UploadId"]>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars["UploadId"]>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

export enum UploadOrientation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
  SQUARE = "square",
}

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  ICON = "icon",
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars["String"]>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars["String"]>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars["BooleanType"]>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

export enum UploadType {
  ARCHIVE = "archive",
  AUDIO = "audio",
  IMAGE = "image",
  PDFDOCUMENT = "pdfdocument",
  PRESENTATION = "presentation",
  RICHTEXT = "richtext",
  SPREADSHEET = "spreadsheet",
  VIDEO = "video",
}

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars["IntType"]>;
};

export type Site = {
  __typename?: "Site";
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileField = FileFieldInterface & {
  __typename?: "FileField";
  _createdAt: Scalars["DateTime"];
  _updatedAt: Scalars["DateTime"];
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurhash?: Maybe<Scalars["String"]>;
  blurUpThumb?: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars["String"];
  height?: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars["IntType"]>;
};

export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: InputMaybe<Scalars["Float"]>;
  quality?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars["String"]>;
};

export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars["DateTime"];
  _updatedAt: Scalars["DateTime"];
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurhash?: Maybe<Scalars["String"]>;
  blurUpThumb?: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars["String"];
  height?: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars["IntType"]>;
};

export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: InputMaybe<Scalars["Float"]>;
  quality?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars["String"]>;
};

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars["String"]>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars["String"]>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars["String"]>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars["String"]>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars["String"]>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars["IntType"]>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars["String"]>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars["String"]>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars["IntType"]>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars["IntType"]>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars["IntType"]>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars["IntType"]>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars["String"]>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars["String"]>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars["IntType"]>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars["String"]>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars["IntType"]>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars["IntType"]>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars["String"]>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars["IntType"]>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars["String"]>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars["IntType"]>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars["IntType"]>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars["IntType"]>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars["String"]>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars["String"]>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars["IntType"]>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars["IntType"]>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars["String"]>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars["IntType"]>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars["IntType"]>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars["IntType"]>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars["String"]>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars["IntType"]>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars["IntType"]>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars["String"]>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars["IntType"]>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars["IntType"]>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars["String"]>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars["String"]>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars["String"]>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars["String"]>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Ligatures
   *
   * Controls the level of ligature substitution
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-lig)
   */
  txtLig?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars["String"]>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars["IntType"]>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars["IntType"]>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars["FloatType"]>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars["FloatType"]>;
};

export enum ImgixParamsAuto {
  COMPRESS = "compress",
  ENHANCE = "enhance",
  FORMAT = "format",
  REDEYE = "redeye",
}

export enum ImgixParamsBlendAlign {
  BOTTOM = "bottom",
  CENTER = "center",
  LEFT = "left",
  MIDDLE = "middle",
  RIGHT = "right",
  TOP = "top",
}

export enum ImgixParamsBlendCrop {
  BOTTOM = "bottom",
  FACES = "faces",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

export enum ImgixParamsBlendFit {
  CLAMP = "clamp",
  CLIP = "clip",
  CROP = "crop",
  MAX = "max",
  SCALE = "scale",
}

export enum ImgixParamsBlendMode {
  BURN = "burn",
  COLOR = "color",
  DARKEN = "darken",
  DIFFERENCE = "difference",
  DODGE = "dodge",
  EXCLUSION = "exclusion",
  HARDLIGHT = "hardlight",
  HUE = "hue",
  LIGHTEN = "lighten",
  LUMINOSITY = "luminosity",
  MULTIPLY = "multiply",
  NORMAL = "normal",
  OVERLAY = "overlay",
  SATURATION = "saturation",
  SCREEN = "screen",
  SOFTLIGHT = "softlight",
}

export enum ImgixParamsBlendSize {
  INHERIT = "inherit",
}

export enum ImgixParamsCh {
  DPR = "dpr",
  SAVEDATA = "saveData",
  WIDTH = "width",
}

export enum ImgixParamsCrop {
  BOTTOM = "bottom",
  EDGES = "edges",
  ENTROPY = "entropy",
  FACES = "faces",
  FOCALPOINT = "focalpoint",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

export enum ImgixParamsCs {
  ADOBERGB1998 = "adobergb1998",
  SRGB = "srgb",
  STRIP = "strip",
  TINYSRGB = "tinysrgb",
}

export enum ImgixParamsFill {
  BLUR = "blur",
  SOLID = "solid",
}

export enum ImgixParamsFit {
  CLAMP = "clamp",
  CLIP = "clip",
  CROP = "crop",
  FACEAREA = "facearea",
  FILL = "fill",
  FILLMAX = "fillmax",
  MAX = "max",
  MIN = "min",
  SCALE = "scale",
}

export enum ImgixParamsFlip {
  H = "h",
  HV = "hv",
  V = "v",
}

export enum ImgixParamsFm {
  AVIF = "avif",
  BLURHASH = "blurhash",
  GIF = "gif",
  JP2 = "jp2",
  JPG = "jpg",
  JSON = "json",
  JXR = "jxr",
  MP4 = "mp4",
  PJPG = "pjpg",
  PNG = "png",
  PNG32 = "png32",
  PNG8 = "png8",
  WEBM = "webm",
  WEBP = "webp",
}

export enum ImgixParamsIptc {
  ALLOW = "allow",
  BLOCK = "block",
}

export enum ImgixParamsMarkAlign {
  BOTTOM = "bottom",
  CENTER = "center",
  LEFT = "left",
  MIDDLE = "middle",
  RIGHT = "right",
  TOP = "top",
}

export enum ImgixParamsMarkFit {
  CLIP = "clip",
  CROP = "crop",
  FILL = "fill",
  MAX = "max",
  SCALE = "scale",
}

export enum ImgixParamsMarkTile {
  GRID = "grid",
}

export enum ImgixParamsPalette {
  CSS = "css",
  JSON = "json",
}

export enum ImgixParamsTransparency {
  GRID = "grid",
}

export enum ImgixParamsTrim {
  AUTO = "auto",
  COLOR = "color",
}

export enum ImgixParamsTxtAlign {
  BOTTOM = "bottom",
  CENTER = "center",
  LEFT = "left",
  MIDDLE = "middle",
  RIGHT = "right",
  TOP = "top",
}

export enum ImgixParamsTxtClip {
  ELLIPSIS = "ellipsis",
  END = "end",
  MIDDLE = "middle",
  START = "start",
}

export enum ImgixParamsTxtFit {
  MAX = "max",
}

export type ColorField = {
  __typename?: "ColorField";
  alpha: Scalars["IntType"];
  blue: Scalars["IntType"];
  green: Scalars["IntType"];
  hex: Scalars["String"];
  red: Scalars["IntType"];
};

export type FocalPoint = {
  __typename?: "focalPoint";
  x: Scalars["FloatType"];
  y: Scalars["FloatType"];
};

export type ResponsiveImage = {
  __typename?: "ResponsiveImage";
  alt?: Maybe<Scalars["String"]>;
  aspectRatio: Scalars["FloatType"];
  base64?: Maybe<Scalars["String"]>;
  bgColor?: Maybe<Scalars["String"]>;
  height: Scalars["IntType"];
  sizes: Scalars["String"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  webpSrcSet: Scalars["String"];
  width: Scalars["IntType"];
};

export type UploadVideoField = {
  __typename?: "UploadVideoField";
  duration?: Maybe<Scalars["Int"]>;
  framerate?: Maybe<Scalars["Int"]>;
  mp4Url?: Maybe<Scalars["String"]>;
  muxAssetId: Scalars["String"];
  muxPlaybackId: Scalars["String"];
  streamingUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
};

export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};

export enum VideoMp4Res {
  HIGH = "high",
  LOW = "low",
  MEDIUM = "medium",
}

export enum MuxThumbnailFormatType {
  GIF = "gif",
  JPG = "jpg",
  PNG = "png",
}

export enum FaviconType {
  APPLETOUCHICON = "appleTouchIcon",
  ICON = "icon",
  MSAPPLICATION = "msApplication",
}

export type Tag = {
  __typename?: "Tag";
  attributes?: Maybe<Scalars["MetaTagAttributes"]>;
  content?: Maybe<Scalars["String"]>;
  tag: Scalars["String"];
};

export type GlobalSeoField = {
  __typename?: "GlobalSeoField";
  facebookPageUrl?: Maybe<Scalars["String"]>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars["String"]>;
  titleSuffix?: Maybe<Scalars["String"]>;
  twitterAccount?: Maybe<Scalars["String"]>;
};

export type SeoField = {
  __typename?: "SeoField";
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<FileField>;
  title?: Maybe<Scalars["String"]>;
  twitterCard?: Maybe<Scalars["String"]>;
};

export enum FirstNameModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type First Name (first_name) */
export type FirstNameRecord = RecordInterface & {
  __typename?: "FirstNameRecord";
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type First Name (first_name) */
export type FirstNameRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type RecordInterface = {
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
};

export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum FrontDoorModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Front Door (front_door) */
export type FrontDoorRecord = RecordInterface & {
  __typename?: "FrontDoorRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type StringMultiLocaleField = {
  __typename?: "StringMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["String"]>;
};

export enum GiftModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Gift (gift) */
export type GiftRecord = RecordInterface & {
  __typename?: "GiftRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Gift (gift) */
export type GiftRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum HobbyModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Hobby (hobby) */
export type HobbyRecord = RecordInterface & {
  __typename?: "HobbyRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum LetterPostscriptModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord = RecordInterface & {
  __typename?: "LetterPostscriptRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum LetterSignatureModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord = RecordInterface & {
  __typename?: "LetterSignatureRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum LetterTemplateModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  SORTGROUP_ASC = "sortGroup_ASC",
  SORTGROUP_DESC = "sortGroup_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord = RecordInterface & {
  __typename?: "LetterTemplateRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allShowOnSiteLocales?: Maybe<Array<BooleanTypeMultiLocaleField>>;
  _allTemplateLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  sortGroup?: Maybe<Scalars["IntType"]>;
  template?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllShowOnSiteLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllTemplateLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordShowOnSiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordTemplateArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

export type BooleanTypeMultiLocaleField = {
  __typename?: "BooleanTypeMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["BooleanType"]>;
};

export enum PageModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  ALPHAID_ASC = "alphaId_ASC",
  ALPHAID_DESC = "alphaId_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  VIDEOFALLBACKIMAGEURL_ASC = "videoFallbackImageUrl_ASC",
  VIDEOFALLBACKIMAGEURL_DESC = "videoFallbackImageUrl_DESC",
  VIDEOURL_ASC = "videoUrl_ASC",
  VIDEOURL_DESC = "videoUrl_DESC",
}

/** Record of type Page (page) */
export type PageRecord = RecordInterface & {
  __typename?: "PageRecord";
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  alphaId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  headerImage?: Maybe<FileField>;
  id: Scalars["ItemId"];
  sections: Array<PageSectionRecord>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  videoFallbackImageUrl?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

/** Record of type Page (page) */
export type PageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Page (page) */
export type PageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Page Section (page_section) */
export type PageSectionRecord = RecordInterface & {
  __typename?: "PageSectionRecord";
  _allBodyLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  body?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  identifier?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Page Section (page_section) */
export type PageSectionRecord_AllBodyLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Page Section (page_section) */
export type PageSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Page Section (page_section) */
export type PageSectionRecordBodyArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

export enum PageSectionModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  IDENTIFIER_ASC = "identifier_ASC",
  IDENTIFIER_DESC = "identifier_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

export enum PetModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  TEXTPLURAL_ASC = "textPlural_ASC",
  TEXTPLURAL_DESC = "textPlural_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Pet (pet) */
export type PetRecord = RecordInterface & {
  __typename?: "PetRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextPluralLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  textPlural?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Pet (pet) */
export type PetRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_AllTextPluralLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordTextPluralArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum ProductFaqModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecord = RecordInterface & {
  __typename?: "ProductFaqRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecord_AllDescriptionLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecordDescriptionArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product FAQ (product_faq) */
export type ProductFaqRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum ProductModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  ALPHAID_ASC = "alphaId_ASC",
  ALPHAID_DESC = "alphaId_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  ISADDON_ASC = "isAddOn_ASC",
  ISADDON_DESC = "isAddOn_DESC",
  ISPOPULAR_ASC = "isPopular_ASC",
  ISPOPULAR_DESC = "isPopular_DESC",
  PRICE_ASC = "price_ASC",
  PRICE_DESC = "price_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Product (product) */
export type ProductRecord = RecordInterface & {
  __typename?: "ProductRecord";
  _allDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allHomePageDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allLetterHomePageDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allPriceLocales?: Maybe<Array<FloatTypeMultiLocaleField>>;
  _allProductPageDescriptionLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  addOns: Array<ProductRecord>;
  alphaId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  faqs: Array<ProductFaqRecord>;
  homePageDescription?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  internationalSurcharge?: Maybe<ProductRecord>;
  isAddOn?: Maybe<Scalars["BooleanType"]>;
  isPopular?: Maybe<Scalars["BooleanType"]>;
  letterHomePageDescription?: Maybe<Scalars["String"]>;
  postagePackingSurcharge?: Maybe<ProductRecord>;
  price?: Maybe<Scalars["FloatType"]>;
  productPageDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product (product) */
export type ProductRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllHomePageDescriptionLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecord_AllLetterHomePageDescriptionLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecord_AllPriceLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllProductPageDescriptionLocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordHomePageDescriptionArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecordLetterHomePageDescriptionArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecordPriceArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordProductPageDescriptionArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FloatTypeMultiLocaleField = {
  __typename?: "FloatTypeMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["FloatType"]>;
};

export enum TextTemplateModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
}

/** Record of type Text Template (text_template) */
export type TextTemplateRecord = RecordInterface & {
  __typename?: "TextTemplateRecord";
  _allLabelLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTemplate1Locales?: Maybe<Array<StringMultiLocaleField>>;
  _allTemplate2Locales?: Maybe<Array<StringMultiLocaleField>>;
  _allTemplate3Locales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  template1?: Maybe<Scalars["String"]>;
  template2?: Maybe<Scalars["String"]>;
  template3?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate1LocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate2LocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate3LocalesArgs = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate1Args = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate2Args = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate3Args = {
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars["Boolean"]>;
};

export enum UploadOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  BASENAME_ASC = "basename_ASC",
  BASENAME_DESC = "basename_DESC",
  FILENAME_ASC = "filename_ASC",
  FILENAME_DESC = "filename_DESC",
  FORMAT_ASC = "format_ASC",
  FORMAT_DESC = "format_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  MIMETYPE_ASC = "mimeType_ASC",
  MIMETYPE_DESC = "mimeType_DESC",
  RESOLUTION_ASC = "resolution_ASC",
  RESOLUTION_DESC = "resolution_DESC",
  SIZE_ASC = "size_ASC",
  SIZE_DESC = "size_DESC",
}

export enum CallType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export type AvailableCallDatesResponse = {
  __typename?: "AvailableCallDatesResponse";
  date: Scalars["String"];
};

export type AvailableCallSlotsResponse = {
  __typename?: "AvailableCallSlotsResponse";
  isNextSlotAvailable: Scalars["Boolean"];
  time: Scalars["String"];
};

export enum SiteCountryCode {
  GB = "GB",
  US = "US",
}

export type Call = {
  __typename?: "Call";
  addressCountry?: Maybe<Country>;
  addressCounty?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressPostcode?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressUsState?: Maybe<UsState>;
  ageMonths?: Maybe<Scalars["Int"]>;
  ageYears?: Maybe<Scalars["Int"]>;
  boyGirl: BoyGirl;
  firstName: Scalars["String"];
  friend?: Maybe<Scalars["String"]>;
  frontDoor?: Maybe<FrontDoorRecord>;
  gift?: Maybe<Scalars["String"]>;
  hobby?: Maybe<Scalars["String"]>;
  isEditable: Scalars["Boolean"];
  lastName: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  orderItem: OrderItem;
  pet1?: Maybe<PetRecord>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2?: Maybe<PetRecord>;
  pet2Name?: Maybe<Scalars["String"]>;
  phoneCountry?: Maybe<Country>;
  phoneNumber?: Maybe<Scalars["String"]>;
  product: ProductRecord;
  santa: User;
  time: Scalars["DateTime"];
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  timezone: Timezone;
  type: CallType;
  urn: Scalars["String"];
  user: User;
};

export type Country = {
  __typename?: "Country";
  callingCode: Scalars["String"];
  iso: Scalars["String"];
  name: Scalars["String"];
  urn: Scalars["String"];
};

export type UsState = {
  __typename?: "UsState";
  code: Scalars["String"];
  name: Scalars["String"];
  urn: Scalars["String"];
};

export enum BoyGirl {
  BOY = "BOY",
  GIRL = "GIRL",
}

export type OrderItem = {
  __typename?: "OrderItem";
  addOnProducts: Array<ProductRecord>;
  call?: Maybe<Call>;
  letter?: Maybe<Letter>;
  order: Order;
  price: Scalars["Float"];
  product: ProductRecord;
  productAlphaId: ProductAlphaId;
  sack?: Maybe<Sack>;
  text?: Maybe<Text>;
  urn: Scalars["String"];
  video?: Maybe<Video>;
};

export type OrderItemAddOnProductsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type OrderItemProductArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Letter = {
  __typename?: "Letter";
  addressCountry: Country;
  addressCounty?: Maybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsState?: Maybe<UsState>;
  ageMonths?: Maybe<Scalars["Int"]>;
  ageYears?: Maybe<Scalars["Int"]>;
  boyGirl: BoyGirl;
  date: Scalars["DateTime"];
  firstName: Scalars["String"];
  friend?: Maybe<Scalars["String"]>;
  frontDoor?: Maybe<FrontDoorRecord>;
  gift?: Maybe<Scalars["String"]>;
  hobby?: Maybe<Scalars["String"]>;
  isEditable: Scalars["Boolean"];
  lastName: Scalars["String"];
  orderItem: OrderItem;
  pet1?: Maybe<PetRecord>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2?: Maybe<PetRecord>;
  pet2Name?: Maybe<Scalars["String"]>;
  product: ProductRecord;
  ps?: Maybe<LetterPostscriptRecord>;
  psCustom?: Maybe<Scalars["String"]>;
  secretAccessLogin?: Maybe<Scalars["String"]>;
  secretAccessPassword?: Maybe<Scalars["String"]>;
  signature: LetterSignatureRecord;
  template: LetterTemplateRecord;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  urn: Scalars["String"];
  user: User;
};

export type LetterTemplateArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  urn: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  orderItems: Array<OrderItem>;
  paymentTransaction?: Maybe<Transaction>;
  promoCode?: Maybe<PromoCode>;
  promoDiscount?: Maybe<Scalars["Float"]>;
  refundTransactions: Array<Transaction>;
  siteCountryCode: SiteCountryCode;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  total: Scalars["Float"];
  urn: Scalars["String"];
  user: User;
};

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["Float"];
  description: Scalars["String"];
  type: Scalars["String"];
  urn: Scalars["String"];
};

export type PromoCode = {
  __typename?: "PromoCode";
  amount?: Maybe<Scalars["Float"]>;
  code: Scalars["String"];
  dateExpires: Scalars["String"];
  isSingleUseForCustomer: Scalars["Boolean"];
  isSingleUseGlobally: Scalars["Boolean"];
  isValid: Scalars["Boolean"];
  type: Scalars["String"];
  urn: Scalars["String"];
};

export enum ProductAlphaId {
  CALL_CERTIFICATE = "CALL_CERTIFICATE",
  COOKIE_MIX = "COOKIE_MIX",
  DANGLY_ELF = "DANGLY_ELF",
  INTERNATIONAL_SURCHARGE_LETTER = "INTERNATIONAL_SURCHARGE_LETTER",
  INTERNATIONAL_SURCHARGE_PRODUCT = "INTERNATIONAL_SURCHARGE_PRODUCT",
  MAGIC_SNOW = "MAGIC_SNOW",
  PANDP_SURCHARGE = "PANDP_SURCHARGE",
  SANTA_CALL = "SANTA_CALL",
  SANTA_CALL_VIDEO = "SANTA_CALL_VIDEO",
  SANTA_LETTER = "SANTA_LETTER",
  SANTA_PLUSH = "SANTA_PLUSH",
  SANTA_SACK = "SANTA_SACK",
  SANTA_TEXT = "SANTA_TEXT",
  SANTA_TEXT_BONUS = "SANTA_TEXT_BONUS",
  SANTA_VIDEO_MESSAGE = "SANTA_VIDEO_MESSAGE",
  VIDEO_MESSAGE = "VIDEO_MESSAGE",
}

export type Sack = {
  __typename?: "Sack";
  addressCountry: Country;
  addressCounty?: Maybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsState?: Maybe<UsState>;
  date: Scalars["DateTime"];
  name: Scalars["String"];
  orderItem: OrderItem;
  product: ProductRecord;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  urn: Scalars["String"];
  user: User;
};

export type Text = {
  __typename?: "Text";
  ageYears: Scalars["Int"];
  bonusBody?: Maybe<Scalars["String"]>;
  bonusTextTransactionId?: Maybe<Scalars["String"]>;
  boyGirl: BoyGirl;
  firstName: Scalars["String"];
  isEditable: Scalars["Boolean"];
  orderItem: OrderItem;
  pet?: Maybe<PetRecord>;
  petName?: Maybe<Scalars["String"]>;
  phoneCountry: Country;
  phoneNumber: Scalars["String"];
  product: ProductRecord;
  status?: Maybe<TextStatus>;
  template: TextTemplateRecord;
  text1Body: Scalars["String"];
  text1Time: Scalars["DateTime"];
  text1TransactionId?: Maybe<Scalars["String"]>;
  text2Body: Scalars["String"];
  text2Time: Scalars["DateTime"];
  text2TransactionId?: Maybe<Scalars["String"]>;
  text3Body: Scalars["String"];
  text3Time: Scalars["DateTime"];
  text3TransactionId?: Maybe<Scalars["String"]>;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  timezone: Timezone;
  town: Scalars["String"];
  urn: Scalars["String"];
  user: User;
};

export enum TextStatus {
  PARTIALLY_SCHEDULED = "PARTIALLY_SCHEDULED",
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
}

export type Timezone = {
  __typename?: "Timezone";
  country: Country;
  name: Scalars["String"];
  timezone: Scalars["String"];
  urn: Scalars["String"];
};

export type Video = {
  __typename?: "Video";
  ageYears?: Maybe<Scalars["Int"]>;
  childImageId?: Maybe<Scalars["String"]>;
  country?: Maybe<Country>;
  firstName: FirstNameRecord;
  frontDoor?: Maybe<FrontDoorRecord>;
  gift?: Maybe<GiftRecord>;
  hobby?: Maybe<HobbyRecord>;
  houseImageId?: Maybe<Scalars["String"]>;
  orderItem: OrderItem;
  pet1?: Maybe<PetRecord>;
  pet2?: Maybe<PetRecord>;
  product: ProductRecord;
  streamUrl?: Maybe<Scalars["String"]>;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  urn: Scalars["String"];
  user: User;
};

export type CallSearchFilterInput = {
  santaUrn?: InputMaybe<Scalars["String"]>;
  time?: InputMaybe<Scalars["String"]>;
  timeFrom?: InputMaybe<Scalars["String"]>;
  timeTo?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<CallType>;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type CallsSearchResponse = {
  __typename?: "CallsSearchResponse";
  edges: Array<CallsSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CallsSearchEdge = {
  __typename?: "CallsSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Call;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord = RecordInterface & {
  __typename?: "FeatureFlagRecord";
  _allHomePageHeroProductLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allLettersEnabledLocales?: Maybe<Array<BooleanTypeMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  homePageHeroProduct?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  lettersEnabled?: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_AllHomePageHeroProductLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_AllLettersEnabledLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecordHomePageHeroProductArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecordLettersEnabledArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type LetterSearchFilterInput = {
  date?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  products?: InputMaybe<Array<ProductAlphaId>>;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type LettersSearchResponse = {
  __typename?: "LettersSearchResponse";
  edges: Array<LettersSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LettersSearchEdge = {
  __typename?: "LettersSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Letter;
};

export type OrderSearchFilterInput = {
  date?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  products?: InputMaybe<Array<ProductAlphaId>>;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type OrdersSearchResponse = {
  __typename?: "OrdersSearchResponse";
  edges: Array<OrdersSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrdersSearchEdge = {
  __typename?: "OrdersSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Order;
};

export type SackSearchFilterInput = {
  date?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type SacksSearchResponse = {
  __typename?: "SacksSearchResponse";
  edges: Array<SacksSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SacksSearchEdge = {
  __typename?: "SacksSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Sack;
};

export type UsersSearchResponse = {
  __typename?: "UsersSearchResponse";
  edges: Array<UsersSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UsersSearchEdge = {
  __typename?: "UsersSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: User;
};

export type TextSearchFilterInput = {
  text1TimeFrom?: InputMaybe<Scalars["String"]>;
  text1TimeTo?: InputMaybe<Scalars["String"]>;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type TextsSearchResponse = {
  __typename?: "TextsSearchResponse";
  edges: Array<TextsSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type TextsSearchEdge = {
  __typename?: "TextsSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Text;
};

export type ValidatePhoneNumberResponse = {
  __typename?: "ValidatePhoneNumberResponse";
  number?: Maybe<Scalars["String"]>;
};

export type VideoSearchFilterInput = {
  createdFrom?: InputMaybe<Scalars["String"]>;
  createdTo?: InputMaybe<Scalars["String"]>;
  userUrn?: InputMaybe<Scalars["String"]>;
  withoutStream?: InputMaybe<Scalars["Boolean"]>;
};

export type VideosSearchResponse = {
  __typename?: "VideosSearchResponse";
  edges: Array<VideosSearchEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VideosSearchEdge = {
  __typename?: "VideosSearchEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: Video;
};

export type Mutation = {
  __typename?: "Mutation";
  addToMailingList: SuccessResponse;
  applyPromoCode: ApplyPromoCodeResponse;
  createVideoLogin: CreateVideoLoginResponse;
  makeCallReservation: MakeCallReservationResponse;
  makeReservationFollowing: MakeCallReservationResponse;
  placeOrder: Order;
  swapReservationForNonDoubleSlot: MakeCallReservationResponse;
  updateCall: Call;
  updateLetter: Letter;
  updateSack: Sack;
  updateText: Text;
  updateVideo: Video;
};

export type MutationAddToMailingListArgs = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationApplyPromoCodeArgs = {
  code: Scalars["String"];
  total: Scalars["Float"];
};

export type MutationCreateVideoLoginArgs = {
  letterUrn?: InputMaybe<Scalars["String"]>;
  usernamePrefix: Scalars["String"];
  videoUrn?: InputMaybe<Scalars["String"]>;
};

export type MutationMakeCallReservationArgs = {
  time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

export type MutationMakeReservationFollowingArgs = {
  reservationUrn: Scalars["String"];
  type: CallType;
};

export type MutationPlaceOrderArgs = {
  items: Array<OrderItemInput>;
  paymentToken?: InputMaybe<Scalars["String"]>;
  promoCodeUrn?: InputMaybe<Scalars["String"]>;
  siteCountryCode: SiteCountryCode;
};

export type MutationSwapReservationForNonDoubleSlotArgs = {
  callType: CallType;
  reservationUrn: Scalars["String"];
};

export type MutationUpdateCallArgs = {
  callUrn: Scalars["String"];
  details: CallUpdateInput;
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateLetterArgs = {
  details: LetterUpdateInput;
  letterUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateSackArgs = {
  details: SackUpdateInput;
  sackUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateTextArgs = {
  details: TextUpdateInput;
  textUrn: Scalars["String"];
  userUrn?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateVideoArgs = {
  details: VideoUpdateInput;
  userUrn?: InputMaybe<Scalars["String"]>;
  videoUrn: Scalars["String"];
};

export type SuccessResponse = {
  __typename?: "SuccessResponse";
  isSuccess: Scalars["Boolean"];
};

export type ApplyPromoCodeResponse = {
  __typename?: "ApplyPromoCodeResponse";
  deduction: Scalars["Float"];
  promoCode: PromoCode;
};

export type CreateVideoLoginResponse = {
  __typename?: "CreateVideoLoginResponse";
  password: Scalars["String"];
  username: Scalars["String"];
};

export type MakeCallReservationResponse = {
  __typename?: "MakeCallReservationResponse";
  reservationUrn: Scalars["String"];
};

export type OrderItemInput = {
  addOns: Array<ProductAlphaId>;
  alphaId: ProductAlphaId;
  callDetails?: InputMaybe<CallCreateInput>;
  letterDetails?: InputMaybe<LetterCreateInput>;
  sackDetails?: InputMaybe<SackCreateInput>;
  textDetails?: InputMaybe<TextCreateInput>;
  videoDetails?: InputMaybe<VideoCreateInput>;
};

export type CallCreateInput = {
  addressCountryUrn?: InputMaybe<Scalars["String"]>;
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressPostcode?: InputMaybe<Scalars["String"]>;
  addressTown?: InputMaybe<Scalars["String"]>;
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  ageMonths?: InputMaybe<Scalars["Int"]>;
  ageYears?: InputMaybe<Scalars["Int"]>;
  boyGirl: BoyGirl;
  callReservationUrn: Scalars["String"];
  firstName: Scalars["String"];
  friend?: InputMaybe<Scalars["String"]>;
  frontDoorId?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  phoneCountryUrn?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  timezoneUrn: Scalars["String"];
};

export type LetterCreateInput = {
  addressCountryUrn: Scalars["String"];
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  ageMonths?: InputMaybe<Scalars["Int"]>;
  ageYears?: InputMaybe<Scalars["Int"]>;
  boyGirl: BoyGirl;
  date: Scalars["String"];
  firstName: Scalars["String"];
  friend?: InputMaybe<Scalars["String"]>;
  frontDoorId?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  psCustom?: InputMaybe<Scalars["String"]>;
  psId?: InputMaybe<Scalars["String"]>;
  signatureId: Scalars["String"];
  templateId: Scalars["String"];
};

export type SackCreateInput = {
  addressCountryUrn: Scalars["String"];
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  date: Scalars["String"];
  name: Scalars["String"];
};

export type TextCreateInput = {
  ageYears: Scalars["Int"];
  bonusBody?: InputMaybe<Scalars["String"]>;
  boyGirl: BoyGirl;
  firstName: Scalars["String"];
  petId?: InputMaybe<Scalars["String"]>;
  petName?: InputMaybe<Scalars["String"]>;
  phoneCountryUrn: Scalars["String"];
  phoneNumber: Scalars["String"];
  templateId: Scalars["String"];
  text1Body: Scalars["String"];
  text1Time: Scalars["String"];
  text2Body: Scalars["String"];
  text2Time: Scalars["String"];
  text3Body: Scalars["String"];
  text3Time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  town: Scalars["String"];
};

export type VideoCreateInput = {
  ageYears?: InputMaybe<Scalars["Int"]>;
  childImageId?: InputMaybe<Scalars["String"]>;
  countryUrn?: InputMaybe<Scalars["String"]>;
  firstNameId: Scalars["String"];
  frontDoorId?: InputMaybe<Scalars["String"]>;
  giftId?: InputMaybe<Scalars["String"]>;
  hobbyId?: InputMaybe<Scalars["String"]>;
  houseImageId?: InputMaybe<Scalars["String"]>;
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
};

export type CallUpdateInput = {
  ageMonths?: InputMaybe<Scalars["Int"]>;
  ageYears?: InputMaybe<Scalars["Int"]>;
  boyGirl?: InputMaybe<BoyGirl>;
  firstName?: InputMaybe<Scalars["String"]>;
  friend?: InputMaybe<Scalars["String"]>;
  frontDoorId?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  phoneCountryUrn?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  santaUrn?: InputMaybe<Scalars["String"]>;
  time?: InputMaybe<Scalars["String"]>;
  timezoneUrn?: InputMaybe<Scalars["String"]>;
};

export type LetterUpdateInput = {
  addressCountryUrn: Scalars["String"];
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  ageMonths?: InputMaybe<Scalars["Int"]>;
  ageYears?: InputMaybe<Scalars["Int"]>;
  boyGirl: BoyGirl;
  date: Scalars["String"];
  firstName: Scalars["String"];
  friend?: InputMaybe<Scalars["String"]>;
  frontDoorId?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  psCustom?: InputMaybe<Scalars["String"]>;
  psId?: InputMaybe<Scalars["String"]>;
  secretAccessLogin?: InputMaybe<Scalars["String"]>;
  secretAccessPassword?: InputMaybe<Scalars["String"]>;
  signatureId: Scalars["String"];
  templateId: Scalars["String"];
};

export type SackUpdateInput = {
  addressCountryUrn: Scalars["String"];
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressTown: Scalars["String"];
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  date: Scalars["String"];
  name: Scalars["String"];
};

export type TextUpdateInput = {
  ageYears?: InputMaybe<Scalars["Int"]>;
  bonusBody?: InputMaybe<Scalars["String"]>;
  bonusTextTransactionId?: InputMaybe<Scalars["String"]>;
  boyGirl?: InputMaybe<BoyGirl>;
  firstName?: InputMaybe<Scalars["String"]>;
  petId?: InputMaybe<Scalars["String"]>;
  petName?: InputMaybe<Scalars["String"]>;
  phoneCountryUrn?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  templateId?: InputMaybe<Scalars["String"]>;
  text1Body?: InputMaybe<Scalars["String"]>;
  text1Time?: InputMaybe<Scalars["String"]>;
  text1TransactionId?: InputMaybe<Scalars["String"]>;
  text2Body?: InputMaybe<Scalars["String"]>;
  text2Time?: InputMaybe<Scalars["String"]>;
  text2TransactionId?: InputMaybe<Scalars["String"]>;
  text3Body?: InputMaybe<Scalars["String"]>;
  text3Time?: InputMaybe<Scalars["String"]>;
  text3TransactionId?: InputMaybe<Scalars["String"]>;
  timezoneUrn?: InputMaybe<Scalars["String"]>;
  town?: InputMaybe<Scalars["String"]>;
};

export type VideoUpdateInput = {
  streamId?: InputMaybe<Scalars["String"]>;
};

export type PlaceOrderResponse = {
  __typename?: "PlaceOrderResponse";
  orderUrn: Scalars["String"];
};

export type BasketQueryVariables = Exact<{
  alphaIds: Array<Scalars["String"]> | Scalars["String"];
  letterTemplateIds: Array<Scalars["ItemId"]> | Scalars["ItemId"];
  textTemplateIds: Array<Scalars["ItemId"]> | Scalars["ItemId"];
  firstNameIds: Array<Scalars["ItemId"]> | Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type BasketQuery = {
  __typename?: "Query";
  letterTemplates: Array<{
    __typename?: "LetterTemplateRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  textTemplates: Array<{
    __typename?: "TextTemplateRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  products: Array<{
    __typename?: "ProductRecord";
    alphaId?: string | null | undefined;
    title?: string | null | undefined;
    price?: any | null | undefined;
    image?: { __typename?: "FileField"; url: string } | null | undefined;
  }>;
  firstNames: Array<{ __typename?: "FirstNameRecord"; id: any; label?: string | null | undefined }>;
};

export type CallsAddOnsQueryVariables = Exact<{
  alphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type CallsAddOnsQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        addOns: Array<{
          __typename?: "ProductRecord";
          id: any;
          alphaId?: string | null | undefined;
          title?: string | null | undefined;
          description?: string | null | undefined;
          price?: any | null | undefined;
          isPopular?: any | null | undefined;
          image?: { __typename?: "FileField"; url: string } | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CallsCallingQueryVariables = Exact<{
  callAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type CallsCallingQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; iso: string; callingCode: string }>;
  timezones: Array<{
    __typename?: "Timezone";
    urn: string;
    name: string;
    country: { __typename?: "Country"; urn: string };
  }>;
  product?:
    | {
        __typename?: "ProductRecord";
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CallsDatesQueryVariables = Exact<{
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
}>;

export type CallsDatesQuery = {
  __typename?: "Query";
  availableCallDates: Array<{ __typename?: "AvailableCallDatesResponse"; date: string }>;
};

export type CallsSlotsQueryVariables = Exact<{
  date: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
}>;

export type CallsSlotsQuery = {
  __typename?: "Query";
  availableCallSlots: Array<{
    __typename?: "AvailableCallSlotsResponse";
    time: string;
    isNextSlotAvailable: boolean;
  }>;
};

export type MakeCallReservationMutationVariables = Exact<{
  time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
}>;

export type MakeCallReservationMutation = {
  __typename?: "Mutation";
  makeCallReservation: { __typename?: "MakeCallReservationResponse"; reservationUrn: string };
};

export type CallsRecipientFormQueryVariables = Exact<{
  reservationUrn: Scalars["String"];
  locale: SiteLocale;
  type: CallType;
}>;

export type CallsRecipientFormQuery = {
  __typename?: "Query";
  checkSlotFollowingReservation: boolean;
  frontDoors: Array<{
    __typename?: "FrontDoorRecord";
    id: any;
    label?: string | null | undefined;
    text?: string | null | undefined;
  }>;
  pets: Array<{
    __typename?: "PetRecord";
    id: any;
    label?: string | null | undefined;
    text?: string | null | undefined;
    textPlural?: string | null | undefined;
  }>;
};

export type MakeSecondCallReservationMutationVariables = Exact<{
  reservationUrn: Scalars["String"];
  type: CallType;
}>;

export type MakeSecondCallReservationMutation = {
  __typename?: "Mutation";
  makeReservationFollowing: { __typename?: "MakeCallReservationResponse"; reservationUrn: string };
};

export type CallsHomePageQueryVariables = Exact<{
  callAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type CallsHomePageQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        description?: string | null | undefined;
        productPageDescription?: string | null | undefined;
        price?: any | null | undefined;
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CheckoutPaymentQueryVariables = Exact<{
  alphaIds: Array<Scalars["String"]> | Scalars["String"];
  locale: SiteLocale;
  siteCountryCode: SiteCountryCode;
}>;

export type CheckoutPaymentQuery = {
  __typename?: "Query";
  braintreeToken: string;
  products: Array<{
    __typename?: "ProductRecord";
    alphaId?: string | null | undefined;
    price?: any | null | undefined;
  }>;
};

export type PlaceOrderMutationVariables = Exact<{
  paymentToken?: InputMaybe<Scalars["String"]>;
  items: Array<OrderItemInput> | OrderItemInput;
  promoCodeUrn?: InputMaybe<Scalars["String"]>;
  siteCountryCode: SiteCountryCode;
}>;

export type PlaceOrderMutation = {
  __typename?: "Mutation";
  placeOrder: { __typename?: "Order"; urn: string };
};

export type AddToMailingListMutationVariables = Exact<{
  email: Scalars["String"];
  name: Scalars["String"];
}>;

export type AddToMailingListMutation = {
  __typename?: "Mutation";
  addToMailingList: { __typename?: "SuccessResponse"; isSuccess: boolean };
};

export type CheckoutDetailsQueryVariables = Exact<{
  alphaIds: Array<Scalars["String"]> | Scalars["String"];
  firstNameIds: Array<Scalars["ItemId"]> | Scalars["ItemId"];
  locale: SiteLocale;
}>;

export type CheckoutDetailsQuery = {
  __typename?: "Query";
  products: Array<{
    __typename?: "ProductRecord";
    alphaId?: string | null | undefined;
    title?: string | null | undefined;
    price?: any | null | undefined;
    image?: { __typename?: "FileField"; url: string } | null | undefined;
  }>;
  firstNames: Array<{ __typename?: "FirstNameRecord"; id: any; label?: string | null | undefined }>;
};

export type ContentPageQueryVariables = Exact<{
  alphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type ContentPageQuery = {
  __typename?: "Query";
  page?:
    | {
        __typename?: "PageRecord";
        alphaId?: string | null | undefined;
        title?: string | null | undefined;
        headerImage?: { __typename?: "FileField"; url: string } | null | undefined;
        sections: Array<{ __typename?: "PageSectionRecord"; body?: string | null | undefined }>;
      }
    | null
    | undefined;
};

export type HomePageQueryVariables = Exact<{
  letterAlphaId: Scalars["String"];
  audioCallAlphaId: Scalars["String"];
  videoCallAlphaId: Scalars["String"];
  textAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type HomePageQuery = {
  __typename?: "Query";
  page?:
    | {
        __typename?: "PageRecord";
        videoUrl?: string | null | undefined;
        videoFallbackImageUrl?: string | null | undefined;
      }
    | null
    | undefined;
  letter?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        homePageDescription?: string | null | undefined;
      }
    | null
    | undefined;
  audioCall?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        homePageDescription?: string | null | undefined;
      }
    | null
    | undefined;
  videoCall?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        homePageDescription?: string | null | undefined;
      }
    | null
    | undefined;
  text?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        homePageDescription?: string | null | undefined;
      }
    | null
    | undefined;
  featureFlag?:
    | { __typename?: "FeatureFlagRecord"; homePageHeroProduct?: string | null | undefined }
    | null
    | undefined;
};

export type MyAccountQueryVariables = Exact<{
  locale: SiteLocale;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type MyAccountQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrdersSearchResponse";
    totalCount?: number | null | undefined;
    edges: Array<{
      __typename?: "OrdersSearchEdge";
      cursor?: string | null | undefined;
      node: {
        __typename?: "Order";
        urn: string;
        total: number;
        timeCreated: any;
        orderItems: Array<{
          __typename?: "OrderItem";
          productAlphaId: ProductAlphaId;
          price: number;
          addOnProducts: Array<{
            __typename?: "ProductRecord";
            alphaId?: string | null | undefined;
            title?: string | null | undefined;
            price?: any | null | undefined;
          }>;
          product: {
            __typename?: "ProductRecord";
            title?: string | null | undefined;
            image?: { __typename?: "FileField"; url: string } | null | undefined;
          };
          letter?:
            | {
                __typename?: "Letter";
                urn: string;
                date: any;
                firstName: string;
                isEditable: boolean;
                template: {
                  __typename?: "LetterTemplateRecord";
                  label?: string | null | undefined;
                };
              }
            | null
            | undefined;
          call?:
            | {
                __typename?: "Call";
                urn: string;
                firstName: string;
                lastName: string;
                time: any;
                phoneNumber?: string | null | undefined;
                isEditable: boolean;
                timezone: { __typename?: "Timezone"; timezone: string };
                phoneCountry?: { __typename?: "Country"; callingCode: string } | null | undefined;
              }
            | null
            | undefined;
          text?:
            | {
                __typename?: "Text";
                urn: string;
                firstName: string;
                text1Time: any;
                phoneNumber: string;
                isEditable: boolean;
                timezone: { __typename?: "Timezone"; timezone: string };
                phoneCountry: { __typename?: "Country"; callingCode: string };
              }
            | null
            | undefined;
          sack?:
            | { __typename?: "Sack"; name: string; addressTown: string; date: any }
            | null
            | undefined;
          video?:
            | {
                __typename?: "Video";
                firstName: { __typename?: "FirstNameRecord"; label?: string | null | undefined };
              }
            | null
            | undefined;
        }>;
      };
    }>;
  };
};

export type MyAccountCallQueryVariables = Exact<{
  callUrn: Scalars["String"];
  locale: SiteLocale;
}>;

export type MyAccountCallQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; iso: string; callingCode: string }>;
  timezones: Array<{
    __typename?: "Timezone";
    urn: string;
    name: string;
    country: { __typename?: "Country"; urn: string };
  }>;
  frontDoors: Array<{ __typename?: "FrontDoorRecord"; id: any; label?: string | null | undefined }>;
  pets: Array<{ __typename?: "PetRecord"; id: any; label?: string | null | undefined }>;
  call?:
    | {
        __typename?: "Call";
        type: CallType;
        phoneNumber?: string | null | undefined;
        time: any;
        firstName: string;
        lastName: string;
        boyGirl: BoyGirl;
        ageMonths?: number | null | undefined;
        ageYears?: number | null | undefined;
        hobby?: string | null | undefined;
        gift?: string | null | undefined;
        pet1Name?: string | null | undefined;
        pet2Name?: string | null | undefined;
        friend?: string | null | undefined;
        notes?: string | null | undefined;
        timezone: { __typename?: "Timezone"; urn: string };
        phoneCountry?: { __typename?: "Country"; urn: string } | null | undefined;
        frontDoor?: { __typename?: "FrontDoorRecord"; id: any } | null | undefined;
        pet1?: { __typename?: "PetRecord"; id: any } | null | undefined;
        pet2?: { __typename?: "PetRecord"; id: any } | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCallMutationVariables = Exact<{
  callUrn: Scalars["String"];
  phoneCountryUrn?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  timezoneUrn: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  time: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: InputMaybe<Scalars["Int"]>;
  ageMonths?: InputMaybe<Scalars["Int"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  friend?: InputMaybe<Scalars["String"]>;
  frontDoorId?: InputMaybe<Scalars["String"]>;
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateCallMutation = {
  __typename?: "Mutation";
  updateCall: { __typename?: "Call"; urn: string };
};

export type MyAccountLetterQueryVariables = Exact<{
  letterUrn: Scalars["String"];
  locale: SiteLocale;
}>;

export type MyAccountLetterQuery = {
  __typename?: "Query";
  firstName?:
    | { __typename?: "FirstNameRecord"; id: any; label?: string | null | undefined }
    | null
    | undefined;
  allFrontDoors: Array<{
    __typename?: "FrontDoorRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterPostscripts: Array<{
    __typename?: "LetterPostscriptRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterSignatures: Array<{
    __typename?: "LetterSignatureRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterTemplates: Array<{
    __typename?: "LetterTemplateRecord";
    id: any;
    label?: string | null | undefined;
    description?: string | null | undefined;
  }>;
  allPets: Array<{ __typename?: "PetRecord"; id: any; label?: string | null | undefined }>;
  letter?:
    | {
        __typename?: "Letter";
        firstName: string;
        lastName: string;
        boyGirl: BoyGirl;
        ageMonths?: number | null | undefined;
        ageYears?: number | null | undefined;
        date: any;
        addressLine1: string;
        addressLine2?: string | null | undefined;
        addressTown: string;
        addressCounty?: string | null | undefined;
        addressPostcode: string;
        hobby?: string | null | undefined;
        gift?: string | null | undefined;
        friend?: string | null | undefined;
        pet1Name?: string | null | undefined;
        pet2Name?: string | null | undefined;
        psCustom?: string | null | undefined;
        addressUsState?: { __typename?: "UsState"; urn: string } | null | undefined;
        addressCountry: { __typename?: "Country"; urn: string };
        template: {
          __typename?: "LetterTemplateRecord";
          id: any;
          label?: string | null | undefined;
          description?: string | null | undefined;
        };
        signature: {
          __typename?: "LetterSignatureRecord";
          id: any;
          label?: string | null | undefined;
        };
        frontDoor?:
          | { __typename?: "FrontDoorRecord"; id: any; label?: string | null | undefined }
          | null
          | undefined;
        pet1?:
          | { __typename?: "PetRecord"; id: any; label?: string | null | undefined }
          | null
          | undefined;
        pet2?:
          | { __typename?: "PetRecord"; id: any; label?: string | null | undefined }
          | null
          | undefined;
        ps?:
          | { __typename?: "LetterPostscriptRecord"; id: any; label?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateLetterMutationVariables = Exact<{
  letterUrn: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: InputMaybe<Scalars["String"]>;
  addressUsStateUrn?: InputMaybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressCountryUrn: Scalars["String"];
  date: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: InputMaybe<Scalars["Int"]>;
  ageMonths?: InputMaybe<Scalars["Int"]>;
  hobby?: InputMaybe<Scalars["String"]>;
  gift?: InputMaybe<Scalars["String"]>;
  friend?: InputMaybe<Scalars["String"]>;
  signatureId: Scalars["String"];
  frontDoorId?: InputMaybe<Scalars["String"]>;
  pet1Id?: InputMaybe<Scalars["String"]>;
  pet1Name?: InputMaybe<Scalars["String"]>;
  pet2Id?: InputMaybe<Scalars["String"]>;
  pet2Name?: InputMaybe<Scalars["String"]>;
  psId?: InputMaybe<Scalars["String"]>;
  psCustom?: InputMaybe<Scalars["String"]>;
  templateId: Scalars["String"];
}>;

export type UpdateLetterMutation = {
  __typename?: "Mutation";
  updateLetter: { __typename?: "Letter"; urn: string };
};

export type MyAccountTextQueryVariables = Exact<{
  textUrn: Scalars["String"];
  locale: SiteLocale;
}>;

export type MyAccountTextQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; iso: string; callingCode: string }>;
  timezones: Array<{
    __typename?: "Timezone";
    urn: string;
    name: string;
    country: { __typename?: "Country"; urn: string };
  }>;
  pets: Array<{
    __typename?: "PetRecord";
    id: any;
    label?: string | null | undefined;
    text?: string | null | undefined;
  }>;
  textTemplates: Array<{
    __typename?: "TextTemplateRecord";
    id: any;
    label?: string | null | undefined;
    template1?: string | null | undefined;
    template2?: string | null | undefined;
    template3?: string | null | undefined;
  }>;
  text?:
    | {
        __typename?: "Text";
        phoneNumber: string;
        firstName: string;
        boyGirl: BoyGirl;
        ageYears: number;
        town: string;
        petName?: string | null | undefined;
        text1Time: any;
        text1Body: string;
        text2Time: any;
        text2Body: string;
        text3Time: any;
        text3Body: string;
        bonusBody?: string | null | undefined;
        timezone: { __typename?: "Timezone"; urn: string; timezone: string };
        phoneCountry: { __typename?: "Country"; urn: string };
        pet?:
          | { __typename?: "PetRecord"; id: any; label?: string | null | undefined }
          | null
          | undefined;
        template: { __typename?: "TextTemplateRecord"; id: any; label?: string | null | undefined };
      }
    | null
    | undefined;
};

export type UpdateTextMutationVariables = Exact<{
  textUrn: Scalars["String"];
  ageYears: Scalars["Int"];
  boyGirl: BoyGirl;
  templateId: Scalars["String"];
  petId?: InputMaybe<Scalars["String"]>;
  petName?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  town: Scalars["String"];
  phoneCountryUrn: Scalars["String"];
  phoneNumber: Scalars["String"];
  timezoneUrn: Scalars["String"];
  text1Time: Scalars["String"];
  text1Body: Scalars["String"];
  text2Time: Scalars["String"];
  text2Body: Scalars["String"];
  text3Time: Scalars["String"];
  text3Body: Scalars["String"];
}>;

export type UpdateTextMutation = {
  __typename?: "Mutation";
  updateText: { __typename?: "Text"; urn: string };
};

export type OrderConfirmationQueryVariables = Exact<{
  orderUrn: Scalars["String"];
  locale: SiteLocale;
}>;

export type OrderConfirmationQuery = {
  __typename?: "Query";
  order?:
    | {
        __typename?: "Order";
        urn: string;
        promoDiscount?: number | null | undefined;
        promoCode?: { __typename?: "PromoCode"; code: string } | null | undefined;
        orderItems: Array<{
          __typename?: "OrderItem";
          productAlphaId: ProductAlphaId;
          price: number;
          addOnProducts: Array<{
            __typename?: "ProductRecord";
            title?: string | null | undefined;
            alphaId?: string | null | undefined;
            price?: any | null | undefined;
          }>;
          product: {
            __typename?: "ProductRecord";
            title?: string | null | undefined;
            image?: { __typename?: "FileField"; url: string } | null | undefined;
          };
          letter?:
            | {
                __typename?: "Letter";
                firstName: string;
                date: any;
                template: {
                  __typename?: "LetterTemplateRecord";
                  label?: string | null | undefined;
                };
              }
            | null
            | undefined;
          call?:
            | {
                __typename?: "Call";
                firstName: string;
                lastName: string;
                time: any;
                phoneNumber?: string | null | undefined;
                timezone: { __typename?: "Timezone"; timezone: string };
                phoneCountry?: { __typename?: "Country"; callingCode: string } | null | undefined;
              }
            | null
            | undefined;
          text?:
            | {
                __typename?: "Text";
                firstName: string;
                text1Time: any;
                phoneNumber: string;
                timezone: { __typename?: "Timezone"; timezone: string };
                phoneCountry: { __typename?: "Country"; callingCode: string };
              }
            | null
            | undefined;
          sack?:
            | { __typename?: "Sack"; name: string; addressTown: string; date: any }
            | null
            | undefined;
          video?:
            | {
                __typename?: "Video";
                firstName: { __typename?: "FirstNameRecord"; label?: string | null | undefined };
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type SackHomePageQueryVariables = Exact<{
  sackAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type SackHomePageQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        description?: string | null | undefined;
        productPageDescription?: string | null | undefined;
        price?: any | null | undefined;
        internationalSurcharge?:
          | {
              __typename?: "ProductRecord";
              alphaId?: string | null | undefined;
              title?: string | null | undefined;
              price?: any | null | undefined;
            }
          | null
          | undefined;
        postagePackingSurcharge?:
          | {
              __typename?: "ProductRecord";
              alphaId?: string | null | undefined;
              title?: string | null | undefined;
              price?: any | null | undefined;
            }
          | null
          | undefined;
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type LetterHomePageQueryVariables = Exact<{
  alphaIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locale: SiteLocale;
}>;

export type LetterHomePageQuery = {
  __typename?: "Query";
  allProducts: Array<{
    __typename?: "ProductRecord";
    alphaId?: string | null | undefined;
    title?: string | null | undefined;
    letterHomePageDescription?: string | null | undefined;
    price?: any | null | undefined;
    image?: { __typename?: "FileField"; url: string } | null | undefined;
  }>;
};

export type LetterHomeQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type LetterHomeQuery = {
  __typename?: "Query";
  featureFlag?:
    | { __typename?: "FeatureFlagRecord"; lettersEnabled?: any | null | undefined }
    | null
    | undefined;
};

export type LetterAddOnsQueryVariables = Exact<{
  alphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type LetterAddOnsQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        alphaId?: string | null | undefined;
        title?: string | null | undefined;
        price?: any | null | undefined;
        internationalSurcharge?:
          | {
              __typename?: "ProductRecord";
              alphaId?: string | null | undefined;
              title?: string | null | undefined;
              price?: any | null | undefined;
            }
          | null
          | undefined;
        postagePackingSurcharge?:
          | {
              __typename?: "ProductRecord";
              alphaId?: string | null | undefined;
              title?: string | null | undefined;
              price?: any | null | undefined;
            }
          | null
          | undefined;
        addOns: Array<{
          __typename?: "ProductRecord";
          id: any;
          alphaId?: string | null | undefined;
          title?: string | null | undefined;
          description?: string | null | undefined;
          price?: any | null | undefined;
          isPopular?: any | null | undefined;
          internationalSurcharge?:
            | {
                __typename?: "ProductRecord";
                alphaId?: string | null | undefined;
                title?: string | null | undefined;
                price?: any | null | undefined;
              }
            | null
            | undefined;
          postagePackingSurcharge?:
            | {
                __typename?: "ProductRecord";
                alphaId?: string | null | undefined;
                title?: string | null | undefined;
                price?: any | null | undefined;
              }
            | null
            | undefined;
          image?: { __typename?: "FileField"; url: string } | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type LetterFaqsQueryVariables = Exact<{
  letterAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type LetterFaqsQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type LetterTypePageQueryVariables = Exact<{
  letterAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type LetterTypePageQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        description?: string | null | undefined;
        productPageDescription?: string | null | undefined;
        price?: any | null | undefined;
        image?: { __typename?: "FileField"; url: string } | null | undefined;
        addOns: Array<{
          __typename?: "ProductRecord";
          alphaId?: string | null | undefined;
          title?: string | null | undefined;
          productPageDescription?: string | null | undefined;
          price?: any | null | undefined;
          image?: { __typename?: "FileField"; url: string } | null | undefined;
        }>;
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type TextsHomePageQueryVariables = Exact<{
  textAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type TextsHomePageQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        description?: string | null | undefined;
        productPageDescription?: string | null | undefined;
        price?: any | null | undefined;
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type TextsMessagesQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type TextsMessagesQuery = {
  __typename?: "Query";
  pets: Array<{
    __typename?: "PetRecord";
    id: any;
    label?: string | null | undefined;
    text?: string | null | undefined;
  }>;
  textTemplates: Array<{
    __typename?: "TextTemplateRecord";
    id: any;
    label?: string | null | undefined;
    template1?: string | null | undefined;
    template2?: string | null | undefined;
    template3?: string | null | undefined;
  }>;
};

export type TextsTextingQueryVariables = Exact<{
  textAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type TextsTextingQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; iso: string; callingCode: string }>;
  timezones: Array<{
    __typename?: "Timezone";
    urn: string;
    name: string;
    country: { __typename?: "Country"; urn: string };
  }>;
  product?:
    | {
        __typename?: "ProductRecord";
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type TextsBonusQueryVariables = Exact<{
  locale: SiteLocale;
}>;

export type TextsBonusQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        id: any;
        alphaId?: string | null | undefined;
        title?: string | null | undefined;
        price?: any | null | undefined;
        image?: { __typename?: "FileField"; url: string } | null | undefined;
      }
    | null
    | undefined;
};

export type ValidatePhoneNumberQueryVariables = Exact<{
  number: Scalars["String"];
  countryUrn: Scalars["String"];
}>;

export type ValidatePhoneNumberQuery = {
  __typename?: "Query";
  validatePhoneNumber?:
    | { __typename?: "ValidatePhoneNumberResponse"; number?: string | null | undefined }
    | null
    | undefined;
};

export type VideoDetailsQueryVariables = Exact<{
  videoAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type VideoDetailsQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; name: string }>;
  frontDoors: Array<{ __typename?: "FrontDoorRecord"; id: any; label?: string | null | undefined }>;
  pets: Array<{ __typename?: "PetRecord"; id: any; label?: string | null | undefined }>;
  product?:
    | {
        __typename?: "ProductRecord";
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type VideosHomePageQueryVariables = Exact<{
  videoAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type VideosHomePageQuery = {
  __typename?: "Query";
  product?:
    | {
        __typename?: "ProductRecord";
        title?: string | null | undefined;
        description?: string | null | undefined;
        productPageDescription?: string | null | undefined;
        price?: any | null | undefined;
        faqs: Array<{
          __typename?: "ProductFaqRecord";
          id: any;
          title?: string | null | undefined;
          description?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type FirstNameSearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type FirstNameSearchQuery = {
  __typename?: "Query";
  firstNames: Array<{ __typename?: "FirstNameRecord"; id: any; label?: string | null | undefined }>;
};

export type GiftSearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GiftSearchQuery = {
  __typename?: "Query";
  allGifts: Array<{ __typename?: "GiftRecord"; id: any; label?: string | null | undefined }>;
};

export type HobbySearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type HobbySearchQuery = {
  __typename?: "Query";
  allHobbies: Array<{ __typename?: "HobbyRecord"; id: any; label?: string | null | undefined }>;
};

export type AddressUsStatesQueryVariables = Exact<{ [key: string]: never }>;

export type AddressUsStatesQuery = {
  __typename?: "Query";
  usStates: Array<{ __typename?: "UsState"; urn: string; name: string; code: string }>;
};

export type AddressCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type AddressCountriesQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; urn: string; name: string }>;
};

export type SantaLetterHomeQueryVariables = Exact<{
  alphaIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locale: SiteLocale;
}>;

export type SantaLetterHomeQuery = {
  __typename?: "Query";
  allProducts: Array<{
    __typename?: "ProductRecord";
    alphaId?: string | null | undefined;
    title?: string | null | undefined;
    price?: any | null | undefined;
    image?: { __typename?: "FileField"; url: string } | null | undefined;
  }>;
};

export type ApplyPromoCodeMutationVariables = Exact<{
  total: Scalars["Float"];
  code: Scalars["String"];
}>;

export type ApplyPromoCodeMutation = {
  __typename?: "Mutation";
  applyPromoCode: {
    __typename?: "ApplyPromoCodeResponse";
    deduction: number;
    promoCode: { __typename?: "PromoCode"; code: string; urn: string };
  };
};

export type SantaLetterFormQueryVariables = Exact<{
  letterAlphaId: Scalars["String"];
  locale: SiteLocale;
}>;

export type SantaLetterFormQuery = {
  __typename?: "Query";
  allFrontDoors: Array<{
    __typename?: "FrontDoorRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterPostscripts: Array<{
    __typename?: "LetterPostscriptRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterSignatures: Array<{
    __typename?: "LetterSignatureRecord";
    id: any;
    label?: string | null | undefined;
  }>;
  allLetterTemplates: Array<{
    __typename?: "LetterTemplateRecord";
    id: any;
    label?: string | null | undefined;
    description?: string | null | undefined;
  }>;
  allPets: Array<{ __typename?: "PetRecord"; id: any; label?: string | null | undefined }>;
  product?:
    | {
        __typename?: "ProductRecord";
        internationalSurcharge?:
          | {
              __typename?: "ProductRecord";
              price?: any | null | undefined;
              title?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LetterSavedDataEnrichmentQueryVariables = Exact<{
  addressCountryUrn: Scalars["String"];
  usStateUrn: Scalars["String"];
  includeUsState: Scalars["Boolean"];
  signatureId?: InputMaybe<Scalars["ItemId"]>;
  includeSignature: Scalars["Boolean"];
  templateId?: InputMaybe<Scalars["ItemId"]>;
  includeTemplate: Scalars["Boolean"];
  pet1Id?: InputMaybe<Scalars["ItemId"]>;
  includePet1: Scalars["Boolean"];
  pet2Id?: InputMaybe<Scalars["ItemId"]>;
  includePet2: Scalars["Boolean"];
  frontDoorId?: InputMaybe<Scalars["ItemId"]>;
  includeFrontDoor: Scalars["Boolean"];
  psId?: InputMaybe<Scalars["ItemId"]>;
  includePs: Scalars["Boolean"];
}>;

export type LetterSavedDataEnrichmentQuery = {
  __typename?: "Query";
  country?: { __typename?: "Country"; urn: string; name: string } | null | undefined;
  usState?: { __typename?: "UsState"; urn: string; name: string } | null | undefined;
  letterSignature?:
    | { __typename?: "LetterSignatureRecord"; id: any; text?: string | null | undefined }
    | null
    | undefined;
  letterTemplate?:
    | {
        __typename?: "LetterTemplateRecord";
        id: any;
        label?: string | null | undefined;
        template?: string | null | undefined;
      }
    | null
    | undefined;
  pet1?:
    | {
        __typename?: "PetRecord";
        id: any;
        text?: string | null | undefined;
        textPlural?: string | null | undefined;
      }
    | null
    | undefined;
  pet2?:
    | {
        __typename?: "PetRecord";
        id: any;
        text?: string | null | undefined;
        textPlural?: string | null | undefined;
      }
    | null
    | undefined;
  frontDoor?:
    | { __typename?: "FrontDoorRecord"; id: any; text?: string | null | undefined }
    | null
    | undefined;
  letterPostscript?:
    | { __typename?: "LetterPostscriptRecord"; id: any; text?: string | null | undefined }
    | null
    | undefined;
};

export const BasketDocument = gql`
  query Basket(
    $alphaIds: [String!]!
    $letterTemplateIds: [ItemId!]!
    $textTemplateIds: [ItemId!]!
    $firstNameIds: [ItemId!]!
    $locale: SiteLocale!
  ) {
    letterTemplates: allLetterTemplates(
      filter: { id: { in: $letterTemplateIds } }
      locale: $locale
    ) {
      id
      label
    }
    textTemplates: allTextTemplates(filter: { id: { in: $textTemplateIds } }, locale: $locale) {
      id
      label
    }
    products: allProducts(filter: { alphaId: { in: $alphaIds } }, locale: $locale) {
      alphaId
      title
      image {
        url
      }
      price
    }
    firstNames: allFirstNames(filter: { id: { in: $firstNameIds } }) {
      id
      label
    }
  }
`;

/**
 * __useBasketQuery__
 *
 * To run a query within a React component, call `useBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketQuery({
 *   variables: {
 *      alphaIds: // value for 'alphaIds'
 *      letterTemplateIds: // value for 'letterTemplateIds'
 *      textTemplateIds: // value for 'textTemplateIds'
 *      firstNameIds: // value for 'firstNameIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useBasketQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BasketQuery, BasketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BasketQuery, BasketQueryVariables>(BasketDocument, options);
}
export function useBasketLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasketQuery, BasketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BasketQuery, BasketQueryVariables>(BasketDocument, options);
}
export type BasketQueryHookResult = ReturnType<typeof useBasketQuery>;
export type BasketLazyQueryHookResult = ReturnType<typeof useBasketLazyQuery>;
export type BasketQueryResult = ApolloReactCommon.QueryResult<BasketQuery, BasketQueryVariables>;
export const CallsAddOnsDocument = gql`
  query CallsAddOns($alphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $alphaId } }, locale: $locale) {
      addOns {
        id
        alphaId
        title
        description
        price
        isPopular
        image {
          url
        }
      }
    }
  }
`;

/**
 * __useCallsAddOnsQuery__
 *
 * To run a query within a React component, call `useCallsAddOnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsAddOnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsAddOnsQuery({
 *   variables: {
 *      alphaId: // value for 'alphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCallsAddOnsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CallsAddOnsQuery, CallsAddOnsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsAddOnsQuery, CallsAddOnsQueryVariables>(
    CallsAddOnsDocument,
    options,
  );
}
export function useCallsAddOnsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CallsAddOnsQuery, CallsAddOnsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsAddOnsQuery, CallsAddOnsQueryVariables>(
    CallsAddOnsDocument,
    options,
  );
}
export type CallsAddOnsQueryHookResult = ReturnType<typeof useCallsAddOnsQuery>;
export type CallsAddOnsLazyQueryHookResult = ReturnType<typeof useCallsAddOnsLazyQuery>;
export type CallsAddOnsQueryResult = ApolloReactCommon.QueryResult<
  CallsAddOnsQuery,
  CallsAddOnsQueryVariables
>;
export const CallsCallingDocument = gql`
  query CallsCalling($callAlphaId: String!, $locale: SiteLocale!) {
    countries {
      urn
      iso
      callingCode
    }
    timezones {
      urn
      country {
        urn
      }
      name
    }
    product(filter: { alphaId: { eq: $callAlphaId } }, locale: $locale) {
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useCallsCallingQuery__
 *
 * To run a query within a React component, call `useCallsCallingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsCallingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsCallingQuery({
 *   variables: {
 *      callAlphaId: // value for 'callAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCallsCallingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CallsCallingQuery, CallsCallingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsCallingQuery, CallsCallingQueryVariables>(
    CallsCallingDocument,
    options,
  );
}
export function useCallsCallingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CallsCallingQuery,
    CallsCallingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsCallingQuery, CallsCallingQueryVariables>(
    CallsCallingDocument,
    options,
  );
}
export type CallsCallingQueryHookResult = ReturnType<typeof useCallsCallingQuery>;
export type CallsCallingLazyQueryHookResult = ReturnType<typeof useCallsCallingLazyQuery>;
export type CallsCallingQueryResult = ApolloReactCommon.QueryResult<
  CallsCallingQuery,
  CallsCallingQueryVariables
>;
export const CallsDatesDocument = gql`
  query CallsDates(
    $startDate: String!
    $endDate: String!
    $timezoneUrn: String!
    $type: CallType!
  ) {
    availableCallDates(start: $startDate, end: $endDate, timezoneUrn: $timezoneUrn, type: $type) {
      date
    }
  }
`;

/**
 * __useCallsDatesQuery__
 *
 * To run a query within a React component, call `useCallsDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsDatesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCallsDatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CallsDatesQuery, CallsDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsDatesQuery, CallsDatesQueryVariables>(
    CallsDatesDocument,
    options,
  );
}
export function useCallsDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CallsDatesQuery, CallsDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsDatesQuery, CallsDatesQueryVariables>(
    CallsDatesDocument,
    options,
  );
}
export type CallsDatesQueryHookResult = ReturnType<typeof useCallsDatesQuery>;
export type CallsDatesLazyQueryHookResult = ReturnType<typeof useCallsDatesLazyQuery>;
export type CallsDatesQueryResult = ApolloReactCommon.QueryResult<
  CallsDatesQuery,
  CallsDatesQueryVariables
>;
export const CallsSlotsDocument = gql`
  query CallsSlots($date: String!, $timezoneUrn: String!, $type: CallType!) {
    availableCallSlots(date: $date, timezoneUrn: $timezoneUrn, type: $type) {
      time
      isNextSlotAvailable
    }
  }
`;

/**
 * __useCallsSlotsQuery__
 *
 * To run a query within a React component, call `useCallsSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsSlotsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCallsSlotsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CallsSlotsQuery, CallsSlotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsSlotsQuery, CallsSlotsQueryVariables>(
    CallsSlotsDocument,
    options,
  );
}
export function useCallsSlotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CallsSlotsQuery, CallsSlotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsSlotsQuery, CallsSlotsQueryVariables>(
    CallsSlotsDocument,
    options,
  );
}
export type CallsSlotsQueryHookResult = ReturnType<typeof useCallsSlotsQuery>;
export type CallsSlotsLazyQueryHookResult = ReturnType<typeof useCallsSlotsLazyQuery>;
export type CallsSlotsQueryResult = ApolloReactCommon.QueryResult<
  CallsSlotsQuery,
  CallsSlotsQueryVariables
>;
export const MakeCallReservationDocument = gql`
  mutation MakeCallReservation($time: String!, $timezoneUrn: String!, $type: CallType!) {
    makeCallReservation(time: $time, timezoneUrn: $timezoneUrn, type: $type) {
      reservationUrn
    }
  }
`;
export type MakeCallReservationMutationFn = ApolloReactCommon.MutationFunction<
  MakeCallReservationMutation,
  MakeCallReservationMutationVariables
>;

/**
 * __useMakeCallReservationMutation__
 *
 * To run a mutation, you first call `useMakeCallReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCallReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCallReservationMutation, { data, loading, error }] = useMakeCallReservationMutation({
 *   variables: {
 *      time: // value for 'time'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMakeCallReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MakeCallReservationMutation,
    MakeCallReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MakeCallReservationMutation,
    MakeCallReservationMutationVariables
  >(MakeCallReservationDocument, options);
}
export type MakeCallReservationMutationHookResult = ReturnType<
  typeof useMakeCallReservationMutation
>;
export type MakeCallReservationMutationResult =
  ApolloReactCommon.MutationResult<MakeCallReservationMutation>;
export type MakeCallReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MakeCallReservationMutation,
  MakeCallReservationMutationVariables
>;
export const CallsRecipientFormDocument = gql`
  query CallsRecipientForm($reservationUrn: String!, $locale: SiteLocale!, $type: CallType!) {
    frontDoors: allFrontDoors(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
      text
    }
    pets: allPets(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
      text
      textPlural
    }
    checkSlotFollowingReservation(reservationUrn: $reservationUrn, type: $type)
  }
`;

/**
 * __useCallsRecipientFormQuery__
 *
 * To run a query within a React component, call `useCallsRecipientFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsRecipientFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsRecipientFormQuery({
 *   variables: {
 *      reservationUrn: // value for 'reservationUrn'
 *      locale: // value for 'locale'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCallsRecipientFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CallsRecipientFormQuery,
    CallsRecipientFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsRecipientFormQuery, CallsRecipientFormQueryVariables>(
    CallsRecipientFormDocument,
    options,
  );
}
export function useCallsRecipientFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CallsRecipientFormQuery,
    CallsRecipientFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsRecipientFormQuery, CallsRecipientFormQueryVariables>(
    CallsRecipientFormDocument,
    options,
  );
}
export type CallsRecipientFormQueryHookResult = ReturnType<typeof useCallsRecipientFormQuery>;
export type CallsRecipientFormLazyQueryHookResult = ReturnType<
  typeof useCallsRecipientFormLazyQuery
>;
export type CallsRecipientFormQueryResult = ApolloReactCommon.QueryResult<
  CallsRecipientFormQuery,
  CallsRecipientFormQueryVariables
>;
export const MakeSecondCallReservationDocument = gql`
  mutation MakeSecondCallReservation($reservationUrn: String!, $type: CallType!) {
    makeReservationFollowing(reservationUrn: $reservationUrn, type: $type) {
      reservationUrn
    }
  }
`;
export type MakeSecondCallReservationMutationFn = ApolloReactCommon.MutationFunction<
  MakeSecondCallReservationMutation,
  MakeSecondCallReservationMutationVariables
>;

/**
 * __useMakeSecondCallReservationMutation__
 *
 * To run a mutation, you first call `useMakeSecondCallReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSecondCallReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSecondCallReservationMutation, { data, loading, error }] = useMakeSecondCallReservationMutation({
 *   variables: {
 *      reservationUrn: // value for 'reservationUrn'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMakeSecondCallReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MakeSecondCallReservationMutation,
    MakeSecondCallReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MakeSecondCallReservationMutation,
    MakeSecondCallReservationMutationVariables
  >(MakeSecondCallReservationDocument, options);
}
export type MakeSecondCallReservationMutationHookResult = ReturnType<
  typeof useMakeSecondCallReservationMutation
>;
export type MakeSecondCallReservationMutationResult =
  ApolloReactCommon.MutationResult<MakeSecondCallReservationMutation>;
export type MakeSecondCallReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MakeSecondCallReservationMutation,
  MakeSecondCallReservationMutationVariables
>;
export const CallsHomePageDocument = gql`
  query CallsHomePage($callAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $callAlphaId } }, locale: $locale) {
      title
      description
      productPageDescription
      price
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useCallsHomePageQuery__
 *
 * To run a query within a React component, call `useCallsHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsHomePageQuery({
 *   variables: {
 *      callAlphaId: // value for 'callAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCallsHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CallsHomePageQuery, CallsHomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CallsHomePageQuery, CallsHomePageQueryVariables>(
    CallsHomePageDocument,
    options,
  );
}
export function useCallsHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CallsHomePageQuery,
    CallsHomePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CallsHomePageQuery, CallsHomePageQueryVariables>(
    CallsHomePageDocument,
    options,
  );
}
export type CallsHomePageQueryHookResult = ReturnType<typeof useCallsHomePageQuery>;
export type CallsHomePageLazyQueryHookResult = ReturnType<typeof useCallsHomePageLazyQuery>;
export type CallsHomePageQueryResult = ApolloReactCommon.QueryResult<
  CallsHomePageQuery,
  CallsHomePageQueryVariables
>;
export const CheckoutPaymentDocument = gql`
  query CheckoutPayment(
    $alphaIds: [String!]!
    $locale: SiteLocale!
    $siteCountryCode: SiteCountryCode!
  ) {
    braintreeToken(siteCountryCode: $siteCountryCode)
    products: allProducts(filter: { alphaId: { in: $alphaIds } }, locale: $locale) {
      alphaId
      price
    }
  }
`;

/**
 * __useCheckoutPaymentQuery__
 *
 * To run a query within a React component, call `useCheckoutPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPaymentQuery({
 *   variables: {
 *      alphaIds: // value for 'alphaIds'
 *      locale: // value for 'locale'
 *      siteCountryCode: // value for 'siteCountryCode'
 *   },
 * });
 */
export function useCheckoutPaymentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CheckoutPaymentQuery,
    CheckoutPaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>(
    CheckoutPaymentDocument,
    options,
  );
}
export function useCheckoutPaymentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckoutPaymentQuery,
    CheckoutPaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>(
    CheckoutPaymentDocument,
    options,
  );
}
export type CheckoutPaymentQueryHookResult = ReturnType<typeof useCheckoutPaymentQuery>;
export type CheckoutPaymentLazyQueryHookResult = ReturnType<typeof useCheckoutPaymentLazyQuery>;
export type CheckoutPaymentQueryResult = ApolloReactCommon.QueryResult<
  CheckoutPaymentQuery,
  CheckoutPaymentQueryVariables
>;
export const PlaceOrderDocument = gql`
  mutation PlaceOrder(
    $paymentToken: String
    $items: [OrderItemInput!]!
    $promoCodeUrn: String
    $siteCountryCode: SiteCountryCode!
  ) {
    placeOrder(
      paymentToken: $paymentToken
      items: $items
      promoCodeUrn: $promoCodeUrn
      siteCountryCode: $siteCountryCode
    ) {
      urn
    }
  }
`;
export type PlaceOrderMutationFn = ApolloReactCommon.MutationFunction<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      paymentToken: // value for 'paymentToken'
 *      items: // value for 'items'
 *      promoCodeUrn: // value for 'promoCodeUrn'
 *      siteCountryCode: // value for 'siteCountryCode'
 *   },
 * });
 */
export function usePlaceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PlaceOrderMutation,
    PlaceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(
    PlaceOrderDocument,
    options,
  );
}
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = ApolloReactCommon.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;
export const AddToMailingListDocument = gql`
  mutation AddToMailingList($email: String!, $name: String!) {
    addToMailingList(email: $email, name: $name) {
      isSuccess
    }
  }
`;
export type AddToMailingListMutationFn = ApolloReactCommon.MutationFunction<
  AddToMailingListMutation,
  AddToMailingListMutationVariables
>;

/**
 * __useAddToMailingListMutation__
 *
 * To run a mutation, you first call `useAddToMailingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMailingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMailingListMutation, { data, loading, error }] = useAddToMailingListMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddToMailingListMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToMailingListMutation,
    AddToMailingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddToMailingListMutation, AddToMailingListMutationVariables>(
    AddToMailingListDocument,
    options,
  );
}
export type AddToMailingListMutationHookResult = ReturnType<typeof useAddToMailingListMutation>;
export type AddToMailingListMutationResult =
  ApolloReactCommon.MutationResult<AddToMailingListMutation>;
export type AddToMailingListMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddToMailingListMutation,
  AddToMailingListMutationVariables
>;
export const CheckoutDetailsDocument = gql`
  query CheckoutDetails($alphaIds: [String!]!, $firstNameIds: [ItemId!]!, $locale: SiteLocale!) {
    products: allProducts(filter: { alphaId: { in: $alphaIds } }, locale: $locale) {
      alphaId
      title
      image {
        url
      }
      price
    }
    firstNames: allFirstNames(filter: { id: { in: $firstNameIds } }) {
      id
      label
    }
  }
`;

/**
 * __useCheckoutDetailsQuery__
 *
 * To run a query within a React component, call `useCheckoutDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutDetailsQuery({
 *   variables: {
 *      alphaIds: // value for 'alphaIds'
 *      firstNameIds: // value for 'firstNameIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCheckoutDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CheckoutDetailsQuery,
    CheckoutDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>(
    CheckoutDetailsDocument,
    options,
  );
}
export function useCheckoutDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckoutDetailsQuery,
    CheckoutDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>(
    CheckoutDetailsDocument,
    options,
  );
}
export type CheckoutDetailsQueryHookResult = ReturnType<typeof useCheckoutDetailsQuery>;
export type CheckoutDetailsLazyQueryHookResult = ReturnType<typeof useCheckoutDetailsLazyQuery>;
export type CheckoutDetailsQueryResult = ApolloReactCommon.QueryResult<
  CheckoutDetailsQuery,
  CheckoutDetailsQueryVariables
>;
export const ContentPageDocument = gql`
  query ContentPage($alphaId: String!, $locale: SiteLocale!) {
    page(filter: { alphaId: { eq: $alphaId } }, locale: $locale) {
      alphaId
      title
      headerImage {
        url
      }
      sections {
        body
      }
    }
  }
`;

/**
 * __useContentPageQuery__
 *
 * To run a query within a React component, call `useContentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPageQuery({
 *   variables: {
 *      alphaId: // value for 'alphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useContentPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentPageQuery, ContentPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentPageQuery, ContentPageQueryVariables>(
    ContentPageDocument,
    options,
  );
}
export function useContentPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentPageQuery, ContentPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentPageQuery, ContentPageQueryVariables>(
    ContentPageDocument,
    options,
  );
}
export type ContentPageQueryHookResult = ReturnType<typeof useContentPageQuery>;
export type ContentPageLazyQueryHookResult = ReturnType<typeof useContentPageLazyQuery>;
export type ContentPageQueryResult = ApolloReactCommon.QueryResult<
  ContentPageQuery,
  ContentPageQueryVariables
>;
export const HomePageDocument = gql`
  query HomePage(
    $letterAlphaId: String!
    $audioCallAlphaId: String!
    $videoCallAlphaId: String!
    $textAlphaId: String!
    $locale: SiteLocale!
  ) {
    page(filter: { alphaId: { eq: "HOME" } }, locale: $locale) {
      videoUrl
      videoFallbackImageUrl
    }
    letter: product(filter: { alphaId: { eq: $letterAlphaId } }, locale: $locale) {
      title
      homePageDescription
    }
    audioCall: product(filter: { alphaId: { eq: $audioCallAlphaId } }, locale: $locale) {
      title
      homePageDescription
    }
    videoCall: product(filter: { alphaId: { eq: $videoCallAlphaId } }, locale: $locale) {
      title
      homePageDescription
    }
    text: product(filter: { alphaId: { eq: $textAlphaId } }, locale: $locale) {
      title
      homePageDescription
    }
    featureFlag(locale: $locale) {
      homePageHeroProduct
    }
  }
`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      letterAlphaId: // value for 'letterAlphaId'
 *      audioCallAlphaId: // value for 'audioCallAlphaId'
 *      videoCallAlphaId: // value for 'videoCallAlphaId'
 *      textAlphaId: // value for 'textAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<HomePageQuery, HomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HomePageQuery, HomePageQueryVariables>(
    HomePageDocument,
    options,
  );
}
export function useHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<HomePageQuery, HomePageQueryVariables>(
    HomePageDocument,
    options,
  );
}
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = ApolloReactCommon.QueryResult<
  HomePageQuery,
  HomePageQueryVariables
>;
export const MyAccountDocument = gql`
  query MyAccount($locale: SiteLocale!, $cursor: String) {
    orders(sort: "timeCreated", sortDirection: DESC, size: 10, searchAfter: $cursor) {
      totalCount
      edges {
        cursor
        node {
          urn
          total
          timeCreated
          orderItems {
            productAlphaId
            price
            addOnProducts(locale: $locale) {
              alphaId
              title
              price
            }
            product(locale: $locale) {
              title
              image {
                url
              }
            }
            letter {
              urn
              date
              firstName
              template {
                label
              }
              isEditable
            }
            call {
              urn
              firstName
              lastName
              time
              timezone {
                timezone
              }
              phoneCountry {
                callingCode
              }
              phoneNumber
              isEditable
            }
            text {
              urn
              firstName
              text1Time
              timezone {
                timezone
              }
              phoneCountry {
                callingCode
              }
              phoneNumber
              isEditable
            }
            sack {
              name
              addressTown
              date
            }
            video {
              firstName {
                label
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMyAccountQuery__
 *
 * To run a query within a React component, call `useMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useMyAccountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MyAccountQuery, MyAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyAccountQuery, MyAccountQueryVariables>(
    MyAccountDocument,
    options,
  );
}
export function useMyAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyAccountQuery, MyAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyAccountQuery, MyAccountQueryVariables>(
    MyAccountDocument,
    options,
  );
}
export type MyAccountQueryHookResult = ReturnType<typeof useMyAccountQuery>;
export type MyAccountLazyQueryHookResult = ReturnType<typeof useMyAccountLazyQuery>;
export type MyAccountQueryResult = ApolloReactCommon.QueryResult<
  MyAccountQuery,
  MyAccountQueryVariables
>;
export const MyAccountCallDocument = gql`
  query MyAccountCall($callUrn: String!, $locale: SiteLocale!) {
    countries {
      urn
      iso
      callingCode
    }
    timezones {
      urn
      country {
        urn
      }
      name
    }
    frontDoors: allFrontDoors(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    pets: allPets(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    call(callUrn: $callUrn) {
      type
      timezone {
        urn
      }
      phoneCountry {
        urn
      }
      phoneNumber
      time
      firstName
      lastName
      boyGirl
      ageMonths
      ageYears
      frontDoor {
        id
      }
      pet1 {
        id
      }
      pet2 {
        id
      }
      hobby
      gift
      pet1Name
      pet2Name
      friend
      notes
    }
  }
`;

/**
 * __useMyAccountCallQuery__
 *
 * To run a query within a React component, call `useMyAccountCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountCallQuery({
 *   variables: {
 *      callUrn: // value for 'callUrn'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMyAccountCallQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MyAccountCallQuery, MyAccountCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyAccountCallQuery, MyAccountCallQueryVariables>(
    MyAccountCallDocument,
    options,
  );
}
export function useMyAccountCallLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyAccountCallQuery,
    MyAccountCallQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyAccountCallQuery, MyAccountCallQueryVariables>(
    MyAccountCallDocument,
    options,
  );
}
export type MyAccountCallQueryHookResult = ReturnType<typeof useMyAccountCallQuery>;
export type MyAccountCallLazyQueryHookResult = ReturnType<typeof useMyAccountCallLazyQuery>;
export type MyAccountCallQueryResult = ApolloReactCommon.QueryResult<
  MyAccountCallQuery,
  MyAccountCallQueryVariables
>;
export const UpdateCallDocument = gql`
  mutation UpdateCall(
    $callUrn: String!
    $phoneCountryUrn: String
    $phoneNumber: String
    $timezoneUrn: String!
    $firstName: String!
    $lastName: String!
    $time: String!
    $boyGirl: BoyGirl!
    $ageYears: Int
    $ageMonths: Int
    $hobby: String
    $gift: String
    $friend: String
    $frontDoorId: String
    $pet1Id: String
    $pet1Name: String
    $pet2Id: String
    $pet2Name: String
    $notes: String
  ) {
    updateCall(
      callUrn: $callUrn
      details: {
        phoneCountryUrn: $phoneCountryUrn
        phoneNumber: $phoneNumber
        timezoneUrn: $timezoneUrn
        firstName: $firstName
        lastName: $lastName
        time: $time
        boyGirl: $boyGirl
        ageYears: $ageYears
        ageMonths: $ageMonths
        hobby: $hobby
        gift: $gift
        friend: $friend
        frontDoorId: $frontDoorId
        pet1Id: $pet1Id
        pet1Name: $pet1Name
        pet2Id: $pet2Id
        pet2Name: $pet2Name
        notes: $notes
      }
    ) {
      urn
    }
  }
`;
export type UpdateCallMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;

/**
 * __useUpdateCallMutation__
 *
 * To run a mutation, you first call `useUpdateCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallMutation, { data, loading, error }] = useUpdateCallMutation({
 *   variables: {
 *      callUrn: // value for 'callUrn'
 *      phoneCountryUrn: // value for 'phoneCountryUrn'
 *      phoneNumber: // value for 'phoneNumber'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      time: // value for 'time'
 *      boyGirl: // value for 'boyGirl'
 *      ageYears: // value for 'ageYears'
 *      ageMonths: // value for 'ageMonths'
 *      hobby: // value for 'hobby'
 *      gift: // value for 'gift'
 *      friend: // value for 'friend'
 *      frontDoorId: // value for 'frontDoorId'
 *      pet1Id: // value for 'pet1Id'
 *      pet1Name: // value for 'pet1Name'
 *      pet2Id: // value for 'pet2Id'
 *      pet2Name: // value for 'pet2Name'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCallMutation,
    UpdateCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCallMutation, UpdateCallMutationVariables>(
    UpdateCallDocument,
    options,
  );
}
export type UpdateCallMutationHookResult = ReturnType<typeof useUpdateCallMutation>;
export type UpdateCallMutationResult = ApolloReactCommon.MutationResult<UpdateCallMutation>;
export type UpdateCallMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;
export const MyAccountLetterDocument = gql`
  query MyAccountLetter($letterUrn: String!, $locale: SiteLocale!) {
    firstName {
      id
      label
    }
    allFrontDoors(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    allLetterPostscripts(first: 50, locale: $locale) {
      id
      label
    }
    allLetterSignatures(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    allLetterTemplates(
      filter: { showOnSite: { eq: true } }
      first: 50
      orderBy: [sortGroup_ASC]
      locale: $locale
    ) {
      id
      label
      description
    }
    allPets(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    letter(letterUrn: $letterUrn) {
      firstName
      lastName
      boyGirl
      ageMonths
      ageYears
      date
      addressLine1
      addressLine2
      addressTown
      addressCounty
      addressPostcode
      addressUsState {
        urn
      }
      addressCountry {
        urn
      }
      template {
        id
        label
        description
      }
      signature {
        id
        label
      }
      frontDoor {
        id
        label
      }
      pet1 {
        id
        label
      }
      pet2 {
        id
        label
      }
      hobby
      gift
      ps {
        id
        label
      }
      friend
      pet1Name
      pet2Name
      psCustom
    }
  }
`;

/**
 * __useMyAccountLetterQuery__
 *
 * To run a query within a React component, call `useMyAccountLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountLetterQuery({
 *   variables: {
 *      letterUrn: // value for 'letterUrn'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMyAccountLetterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MyAccountLetterQuery,
    MyAccountLetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyAccountLetterQuery, MyAccountLetterQueryVariables>(
    MyAccountLetterDocument,
    options,
  );
}
export function useMyAccountLetterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyAccountLetterQuery,
    MyAccountLetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyAccountLetterQuery, MyAccountLetterQueryVariables>(
    MyAccountLetterDocument,
    options,
  );
}
export type MyAccountLetterQueryHookResult = ReturnType<typeof useMyAccountLetterQuery>;
export type MyAccountLetterLazyQueryHookResult = ReturnType<typeof useMyAccountLetterLazyQuery>;
export type MyAccountLetterQueryResult = ApolloReactCommon.QueryResult<
  MyAccountLetterQuery,
  MyAccountLetterQueryVariables
>;
export const UpdateLetterDocument = gql`
  mutation UpdateLetter(
    $letterUrn: String!
    $firstName: String!
    $lastName: String!
    $addressLine1: String!
    $addressLine2: String
    $addressTown: String!
    $addressCounty: String
    $addressUsStateUrn: String
    $addressPostcode: String!
    $addressCountryUrn: String!
    $date: String!
    $boyGirl: BoyGirl!
    $ageYears: Int
    $ageMonths: Int
    $hobby: String
    $gift: String
    $friend: String
    $signatureId: String!
    $frontDoorId: String
    $pet1Id: String
    $pet1Name: String
    $pet2Id: String
    $pet2Name: String
    $psId: String
    $psCustom: String
    $templateId: String!
  ) {
    updateLetter(
      letterUrn: $letterUrn
      details: {
        firstName: $firstName
        lastName: $lastName
        ageMonths: $ageMonths
        ageYears: $ageYears
        boyGirl: $boyGirl
        date: $date
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressTown: $addressTown
        addressCounty: $addressCounty
        addressUsStateUrn: $addressUsStateUrn
        addressPostcode: $addressPostcode
        addressCountryUrn: $addressCountryUrn
        hobby: $hobby
        gift: $gift
        friend: $friend
        signatureId: $signatureId
        frontDoorId: $frontDoorId
        pet1Id: $pet1Id
        pet1Name: $pet1Name
        pet2Id: $pet2Id
        pet2Name: $pet2Name
        templateId: $templateId
        psId: $psId
        psCustom: $psCustom
      }
    ) {
      urn
    }
  }
`;
export type UpdateLetterMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLetterMutation,
  UpdateLetterMutationVariables
>;

/**
 * __useUpdateLetterMutation__
 *
 * To run a mutation, you first call `useUpdateLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLetterMutation, { data, loading, error }] = useUpdateLetterMutation({
 *   variables: {
 *      letterUrn: // value for 'letterUrn'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      addressTown: // value for 'addressTown'
 *      addressCounty: // value for 'addressCounty'
 *      addressUsStateUrn: // value for 'addressUsStateUrn'
 *      addressPostcode: // value for 'addressPostcode'
 *      addressCountryUrn: // value for 'addressCountryUrn'
 *      date: // value for 'date'
 *      boyGirl: // value for 'boyGirl'
 *      ageYears: // value for 'ageYears'
 *      ageMonths: // value for 'ageMonths'
 *      hobby: // value for 'hobby'
 *      gift: // value for 'gift'
 *      friend: // value for 'friend'
 *      signatureId: // value for 'signatureId'
 *      frontDoorId: // value for 'frontDoorId'
 *      pet1Id: // value for 'pet1Id'
 *      pet1Name: // value for 'pet1Name'
 *      pet2Id: // value for 'pet2Id'
 *      pet2Name: // value for 'pet2Name'
 *      psId: // value for 'psId'
 *      psCustom: // value for 'psCustom'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useUpdateLetterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLetterMutation,
    UpdateLetterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLetterMutation, UpdateLetterMutationVariables>(
    UpdateLetterDocument,
    options,
  );
}
export type UpdateLetterMutationHookResult = ReturnType<typeof useUpdateLetterMutation>;
export type UpdateLetterMutationResult = ApolloReactCommon.MutationResult<UpdateLetterMutation>;
export type UpdateLetterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLetterMutation,
  UpdateLetterMutationVariables
>;
export const MyAccountTextDocument = gql`
  query MyAccountText($textUrn: String!, $locale: SiteLocale!) {
    countries(isTextAccepted: true) {
      urn
      iso
      callingCode
    }
    timezones {
      urn
      country {
        urn
      }
      name
    }
    pets: allPets(
      first: 50
      orderBy: [label_ASC]
      locale: $locale
      filter: { showOnSite: { eq: true } }
    ) {
      id
      label
      text
    }
    textTemplates: allTextTemplates(
      first: 50
      orderBy: [label_ASC]
      locale: $locale
      filter: { showOnSite: { eq: true } }
    ) {
      id
      label
      template1
      template2
      template3
    }
    text(textUrn: $textUrn) {
      timezone {
        urn
        timezone
      }
      phoneCountry {
        urn
      }
      phoneNumber
      firstName
      boyGirl
      ageYears
      town
      pet {
        id
        label
      }
      petName
      template {
        id
        label
      }
      text1Time
      text1Body
      text2Time
      text2Body
      text3Time
      text3Body
      bonusBody
    }
  }
`;

/**
 * __useMyAccountTextQuery__
 *
 * To run a query within a React component, call `useMyAccountTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountTextQuery({
 *   variables: {
 *      textUrn: // value for 'textUrn'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMyAccountTextQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MyAccountTextQuery, MyAccountTextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyAccountTextQuery, MyAccountTextQueryVariables>(
    MyAccountTextDocument,
    options,
  );
}
export function useMyAccountTextLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyAccountTextQuery,
    MyAccountTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyAccountTextQuery, MyAccountTextQueryVariables>(
    MyAccountTextDocument,
    options,
  );
}
export type MyAccountTextQueryHookResult = ReturnType<typeof useMyAccountTextQuery>;
export type MyAccountTextLazyQueryHookResult = ReturnType<typeof useMyAccountTextLazyQuery>;
export type MyAccountTextQueryResult = ApolloReactCommon.QueryResult<
  MyAccountTextQuery,
  MyAccountTextQueryVariables
>;
export const UpdateTextDocument = gql`
  mutation UpdateText(
    $textUrn: String!
    $ageYears: Int!
    $boyGirl: BoyGirl!
    $templateId: String!
    $petId: String
    $petName: String
    $firstName: String!
    $town: String!
    $phoneCountryUrn: String!
    $phoneNumber: String!
    $timezoneUrn: String!
    $text1Time: String!
    $text1Body: String!
    $text2Time: String!
    $text2Body: String!
    $text3Time: String!
    $text3Body: String!
  ) {
    updateText(
      textUrn: $textUrn
      details: {
        ageYears: $ageYears
        boyGirl: $boyGirl
        templateId: $templateId
        petId: $petId
        petName: $petName
        firstName: $firstName
        town: $town
        phoneCountryUrn: $phoneCountryUrn
        phoneNumber: $phoneNumber
        timezoneUrn: $timezoneUrn
        text1Time: $text1Time
        text1Body: $text1Body
        text2Time: $text2Time
        text2Body: $text2Body
        text3Time: $text3Time
        text3Body: $text3Body
      }
    ) {
      urn
    }
  }
`;
export type UpdateTextMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTextMutation,
  UpdateTextMutationVariables
>;

/**
 * __useUpdateTextMutation__
 *
 * To run a mutation, you first call `useUpdateTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextMutation, { data, loading, error }] = useUpdateTextMutation({
 *   variables: {
 *      textUrn: // value for 'textUrn'
 *      ageYears: // value for 'ageYears'
 *      boyGirl: // value for 'boyGirl'
 *      templateId: // value for 'templateId'
 *      petId: // value for 'petId'
 *      petName: // value for 'petName'
 *      firstName: // value for 'firstName'
 *      town: // value for 'town'
 *      phoneCountryUrn: // value for 'phoneCountryUrn'
 *      phoneNumber: // value for 'phoneNumber'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      text1Time: // value for 'text1Time'
 *      text1Body: // value for 'text1Body'
 *      text2Time: // value for 'text2Time'
 *      text2Body: // value for 'text2Body'
 *      text3Time: // value for 'text3Time'
 *      text3Body: // value for 'text3Body'
 *   },
 * });
 */
export function useUpdateTextMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTextMutation,
    UpdateTextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTextMutation, UpdateTextMutationVariables>(
    UpdateTextDocument,
    options,
  );
}
export type UpdateTextMutationHookResult = ReturnType<typeof useUpdateTextMutation>;
export type UpdateTextMutationResult = ApolloReactCommon.MutationResult<UpdateTextMutation>;
export type UpdateTextMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTextMutation,
  UpdateTextMutationVariables
>;
export const OrderConfirmationDocument = gql`
  query OrderConfirmation($orderUrn: String!, $locale: SiteLocale!) {
    order(orderUrn: $orderUrn) {
      urn
      promoCode {
        code
      }
      promoDiscount
      orderItems {
        productAlphaId
        price
        addOnProducts(locale: $locale) {
          title
          alphaId
          price
        }
        product(locale: $locale) {
          title
          image {
            url
          }
        }
        letter {
          firstName
          template {
            label
          }
          date
        }
        call {
          firstName
          lastName
          time
          phoneNumber
          timezone {
            timezone
          }
          phoneCountry {
            callingCode
          }
        }
        text {
          firstName
          text1Time
          timezone {
            timezone
          }
          phoneNumber
          phoneCountry {
            callingCode
          }
        }
        sack {
          name
          addressTown
          date
        }
        video {
          firstName {
            label
          }
        }
      }
    }
  }
`;

/**
 * __useOrderConfirmationQuery__
 *
 * To run a query within a React component, call `useOrderConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderConfirmationQuery({
 *   variables: {
 *      orderUrn: // value for 'orderUrn'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useOrderConfirmationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrderConfirmationQuery,
    OrderConfirmationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderConfirmationQuery, OrderConfirmationQueryVariables>(
    OrderConfirmationDocument,
    options,
  );
}
export function useOrderConfirmationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderConfirmationQuery,
    OrderConfirmationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderConfirmationQuery, OrderConfirmationQueryVariables>(
    OrderConfirmationDocument,
    options,
  );
}
export type OrderConfirmationQueryHookResult = ReturnType<typeof useOrderConfirmationQuery>;
export type OrderConfirmationLazyQueryHookResult = ReturnType<typeof useOrderConfirmationLazyQuery>;
export type OrderConfirmationQueryResult = ApolloReactCommon.QueryResult<
  OrderConfirmationQuery,
  OrderConfirmationQueryVariables
>;
export const SackHomePageDocument = gql`
  query SackHomePage($sackAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $sackAlphaId } }, locale: $locale) {
      title
      description
      productPageDescription
      price
      internationalSurcharge {
        alphaId
        title
        price
      }
      postagePackingSurcharge {
        alphaId
        title
        price
      }
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useSackHomePageQuery__
 *
 * To run a query within a React component, call `useSackHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSackHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSackHomePageQuery({
 *   variables: {
 *      sackAlphaId: // value for 'sackAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSackHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SackHomePageQuery, SackHomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SackHomePageQuery, SackHomePageQueryVariables>(
    SackHomePageDocument,
    options,
  );
}
export function useSackHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SackHomePageQuery,
    SackHomePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SackHomePageQuery, SackHomePageQueryVariables>(
    SackHomePageDocument,
    options,
  );
}
export type SackHomePageQueryHookResult = ReturnType<typeof useSackHomePageQuery>;
export type SackHomePageLazyQueryHookResult = ReturnType<typeof useSackHomePageLazyQuery>;
export type SackHomePageQueryResult = ApolloReactCommon.QueryResult<
  SackHomePageQuery,
  SackHomePageQueryVariables
>;
export const LetterHomePageDocument = gql`
  query LetterHomePage($alphaIds: [String!], $locale: SiteLocale!) {
    allProducts(filter: { alphaId: { in: $alphaIds } }, locale: $locale) {
      alphaId
      title
      letterHomePageDescription
      price
      image {
        url
      }
    }
  }
`;

/**
 * __useLetterHomePageQuery__
 *
 * To run a query within a React component, call `useLetterHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterHomePageQuery({
 *   variables: {
 *      alphaIds: // value for 'alphaIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLetterHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LetterHomePageQuery, LetterHomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LetterHomePageQuery, LetterHomePageQueryVariables>(
    LetterHomePageDocument,
    options,
  );
}
export function useLetterHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LetterHomePageQuery,
    LetterHomePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LetterHomePageQuery, LetterHomePageQueryVariables>(
    LetterHomePageDocument,
    options,
  );
}
export type LetterHomePageQueryHookResult = ReturnType<typeof useLetterHomePageQuery>;
export type LetterHomePageLazyQueryHookResult = ReturnType<typeof useLetterHomePageLazyQuery>;
export type LetterHomePageQueryResult = ApolloReactCommon.QueryResult<
  LetterHomePageQuery,
  LetterHomePageQueryVariables
>;
export const LetterHomeDocument = gql`
  query LetterHome($locale: SiteLocale!) {
    featureFlag(locale: $locale) {
      lettersEnabled
    }
  }
`;

/**
 * __useLetterHomeQuery__
 *
 * To run a query within a React component, call `useLetterHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterHomeQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLetterHomeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LetterHomeQuery, LetterHomeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LetterHomeQuery, LetterHomeQueryVariables>(
    LetterHomeDocument,
    options,
  );
}
export function useLetterHomeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LetterHomeQuery, LetterHomeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LetterHomeQuery, LetterHomeQueryVariables>(
    LetterHomeDocument,
    options,
  );
}
export type LetterHomeQueryHookResult = ReturnType<typeof useLetterHomeQuery>;
export type LetterHomeLazyQueryHookResult = ReturnType<typeof useLetterHomeLazyQuery>;
export type LetterHomeQueryResult = ApolloReactCommon.QueryResult<
  LetterHomeQuery,
  LetterHomeQueryVariables
>;
export const LetterAddOnsDocument = gql`
  query LetterAddOns($alphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $alphaId } }, locale: $locale) {
      alphaId
      title
      price
      internationalSurcharge {
        alphaId
        title
        price
      }
      postagePackingSurcharge {
        alphaId
        title
        price
      }
      addOns {
        id
        alphaId
        title
        description
        price
        isPopular
        internationalSurcharge {
          alphaId
          title
          price
        }
        postagePackingSurcharge {
          alphaId
          title
          price
        }
        image {
          url
        }
      }
    }
  }
`;

/**
 * __useLetterAddOnsQuery__
 *
 * To run a query within a React component, call `useLetterAddOnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterAddOnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterAddOnsQuery({
 *   variables: {
 *      alphaId: // value for 'alphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLetterAddOnsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LetterAddOnsQuery, LetterAddOnsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LetterAddOnsQuery, LetterAddOnsQueryVariables>(
    LetterAddOnsDocument,
    options,
  );
}
export function useLetterAddOnsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LetterAddOnsQuery,
    LetterAddOnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LetterAddOnsQuery, LetterAddOnsQueryVariables>(
    LetterAddOnsDocument,
    options,
  );
}
export type LetterAddOnsQueryHookResult = ReturnType<typeof useLetterAddOnsQuery>;
export type LetterAddOnsLazyQueryHookResult = ReturnType<typeof useLetterAddOnsLazyQuery>;
export type LetterAddOnsQueryResult = ApolloReactCommon.QueryResult<
  LetterAddOnsQuery,
  LetterAddOnsQueryVariables
>;
export const LetterFaqsDocument = gql`
  query LetterFaqs($letterAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $letterAlphaId } }, locale: $locale) {
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useLetterFaqsQuery__
 *
 * To run a query within a React component, call `useLetterFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterFaqsQuery({
 *   variables: {
 *      letterAlphaId: // value for 'letterAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLetterFaqsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LetterFaqsQuery, LetterFaqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LetterFaqsQuery, LetterFaqsQueryVariables>(
    LetterFaqsDocument,
    options,
  );
}
export function useLetterFaqsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LetterFaqsQuery, LetterFaqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LetterFaqsQuery, LetterFaqsQueryVariables>(
    LetterFaqsDocument,
    options,
  );
}
export type LetterFaqsQueryHookResult = ReturnType<typeof useLetterFaqsQuery>;
export type LetterFaqsLazyQueryHookResult = ReturnType<typeof useLetterFaqsLazyQuery>;
export type LetterFaqsQueryResult = ApolloReactCommon.QueryResult<
  LetterFaqsQuery,
  LetterFaqsQueryVariables
>;
export const LetterTypePageDocument = gql`
  query LetterTypePage($letterAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $letterAlphaId } }, locale: $locale) {
      title
      description
      productPageDescription
      price
      image {
        url
      }
      addOns {
        alphaId
        title
        productPageDescription
        price
        image {
          url
        }
      }
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useLetterTypePageQuery__
 *
 * To run a query within a React component, call `useLetterTypePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterTypePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterTypePageQuery({
 *   variables: {
 *      letterAlphaId: // value for 'letterAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLetterTypePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LetterTypePageQuery, LetterTypePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LetterTypePageQuery, LetterTypePageQueryVariables>(
    LetterTypePageDocument,
    options,
  );
}
export function useLetterTypePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LetterTypePageQuery,
    LetterTypePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LetterTypePageQuery, LetterTypePageQueryVariables>(
    LetterTypePageDocument,
    options,
  );
}
export type LetterTypePageQueryHookResult = ReturnType<typeof useLetterTypePageQuery>;
export type LetterTypePageLazyQueryHookResult = ReturnType<typeof useLetterTypePageLazyQuery>;
export type LetterTypePageQueryResult = ApolloReactCommon.QueryResult<
  LetterTypePageQuery,
  LetterTypePageQueryVariables
>;
export const TextsHomePageDocument = gql`
  query TextsHomePage($textAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $textAlphaId } }, locale: $locale) {
      title
      description
      productPageDescription
      price
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useTextsHomePageQuery__
 *
 * To run a query within a React component, call `useTextsHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextsHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextsHomePageQuery({
 *   variables: {
 *      textAlphaId: // value for 'textAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTextsHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TextsHomePageQuery, TextsHomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TextsHomePageQuery, TextsHomePageQueryVariables>(
    TextsHomePageDocument,
    options,
  );
}
export function useTextsHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TextsHomePageQuery,
    TextsHomePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TextsHomePageQuery, TextsHomePageQueryVariables>(
    TextsHomePageDocument,
    options,
  );
}
export type TextsHomePageQueryHookResult = ReturnType<typeof useTextsHomePageQuery>;
export type TextsHomePageLazyQueryHookResult = ReturnType<typeof useTextsHomePageLazyQuery>;
export type TextsHomePageQueryResult = ApolloReactCommon.QueryResult<
  TextsHomePageQuery,
  TextsHomePageQueryVariables
>;
export const TextsMessagesDocument = gql`
  query TextsMessages($locale: SiteLocale!) {
    pets: allPets(
      first: 50
      orderBy: [label_ASC]
      locale: $locale
      filter: { showOnSite: { eq: true } }
    ) {
      id
      label
      text
    }
    textTemplates: allTextTemplates(
      first: 50
      orderBy: [label_ASC]
      locale: $locale
      filter: { showOnSite: { eq: true } }
    ) {
      id
      label
      template1
      template2
      template3
    }
  }
`;

/**
 * __useTextsMessagesQuery__
 *
 * To run a query within a React component, call `useTextsMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextsMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextsMessagesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTextsMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TextsMessagesQuery, TextsMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TextsMessagesQuery, TextsMessagesQueryVariables>(
    TextsMessagesDocument,
    options,
  );
}
export function useTextsMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TextsMessagesQuery,
    TextsMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TextsMessagesQuery, TextsMessagesQueryVariables>(
    TextsMessagesDocument,
    options,
  );
}
export type TextsMessagesQueryHookResult = ReturnType<typeof useTextsMessagesQuery>;
export type TextsMessagesLazyQueryHookResult = ReturnType<typeof useTextsMessagesLazyQuery>;
export type TextsMessagesQueryResult = ApolloReactCommon.QueryResult<
  TextsMessagesQuery,
  TextsMessagesQueryVariables
>;
export const TextsTextingDocument = gql`
  query TextsTexting($textAlphaId: String!, $locale: SiteLocale!) {
    countries(isTextAccepted: true) {
      urn
      iso
      callingCode
    }
    timezones {
      urn
      country {
        urn
      }
      name
    }
    product(filter: { alphaId: { eq: $textAlphaId } }, locale: $locale) {
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useTextsTextingQuery__
 *
 * To run a query within a React component, call `useTextsTextingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextsTextingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextsTextingQuery({
 *   variables: {
 *      textAlphaId: // value for 'textAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTextsTextingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TextsTextingQuery, TextsTextingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TextsTextingQuery, TextsTextingQueryVariables>(
    TextsTextingDocument,
    options,
  );
}
export function useTextsTextingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TextsTextingQuery,
    TextsTextingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TextsTextingQuery, TextsTextingQueryVariables>(
    TextsTextingDocument,
    options,
  );
}
export type TextsTextingQueryHookResult = ReturnType<typeof useTextsTextingQuery>;
export type TextsTextingLazyQueryHookResult = ReturnType<typeof useTextsTextingLazyQuery>;
export type TextsTextingQueryResult = ApolloReactCommon.QueryResult<
  TextsTextingQuery,
  TextsTextingQueryVariables
>;
export const TextsBonusDocument = gql`
  query TextsBonus($locale: SiteLocale!) {
    product(filter: { alphaId: { eq: "SANTA_TEXT_BONUS" } }, locale: $locale) {
      id
      alphaId
      title
      price
      image {
        url
      }
    }
  }
`;

/**
 * __useTextsBonusQuery__
 *
 * To run a query within a React component, call `useTextsBonusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextsBonusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextsBonusQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTextsBonusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TextsBonusQuery, TextsBonusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TextsBonusQuery, TextsBonusQueryVariables>(
    TextsBonusDocument,
    options,
  );
}
export function useTextsBonusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TextsBonusQuery, TextsBonusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TextsBonusQuery, TextsBonusQueryVariables>(
    TextsBonusDocument,
    options,
  );
}
export type TextsBonusQueryHookResult = ReturnType<typeof useTextsBonusQuery>;
export type TextsBonusLazyQueryHookResult = ReturnType<typeof useTextsBonusLazyQuery>;
export type TextsBonusQueryResult = ApolloReactCommon.QueryResult<
  TextsBonusQuery,
  TextsBonusQueryVariables
>;
export const ValidatePhoneNumberDocument = gql`
  query ValidatePhoneNumber($number: String!, $countryUrn: String!) {
    validatePhoneNumber(number: $number, countryUrn: $countryUrn) {
      number
    }
  }
`;

/**
 * __useValidatePhoneNumberQuery__
 *
 * To run a query within a React component, call `useValidatePhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePhoneNumberQuery({
 *   variables: {
 *      number: // value for 'number'
 *      countryUrn: // value for 'countryUrn'
 *   },
 * });
 */
export function useValidatePhoneNumberQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ValidatePhoneNumberQuery,
    ValidatePhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>(
    ValidatePhoneNumberDocument,
    options,
  );
}
export function useValidatePhoneNumberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ValidatePhoneNumberQuery,
    ValidatePhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>(
    ValidatePhoneNumberDocument,
    options,
  );
}
export type ValidatePhoneNumberQueryHookResult = ReturnType<typeof useValidatePhoneNumberQuery>;
export type ValidatePhoneNumberLazyQueryHookResult = ReturnType<
  typeof useValidatePhoneNumberLazyQuery
>;
export type ValidatePhoneNumberQueryResult = ApolloReactCommon.QueryResult<
  ValidatePhoneNumberQuery,
  ValidatePhoneNumberQueryVariables
>;
export const VideoDetailsDocument = gql`
  query VideoDetails($videoAlphaId: String!, $locale: SiteLocale!) {
    countries {
      urn
      name
    }
    frontDoors: allFrontDoors(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    pets: allPets(first: 50, orderBy: [label_ASC], locale: $locale) {
      id
      label
    }
    product(filter: { alphaId: { eq: $videoAlphaId } }, locale: $locale) {
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useVideoDetailsQuery__
 *
 * To run a query within a React component, call `useVideoDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoDetailsQuery({
 *   variables: {
 *      videoAlphaId: // value for 'videoAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useVideoDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<VideoDetailsQuery, VideoDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VideoDetailsQuery, VideoDetailsQueryVariables>(
    VideoDetailsDocument,
    options,
  );
}
export function useVideoDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VideoDetailsQuery,
    VideoDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VideoDetailsQuery, VideoDetailsQueryVariables>(
    VideoDetailsDocument,
    options,
  );
}
export type VideoDetailsQueryHookResult = ReturnType<typeof useVideoDetailsQuery>;
export type VideoDetailsLazyQueryHookResult = ReturnType<typeof useVideoDetailsLazyQuery>;
export type VideoDetailsQueryResult = ApolloReactCommon.QueryResult<
  VideoDetailsQuery,
  VideoDetailsQueryVariables
>;
export const VideosHomePageDocument = gql`
  query VideosHomePage($videoAlphaId: String!, $locale: SiteLocale!) {
    product(filter: { alphaId: { eq: $videoAlphaId } }, locale: $locale) {
      title
      description
      productPageDescription
      price
      faqs {
        id
        title
        description
      }
    }
  }
`;

/**
 * __useVideosHomePageQuery__
 *
 * To run a query within a React component, call `useVideosHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosHomePageQuery({
 *   variables: {
 *      videoAlphaId: // value for 'videoAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useVideosHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<VideosHomePageQuery, VideosHomePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VideosHomePageQuery, VideosHomePageQueryVariables>(
    VideosHomePageDocument,
    options,
  );
}
export function useVideosHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VideosHomePageQuery,
    VideosHomePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VideosHomePageQuery, VideosHomePageQueryVariables>(
    VideosHomePageDocument,
    options,
  );
}
export type VideosHomePageQueryHookResult = ReturnType<typeof useVideosHomePageQuery>;
export type VideosHomePageLazyQueryHookResult = ReturnType<typeof useVideosHomePageLazyQuery>;
export type VideosHomePageQueryResult = ApolloReactCommon.QueryResult<
  VideosHomePageQuery,
  VideosHomePageQueryVariables
>;
export const FirstNameSearchDocument = gql`
  query FirstNameSearch($search: String!) {
    firstNames: allFirstNames(
      first: 50
      orderBy: [label_ASC]
      filter: {
        label: { matches: { pattern: $search, caseSensitive: false } }
        showOnSite: { eq: true }
      }
    ) {
      id
      label
    }
  }
`;

/**
 * __useFirstNameSearchQuery__
 *
 * To run a query within a React component, call `useFirstNameSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstNameSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstNameSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFirstNameSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FirstNameSearchQuery,
    FirstNameSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FirstNameSearchQuery, FirstNameSearchQueryVariables>(
    FirstNameSearchDocument,
    options,
  );
}
export function useFirstNameSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FirstNameSearchQuery,
    FirstNameSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FirstNameSearchQuery, FirstNameSearchQueryVariables>(
    FirstNameSearchDocument,
    options,
  );
}
export type FirstNameSearchQueryHookResult = ReturnType<typeof useFirstNameSearchQuery>;
export type FirstNameSearchLazyQueryHookResult = ReturnType<typeof useFirstNameSearchLazyQuery>;
export type FirstNameSearchQueryResult = ApolloReactCommon.QueryResult<
  FirstNameSearchQuery,
  FirstNameSearchQueryVariables
>;
export const GiftSearchDocument = gql`
  query GiftSearch($search: String!) {
    allGifts(
      orderBy: [label_ASC]
      filter: {
        label: { matches: { pattern: $search, caseSensitive: false } }
        showOnSite: { eq: true }
      }
    ) {
      id
      label
    }
  }
`;

/**
 * __useGiftSearchQuery__
 *
 * To run a query within a React component, call `useGiftSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGiftSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GiftSearchQuery, GiftSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GiftSearchQuery, GiftSearchQueryVariables>(
    GiftSearchDocument,
    options,
  );
}
export function useGiftSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GiftSearchQuery, GiftSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GiftSearchQuery, GiftSearchQueryVariables>(
    GiftSearchDocument,
    options,
  );
}
export type GiftSearchQueryHookResult = ReturnType<typeof useGiftSearchQuery>;
export type GiftSearchLazyQueryHookResult = ReturnType<typeof useGiftSearchLazyQuery>;
export type GiftSearchQueryResult = ApolloReactCommon.QueryResult<
  GiftSearchQuery,
  GiftSearchQueryVariables
>;
export const HobbySearchDocument = gql`
  query HobbySearch($search: String!) {
    allHobbies(
      orderBy: [label_ASC]
      filter: {
        label: { matches: { pattern: $search, caseSensitive: false } }
        showOnSite: { eq: true }
      }
    ) {
      id
      label
    }
  }
`;

/**
 * __useHobbySearchQuery__
 *
 * To run a query within a React component, call `useHobbySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useHobbySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHobbySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useHobbySearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<HobbySearchQuery, HobbySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HobbySearchQuery, HobbySearchQueryVariables>(
    HobbySearchDocument,
    options,
  );
}
export function useHobbySearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HobbySearchQuery, HobbySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<HobbySearchQuery, HobbySearchQueryVariables>(
    HobbySearchDocument,
    options,
  );
}
export type HobbySearchQueryHookResult = ReturnType<typeof useHobbySearchQuery>;
export type HobbySearchLazyQueryHookResult = ReturnType<typeof useHobbySearchLazyQuery>;
export type HobbySearchQueryResult = ApolloReactCommon.QueryResult<
  HobbySearchQuery,
  HobbySearchQueryVariables
>;
export const AddressUsStatesDocument = gql`
  query AddressUsStates {
    usStates {
      urn
      name
      code
    }
  }
`;

/**
 * __useAddressUsStatesQuery__
 *
 * To run a query within a React component, call `useAddressUsStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressUsStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressUsStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressUsStatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddressUsStatesQuery,
    AddressUsStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddressUsStatesQuery, AddressUsStatesQueryVariables>(
    AddressUsStatesDocument,
    options,
  );
}
export function useAddressUsStatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddressUsStatesQuery,
    AddressUsStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddressUsStatesQuery, AddressUsStatesQueryVariables>(
    AddressUsStatesDocument,
    options,
  );
}
export type AddressUsStatesQueryHookResult = ReturnType<typeof useAddressUsStatesQuery>;
export type AddressUsStatesLazyQueryHookResult = ReturnType<typeof useAddressUsStatesLazyQuery>;
export type AddressUsStatesQueryResult = ApolloReactCommon.QueryResult<
  AddressUsStatesQuery,
  AddressUsStatesQueryVariables
>;
export const AddressCountriesDocument = gql`
  query AddressCountries {
    countries {
      urn
      name
    }
  }
`;

/**
 * __useAddressCountriesQuery__
 *
 * To run a query within a React component, call `useAddressCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddressCountriesQuery,
    AddressCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddressCountriesQuery, AddressCountriesQueryVariables>(
    AddressCountriesDocument,
    options,
  );
}
export function useAddressCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddressCountriesQuery,
    AddressCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddressCountriesQuery, AddressCountriesQueryVariables>(
    AddressCountriesDocument,
    options,
  );
}
export type AddressCountriesQueryHookResult = ReturnType<typeof useAddressCountriesQuery>;
export type AddressCountriesLazyQueryHookResult = ReturnType<typeof useAddressCountriesLazyQuery>;
export type AddressCountriesQueryResult = ApolloReactCommon.QueryResult<
  AddressCountriesQuery,
  AddressCountriesQueryVariables
>;
export const SantaLetterHomeDocument = gql`
  query SantaLetterHome($alphaIds: [String!], $locale: SiteLocale!) {
    allProducts(filter: { alphaId: { in: $alphaIds } }, locale: $locale) {
      alphaId
      title
      price
      image {
        url
      }
    }
  }
`;

/**
 * __useSantaLetterHomeQuery__
 *
 * To run a query within a React component, call `useSantaLetterHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSantaLetterHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSantaLetterHomeQuery({
 *   variables: {
 *      alphaIds: // value for 'alphaIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSantaLetterHomeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SantaLetterHomeQuery,
    SantaLetterHomeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SantaLetterHomeQuery, SantaLetterHomeQueryVariables>(
    SantaLetterHomeDocument,
    options,
  );
}
export function useSantaLetterHomeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SantaLetterHomeQuery,
    SantaLetterHomeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SantaLetterHomeQuery, SantaLetterHomeQueryVariables>(
    SantaLetterHomeDocument,
    options,
  );
}
export type SantaLetterHomeQueryHookResult = ReturnType<typeof useSantaLetterHomeQuery>;
export type SantaLetterHomeLazyQueryHookResult = ReturnType<typeof useSantaLetterHomeLazyQuery>;
export type SantaLetterHomeQueryResult = ApolloReactCommon.QueryResult<
  SantaLetterHomeQuery,
  SantaLetterHomeQueryVariables
>;
export const ApplyPromoCodeDocument = gql`
  mutation ApplyPromoCode($total: Float!, $code: String!) {
    applyPromoCode(total: $total, code: $code) {
      deduction
      promoCode {
        code
        urn
      }
    }
  }
`;
export type ApplyPromoCodeMutationFn = ApolloReactCommon.MutationFunction<
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables
>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      total: // value for 'total'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApplyPromoCodeMutation,
    ApplyPromoCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>(
    ApplyPromoCodeDocument,
    options,
  );
}
export type ApplyPromoCodeMutationHookResult = ReturnType<typeof useApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationResult = ApolloReactCommon.MutationResult<ApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables
>;
export const SantaLetterFormDocument = gql`
  query SantaLetterForm($letterAlphaId: String!, $locale: SiteLocale!) {
    allFrontDoors(
      filter: { showOnSite: { eq: true } }
      first: 50
      orderBy: [label_ASC]
      locale: $locale
    ) {
      id
      label
    }
    allLetterPostscripts(filter: { showOnSite: { eq: true } }, first: 50, locale: $locale) {
      id
      label
    }
    allLetterSignatures(
      filter: { showOnSite: { eq: true } }
      first: 50
      orderBy: [label_ASC]
      locale: $locale
    ) {
      id
      label
    }
    allLetterTemplates(
      filter: { showOnSite: { eq: true } }
      first: 50
      orderBy: [sortGroup_ASC]
      locale: $locale
    ) {
      id
      label
      description
    }
    allPets(
      filter: { showOnSite: { eq: true } }
      first: 50
      orderBy: [label_ASC]
      locale: $locale
    ) {
      id
      label
    }
    product(filter: { alphaId: { eq: $letterAlphaId } }, locale: $locale) {
      internationalSurcharge {
        price
        title
      }
    }
  }
`;

/**
 * __useSantaLetterFormQuery__
 *
 * To run a query within a React component, call `useSantaLetterFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSantaLetterFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSantaLetterFormQuery({
 *   variables: {
 *      letterAlphaId: // value for 'letterAlphaId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSantaLetterFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SantaLetterFormQuery,
    SantaLetterFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SantaLetterFormQuery, SantaLetterFormQueryVariables>(
    SantaLetterFormDocument,
    options,
  );
}
export function useSantaLetterFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SantaLetterFormQuery,
    SantaLetterFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SantaLetterFormQuery, SantaLetterFormQueryVariables>(
    SantaLetterFormDocument,
    options,
  );
}
export type SantaLetterFormQueryHookResult = ReturnType<typeof useSantaLetterFormQuery>;
export type SantaLetterFormLazyQueryHookResult = ReturnType<typeof useSantaLetterFormLazyQuery>;
export type SantaLetterFormQueryResult = ApolloReactCommon.QueryResult<
  SantaLetterFormQuery,
  SantaLetterFormQueryVariables
>;
export const LetterSavedDataEnrichmentDocument = gql`
  query LetterSavedDataEnrichment(
    $addressCountryUrn: String!
    $usStateUrn: String!
    $includeUsState: Boolean!
    $signatureId: ItemId
    $includeSignature: Boolean!
    $templateId: ItemId
    $includeTemplate: Boolean!
    $pet1Id: ItemId
    $includePet1: Boolean!
    $pet2Id: ItemId
    $includePet2: Boolean!
    $frontDoorId: ItemId
    $includeFrontDoor: Boolean!
    $psId: ItemId
    $includePs: Boolean!
  ) {
    country(countryUrn: $addressCountryUrn) {
      urn
      name
    }
    usState(usStateUrn: $usStateUrn) @include(if: $includeUsState) {
      urn
      name
    }
    letterSignature(filter: { id: { eq: $signatureId } }) @include(if: $includeSignature) {
      id
      text
    }
    letterTemplate(filter: { id: { eq: $templateId } }) @include(if: $includeTemplate) {
      id
      label
      template
    }
    pet1: pet(filter: { id: { eq: $pet1Id } }) @include(if: $includePet1) {
      id
      text
      textPlural
    }
    pet2: pet(filter: { id: { eq: $pet2Id } }) @include(if: $includePet2) {
      id
      text
      textPlural
    }
    frontDoor(filter: { id: { eq: $frontDoorId } }) @include(if: $includeFrontDoor) {
      id
      text
    }
    letterPostscript(filter: { id: { eq: $psId } }) @include(if: $includePs) {
      id
      text
    }
  }
`;

/**
 * __useLetterSavedDataEnrichmentQuery__
 *
 * To run a query within a React component, call `useLetterSavedDataEnrichmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterSavedDataEnrichmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterSavedDataEnrichmentQuery({
 *   variables: {
 *      addressCountryUrn: // value for 'addressCountryUrn'
 *      usStateUrn: // value for 'usStateUrn'
 *      includeUsState: // value for 'includeUsState'
 *      signatureId: // value for 'signatureId'
 *      includeSignature: // value for 'includeSignature'
 *      templateId: // value for 'templateId'
 *      includeTemplate: // value for 'includeTemplate'
 *      pet1Id: // value for 'pet1Id'
 *      includePet1: // value for 'includePet1'
 *      pet2Id: // value for 'pet2Id'
 *      includePet2: // value for 'includePet2'
 *      frontDoorId: // value for 'frontDoorId'
 *      includeFrontDoor: // value for 'includeFrontDoor'
 *      psId: // value for 'psId'
 *      includePs: // value for 'includePs'
 *   },
 * });
 */
export function useLetterSavedDataEnrichmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LetterSavedDataEnrichmentQuery,
    LetterSavedDataEnrichmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LetterSavedDataEnrichmentQuery,
    LetterSavedDataEnrichmentQueryVariables
  >(LetterSavedDataEnrichmentDocument, options);
}
export function useLetterSavedDataEnrichmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LetterSavedDataEnrichmentQuery,
    LetterSavedDataEnrichmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LetterSavedDataEnrichmentQuery,
    LetterSavedDataEnrichmentQueryVariables
  >(LetterSavedDataEnrichmentDocument, options);
}
export type LetterSavedDataEnrichmentQueryHookResult = ReturnType<
  typeof useLetterSavedDataEnrichmentQuery
>;
export type LetterSavedDataEnrichmentLazyQueryHookResult = ReturnType<
  typeof useLetterSavedDataEnrichmentLazyQuery
>;
export type LetterSavedDataEnrichmentQueryResult = ApolloReactCommon.QueryResult<
  LetterSavedDataEnrichmentQuery,
  LetterSavedDataEnrichmentQueryVariables
>;
