/**
 * Redice a string down to an integer by simply hashing it
 * @param text Text to hash
 * @param limit Limit integer of hash to return
 */
export const hashStringToInteger = (text: string, limit: number): number => {
  const sum = text.split("").reduce<number>((a, c) => a + c.charCodeAt(0), 0);

  return sum % limit;
};

/**
 * Get initials of words from string
 * @param text Text to get initals from
 */
export const initialsFromString = (text: string): string => {
  const matches = text.match(/\b(\w)/g);

  if (!matches) {
    return "";
  }

  return matches.join("").toUpperCase();
};
