import { Col, Container, Hidden, Row } from "react-grid-system";
import styled from "styled-components";

import { LetterPreviewPlaceholder } from "../../../atoms/letter-preview-placeholder";
import { LetterPreview } from "../../../organisms/letter-preview";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { SantaLetterEditForm } from "../../../organisms/forms/santa-letter-edit";
import { MobileOverlay } from "../../../organisms/mobile-overlay";

import { useData } from "./use-data";

const PreviewWrapper = styled.div`
  position: sticky;
  top: 0;

  canvas {
    width: 100%;
  }
`;

const PreviewPlaceholder = styled(LetterPreviewPlaceholder)`
  margin-top: 30px;
`;

const MobilePreviewContainer = styled.div`
  canvas {
    width: 100vw;
  }
`;

export const MyAccountLetterEdit: React.FC = () => {
  const {
    formProps,
    previewParams,
    loaderProps,
    shouldShowMobilePreview,
    onClickCloseMobilePreview,
  } = useData();

  return (
    <DataLoadedContainer {...loaderProps}>
      <MainWrapper>
        <Container>
          <Row>
            <Col md={6}>{formProps && <SantaLetterEditForm {...formProps} />}</Col>
            <Hidden xs>
              <Col md={6}>
                <PreviewWrapper>
                  {previewParams ? (
                    <LetterPreview params={previewParams} />
                  ) : (
                    <PreviewPlaceholder />
                  )}
                </PreviewWrapper>
              </Col>
            </Hidden>
          </Row>
        </Container>
        {shouldShowMobilePreview && previewParams && (
          <MobileOverlay onClickClose={onClickCloseMobilePreview}>
            <MobilePreviewContainer>
              <LetterPreview params={previewParams} />
            </MobilePreviewContainer>
          </MobileOverlay>
        )}
      </MainWrapper>
    </DataLoadedContainer>
  );
};
