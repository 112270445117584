import React, { useCallback } from "react";
import styled from "styled-components";

import { AccordionItem } from "../accordion-item";

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  > *:not(:first-child) {
    margin-top: 10px;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

interface IProps {
  items: Pick<React.ComponentProps<typeof AccordionItem>, "id" | "title" | "description">[];
  className?: string;
}

export const Accordion = ({ items, className }: IProps): JSX.Element => {
  const [openId, setOpenId] = React.useState<string | undefined>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClickHeader = (id: string) => useCallback((): void => setOpenId(id), []);

  return (
    <Container className={className}>
      {items.map(item => (
        <AccordionItem
          {...item}
          key={item.id}
          isOpen={item.id === openId}
          onClickHeader={handleClickHeader(item.id)}
        />
      ))}
    </Container>
  );
};
