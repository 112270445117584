import styled, { StyledComponentPropsWithRef } from "styled-components";

import { Loader } from "../../../atoms/loader";

interface IWithIsError {
  isError?: boolean;
}

const Container = styled.div<IWithIsError>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  padding: 3px;
  background-color: #ffffff;
  border: 1px solid
    ${({ isError, theme }): string =>
      isError ? theme.colours.alert : theme.colours.form.placeholder};
  border-radius: 6px;

  :focus-within {
    border-color: ${({ theme }): string => theme.colours.spotGold};
  }
`;

const InputWrapper = styled.div`
  padding: 4px 13px;
`;

const Input = styled.input`
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  border: 0;
  padding: 0;

  ::placeholder {
    color: ${({ theme }): string => theme.colours.form.placeholder};
  }

  :focus {
    outline: none;
  }
`;

interface IButtonStyleProps {
  isLoading: boolean;
}

const Button = styled.button<IButtonStyleProps>`
  min-width: 80px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  background-color: ${({ isLoading, theme }): string =>
    isLoading ? theme.colours.form.placeholder : theme.colours.spotGold};
  color: white;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;

  :focus {
    outline: none;
  }
`;

type IProps = StyledComponentPropsWithRef<"input"> &
  IWithIsError & {
    isLoading: boolean;
    submitLabel: string;
    // we cannot call this 'onClickSubmit' because  of https://github.com/styled-components/styled-components/issues/2218
    clickSubmit(): void;
  };

export const FormInputTextWithSubmit: React.FC<IProps> = props => (
  <Container isError={props.isError}>
    <InputWrapper>
      <Input {...props} />
    </InputWrapper>
    <Button onClick={props.clickSubmit} isLoading={props.isLoading}>
      {props.isLoading ? <Loader colour="white" width={16} /> : props.submitLabel}
    </Button>
  </Container>
);
