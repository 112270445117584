import styled from "styled-components";
import Bounce from "react-reveal/Bounce";

import { NumberBadge } from "../number-badge";

import { ReactComponent as HamburgerImage } from "./hamburger.svg";

const HamburgerContainer = styled.div`
  padding-left: 10px;
  padding-right: 15px;
  position: relative;
`;

const Hamburger = styled(HamburgerImage)`
  width: 20px;
  height: 14px;
  fill: black;
`;

const Badge = styled(NumberBadge)`
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
`;

interface IProps {
  count: number;
  onClick(): void;
}

export const HamburgerMenu: React.FC<IProps> = ({ onClick, count }) => (
  <HamburgerContainer onClick={onClick}>
    {count > 0 && (
      <Bounce>
        <Badge small count={count} />
      </Bounce>
    )}
    <Hamburger data-santa-id="hamburger-menu" />
  </HamburgerContainer>
);
