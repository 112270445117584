import { Col, Container, Row, Hidden } from "react-grid-system";
import styled from "styled-components";

import { Paragraph } from "../../../atoms/text";
import { StageTitle } from "../../../atoms/stage-title";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { VideoFrameImage } from "../../../atoms/video-frame-image";
import { VideoImagesForm } from "../../../organisms/forms/video/video-images-form";
import { useData } from "./use-data";

const Notice = styled(Paragraph)`
  margin-bottom: 18px;
`;

export const VideoImages: React.FC = () => {
  const { formProps } = useData();

  return (
    <MainWrapper>
      <Container>
        <Row>
          <Col md={6}>
            <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

            <Row>
              <Col>
                <StageTitle totalStages={2} currentStage={2}>
                  Send Santa a Photo
                </StageTitle>

                <Notice>You can upload/change photos post-purchase within My Account.</Notice>
              </Col>
            </Row>

            <VideoImagesForm {...formProps} />
          </Col>
          <Hidden xs>
            <Col md={6}>
              <VideoFrameImage />
            </Col>
          </Hidden>
        </Row>
      </Container>
    </MainWrapper>
  );
};
