import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { device } from "../../../utils/media-queries";
import { routePaths } from "../../../model/route";

import button from "./button.png";

const Image = styled.img`
  cursor: pointer;

  @media ${device.mobileS} {
    width: 349px;
    height: 96px;
  }

  @media ${device.laptopL} {
    width: 436px;
    height: 115px;
  }
`;

export const GetMySantaVideoButton: React.FC = () => {
  const history = useHistory();

  const handleClick = useCallback((): void => history.push(routePaths.video.home), [history]);

  return <Image src={button} onClick={handleClick} />;
};
