import React from "react";
import { Col, Container, Row, Hidden } from "react-grid-system";

import { StageTitle } from "../../../atoms/stage-title";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { VideoDetailsForm } from "../../../organisms/forms/video/video-details-form";
import { VideoFrameImage } from "../../../atoms/video-frame-image";
import { Accordion } from "../../../molecules/accordion";

import { useData } from "./use-data";

export const VideoDetails: React.FC = () => {
  const { loadingProps, formProps, faqs } = useData();

  return (
    <DataLoadedContainer {...loadingProps}>
      {formProps && (
        <MainWrapper>
          <Container>
            <Row>
              <Col md={6}>
                <MobileBackLink onClick={formProps?.onClickBack}>Back</MobileBackLink>

                <Row>
                  <Col>
                    <StageTitle totalStages={2} currentStage={1}>
                      Santa needs...
                    </StageTitle>
                  </Col>
                </Row>

                {faqs && <Accordion items={faqs} />}

                <VideoDetailsForm {...formProps} />
              </Col>
              <Hidden xs>
                <Col md={6}>
                  <VideoFrameImage />
                </Col>
              </Hidden>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainer>
  );
};
