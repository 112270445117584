import { ISelectOption } from "@santa/common/lib/utils/form";
import { useCallback } from "react";
import { Field } from "formik";
import styled from "styled-components";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../../form-input-text";
import { FormikFormInputSelect } from "../../form-input-select";

const PhoneFormFieldWrapper = styled(FormFieldWrapper)`
  select {
    flex: 0;
    min-width: 125px;
  }

  input {
    flex: 0;
    min-width: 250px;
  }
`;

interface IProps {
  countryId?: string;
  phoneId?: string;
  label?: string;
  required?: boolean;
  countryOptions: ISelectOption[];
  onChangeCountry(urn: string): void;
}

export const FormFieldPhoneNumber: React.FC<IProps> = ({
  countryId = "phoneCountryUrn",
  phoneId = "phoneNumber",
  label = "Telephone number? (make sure it’s correct)",
  required = true,
  countryOptions,
  onChangeCountry,
}) => {
  const handleChangeCountry = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => onChangeCountry(e.target.value),
    [onChangeCountry],
  );

  return (
    <PhoneFormFieldWrapper label={label} name={phoneId} required={required} noLabel>
      <Field
        name={countryId}
        component={FormikFormInputSelect}
        options={countryOptions}
        onChange={handleChangeCountry}
      />
      <Field
        name={phoneId}
        component={FormikFormInputText}
        placeholder="7000 000000"
        required={required}
      />
    </PhoneFormFieldWrapper>
  );
};
