import { SantaErrorCode } from "@santa/common/lib/errors";
import { useCallback, useState, useMemo } from "react";
import { Dropin as WebDropIn } from "braintree-web-drop-in";
import DropIn from "braintree-web-drop-in-react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isApolloError, useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

import { getCheckoutOrderVariables, IPromoCode } from "../../../../model/checkout";
import { config } from "../../../../config";
import { getLocaleForApi } from "../../../../utils/graphql";
import { BowButton } from "../../../atoms/bow-button";
import { Paragraph } from "../../../atoms/text";
import { CheckoutPanel } from "../../../organisms/checkout-panel";
import decoration from "../../../../images/png/deer-tree-sprig-reverse.png";
import { device } from "../../../../utils/media-queries";
import { PromoCodeBox } from "../../../organisms/promo-code-box";
import {
  getBasketTotal,
  getProductLookup,
  IBasketItem,
  ProductLookup,
} from "../../../../model/basket";
import {
  useCheckoutPaymentQuery,
  usePlaceOrderMutation,
  ProductAlphaId,
} from "../../../../types/graphql";
import { basketItemState, basketState } from "../../../../model/graphql/cache";
import { DataLoadedContainer } from "../../../control/data-loaded-container";

const Container = styled.div`
  padding: 19px 20px;
  min-height: 350px;

  @media ${device.laptopL} {
    background-image: url(${decoration});
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  [data-braintree-id="wrapper"] {
    .braintree-dropin {
      font-family: "Lato", sans-serif;
    }

    .braintree-form__label {
      color: ${({ theme }): string => theme.colours.textSubdued};
      font-size: ${({ theme }): string => theme.fontSizes.size16};
    }

    .braintree-sheet__content--form
      .braintree-form__field-group
      .braintree-form__field
      .braintree-form__hosted-field {
      border: 1px solid ${({ theme }): string => theme.colours.form.placeholder};
      border-radius: 6px;
    }

    .braintree-sheet__content--form
      .braintree-form__field-group
      .braintree-form__field
      .braintree-form__hosted-field.braintree-hosted-fields-focused {
      border-color: ${({ theme }): string => theme.colours.spotGold} !important;
    }

    .braintree-sheet__content--form
      .braintree-form__field-group.braintree-form__field-group--has-error
      .braintree-form__field
      .braintree-form__hosted-field {
      border-color: ${({ theme }): string => theme.colours.alert};
    }

    .braintree-form__icon-container {
      display: none;
    }
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;

  @media ${device.mobileS} {
    justify-content: center;
  }

  @media ${device.laptopL} {
    justify-content: flex-end;
  }
`;

const ErrorParagraph = styled(Paragraph)`
  color: ${({ theme }): string => theme.colours.alert};
`;

interface IProps {
  promoCode?: IPromoCode;
  onSetPromoCode(code: IPromoCode | undefined, deduction: number): void;
  items: IBasketItem[];
  productLookup: ProductLookup<{
    title?: string | null;
    price?: number | null;
  }>;
  deduction: number;
}

export const CheckoutPayment: React.FC<IProps> = ({
  onSetPromoCode,
  promoCode,
  items,
  deduction,
  productLookup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const basketItems = useReactiveVar(basketItemState);

  const [formError, setFormError] = useState<string>();
  const [instance, setInstance] = useState<WebDropIn>();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true);

  const { data, refetch, loading } = useCheckoutPaymentQuery({
    fetchPolicy: "network-only",
    variables: {
      locale: getLocaleForApi(),
      siteCountryCode: config.site.country,
      alphaIds: basketItems.reduce<ProductAlphaId[]>(
        (acc, i) => [...acc, ...i.addOns, i.productAlphaId],
        [],
      ),
    },
  });

  const handleOnInstance = useCallback((i: WebDropIn): void => setInstance(i), [setInstance]);

  const [placeOrder] = usePlaceOrderMutation();

  const options: React.ComponentProps<typeof DropIn>["options"] = {
    authorization: data?.braintreeToken || "",
    vaultManager: true,
    threeDSecure: {
      amount: (getBasketTotal(items, productLookup) - deduction).toFixed(2),
    },
    paypal: {
      flow: "vault",
      // buttonStyle: {
      //   color: "blue",
      //   size: "medium",
      // },
    },
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      setIsSubmitEnabled(false);

      let nonce: string | undefined = undefined;
      try {
        const method = await instance!.requestPaymentMethod();
        nonce = method.nonce;
      } catch (e) {
        if (e instanceof Error) {
          setFormError(e.message);
          instance!.clearSelectedPaymentMethod();
          setIsSubmitEnabled(true);
          return;
        }
      }
      const variables = await getCheckoutOrderVariables(nonce, promoCode);

      const response = await placeOrder({ variables });

      if (config.features.clearBasketAfterCheckout) {
        basketState().clear();
      }

      history.push(`/checkout/confirmation/${response.data!.placeOrder!.urn}`);
    } catch (e) {
      let message = "Sorry, an unexpected error occurred. Please try again.";

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isDropInError = (error: any): error is { message: string; name: string } =>
        error.name === "DropInError";

      if (isDropInError(e) && e.name === "DropInError") {
        message = e.message;
      }

      if (e instanceof Error && isApolloError(e)) {
        if (e.graphQLErrors.length) {
          const firstError = e.graphQLErrors[0].message;
          const errorsToTrap: string[] = [
            SantaErrorCode.CALL_RESERVATION_EXPIRED,
            SantaErrorCode.TEXT_TIME_TOO_SOON,
          ];

          if (errorsToTrap.includes(firstError as SantaErrorCode)) {
            message = t(`checkout.errors.${firstError}`);
          } else {
            message = firstError;
          }
        }
      }

      setFormError(message);

      instance!.clearSelectedPaymentMethod();
      setIsSubmitEnabled(true);

      Sentry.captureException(e);
    }
  }, [setIsSubmitEnabled, instance, history, promoCode]);

  const submit: JSX.Element | undefined =
    typeof instance !== "undefined" ? (
      <SubmitContainer>
        <BowButton size={330} onClick={handleSubmit} disabled={!isSubmitEnabled}>
          Place Order
        </BowButton>
      </SubmitContainer>
    ) : undefined;

  const basketTotal = useMemo(() => {
    if (!data) {
      return 0;
    }
    const productLookup = getProductLookup(data.products);
    return getBasketTotal(basketItems, productLookup);
  }, [data, basketItems]);

  return (
    <>
      <CheckoutPanel count={1} title="Log In to Your Account" state="completed" />

      <CheckoutPanel count={2} title="Payment Details" state="current">
        <Container>
          <DataLoadedContainer isLoading={loading} refetch={!data ? refetch : undefined}>
            <PromoCodeBox
              total={basketTotal}
              onSuccess={onSetPromoCode}
              currentPromoCode={promoCode}
            />
            {formError && <ErrorParagraph>{formError}</ErrorParagraph>}
            <DropIn options={options} onInstance={handleOnInstance} />
            {submit}
          </DataLoadedContainer>
        </Container>
      </CheckoutPanel>
    </>
  );
};
