import { FieldProps } from "formik";
import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { config } from "../../../../config";
import { SiteCountryCode } from "../../../../types/graphql";

interface ICommonProps {
  isError?: boolean;
  placeholder?: string;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  includeDates?: Date[];
  name: string;
  monthsShown?: number;
  autoComplete?: string;
  dateFormat?: string;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
}

type InputProps = ICommonProps & {
  onChange(date: Date): void;
};

type FormikInputProps = FieldProps &
  ICommonProps & {
    onChange(date: string): void;
  };

const StyledDatePicker = styled(DatePicker)<InputProps>`
  flex: 1;
  min-width: 50px;
  padding: 7px 16px;
  background-color: #ffffff;
  border: 1px solid ${({ isError, theme }): string => (isError ? theme.colours.alert : "#b0bec5")};
  border-radius: 6px;
  font-family: ${(props): string => props.theme.fonts.main};
  font-size: ${(props): string => props.theme.fontSizes.size16};

  ::placeholder {
    color: #b0bec5;
  }

  :focus {
    border-color: #f8bf2f;
    outline: none;
  }
`;

export const FormInputDate: React.FC<InputProps> = props => (
  <StyledDatePicker
    name={props.name}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
    isError={props.isError}
    onChange={props.onChange}
    placeholderText={props.placeholder}
    selected={props.value}
    minDate={props.minDate}
    maxDate={props.maxDate}
    includeDates={props.includeDates}
    monthsShown={props.monthsShown}
    autoComplete={props.autoComplete}
    dateFormat={props.dateFormat}
    shouldCloseOnSelect={true}
  />
);

export const FormikFormInputDate: React.FC<FormikInputProps> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  onChange,
  ...props
}) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = useCallback(
    (): void => setFieldTouched(field.name),
    [setFieldTouched, field],
  );
  const handleChange = useCallback(
    (date: Date): void => {
      setFieldValue(field.name, date);
      if (onChange) {
        onChange(date.toISOString());
      }
    },
    [setFieldValue, field.name],
  );

  if (!props.dateFormat) {
    if (config.site.country === SiteCountryCode.GB) {
      props.dateFormat = "dd/MM/yyyy";
    } else {
      props.dateFormat = "MM/dd/yyyy";
    }
  }

  return (
    <FormInputDate
      name={field.name}
      onBlur={field.onBlur}
      onFocus={setFieldFocussed}
      isError={isError}
      onChange={handleChange}
      placeholder={props.placeholder}
      value={field.value}
      minDate={props.minDate}
      maxDate={props.maxDate}
      includeDates={props.includeDates}
      monthsShown={props.monthsShown}
      autoComplete={props.autoComplete}
      dateFormat={props.dateFormat}
    />
  );
};
