import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as IconRedImage } from "./user-icon-red.svg";
import { ReactComponent as IconWhiteImage } from "./user-icon-white.svg";

const IconRed = styled(IconRedImage)`
  width: 12px;
  height: 14px;
`;

const IconWhite = styled(IconWhiteImage)`
  width: 12px;
  height: 14px;
`;

const Container = styled(Link)`
  height: 36px;
  border-radius: 6px;
  padding: 0 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-color: ${({ theme }): string => theme.colours.navigation.dark};
  border-width: 1px;
  border-style: solid;
  text-decoration: none;

  [data-santa-id="my-account-button-icon-red"] {
    display: inline-block;
  }

  [data-santa-id="my-account-button-icon-white"] {
    display: none;
  }
`;

const Label = styled.span`
  margin-left: 11px;
  color: ${({ theme }): string => theme.colours.navigation.dark};
`;

export const MyAccountButton: React.FC = () => {
  const history = useHistory();

  return (
    <Container
      data-santa-id="my-account-button"
      to={{ pathname: "/login", state: [history.location.pathname] }}
    >
      <IconRed data-santa-id="my-account-button-icon-red" />
      <IconWhite data-santa-id="my-account-button-icon-white" />
      <Label data-santa-id="my-account-button-label">My Account</Label>
    </Container>
  );
};
