import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-grid-system";
import ScrollTrigger from "react-scroll-trigger";
import { debounce } from "lodash";

import { AuthContext } from "../../contexts/auth-context";
import { DataLoadedContainer } from "../../control/data-loaded-container";
import { MyProfilePanel } from "../../organisms/my-profile-panel";
import { PageWrapper } from "../../atoms/containers/page-wrapper";
import { PageContentPanel } from "../../organisms/panels/page-content-panel";
import { Heading1 } from "../../atoms/text";
import { MyAccountOrder } from "../../molecules/my-account-order";
import { useProfile } from "../../../hooks/use-profile";

import { useMyAccountData } from "./hooks/use-data";

export const MyAccount: React.FC = () => {
  const history = useHistory();
  const isAuthenticated = useContext(AuthContext);
  const [openOrderId, setOpenOrderId] = useState<string>();
  const { profile } = useProfile();

  if (history.location.pathname === "/my-account" && !isAuthenticated) {
    history.replace("/login");
  }

  const { isLoading, refetch, orders, loadMore, hasLoadedAll } = useMyAccountData();

  const handleClickOrder = useCallback(
    (id: string) => (): void => setOpenOrderId(id !== openOrderId ? id : undefined),
    [setOpenOrderId, openOrderId],
  );

  const handleOnScrollProgress = debounce((): void => {
    const cursor = orders[orders.length - 1]?.cursor;

    if (cursor && !hasLoadedAll && !isLoading) {
      loadMore(cursor);
    }
  }, 500);

  return (
    <PageWrapper>
      {profile && (
        <MyProfilePanel name={`${profile.firstName} ${profile.lastName}`} email={profile.email} />
      )}
      <PageContentPanel>
        <Container>
          <Row>
            <Col>
              <Heading1>My Orders</Heading1>
            </Col>
          </Row>
          <Row>
            <Col>
              {orders.map(o => (
                <MyAccountOrder
                  key={`order-${o.props.id}`}
                  onClickHeader={handleClickOrder(o.props.id)}
                  isOpen={o.props.id === openOrderId}
                  {...o.props}
                />
              ))}
              <DataLoadedContainer isLoading={isLoading && !hasLoadedAll} refetch={refetch} />
              {orders.length > 0 && <ScrollTrigger onProgress={handleOnScrollProgress} />}
            </Col>
          </Row>
        </Container>
      </PageContentPanel>
    </PageWrapper>
  );
};
