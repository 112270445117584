import { Field } from "formik";

import { BoyGirl } from "../../../../../types/graphql";
import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputRadio } from "../../form-input-radio";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
}

export const FormFieldBoyGirl: React.FC<IProps> = ({ id = "boyGirl", label = "Boy or girl?" }) => (
  <FormFieldWrapper label={label} name={id} required>
    <Field name={id} component={FormikFormInputRadio} label="Boy" value={BoyGirl.BOY} required />
    <Field name={id} component={FormikFormInputRadio} label="Girl" value={BoyGirl.GIRL} required />
  </FormFieldWrapper>
);
