import { Field } from "formik";

import { FormFieldWrapper } from "../../../organisms/form-field-wrapper";
import { FormikFormInputText } from "../form-input-text";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  tooltip?: string;
}

export const FormFieldNotes: React.FC<IProps> = ({
  id = "notes",
  label = "Special notes",
  placeholder = "Enter last name",
  tooltip = "Feel free to mention any special event or characteristic you would like Santa to mention",
}) => (
  <FormFieldWrapper label={label} name={id} tooltip={tooltip}>
    <Field name={id} component={FormikFormInputText} placeholder={placeholder} />
  </FormFieldWrapper>
);
