import { FieldProps } from "formik";
import styled, { StyledComponentPropsWithRef } from "styled-components";

interface IProps {
  isError?: boolean;
}

export const FormInputText = styled.input<IProps>`
  flex: 1;
  min-width: 50px;
  padding: 7px 16px;
  background-color: #ffffff;
  border: 1px solid
    ${(props): string =>
      props.isError ? props.theme.colours.alert : props.theme.colours.form.placeholder};
  border-radius: 6px;
  font-family: ${(props): string => props.theme.fonts.main};
  font-size: ${(props): string => props.theme.fontSizes.size16};

  ::placeholder {
    color: ${(props): string => props.theme.colours.form.placeholder};
  }

  :focus {
    border-color: ${(props): string => props.theme.colours.spotGold};
    outline: none;
  }
`;

export const FormikFormInputText: React.FC<FieldProps & StyledComponentPropsWithRef<"input">> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = (): void => setFieldTouched(field.name);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(field.name, e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const type = typeof props.type !== "undefined" ? props.type : "text";

  const inputProps: React.ComponentProps<typeof FormInputText> = {
    name: field.name,
    onBlur: field.onBlur,
    onFocus: setFieldFocussed,
    isError: isError,
    onChange: handleChange,
    placeholder: props.placeholder,
    value: field.value,
    maxLength: props.maxLength,
    type,
  };

  if (props.autoComplete) {
    inputProps.autoComplete = props.autoComplete;
  }

  return <FormInputText {...inputProps} />;
};
