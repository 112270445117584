import React, { useContext, useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useReactiveVar } from "@apollo/client";

import { getProductLookup, getTemplateLookup } from "../../../model/basket";
import { getLocaleForApi } from "../../../utils/graphql";
import { MainWrapper } from "../../atoms/containers/main-wrapper";
import { Heading2 } from "../../atoms/text";
import { AuthContext } from "../../contexts/auth-context";
import { BackLink } from "../../molecules/back-link";
import { CheckoutBasket } from "../../organisms/checkout-basket";
import { IPromoCode } from "../../../model/checkout";
import { DataLoadedContainer } from "../../control/data-loaded-container";
import { basketItemState } from "../../../model/graphql/cache";

import { CheckoutPayment } from "./checkout-payment";
import { CheckoutSignup } from "./checkout-signup";
import { ProductAlphaId, useCheckoutDetailsLazyQuery } from "../../../types/graphql";

const BackLinkCol = styled(Col)`
  span {
    margin-left: 9px;
    font-size: ${({ theme }): string => theme.fontSizes.size16};
  }
`;

const MainColumn = styled(Col)`
  > {
    :not(:first-child) {
      margin-top: 20px;
    }

    :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const Checkout: React.FC = () => {
  const history = useHistory();
  const basketItems = useReactiveVar(basketItemState);
  const isAuthenticated = useContext(AuthContext);
  const [deduction, setDeduction] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<IPromoCode | undefined>(undefined);

  if (basketItems.length === 0) {
    history.replace("/basket");
  }

  if (
    history.location.pathname !== "/checkout" &&
    typeof isAuthenticated !== "undefined" &&
    !isAuthenticated
  ) {
    history.replace("/checkout");
  }

  const [loadDetails, { data, loading, refetch }] = useCheckoutDetailsLazyQuery({
    variables: {
      alphaIds: basketItems.reduce<ProductAlphaId[]>(
        (acc, i) => [...acc, ...i.addOns, i.productAlphaId],
        [],
      ),
      firstNameIds: [
        ...basketItems
          .filter(b => b.productAlphaId === ProductAlphaId.SANTA_VIDEO_MESSAGE)
          .map<string>(i => i.video?.firstNameId || ""),
      ],
      locale: getLocaleForApi(),
    },
  });

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadDetails();
  }, [isAuthenticated, loadDetails]);

  const handleClickBackToBasket = useCallback((): void => history.push("/basket"), [history]);

  const handleApplyPromoCode = useCallback(
    (code: IPromoCode | undefined, deduction: number): void => {
      setPromoCode(code);
      setDeduction(deduction);
    },
    [setPromoCode, setDeduction],
  );

  return (
    <MainWrapper>
      <Container>
        <Row>
          <BackLinkCol>
            <BackLink label="Back to Basket" onClick={handleClickBackToBasket} />
          </BackLinkCol>
        </Row>
        <Row>
          <Col>
            <Heading2>Secure Checkout</Heading2>
          </Col>
        </Row>

        <Row>
          <MainColumn lg={9}>
            <Switch>
              <Route exact path="/checkout" component={CheckoutSignup} />
              <Route
                exact
                path="/checkout/payment"
                component={useCallback(
                  (): JSX.Element => (
                    <>
                      {data && (
                        <CheckoutPayment
                          promoCode={promoCode}
                          onSetPromoCode={handleApplyPromoCode}
                          items={basketItems}
                          productLookup={getProductLookup(data.products)}
                          deduction={deduction}
                        />
                      )}
                    </>
                  ),
                  [promoCode, handleApplyPromoCode, data, deduction, basketItems],
                )}
              />

              <Redirect to="/checkout" />
            </Switch>
          </MainColumn>

          <Col lg={3}>
            <DataLoadedContainer isLoading={loading} refetch={!data ? refetch : undefined}>
              {data && (
                <CheckoutBasket
                  items={basketItems}
                  productLookup={getProductLookup(data.products)}
                  firstNameLookup={getTemplateLookup(data.firstNames)}
                  deduction={deduction}
                />
              )}
            </DataLoadedContainer>
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  );
};
