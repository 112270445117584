import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelect } from "../../form-input-select";

interface IProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: ISelectOption[];
}

export const FormFieldPs: React.FC<IProps> = ({
  options,
  id = "psId",
  label = "P.S. message",
  placeholder = "Select message",
}) => (
  <FormFieldWrapper label={label}>
    <Field
      name={id}
      placeholder={placeholder}
      component={FormikFormInputSelect}
      options={options}
    />
  </FormFieldWrapper>
);
