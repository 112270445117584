import { ISelectOption } from "@santa/common/lib/utils/form";
import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelect } from "../../form-input-select";

interface IProps {
  id?: string;
  options: ISelectOption[];
}

export const FormFieldFrontDoor: React.FC<IProps> = ({
  options: frontDoors,
  id = "frontDoorId",
}) => (
  <FormFieldWrapper label="Front door colour of child’s house">
    <Field
      name={id}
      placeholder="Select colour"
      component={FormikFormInputSelect}
      options={frontDoors}
    />
  </FormFieldWrapper>
);
