import { getDescription } from "@santa/common/lib/products";
import { ProductAlphaId } from "@santa/common/lib/products/common";
import { UrnResource } from "@santa/common/lib/utils/urn";
import { extractIdFromUrn } from "@santa/common/lib/utils/urn";

export type TemplateLookup = Partial<Record<string, string>>;

interface ITemplates {
  id: string;
  label?: string | null;
}

/**
 * Get a lookup of template ids to labels
 * @param templates Templates to get lookup for
 */
export const getTemplateLookup = (templates: ITemplates[]): TemplateLookup =>
  templates.reduce<TemplateLookup>((a, t) => {
    if (t) {
      const { id, label } = t;
      if (id && label) {
        a[id] = label;
      }
    }

    return a;
  }, {});

export type ProductLookup<T> = Partial<Record<string, T>>;

/**
 * Get a lookup of product alpha ids to product details
 * @param products Products to get lookup for
 */
export const getProductLookup = <T extends { alphaId?: string | null }>(
  products: T[],
): ProductLookup<T> =>
  products.reduce<ProductLookup<T>>((a, p) => {
    if (p.alphaId) {
      a[p.alphaId] = p;
    }

    return a;
  }, {});

interface IPriceBasketItem {
  addOns: (string | null)[];
  productAlphaId: string;
}

/**
 * Get the total price of an item in the basket (item price plus add ons)
 * @param item Basket item to get price for
 * @param productsLookup Lookup of products
 */
export const getBasketItemPrice = (
  item: IPriceBasketItem,
  productsLookup: ProductLookup<{ price?: number | null }>,
): number => {
  const initialPrice = productsLookup[item.productAlphaId]?.price || 0;
  return item.addOns
    .filter((i): i is string => !!i)
    .reduce<number>((t, addOn) => {
      const price = productsLookup[addOn]?.price;
      if (price) {
        t += price;
      }
      return t;
    }, initialPrice);
};

/**
 * Return basket total
 * @param basket list of basket items
 * @param productsLookup Lookup of products
 */
export const getBasketTotal = (
  basket: IPriceBasketItem[],
  productsLookup: ProductLookup<{ price?: number | null }>,
): number => basket.reduce<number>((t, i) => t + getBasketItemPrice(i, productsLookup), 0);

interface IBasketDescriptionLookups {
  letterTemplate: TemplateLookup;
  text: TemplateLookup;
  firstName: TemplateLookup;
  phoneCountryCode: TemplateLookup;
}

type Item = Parameters<typeof getDescription>[0];

export interface IBasketItem {
  productAlphaId: string;
  addOns: (string | null)[];
  letter?: {
    date: string;
    firstName: string;
    templateId: string;
  } | null;
  call?: {
    phoneNumber?: string | null;
    firstName: string;
    lastName: string;
    time: string;
    timezoneUrn: string;
    phoneCountryUrn?: string | null;
  } | null;
  text?: {
    phoneNumber: string;
    firstName: string;
    text1Time: string;
    timezoneUrn: string;
    phoneCountryUrn: string;
  } | null;
  sack?: {
    name: string;
    addressTown: string;
    date: string;
  } | null;
  video?: {
    firstNameId: string;
  } | null;
}

export const getBasketItemDescription = (
  item: IBasketItem,
  lookups: IBasketDescriptionLookups,
): string => {
  const { productAlphaId, letter, call, text, sack, video, addOns } = item;

  const params: Item = {
    productAlphaId,
    addOnProducts: addOns.map(alphaId => ({ alphaId })),
    letter:
      letter && productAlphaId === ProductAlphaId.SANTA_LETTER
        ? {
            template: {
              label: lookups.letterTemplate[letter.templateId] || null,
            },
            firstName: letter.firstName,
            date: letter.date,
          }
        : null,
    call:
      call &&
      (productAlphaId === ProductAlphaId.SANTA_CALL ||
        productAlphaId === ProductAlphaId.SANTA_CALL_VIDEO)
        ? {
            firstName: call.firstName,
            time: call.time,
            ...(call.phoneNumber && { phoneNumber: call.phoneNumber }),
            timezone: {
              timezone: extractIdFromUrn(UrnResource.TIMEZONE, call.timezoneUrn),
            },
            ...(call.phoneCountryUrn && {
              phoneCountry: {
                callingCode: lookups.phoneCountryCode[call.phoneCountryUrn] || "",
              },
            }),
          }
        : null,
    text:
      text && productAlphaId === ProductAlphaId.SANTA_TEXT
        ? {
            firstName: text.firstName,
            text1Time: text.text1Time,
            timezone: {
              timezone: extractIdFromUrn(UrnResource.TIMEZONE, text.timezoneUrn),
            },
            phoneCountry: {
              callingCode: lookups.phoneCountryCode[text.phoneCountryUrn] || "",
            },
            phoneNumber: text.phoneNumber,
          }
        : null,
    sack:
      sack && productAlphaId === ProductAlphaId.SANTA_SACK
        ? {
            name: sack.name,
            addressTown: sack.addressTown,
            date: sack.date,
          }
        : null,
    video:
      video && productAlphaId === ProductAlphaId.SANTA_VIDEO_MESSAGE
        ? {
            firstName: {
              label: lookups.firstName[video.firstNameId] || null,
            },
          }
        : null,
  };

  return getDescription(params);
};
