import { Field } from "formik";

import { FormFieldWrapper } from "../../../../organisms/form-field-wrapper";
import { FormikFormInputSelectSearch } from "../../form-input-select-search";

import { useSearch } from "./hooks/use-data";

interface IProps {
  id?: string;
  required?: boolean;
}

export const FormFieldGift: React.FC<IProps> = ({ id = "gift", required = false }) => {
  const onSearch = useSearch();

  return (
    <FormFieldWrapper label="Most desired gift this Christmas" required={required} name={id}>
      <Field
        name={id}
        placeholder="Select gift"
        component={FormikFormInputSelectSearch}
        loadOptions={onSearch}
        cacheOptions
      />
    </FormFieldWrapper>
  );
};
