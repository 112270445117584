import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { HomeWave } from "../../molecules/home-wave";
import { HomeUspSection } from "../../organisms/home-usp-section";
import { DataLoadedContainer } from "../../control/data-loaded-container";
import { SantaVoiceCallHomeSection } from "../../organisms/home-product-section/santa-voice-call";
import { SantaLetterHomeSection } from "../../organisms/home-product-section/santa-letter";
import { SantaTextHomeSection } from "../../organisms/home-product-section/santa-text";
import { SantaZoomCallHomeSection } from "../../organisms/home-product-section/santa-zoom-call";

import mobileBackground from "./mobile-background.png";
import { useData } from "./use-data";

const Container = styled.div`
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
`;

const MainContentContainer = styled.div`
  @media ${device.mobileS} {
    background-image: url(${mobileBackground});
    background-repeat: no-repeat;
  }
`;

export const Home: React.FC = () => {
  const { loadingProps, data } = useData();

  return (
    <Container>
      <DataLoadedContainer {...loadingProps}>
        {data && (
          <>
            <HomeWave {...data.heroProps} />

            <MainContentContainer>
              <HomeUspSection />
              {data.letter && <SantaLetterHomeSection {...data.letter} align="right" />}
              {data.zoomCall && <SantaZoomCallHomeSection {...data.zoomCall} align="left" />}
              {data.voiceCall && <SantaVoiceCallHomeSection {...data.voiceCall} align="right" />}
              {data.text && <SantaTextHomeSection {...data.text} align="left" />}
            </MainContentContainer>
          </>
        )}
      </DataLoadedContainer>
    </Container>
  );
};
