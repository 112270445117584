import { ApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { logout, IUserProfile, getUser } from "../../model/authentication";

interface IUseProfileData {
  profile: IUserProfile | undefined;
  logout(client: ApolloClient<unknown>): void;
}

export const useProfile = (): IUseProfileData => {
  const [profile, setProfile] = useState<IUserProfile>();

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      setProfile(await getUser());
    };

    if (!profile) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchUser();
    }
  }, [profile]);

  return { profile, logout };
};
