import styled, { css } from "styled-components";

import { AvatarPlaceholder } from "../../atoms/avatar-placeholder";
import { CaretIcon } from "../../atoms/icons/caret-icon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const avatarContainer = css`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
`;

const Initials = styled(AvatarPlaceholder)`
  ${avatarContainer}
`;

const AvatarImageContainer = styled.div`
  ${avatarContainer}

  img {
    width: 38px;
    height: 38px;
  }
`;

const Label = styled.div`
  margin-left: 10px;
  font-size: ${({ theme }): string => theme.fontSizes.size16};
  color: ${({ theme }): string => theme.colours.text};
`;

const DropDown = styled(CaretIcon)`
  height: 8px;
  width: 15px;
  margin-top: 3px;
  margin-left: 10px;
`;

export interface IProps {
  name: string;
  avatarImage?: string;
  avatarInitials?: string;
  onHover?(): void;
}

export const AccountLink: React.FC<IProps> = ({ name, avatarImage, avatarInitials, onHover }) => {
  let avatar: JSX.Element;

  if (typeof avatarImage !== "undefined") {
    avatar = (
      <AvatarImageContainer>
        <img src={avatarImage} alt={name} />
      </AvatarImageContainer>
    );
  } else if (typeof avatarInitials !== "undefined") {
    avatar = <Initials initials={avatarInitials} />;
  } else {
    throw new Error("You must supply an image or initials");
  }

  return (
    <Container onMouseOver={onHover}>
      {avatar}
      <Label data-santa-id="account-widget-name">{name}</Label>
      <DropDown colour="midGrey" />
    </Container>
  );
};
