import { FormikErrors, FormikTouched } from "formik";
import { Col, Row } from "react-grid-system";
import * as yup from "yup";

import { FormFieldAge } from "../../../../molecules/form/form-fields/form-field-age";
import { FormFieldFirstNameFreeText } from "../../../../molecules/form/form-fields/form-field-first-name-free-text";
import { FormFieldLastName } from "../../../../molecules/form/form-fields/form-field-last-name";
import { AddressForm } from "../../../forms/address-form";
import { FormFieldBoyGirl } from "../../../../molecules/form/form-fields/form-field-boy-girl";
import { FormFieldDate } from "../../../../molecules/form/form-fields/form-field-date";
import { earliestLetterSendDate, lastLetterSendDate } from "@santa/common/lib/products/letter";
import { Paragraph } from "../../../../atoms/text";
import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";

export const validationRules = {
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  ageYears: yup
    .number()
    .min(0)
    .max(200, "Are they really that old?!")
    .test(
      "requiredTest",
      "Enter at least one of years or months (y)",
      (value: number | undefined, context): boolean =>
        typeof context.parent.ageMonths !== "undefined" ? true : !!value,
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  ageMonths: yup
    .number()
    .min(0)
    .max(36, "The letter will read better if you use years")
    .test(
      "requiredTest",
      "Enter at least one of years or months (m)",
      (value: number | undefined, context): boolean =>
        typeof context.parent.ageYears !== "undefined" ? true : !!value,
    )
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value,
    ),
  boyGirl: yup.string().nullable().required("Required"),
  addressLine1: yup.string().required("Required"),
  addressLine2: yup.string(),
  addressTown: yup.string().required("Required"),
  addressCounty: yup.string(),
  addressUsStateUrn: yup.string().when("addressCountryUrn", {
    is: (addressCountryUrn: string) => addressCountryUrn === createUrn(UrnResource.COUNTRY, "us"),
    then: yup.string().required(),
  }),
  addressPostcode: yup.string().required("Required"),
  addressCountryUrn: yup.string().required("Required"),
  date: yup.date().required("Required"),
};

export interface IDefaultValuesModel {
  firstName?: string;
  lastName?: string;
  ageYears?: string;
  ageMonths?: string;
  boyGirl?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressTown?: string;
  addressCounty?: string;
  addressUsStateUrn?: string;
  addressPostcode?: string;
  addressCountryUrn?: string;
  date?: Date;
}

interface IProps {
  errors: FormikErrors<IDefaultValuesModel>;
  touched: FormikTouched<IDefaultValuesModel>;
  values: IDefaultValuesModel;
  surchargeNotice?: string;
  onChangeCountry?(urn: string): void;
  setFieldValue(field: keyof IDefaultValuesModel, value: string, shouldValidate?: boolean): void;
}

/**
 * Form section for video message recipient
 */
export const FormSectionLetterRecipient: React.FC<IProps> = ({
  errors,
  touched,
  values,
  surchargeNotice,
  onChangeCountry,
  setFieldValue,
}) => (
  <>
    <Row>
      <Col xl={6}>
        <FormFieldFirstNameFreeText />
      </Col>
      <Col xl={6}>
        <FormFieldLastName />
      </Col>
    </Row>
    <Row>
      <Col xl={6}>
        <FormFieldBoyGirl />
      </Col>
      <Col xl={6}>
        <FormFieldAge errors={errors} touched={touched} />
      </Col>
    </Row>

    <AddressForm
      setFieldValue={setFieldValue}
      countryUrn={values.addressCountryUrn}
      onChangeCountry={onChangeCountry}
      addressIsFilled={!!(values.addressTown && values.addressPostcode)}
    />

    {surchargeNotice && <Paragraph>{surchargeNotice}</Paragraph>}

    <Row>
      <Col xl={12}>
        <FormFieldDate minDate={earliestLetterSendDate()} maxDate={lastLetterSendDate()} />
      </Col>
    </Row>
  </>
);
