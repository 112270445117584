import { config } from "../../config";
import { SiteCountryCode } from "../../types/graphql";

/**
 * Return a price as a string with currency for display purposes
 * @param price Price to display
 */
export const displayPrice = (
  price: number,
  countryCode: SiteCountryCode = config.site.country,
): string =>
  price.toLocaleString(countryCode === SiteCountryCode.GB ? "en-GB" : "en-US", {
    style: "currency",
    currency: countryCode === SiteCountryCode.GB ? "GBP" : "USD",
  });
