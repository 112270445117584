import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import panelDecoration from "../../../images/png/santa-tree-panel-decoration.png";

interface IStyledCompProps {
  shadow: boolean;
}

const ComponentContainer = styled.div<IStyledCompProps>`
  margin-bottom: 23px;
  flex: 1;
  position: relative;
  background-color: ${({ theme }): string => theme.colours.panels.promo.background};
  border-radius: 6px;
  padding: 14px 20px;
  ${({ shadow }): string => (shadow ? "box-shadow: 0px 2px 8px rgba(100, 34, 32, 0.1);" : "")}

  p {
    margin: 0;
  }
`;

const Content = styled.div`
  z-index: 2;
  display: flex;
  color: ${({ theme }): string => theme.colours.text};

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    color: ${({ theme }): string => theme.colours.text};
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Decoration = styled.img`
  width: 148px;
  height: 107px;
  z-index: 1;
  position: absolute;
  bottom: -15px;
  right: 0;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.laptopL} {
    display: block;
  }
`;

interface IProps {
  shadow?: boolean;
  noDecoration?: boolean;
}

export const CallOutPanel: React.FC<IProps> = ({
  shadow = false,
  noDecoration = false,
  children,
}) => (
  <ComponentContainer shadow={shadow}>
    <Content>{children}</Content>
    {!noDecoration && <Decoration src={panelDecoration} alt="Santa and a tree" />}
  </ComponentContainer>
);
