import styled from "styled-components";

import wreathImage from "../../../../images/png/wreath-corner.png";
import { device } from "../../../../utils/media-queries";

const OuterContainer = styled.div`
  background-color: white;
  position: relative;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  border: 10px solid ${({ theme }): string => theme.colours.spotGold};
  box-sizing: border-box;
  padding: 29px 35px;
  z-index: 0;

  h2 {
    font-size: ${({ theme }): string => theme.fontSizes.size24};
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 5px;

    :first-child {
      margin-top: 0;
    }
  }

  p {
    font-size: ${({ theme }): string => theme.fontSizes.size16};
    margin: 5px 0;
  }

  a {
    color: ${({ theme }): string => theme.colours.link};

    &:hover {
      text-decoration: none;
    }
  }
`;

const Wreath = styled.img`
  position: absolute;
  bottom: 0;
  z-index: 10;

  @media ${device.mobileS} {
    width: 112px;
    height: 160px;
    right: -10px;
  }

  @media ${device.laptopL} {
    width: 225px;
    height: 320px;
    right: 0;
  }
`;

export const MapOverlaySection: React.FC = ({ children }) => (
  <OuterContainer>
    <Wreath src={wreathImage} />
    <ContentContainer>{children}</ContentContainer>
  </OuterContainer>
);
