import { makeVar } from "@apollo/client";

import { VideoDetailsValidatedFormModel } from "../../../components/organisms/forms/video/video-details-form";
import { VideoImagesValidatedFormModel } from "../../../components/organisms/forms/video/video-images-form";
import { ProductAlphaId, VideoCreateInput } from "../../../types/graphql";
import { config } from "../../../config";
import { basketState } from ".";
import { setState } from "./utils";

const CACHE_KEY = "state.videoCreate";

interface IVideoCreateState {
  details?: VideoDetailsValidatedFormModel;
  images?: VideoImagesValidatedFormModel;
  addOns?: ProductAlphaId[];
}

export const videoCreateState = makeVar<IVideoCreateState>(
  JSON.parse(localStorage.getItem(CACHE_KEY) || "{}"),
);

interface ICallState {
  setDetails(data: VideoDetailsValidatedFormModel): void;
  setImages(data: VideoImagesValidatedFormModel): void;
  clear(): void;
  setAddOns(addOns: ProductAlphaId[]): void;
  toggleAddOn(addOn: ProductAlphaId): void;
  addToBasket(options?: { skipImages: boolean }): void;
}

export const videoState = (): ICallState => {
  const setVideoState = (value: IVideoCreateState): void =>
    setState(videoCreateState, CACHE_KEY, value);

  return {
    setDetails: details =>
      setVideoState({
        ...videoCreateState(),
        details,
      }),
    setImages: images =>
      setVideoState({
        ...videoCreateState(),
        images,
      }),
    clear: () => setVideoState({}),
    setAddOns: addOns => setVideoState({ ...videoCreateState(), addOns }),
    toggleAddOn: addOn => {
      const existingAddOns = videoCreateState().addOns || [];
      const addOns = existingAddOns.includes(addOn)
        ? existingAddOns.filter(i => i !== addOn)
        : [...existingAddOns, addOn];

      setVideoState({ ...videoCreateState(), addOns });
    },
    addToBasket: ({ skipImages } = { skipImages: false }) => {
      const { images, details } = videoCreateState();

      if (details && images) {
        const video: VideoCreateInput = {
          firstNameId: details.firstName.value,
          ...(details.ageYears && { ageYears: details.ageYears }),
          ...(details.petId && { pet1Id: details.petId }),
          ...(details.frontDoorId && { frontDoorId: details.frontDoorId }),
          ...(details.countryUrn && { countryUrn: details.countryUrn }),
          ...(details.gift?.value && { giftId: details.gift.value }),
          ...(details.hobby?.value && { hobbyId: details.hobby.value }),
          ...(images?.childImageId && !skipImages && { childImageId: images.childImageId }),
          ...(images?.houseImageId && !skipImages && { houseImageId: images.houseImageId }),
        };

        basketState().addToBasket({
          productAlphaId: ProductAlphaId.SANTA_VIDEO_MESSAGE,
          addOns: [],
          video,
        });

        if (config.features.clearVideoAfterBasket) {
          setVideoState({});
        }
      }
    },
  };
};
