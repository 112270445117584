import styled from "styled-components";

import { ReactComponent as EyeImage } from "./eye.svg";

interface IProps {
  colour?: string;
}

export const EyeIcon = styled(EyeImage)<IProps>`
  width: 21px;
  height: 17px;

  path {
    fill: ${({ colour, theme }): string =>
      typeof colour !== "undefined" ? colour : theme.colours.alert};
  }
`;
