import { Col, Row } from "react-grid-system";
import * as yup from "yup";

import { FormFieldImage } from "../../../../molecules/form/form-fields/form-field-image";

export const validationRules = {
  childImageId: yup.string(),
  houseImageId: yup.string(),
};

export interface IFormSectionModel {
  childImageId: string;
  houseImageId: string;
}

interface IProps {
  setFieldValue(field: string, value: string, shouldValidate?: boolean): void;
  values: IFormSectionModel;
}

/**
 * Form section for video message images
 */
export const FormSectionVideoImages: React.FC<IProps> = ({ setFieldValue, values }) => (
  <>
    <Row>
      <Col>
        <FormFieldImage
          label="Upload a photo of the child"
          setFieldValue={setFieldValue}
          id="childImageId"
          tooltip="Santa can show a picture of the Child (to prove he knows them)"
          imageData={values.childImageId}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormFieldImage
          label="Upload a photo of the child's house"
          setFieldValue={setFieldValue}
          id="houseImageId"
          tooltip="Santa can show a picture of the Child’s front door"
          imageData={values.houseImageId}
        />
      </Col>
    </Row>
  </>
);
