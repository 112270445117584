import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { SpanText } from "../text";

import { ReactComponent as Button212DisabledImage } from "./button-212-disabled.svg";
import { ReactComponent as Button212Image } from "./button-212.svg";
import { ReactComponent as Button330DisabledImage } from "./button-330-disabled.svg";
import { ReactComponent as Button330Image } from "./button-330.svg";
import { ReactComponent as Button432DisabledImage } from "./button-432-disabled.svg";
import { ReactComponent as Button432Image } from "./button-432.svg";

const sizes = {
  210: {
    image: <Button212Image />,
    disabledImage: <Button212DisabledImage />,
    mobile: {
      width: 143,
      height: 56,
      imageWidth: 152,
      imageHeight: 65,
    },
    laptop: {
      width: 200,
      height: 78,
      imageWidth: 212,
      imageHeight: 90,
    },
  },
  330: {
    image: <Button330Image />,
    disabledImage: <Button330DisabledImage />,
    mobile: {
      width: 227,
      height: 56,
      imageWidth: 235,
      imageHeight: 64,
    },
    laptop: {
      width: 318,
      height: 78,
      imageWidth: 330,
      imageHeight: 90,
    },
  },
  430: {
    image: <Button432Image />,
    disabledImage: <Button432DisabledImage />,
    mobile: {
      width: 335,
      height: 62,
      imageWidth: 345,
      imageHeight: 73,
    },
    laptop: {
      width: 420,
      height: 78,
      imageWidth: 432,
      imageHeight: 91,
    },
  },
};

interface ISizeProp {
  size: 430 | 330 | 210;
}

interface IDisabledProp {
  disabled?: boolean;
}

type StyledCompProps = ISizeProp & IDisabledProp;

const ButtonContainer = styled.span<StyledCompProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }): string => (disabled ? "not-allowed" : "pointer;")};

  @media ${device.mobileS} {
    width: ${(props): number => sizes[props.size].mobile.width}px;
    height: ${(props): number => sizes[props.size].mobile.height}px;
  }

  @media ${device.laptopL} {
    width: ${(props): number => sizes[props.size].laptop.width}px;
    height: ${(props): number => sizes[props.size].laptop.height}px;
  }

  svg {
    z-index: 0;
    position: absolute;
    left: 0;
    bottom: 0;

    @media ${device.mobileS} {
      width: ${(props): number => sizes[props.size].mobile.imageWidth}px;
      height: ${(props): number => sizes[props.size].mobile.imageHeight}px;
    }

    @media ${device.laptopL} {
      width: ${(props): number => sizes[props.size].laptop.imageWidth}px;
      height: ${(props): number => sizes[props.size].laptop.imageHeight}px;
    }
  }
`;

const Label = styled(SpanText)<StyledCompProps>`
  display: block;
  position: relative;
  z-index: 0.1;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
  ${({ disabled }): string => (disabled ? "opacity: 0.8;" : "")};

  @media ${device.mobileS} {
    font-size: ${(props): string => props.theme.fontSizes.size24};
  }

  @media ${device.laptopL} {
    font-size: ${(props): string => props.theme.fontSizes.size36};
  }
`;

type IProps = ISizeProp &
  IDisabledProp & {
    className?: string;
    onClick(): void;
  };

export const BowButton: React.FC<IProps> = ({
  size,
  disabled = false,
  onClick,
  className,
  children,
}) => (
  <ButtonContainer size={size} disabled={disabled} className={className} onClick={onClick}>
    {disabled ? sizes[size].disabledImage : sizes[size].image}
    <Label size={size} disabled={disabled}>
      {children}
    </Label>
  </ButtonContainer>
);
