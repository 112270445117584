import styled from "styled-components";

import { ReactComponent as Arrow } from "./arrow.svg";

interface IStyleCompProps {
  direction: "left" | "right";
}

export const CarouselArrow = styled(Arrow)<IStyleCompProps>`
  width: 40px;
  height: 22px;
  ${({ direction }): string => (direction === "right" ? "transform: rotate(180deg);" : "")}
`;
