import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { login, signup } from "../../../../model/authentication";
import { Paragraph } from "../../../atoms/text";
import { AuthContext } from "../../../contexts/auth-context";
import { CheckoutPanel } from "../../../organisms/checkout-panel";
import {
  CheckoutSignupForm,
  SignupFormStatus,
  IFormModel,
} from "../../../organisms/forms/checkout-signup";
import { device } from "../../../../utils/media-queries";
import decoration from "../../../../images/png/deer-tree-sprig-reverse.png";
import { IHistoryLocationState } from "../../../app";
import { config } from "../../../../config";
import { useAddToMailingListMutation } from "../../../../types/graphql";

const Container = styled.div`
  padding: 19px 20px;

  @media ${device.laptopL} {
    background-image: url(${decoration});
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  div p {
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 20px;
    }
  }
`;

export const CheckoutSignup: React.FC = () => {
  const history = useHistory<IHistoryLocationState>();
  const isAuthenticated = useContext(AuthContext);

  if (history.location.pathname === "/checkout" && isAuthenticated) {
    history.replace("/checkout/payment");
  }

  const [formError, setFormError] = useState<string>();
  const [userStatus, setUserStatus] = useState<SignupFormStatus>(SignupFormStatus.NEW);

  const [addToMailingList] = useAddToMailingListMutation();

  const handleSubmit = useCallback(
    async (values: IFormModel): Promise<void> => {
      setFormError(undefined);

      try {
        if (values.status === SignupFormStatus.NEW) {
          await signup({
            email: values.email.toLowerCase(),
            password: values.registerPassword,
            passwordConfirm: values.registerPasswordConfirm,
            firstName: values.registerFirstName,
            lastName: values.registerLastName,
          });

          if (values.registerMailingList) {
            try {
              await addToMailingList({
                variables: {
                  email: values.email.toLowerCase(),
                  name: `${values.registerFirstName} ${values.registerLastName}`,
                },
              });
            } catch (error) {
              console.error(`Could not add ${values.email} to mailing list`, error);
            }
          }

          await login(values.email.toLowerCase(), values.registerPassword);
        } else if (values.status === SignupFormStatus.EXISTING) {
          await login(values.email.toLowerCase(), values.loginPassword);
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message === "Incorrect username or password." ||
            error.message === "UserMigration failed with error Bad password."
          ) {
            setFormError("Incorrect email or password");
          } else {
            setFormError(error.message);
          }
        }
      }
    },
    [setFormError, addToMailingList],
  );

  const handleChangeStatus = useCallback(
    (status: SignupFormStatus): void => setUserStatus(status),
    [setUserStatus],
  );

  const handleClickForgotPassword = useCallback(
    (): void =>
      history.push("/forgot-password", { forgotPasswordReturnLocation: history.location.pathname }),
    [history],
  );

  return (
    <>
      <CheckoutPanel
        count={1}
        title={
          userStatus === SignupFormStatus.NEW ? "Create Your Account" : "Log In to Your Account"
        }
        state="current"
      >
        <Container>
          <div>
            <Paragraph>
              Before you continue we need to create a {config.site.name} account for you. (This is
              so you can edit and track your Santa Letters and Santa Calls).
            </Paragraph>
          </div>
          <CheckoutSignupForm
            onSubmit={handleSubmit}
            formError={formError}
            onChangeStatus={handleChangeStatus}
            onClickForgotPassword={handleClickForgotPassword}
          />
        </Container>
      </CheckoutPanel>

      <CheckoutPanel count={2} title="Payment Details" state="todo" />
    </>
  );
};
