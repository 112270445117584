import { Col, Container, Row } from "react-grid-system";

import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { CallEditForm } from "../../..//organisms/forms/call-edit-form";

import { useData } from "./use-data";

export const MyAccountCallEdit: React.FC = () => {
  const { formProps, isLoading, refetch } = useData();

  return (
    <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
      {formProps && (
        <MainWrapper>
          <Container>
            <Row>
              <Col md={10}>
                <MobileBackLink onClick={formProps.onClickBack}>Back</MobileBackLink>

                <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
                  <CallEditForm {...formProps} />
                </DataLoadedContainer>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      )}
    </DataLoadedContainer>
  );
};
