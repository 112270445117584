import styled from "styled-components";

import { CaretIcon } from "../../atoms/icons/caret-icon";
import { Disc } from "../../atoms/disc";

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.span<{ active: boolean }>`
  margin-left: 15px;
  font-size: ${({ theme }): string => theme.fontSizes.size24};
  color: ${({ active, theme }): string =>
    active ? theme.colours.text : theme.colours.textSubdued};
`;

const JustifiedCaret = styled(CaretIcon)`
  margin-left: auto;
`;

interface IProps {
  icon: string;
  showCaret?: boolean;
  active?: boolean;
  className?: string;
}

export const PanelDiscHeading: React.FC<IProps> = ({
  active,
  children,
  icon,
  showCaret,
  className,
}) => (
  <TitleContainer className={className}>
    <Disc active={Boolean(active)}>
      <span>{icon}</span>
    </Disc>
    <Title data-santa-id="panel-disc-heading-title" active={Boolean(active)}>
      {children}
    </Title>
    {showCaret && <JustifiedCaret colour="blueGrey" />}
  </TitleContainer>
);
