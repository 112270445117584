import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { PreviewButton } from "../../../molecules/buttons/preview-button";
import { device } from "../../../../utils/media-queries";
import { BowButton } from "../../../atoms/bow-button";
import { Tree } from "../../../atoms/images/decorations/tree";
import { Heading1, Heading2, Heading3 } from "../../../atoms/text";
import { BaubleList } from "../../../molecules/bauble-list";
import { BowPrice } from "../../../molecules/bow-price";
import { VideoSquareButton } from "../../../molecules/video-square-button";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { LetterCarousel } from "../../../organisms/letter-carousel";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { LetterHomeOption } from "../../../../model/route";
import { Accordion } from "../../../molecules/accordion";

import deer from "./deer.png";
import { useData } from "./use-data";

const ButtonCol = styled(Col)`
  position: relative;

  @media ${device.tablet} {
    height: 1px;
  }
`;

const OverlayTree = styled(Tree)`
  position: absolute;
  z-index: 20;

  @media ${device.mobileS} {
    top: 90px;
    left: -110px;
    width: 180px;
    height: 333px;
  }

  @media ${device.laptopL} {
    top: 0;
    left: -230px;
    width: 389px;
    height: 720px;
  }
`;

const OverlayDeer = styled.img`
  position: absolute;
  z-index: 21;

  @media ${device.mobileS} {
    top: 340px;
    left: -20px;
    width: 114px;
    height: 68px;
  }

  @media ${device.laptopL} {
    top: 520px;
    left: -40px;
    width: 246px;
    height: 147px;
  }
`;

const MainHeading = styled(Heading1)`
  margin-bottom: 0;

  @media ${device.mobileS} {
    margin-top: 0;
  }

  @media ${device.laptopL} {
    margin-top: 44px;
  }
`;

const LetterBowPrice = styled(BowPrice)`
  @media ${device.mobileS} {
    margin-top: 13px;
  }

  @media ${device.laptopL} {
    margin-top: 60px;
  }
`;

const SubHeading = styled(Heading3)`
  margin-bottom: 35px;
  font-weight: normal;

  @media ${device.mobileS} {
    margin-top: 23px;
  }

  @media ${device.laptopL} {
    margin-top: 10px;
  }
`;

const OrderNowBowButton = styled(BowButton)`
  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

const ClearContainer = styled.div`
  background-color: white;
  flex: 1;
  display: flex;
`;

const MoreHeading = styled(Heading2)`
  @media ${device.laptopL} {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
  }
`;

const Buttons = styled.div`
  > span:not(:first-child) {
    margin-top: 25px;
  }
`;

interface IProps {
  enabled: boolean;
  type: LetterHomeOption;
}

export const SantaLetterType: React.FC<IProps> = ({ enabled, type }) => {
  const {
    data,
    isLoading,
    refetch,
    t,
    handleClickOpenVideo,
    handleClickOrderNow,
    handleClickPreview,
    handleClickVideoCall,
    handleClickVideoMessage,
    handleClickCreateWithAddOns,
    shouldShowCarousel,
  } = useData(enabled, type);

  return (
    <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
      {data && (
        <>
          <MainWrapper>
            <Container>
              <Row>
                <Visible md lg xl>
                  <ButtonCol md={5}>
                    <OverlayTree />
                    <OverlayDeer src={deer} alt="Deer" />
                    <VideoSquareButton image={data.image} onClick={handleClickOpenVideo} />
                  </ButtonCol>
                </Visible>
                <Col lg={5} md={4} xs={12}>
                  <MainHeading>{data.title}</MainHeading>
                </Col>
                <Col lg={2} md={3} xs={6}>
                  <LetterBowPrice price={data.price} />
                </Col>
                {enabled && (
                  <Visible xs sm>
                    <Col xs={6}>
                      <OrderNowBowButton size={210} onClick={handleClickOrderNow}>
                        Order Now
                      </OrderNowBowButton>
                    </Col>
                  </Visible>
                )}
              </Row>
              <Row>
                <Col offset={{ md: 5 }}>
                  <SubHeading>{data.description}</SubHeading>
                </Col>
              </Row>
              <Hidden md lg xl>
                <Row>
                  <ButtonCol xs={9} offset={{ xs: 3 }}>
                    <OverlayTree />
                    <VideoSquareButton image={data.image} onClick={handleClickOpenVideo} />
                  </ButtonCol>
                </Row>
              </Hidden>
              <Row>
                <Col md={7} offset={{ md: 5 }}>
                  {enabled && (
                    <>
                      <PreviewButton onClick={handleClickPreview} label="View our templates >" />
                      <BaubleList>
                        <ReactMarkdown source={data.baubleContent} />
                      </BaubleList>
                      <Accordion items={data.faqs} />
                      <BowButton size={430} onClick={handleClickOrderNow}>
                        {t("letter.home.cta.label")}
                      </BowButton>
                    </>
                  )}
                  {!enabled && (
                    <>
                      <BaubleList>
                        <ReactMarkdown source={data.baubleContent} />
                      </BaubleList>
                      <Buttons>
                        <BowButton size={430} onClick={handleClickVideoCall}>
                          Book a Santa Zoom Call
                        </BowButton>
                        <BowButton size={430} onClick={handleClickVideoMessage}>
                          Book a Video Message
                        </BowButton>
                      </Buttons>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </MainWrapper>

          {shouldShowCarousel && (
            <ClearContainer>
              <Container>
                <MoreHeading>Make Santa&apos;s Letter Even More Magical</MoreHeading>
                <LetterCarousel onClickCreate={handleClickCreateWithAddOns} />
              </Container>
            </ClearContainer>
          )}
        </>
      )}
    </DataLoadedContainer>
  );
};
