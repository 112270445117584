import { useEffect } from "react";
import { Form, FormikProps, withFormik } from "formik";
import * as yup from "yup";

// import { earliestLetterSendDate } from "../../../../model/dates";

// import { config } from "../../../../config";
import {
  FormSectionLetterRecipient,
  validationRules,
  IDefaultValuesModel as FormModel,
} from "../../form-sections/letter/form-section-letter-recipient";
import { FormSubmitRow } from "../../../molecules/form-submit-row";

const validationSchema = yup.object(validationRules);
export type LetterSendingValidatedFormModel = yup.Asserts<typeof validationSchema>;

interface IOwnProps {
  defaultValues: FormModel;
  surchargeNotice?: string;
  onChangeCountry(urn: string): void;
  onChange(values: LetterSendingValidatedFormModel): void;
  onSubmit(values: LetterSendingValidatedFormModel): void;
  onClickBack(): void;
  onClickOpenMobilePreview(): void;
}

type Props = FormikProps<FormModel> & IOwnProps;

const C: React.FC<Props> = ({
  values,
  errors,
  touched,
  surchargeNotice,
  onChangeCountry,
  setFieldValue,
  submitForm,
  onClickBack,
  onChange,
}) => {
  // we need to use validationSchema method as Formik's 'isValid' property is not always up to date
  const isValid = validationSchema.isValidSync(values);

  useEffect(() => {
    if (isValid) {
      onChange(validationSchema.cast(values) as LetterSendingValidatedFormModel);
    }
  }, [values, isValid]);

  return (
    <Form>
      <FormSectionLetterRecipient
        touched={touched}
        errors={errors}
        values={values}
        onChangeCountry={onChangeCountry}
        setFieldValue={setFieldValue}
        surchargeNotice={surchargeNotice}
      />

      <FormSubmitRow
        isSubmitEnabled={isValid}
        onClickSubmit={submitForm}
        onClickBack={onClickBack}
      />
    </Form>
  );
};

export const SantaLetterDetailsSendingForm = withFormik<IOwnProps, FormModel>({
  validationSchema,
  mapPropsToValues: props => props.defaultValues,
  validateOnMount: true,
  handleSubmit: (values, formik) =>
    formik.props.onSubmit(validationSchema.cast(values) as LetterSendingValidatedFormModel),
})(C);
