import { Col, Row, Hidden, Visible } from "react-grid-system";
import styled from "styled-components";

import { theme as baseTheme } from "../../../theme";
import { displayPrice } from "../../../utils/price";
import { CrossButton } from "../../molecules/buttons/cross";
import { ProductAddOnList } from "../../molecules/product-add-on-list";
import { device } from "../../../utils/media-queries";

const ProductRow = styled(Row)`
  font-size: ${({ theme }): string => theme.fontSizes.size22};
  color: ${({ theme }): string => theme.colours.text};

  @media ${device.mobileS} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media ${device.laptopL} {
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;

interface IImageContainerProps {
  url: string;
}

const ImageContainer = styled.div<IImageContainerProps>`
  background-image: url(${({ url }): string => url});
  background-size: cover;
  background-position: center center;
  min-width: 48px;
  height: 64px;
  margin-right: 24px;
`;

const InfoCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  > div {
    display: flex;
    flex-direction: column;

    h2 {
      @media ${device.mobileS} {
        font-weight: 600;
        font-size: ${({ theme }): string => theme.fontSizes.size24};
      }

      @media ${device.laptopL} {
        font-weight: normal;
        font-size: ${({ theme }): string => theme.fontSizes.size22};
      }

      margin: 0;
    }

    p {
      margin: 8px 0;
      font-size: ${({ theme }): string => theme.fontSizes.size16};
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 em {
    margin-left: 8px;
    font-style: normal;
    font-weight: 300;
  }
`;

const MobileCrossButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-left: 10px;
  }
`;

const PriceCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const Price = styled.div`
  align-self: flex-end;
`;

const Cross = styled.div`
  align-self: flex-start;
`;

const Border = styled.div`
  background-color: ${({ theme }): string => theme.colours.divider.simple};
  height: 1px;
`;

const MobilePrice = styled.span`
  align-self: flex-end;
`;

export interface IProps {
  title: JSX.Element;
  imageUrl: string;
  subTitle?: string;
  price: number;
  additionalItems?: JSX.Element[];
  onClickRemove?(): void;
}

export const BasketItemRow: React.FC<IProps> = props => {
  const crossButton =
    typeof props.onClickRemove !== "undefined" ? (
      <CrossButton onClick={props.onClickRemove} colour={baseTheme.colours.spotGold} />
    ) : undefined;

  return (
    <>
      <Row>
        <Col>
          <Border />
        </Col>
      </Row>
      <ProductRow>
        <InfoCol sm={10}>
          <ImageContainer url={`${props.imageUrl}?w=48`} />
          <div>
            <TitleContainer>
              <h2>{props.title}</h2>
              <Visible xs>
                <MobileCrossButtonContainer>{crossButton}</MobileCrossButtonContainer>
              </Visible>
            </TitleContainer>
            {typeof props.subTitle !== "undefined" && <p>{props.subTitle}</p>}
            {typeof props.additionalItems !== "undefined" && props.additionalItems.length > 0 && (
              <ProductAddOnList items={props.additionalItems} />
            )}
            <Visible xs>
              <MobilePrice>{displayPrice(props.price)}</MobilePrice>
            </Visible>
          </div>
        </InfoCol>
        <Hidden xs>
          <PriceCol sm={2}>
            <Price>{displayPrice(props.price)}</Price>
            <Cross>{crossButton}</Cross>
          </PriceCol>
        </Hidden>
      </ProductRow>

      <Row>
        <Col>
          <Border data-santa-id="border-bottom" />
        </Col>
      </Row>
    </>
  );
};
