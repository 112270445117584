import { LETTER_CONTENT_FORM_PS_OTHER_ID } from "@santa/common/lib/form-validation/letter";
import { makeVar } from "@apollo/client";
import * as dateFns from "date-fns";

import { LetterSendingValidatedFormModel } from "../../../components/organisms/forms/santa-letter-details-sending";
import { LetterContentValidatedFormModel } from "../../../components/organisms/forms/santa-letter-details-content";
import { BoyGirl, LetterCreateInput, ProductAlphaId } from "../../../types/graphql";
import { config } from "../../../config";

import { basketState } from "./basket";
import { setState } from "./utils";

const CACHE_KEY = "state.letterCreate";

export interface ILetterCreateState {
  recipient?: LetterSendingValidatedFormModel;
  content?: LetterContentValidatedFormModel;
  addOns?: ProductAlphaId[];
}

const loadLetterState = (): ILetterCreateState => {
  const stored = localStorage.getItem(CACHE_KEY);

  if (stored) {
    const data = JSON.parse(stored);

    return {
      ...data,
      ...(data.recipient && {
        recipient: {
          ...data.recipient,
          date: dateFns.parseISO(data.recipient.date),
        },
      }),
    };
  }

  return {};
};

export const letterCreateState = makeVar<ILetterCreateState>(loadLetterState());

interface ILetterCreate {
  setRecipientDetails(data: ILetterCreateState["recipient"]): void;
  setContentDetails(data: ILetterCreateState["content"]): void;
  clear(): void;
  setAddOns(addOns: ProductAlphaId[]): void;
  toggleAddOn(addOn: ProductAlphaId): void;
  addToBasket(withAddOns: boolean, additionalAddOnIds: ProductAlphaId[]): void;
}

export const letterState = (): ILetterCreate => {
  const setLetterState = (value: ILetterCreateState): void =>
    setState(letterCreateState, CACHE_KEY, value);

  return {
    setRecipientDetails: recipient =>
      setLetterState({
        ...letterCreateState(),
        recipient,
      }),
    setContentDetails: content =>
      setLetterState({
        ...letterCreateState(),
        content,
      }),
    clear: () => setLetterState({}),
    setAddOns: addOns => setLetterState({ ...letterCreateState(), addOns }),
    toggleAddOn: addOn => {
      const existingAddOns = letterCreateState().addOns || [];
      const addOns = existingAddOns.includes(addOn)
        ? existingAddOns.filter(i => i !== addOn)
        : [...existingAddOns, addOn];

      setLetterState({ ...letterCreateState(), addOns });
    },
    addToBasket: (withAddOns, additionalAddOnIds) => {
      const { addOns, content, recipient } = letterCreateState();

      if (content && recipient && content.template.value) {
        const letter: LetterCreateInput = {
          date: recipient.date.toISOString(),
          templateId: content.template.value,
          signatureId: content.signatureId,
          firstName: recipient.firstName,
          lastName: recipient.lastName,
          addressLine1: recipient.addressLine1,
          ...(recipient.addressLine2 && { addressLine2: recipient.addressLine2 }),
          addressTown: recipient.addressTown,
          ...(recipient.addressCounty && { addressCounty: recipient.addressCounty }),
          ...(recipient.addressUsStateUrn && { addressUsStateUrn: recipient.addressUsStateUrn }),
          addressPostcode: recipient.addressPostcode,
          addressCountryUrn: recipient.addressCountryUrn,
          boyGirl: recipient.boyGirl as BoyGirl,
          ...(recipient.ageYears && { ageYears: recipient.ageYears }),
          ...(recipient.ageMonths && { ageMonths: recipient.ageMonths }),
          ...(content.hobby && { hobby: content.hobby }),
          ...(content.gift && { gift: content.gift }),
          ...(content.friend && { friend: content.friend }),
          ...(content.frontDoorId && { frontDoorId: content.frontDoorId }),
          ...(content.pet1Id && { pet1Id: content.pet1Id }),
          ...(content.pet1Name && { pet1Name: content.pet1Name }),
          ...(content.pet2Id && { pet2Id: content.pet2Id }),
          ...(content.pet2Name && { pet2Name: content.pet2Name }),
          ...(content.psId &&
            content.psId !== LETTER_CONTENT_FORM_PS_OTHER_ID && { psId: content.psId }),
          ...(content.psCustom &&
            content.psId === LETTER_CONTENT_FORM_PS_OTHER_ID && { psCustom: content.psCustom }),
        };
        basketState().addToBasket({
          productAlphaId: ProductAlphaId.SANTA_LETTER,
          addOns:
            withAddOns && addOns ? [...addOns, ...additionalAddOnIds] : [...additionalAddOnIds],
          letter,
        });

        if (config.features.clearLetterAfterBasket) {
          setLetterState({});
        }
      }
    },
  };
};
