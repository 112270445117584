import styled from "styled-components";

import { Heading2 } from "../text";

import labelImage from "./north-pole-label.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #d0d0d0;
    text-align: center;
  }
`;

const Label = styled.img`
  width: 200px;
  height: 158px;
`;

export const LetterPreviewPlaceholder: React.FC = () => (
  <Container>
    <Label src={labelImage} alt="Mailed from the North Pole" />
    <Heading2>Santa will show you a preview of your letter here</Heading2>
  </Container>
);
