import { FieldProps } from "formik";
import React, { useCallback } from "react";
import styled, { StyledComponentPropsWithRef } from "styled-components";

type Props = StyledComponentPropsWithRef<"input"> & {
  isError?: boolean;
  label: string;
  onSelect(event?: React.ChangeEvent<HTMLInputElement>): void;
};

interface IStyledCompProps {
  checked: boolean;
}

const Input = styled.input`
  position: absolute;
  left: -2000px;
`;

const Label = styled.label<IStyledCompProps>`
  position: relative;
  text-align: center;
  flex: 1;
  color: ${({ checked, theme }): string => (checked ? "white" : theme.colours.form.placeholder)};
  padding: 7px 16px;
  background-color: ${({ checked, theme }): string => (checked ? theme.colours.spotGold : "white")};
  border: 1px solid
    ${({ checked, theme }): string =>
      checked ? theme.colours.spotGold : theme.colours.form.placeholder};
  border-radius: 6px;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: ${({ theme }): string => theme.fontSizes.size16};

  :focus-within {
    border-color: ${({ theme }): string => theme.colours.spotGold};
  }
`;

export const FormInputRadio: React.FC<Props> = ({ onSelect, ...props }) => {
  const onClick = useCallback((): void => onSelect(), [onSelect]);
  const onChange = useCallback((): void => onSelect(), [onSelect]);

  return (
    <Label checked={Boolean(props.checked)} onClick={onClick}>
      <Input {...props} type="radio" onChange={onChange} />
      {props.label}
    </Label>
  );
};

export const FormikFormInputRadio: React.FC<
  FieldProps & StyledComponentPropsWithRef<"input"> & { label: string }
> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  onChange,
  value,
  ...props
}) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);
  const setFieldFocussed = useCallback(
    (): void => setFieldTouched(field.name),
    [setFieldTouched, field.name],
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFieldValue(field.name, value);

      if (onChange) {
        onChange(event);
      }
    },
    [setFieldValue, onChange, field.name, value],
  );
  const type = typeof props.type !== "undefined" ? props.type : "text";

  return (
    <FormInputRadio
      name={field.name}
      onBlur={field.onBlur}
      onFocus={setFieldFocussed}
      isError={isError}
      onSelect={handleChange}
      placeholder={props.placeholder}
      value={value}
      label={props.label}
      type={type}
      onClick={props.onClick}
      checked={value === field.value}
    />
  );
};
