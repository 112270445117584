import { ILetterCreateState } from "./model/graphql/cache";
import { SiteCountryCode } from "./types/graphql";

const CONFIG_VAR_NAME = "SANTA_CONFIG";

export interface IConfig {
  version: string;
  env: "dev" | "staging" | "prod" | "test";
  site: {
    name: string;
    country: SiteCountryCode;
    currency: "GBP" | "USD";
  };
  graphql: {
    endpoint: string;
  };
  rest: {
    endpoint: string;
  };
  cognito: {
    region: string;
    userPoolId: string;
    appClientId: string;
  };
  addressApi: {
    gbKey: string;
    usKey: string;
  };
  calls: {
    displayTimes: {
      startAt: string;
      endAt: string;
    };
  };
  videos: {
    letters: string;
    calls: string;
    texts: string;
    sack: string;
    video: string;
  };
  texts: {
    messageMaxLength: number;
  };
  links: {
    affiliate: string;
  };
  sentry?: {
    dsn: string;
    release: string;
  };
  hotjar?: {
    id: number;
    snippetVersion: number;
  };
  googleMaps: {
    apiKey: string;
  };
  features: {
    clearLetterAfterBasket: boolean;
    clearBasketAfterCheckout: boolean;
    clearCacheAfterLogout: boolean;
    clearCallAfterBasket: boolean;
    clearTextAfterBasket: boolean;
    clearVideoAfterBasket: boolean;
    clearSackAfterBasket: boolean;
  };
  formDefaults: {
    letterSignature: number;
  };
  tracking: {
    google?: {
      accountId: string;
    };
    paidOnResults?: {
      merchantId: number;
    };
    shareASale?: {
      merchantId: number;
    };
    commissionJunction?: {
      actionId: number;
      customerId: number;
    };
  };
  defaultTimezone: string;
  defaultCountry: string;
  letterSample: ILetterCreateState;
  facebook: {
    appId: string;
  };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    SANTA_CONFIG: IConfig;
  }
}

export const config = window[CONFIG_VAR_NAME];
config.version = require("../package.json").version;
