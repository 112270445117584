import styled from "styled-components";

import { device } from "../../../utils/media-queries";
import { Tree } from "../../atoms/images/decorations/tree";

import { ReactComponent as CirclesImage } from "./circles.svg";

interface ISizeProp {
  size: "small" | "large";
}

const Wrapper = styled.div<ISizeProp>`
  position: relative;
  width: ${({ size }): number => (size === "small" ? 155 : 376)}px;
  height: ${({ size }): number => (size === "small" ? 155 : 376)}px;
`;

const Circles = styled(CirclesImage)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const Content = styled.div<ISizeProp>`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  width: ${({ size }): number => (size === "small" ? 146 : 360)}px;
  height: ${({ size }): number => (size === "small" ? 146 : 360)}px;
  border-radius: 50%;
  top: ${({ size }): number => (size === "small" ? 5 : 10)}px;
  left: ${({ size }): number => (size === "small" ? 5 : 11)}px;
  display: flex;
`;

type LeftRight = "left" | "right";

const TreeImageSmall = styled(Tree)<{ align: LeftRight }>`
  z-index: 3;
  position: absolute;

  ${({ align }): string => (align === "left" ? "" : "transform: scaleX(-1);")};
  left: ${({ align }): string => (align === "left" ? "-70px" : "auto")};
  right: ${({ align }): string => (align === "left" ? "auto" : "-70px")};
  top: 2px;
  width: 100px;
  height: 185px;
`;

const TreeImageLarge = styled(Tree)<{ align: LeftRight }>`
  z-index: 3;
  position: absolute;

  ${({ align }): string => (align === "left" ? "" : "transform: scaleX(-1);")};
  left: ${({ align }): string => (align === "left" ? "-70px" : "auto")};
  right: ${({ align }): string => (align === "left" ? "auto" : "-70px")};
  top: 37px;
  width: 135px;
  height: 250px;

  @media ${device.laptopL} {
    left: ${({ align }): string => (align === "left" ? "-142px" : "auto")};
    right: ${({ align }): string => (align === "left" ? "auto" : "-142px")};
    top: 37px;
    width: 237px;
    height: 437px;
  }
`;

interface IProps extends Partial<ISizeProp>, React.HTMLAttributes<HTMLDivElement> {
  tree?: LeftRight;
}

export const CirclesWrapper: React.FC<IProps> = ({ children, tree, size = "large", className }) => (
  <Wrapper size={size} className={className}>
    {tree && size === "small" && <TreeImageSmall align={tree} />}
    {tree && size === "large" && <TreeImageLarge align={tree} />}
    <Circles />
    <Content size={size}>{children}</Content>
  </Wrapper>
);
